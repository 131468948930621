import { classes } from 'classifizer';
import { PropsWithChildren } from 'react';

import styles from './CompilerContentBlock.module.css';

export interface ICompilerContentBlockProps extends PropsWithChildren {
  isEditing?: boolean;
  noNoteHighlight?: boolean;
}

export function CompilerContentBlock({
  isEditing,
  noNoteHighlight,
  children,
}: ICompilerContentBlockProps) {
  return (
    <div
      className={classes(
        'compiler-content-block',
        styles['compiler-content-block'],
        !isEditing && !noNoteHighlight && styles['compiler-content-block_view-mode'],
      )}
    >
      {children}
    </div>
  );
}
