/* eslint-disable max-len */
import * as React from 'react';

import { createIcon, IIconProps, ISVGProps } from '../../internal';

function IconComponent(props: ISVGProps) {
  return (
    <svg
      {...props}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M15.186 6.91335C15.1233 6.836 15.044 6.7737 14.954 6.73101C14.864 6.68832 14.7656 6.66634 14.666 6.66668H13.3327V6.00002C13.3327 5.46958 13.122 4.96087 12.7469 4.5858C12.3718 4.21073 11.8631 4.00002 11.3327 4.00002H7.14602L6.93268 3.33335C6.79438 2.94217 6.53785 2.60368 6.19862 2.36479C5.85939 2.12589 5.45425 1.99842 5.03935 2.00001H2.66602C2.13558 2.00001 1.62687 2.21073 1.2518 2.5858C0.876729 2.96087 0.666016 3.46958 0.666016 4.00002V12C0.666016 12.5304 0.876729 13.0392 1.2518 13.4142C1.62687 13.7893 2.13558 14 2.66602 14H12.266C12.7202 13.9987 13.1603 13.8428 13.5141 13.5581C13.8679 13.2734 14.1143 12.8767 14.2127 12.4333L15.3327 7.48001C15.353 7.38063 15.3503 7.27792 15.3249 7.17972C15.2995 7.08153 15.252 6.99042 15.186 6.91335ZM3.57935 12.1467C3.54548 12.2971 3.46054 12.4311 3.33899 12.5259C3.21744 12.6207 3.06679 12.6704 2.91268 12.6667H2.66602C2.4892 12.6667 2.31964 12.5964 2.19461 12.4714C2.06959 12.3464 1.99935 12.1768 1.99935 12V4.00002C1.99935 3.8232 2.06959 3.65363 2.19461 3.52861C2.31964 3.40359 2.4892 3.33335 2.66602 3.33335H5.03935C5.18473 3.32576 5.32859 3.36597 5.44897 3.44782C5.56935 3.52968 5.65963 3.64869 5.70602 3.78668L6.06602 4.88002C6.1088 5.00718 6.18895 5.11848 6.296 5.19936C6.40304 5.28024 6.532 5.32693 6.66602 5.33335H11.3327C11.5095 5.33335 11.6791 5.40359 11.8041 5.52861C11.9291 5.65363 11.9993 5.8232 11.9993 6.00002V6.66668H5.33268C5.17857 6.66292 5.02792 6.71267 4.90637 6.80748C4.78482 6.90229 4.69988 7.03629 4.66602 7.18668L3.57935 12.1467ZM12.9127 12.1467C12.8788 12.2971 12.7939 12.4311 12.6723 12.5259C12.5508 12.6207 12.4001 12.6704 12.246 12.6667H4.80602C4.84045 12.5924 4.8651 12.514 4.87935 12.4333L5.86602 8.00001H13.866L12.9127 12.1467Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}

IconComponent.displayName = 'FolderOpen';

export const FolderOpen16: React.FunctionComponent<IIconProps> = createIcon(IconComponent);
