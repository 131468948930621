import { createAction } from '../../utils/createAction';

import {
  DESTROY_SYNC,
  INIT_SYNC,
  IOnDeltaReceivedAction,
  ISyncEnableAction,
  ISyncStatusAction,
  IUpdateConflictStateAction,
  ON_DELTA_RECEIVED,
  SET_NO_AUTH,
  SET_SYNC_ENABLE,
  SET_SYNC_STATUS,
  START_SYNC,
  UPDATE_CONFLICT_STATE,
} from './sync.types';

export const initSync = createAction(INIT_SYNC);
export const startSync = createAction(START_SYNC);
export const destroySync = createAction(DESTROY_SYNC);
export const setNoAuthForEventTrain = createAction(SET_NO_AUTH);

export const setSyncStatus = createAction<ISyncStatusAction>(SET_SYNC_STATUS);
export const setSyncEnable = createAction<ISyncEnableAction>(SET_SYNC_ENABLE);
export const actOnDeltaReceived = createAction<IOnDeltaReceivedAction>(ON_DELTA_RECEIVED);

export const updateConflictState = createAction<IUpdateConflictStateAction>(UPDATE_CONFLICT_STATE);
