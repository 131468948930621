import { createAction } from '../../utils/createAction';

import {
  PROJECT_SET,
  PROJECT_CLEAN,
  UPDATE_PROJECT_META,
  IProjectSetAction,
  APPLY_EVENTS,
  IEventsApplyAction,
  INodeSelectAction,
  SELECT_NODE,
  IProjectMetaUpdateAction,
  INNER_APPLY_EVENTS,
  SELECT_SECONDARY_NODE,
  ISecondaryNodeSelectAction,
} from './editor.types';

export const setProject = createAction<IProjectSetAction>(PROJECT_SET);
export const cleanProject = createAction(PROJECT_CLEAN);
export const updateProjectMeta = createAction<IProjectMetaUpdateAction>(UPDATE_PROJECT_META);

export const applyEvents = createAction<IEventsApplyAction>(APPLY_EVENTS);
export const innerApplyEvents = createAction<IEventsApplyAction>(INNER_APPLY_EVENTS);
export const setSelectedNode = createAction<INodeSelectAction>(SELECT_NODE);
export const setSelectedSecondaryNode =
  createAction<ISecondaryNodeSelectAction>(SELECT_SECONDARY_NODE);
