import { createAction } from '../../utils/createAction';

import {
  CLEAR_BREADCRUMBS,
  IBreadcrumbsSetAction,
  ITargetSearchSetAction,
  SET_BREADCRUMBS,
  SET_TARGET_SEARCH,
} from './breadcrumbs.types';

export const setBreadcrumbs = createAction<IBreadcrumbsSetAction>(SET_BREADCRUMBS);
export const clearBreadcrumbs = createAction(CLEAR_BREADCRUMBS);

export const setTargetSearch = createAction<ITargetSearchSetAction>(SET_TARGET_SEARCH);
