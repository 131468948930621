import { openDB as DBOpen } from 'idb';

const INDEXED_DATABASE_NAME = 'Textator_projects';

export const WST_DB_PROJECTS_KEY_OLD = 'projects';
export const WST_DB_PROJECTS_KEY = 'WST_PROJECTS';
export const WST_DB_SYNC_STATE_KEY = 'WST_SYNC_STATE';

export function openDB() {
  return DBOpen(INDEXED_DATABASE_NAME, 3, {
    async upgrade(db) {
      const storeNames = Array.from(db.objectStoreNames);

      if (!storeNames.includes(WST_DB_PROJECTS_KEY)) {
        db.createObjectStore(WST_DB_PROJECTS_KEY, { keyPath: 'id' });
      }

      if (storeNames.includes(WST_DB_PROJECTS_KEY_OLD)) {
        const results = await db.getAll(WST_DB_PROJECTS_KEY_OLD);

        for await (const result of results) {
          await db.put(WST_DB_PROJECTS_KEY, result);
        }

        db.deleteObjectStore(WST_DB_PROJECTS_KEY_OLD);
      }

      if (!storeNames.includes(WST_DB_SYNC_STATE_KEY)) {
        db.createObjectStore(WST_DB_SYNC_STATE_KEY, { keyPath: 'id' });
      }
    },
  });
}

// openDB().then((db) => {
//   const storeNames = Array.from(db.objectStoreNames);
//
//   if (storeNames.includes(WST_DB_PROJECTS_KEY_OLD)) {
//     db.getAll(WST_DB_PROJECTS_KEY_OLD)
//       .then((results) => {
//         return Promise.all(
//           results.map((result) => {
//             return db.put(WST_DB_PROJECTS_KEY, result);
//           }),
//         );
//       })
//       .then(() => {
//         return db.deleteObjectStore(WST_DB_PROJECTS_KEY_OLD);
//       });
//   }
// });
