export const additionalRemoteEvents = [
  {
    eventId: '8a7359bf-33b6-4b3a-836f-7947af3d0003',
    acts: [
      {
        id: 'd25f1720-38f4-4c7a-9905-dfbfc5c00003',
        eventPartition: '1/1',
        time: '1687875455114',
        type: 'NOTE_UPDATE_CONTENT',
        payload: {
          entityId: '4d99a84b-9b21-44c5-9bf6-d1d21e000003',
          content: 'Text at the beginning. Remote changes.',
          hash: '97a2e390113d627de720d8a83fcb3dd6c70f0ff2',
        },
      },
    ],
    time: '1687875455114',
  },
];

export const additionalLocalEvents = [
  {
    eventId: '8a7359bf-33b6-4b3a-836f-7947af3d0003',
    acts: [
      {
        id: 'd25f1720-38f4-4c7a-9905-dfbfc5c00003',
        eventPartition: '1/1',
        time: '1687875455114',
        type: 'NOTE_UPDATE_CONTENT',
        payload: {
          entityId: '4d99a84b-9b21-44c5-9bf6-d1d21e000003',
          content: 'Text at the beginning. Local changes.',
          hash: '97a2e390113d627de720d8a83fcb3dd6c70f0ff2',
        },
      },
    ],
    time: '1687875455114',
  },
];
