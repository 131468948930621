import { AuthContainer } from '../../../components';

import styles from './ActionPanel.module.css';

export function ActionPanel() {
  return (
    <aside className={styles['panel']}>
      <AuthContainer variant="compressed_primary" />
    </aside>
  );
}
