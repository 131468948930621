/* eslint-disable max-len */
import * as React from 'react';

import { createIcon, IIconProps, ISVGProps } from '../../internal';

function IconComponent(props: ISVGProps) {
  return (
    <svg
      {...props}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M16.9997 13.41L12.7097 9.17C12.6167 9.07628 12.5061 9.00188 12.3843 8.95111C12.2624 8.90035 12.1317 8.87421 11.9997 8.87421C11.8677 8.87421 11.737 8.90035 11.6151 8.95111C11.4933 9.00188 11.3827 9.07628 11.2897 9.17L7.0497 13.41C6.95598 13.503 6.88158 13.6136 6.83081 13.7354C6.78004 13.8573 6.75391 13.988 6.75391 14.12C6.75391 14.252 6.78004 14.3827 6.83081 14.5046C6.88158 14.6264 6.95598 14.737 7.0497 14.83C7.23707 15.0163 7.49052 15.1208 7.7547 15.1208C8.01889 15.1208 8.27234 15.0163 8.4597 14.83L11.9997 11.29L15.5397 14.83C15.726 15.0147 15.9774 15.1189 16.2397 15.12C16.3713 15.1208 16.5018 15.0955 16.6236 15.0458C16.7454 14.996 16.8563 14.9227 16.9497 14.83C17.0468 14.7404 17.1251 14.6324 17.1802 14.5123C17.2353 14.3923 17.2661 14.2625 17.2708 14.1304C17.2754 13.9984 17.2539 13.8667 17.2073 13.7431C17.1608 13.6194 17.0902 13.5062 16.9997 13.41Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}

IconComponent.displayName = 'AngleUp';

export const AngleUp24: React.FunctionComponent<IIconProps> = createIcon(IconComponent);
