import { TProjectV1 } from '@ws/shared/types';
import Joi from 'joi';

import { Validator } from '../types';

export class ProjectValidatorV1 extends Validator<TProjectV1> {
  private getCollectionSchema() {
    return Joi.object({
      id: Joi.string().required(),
      parentId: Joi.string().required(),
      type: Joi.string().required().valid('note'),

      name: Joi.string().required(),
      withTitle: Joi.boolean().required(),
      isHidden: Joi.boolean().required(),

      relevantVersion: Joi.number().required(),
      content: Joi.array().items(
        Joi.object({
          version: Joi.number().required(),
          text: Joi.string().required(),
        }),
      ),

      created: Joi.string().required(),
      updated: Joi.string().required(),
    });
  }

  private getNoteSchema() {
    return Joi.object({
      id: Joi.string().required(),
      parentId: Joi.string().required(),
      type: Joi.string().required().valid('collection'),
      root: Joi.boolean().required(),

      name: Joi.string().required(),
      withTitle: Joi.boolean().required(),
      isHidden: Joi.boolean().required(),

      children: Joi.array().items(Joi.string()),

      created: Joi.string().required(),
      updated: Joi.string().required(),
    });
  }

  private getProjectSchema() {
    return Joi.object({
      id: Joi.string().required(),
      created: Joi.string().required(),
      updated: Joi.string().required(),

      root: Joi.object({
        id: Joi.string().required(),
        type: Joi.string().required().valid('collection'),
        name: Joi.string().required(),
        withTitle: Joi.boolean().required(),
        updated: Joi.string().required(),
        children: Joi.array().items(Joi.string()),
      }).required(),

      kit: Joi.array().items(this.getNoteSchema(), this.getCollectionSchema()),
      unbound: Joi.array().items(Joi.string()),
      version: Joi.number(),
    });
  }

  public validate(project: TProjectV1) {
    const projectSchema = this.getProjectSchema();

    const result = projectSchema.validate(project);

    if (result.error) {
      return this.createFailureResponse(this.createProjectValidationMessage(1));
    }

    return this.createSuccessResponse(result.value);
  }
}

export const projectValidatorV1 = new ProjectValidatorV1();
