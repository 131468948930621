import { Button } from '@ws/shared/components';
import { AngleUp20, Close16 } from '@ws/shared/icons';
import { classes } from 'classifizer';

import styles from './WindowTabs.module.css';

interface WindowTabProps {
  className?: string;
  name: string;
  isOpen: boolean;
  onCloseClick(): void;
  onExposeClick(): void;
}

export function WindowTab({
  className,
  name,
  isOpen,
  onCloseClick,
  onExposeClick,
}: WindowTabProps) {
  function handleCloseClick() {
    onCloseClick();
  }

  function handleExposeClick() {
    onExposeClick();
  }

  return (
    <div className={classes(styles['window-tab'], className)}>
      <div className={styles['window-tab__name']}>{name}</div>
      {!isOpen && (
        <Button
          className={classes(styles['window-tab__button'], styles['window-tab__expose-button'])}
          variant="shapeless"
          onClick={handleExposeClick}
          icon={<AngleUp20 />}
        />
      )}
      <Button
        className={classes(styles['window-tab__button'], styles['window-tab__close-button'])}
        variant="shapeless"
        onClick={handleCloseClick}
        icon={<Close16 />}
      />
    </div>
  );
}
