import { ISelectionState } from '../../types';

interface ITextStyleProps {
  newString: string[];
  start: number;
  end: number;
  symbol: string;
}

const isStyleApplied = ({ newString, start, end, symbol }: ITextStyleProps): boolean => {
  const step: number = symbol.length;
  const startOfString: string = newString.slice((step || start) - step, start + step).join('');
  const endOfString: string = newString.slice(end - step, end + step).join('');

  return startOfString.includes(symbol) && endOfString.includes(symbol);
};

const removeTextStyle = ({ newString, start, end, symbol }: ITextStyleProps) => {
  const step: number = symbol.length;
  const startOfString: string = newString
    .slice(start - step, start + step)
    .join('')
    .split(symbol)
    .join('');
  const endOfString: string = newString
    .slice(end - step, end + step)
    .join('')
    .split(symbol)
    .join('');
  newString.splice(end - step, 2 * step, endOfString);
  newString.splice((start || step) - step, (start && 2 * step) || step, startOfString);

  return newString.join('');
};

const addTextStyle = ({ newString, start, end, symbol }: ITextStyleProps) => {
  newString.splice(end, 0, symbol);
  newString.splice(start, 0, symbol);

  return newString.join('');
};

interface IPrepareWordProps {
  value: string;
  selection: ISelectionState;
}

// TODO написать тесты на преобразования
export const prepareWord = (symbol: string, { selection, value }: IPrepareWordProps) => {
  const start: number = selection.selectionStart;
  let end: number = selection.selectionEnd;

  while (value[end - 1] === ' ') {
    end -= 1;
  }

  if (!(start || end) || start === end) {
    return value;
  }

  const newString: Array<string> = value.split('');
  if (isStyleApplied({ newString, start, end, symbol })) {
    return removeTextStyle({ newString, start, end, symbol });
  }
  return addTextStyle({ newString, start, end, symbol });
};
