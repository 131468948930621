import { ReactNode } from 'react';

import styles from './Tree.module.css';

interface ITreeProps {
  children: ReactNode;
}

export function Tree({ children }: ITreeProps) {
  return <ul className={styles['tree']}>{children}</ul>;
}
