/* eslint-disable max-len */
import * as React from 'react';

import { createIcon, IIconProps, ISVGProps } from '../../internal';

function IconComponent(props: ISVGProps) {
  return (
    <svg
      {...props}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.1387 2.19526C15.3991 2.45561 15.3991 2.87772 15.1387 3.13807L8.47206 9.80474C8.21171 10.0651 7.7896 10.0651 7.52925 9.80474L5.52925 7.80474C5.2689 7.54439 5.2689 7.12228 5.52925 6.86193C5.7896 6.60158 6.21171 6.60158 6.47206 6.86193L8.00065 8.39052L14.1959 2.19526C14.4563 1.93491 14.8784 1.93491 15.1387 2.19526Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.33398 2.66671C3.15717 2.66671 2.9876 2.73695 2.86258 2.86197C2.73756 2.98699 2.66732 3.15656 2.66732 3.33337V12.6667C2.66732 12.8435 2.73756 13.0131 2.86258 13.1381C2.9876 13.2631 3.15717 13.3334 3.33398 13.3334H12.6673C12.8441 13.3334 13.0137 13.2631 13.1387 13.1381C13.2637 13.0131 13.334 12.8435 13.334 12.6667V8.00004C13.334 7.63185 13.6325 7.33337 14.0007 7.33337C14.3688 7.33337 14.6673 7.63185 14.6673 8.00004V12.6667C14.6673 13.1971 14.4566 13.7058 14.0815 14.0809C13.7065 14.456 13.1978 14.6667 12.6673 14.6667H3.33398C2.80355 14.6667 2.29484 14.456 1.91977 14.0809C1.5447 13.7058 1.33398 13.1971 1.33398 12.6667V3.33337C1.33398 2.80294 1.5447 2.29423 1.91977 1.91916C2.29484 1.54409 2.80355 1.33337 3.33398 1.33337H10.6673C11.0355 1.33337 11.334 1.63185 11.334 2.00004C11.334 2.36823 11.0355 2.66671 10.6673 2.66671H3.33398Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}

IconComponent.displayName = 'CheckSquare';

export const CheckSquare16: React.FunctionComponent<IIconProps> = createIcon(IconComponent);
