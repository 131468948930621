import { Button, FileUpload, IModalsContextProps, ModalsContext } from '@ws/shared/components';
import { FolderPlus24, Upload24 } from '@ws/shared/icons';
import { TMixedProjectDict, TProject } from '@ws/shared/types';
import { isMobile } from '@ws/shared/utils';
import { Component, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { v4 } from 'uuid';

import { ResolutionContext, IResolutionContextProps } from '../../context';
import { TAppState } from '../../redux';
import * as editorActions from '../../redux/editor/editor.actions';
import { IRemoteState } from '../../redux/remote';
import * as remoteActions from '../../redux/remote/remote.actions';
import { IUserState } from '../../redux/user';
import { getResourceManager } from '../../resources';
import { iDBActService } from '../../resources/iDBAct';
import { iDBProjectService } from '../../resources/iDBProject';
import { handleFileUpload } from '../../utils/handleFileUpload';
import { projectBuilder, fileProcessor } from '../../utils/projectProcessing';
import { getProjectRoute } from '../../utils/routes';

import { Placeholder } from './Placeholder/Placeholder';
import styles from './ProjectBoard.module.css';
import { ProjectCard } from './ProjectCard';
import { showProjectCreationModal } from './ProjectCreationModal';
import { ICreateProjectParams } from './types';

interface IProjectBoardProps extends IResolutionContextProps, IModalsContextProps {
  navigate: NavigateFunction;
  user: IUserState;
  remote: IRemoteState;
  getProjectsByUser: typeof remoteActions.getProjectsByUser;
  setProject: typeof editorActions.setProject;
}

interface IProjectBoardState {
  projects: TProject[];
}

class ProjectBoardComponent extends Component<IProjectBoardProps, IProjectBoardState> {
  constructor(props: IProjectBoardProps) {
    super(props);

    this.state = {
      projects: [],
    };
  }

  componentDidMount() {
    void this.refresh();
  }

  refresh = async () => {
    this.getRemoteProjects();

    const updatedProjects = await iDBProjectService.getAll();
    this.setState({ projects: updatedProjects });
  };

  getRemoteProjects = () => {
    const { user } = this.props;

    if (user.info) {
      this.props.getProjectsByUser({ login: user.info.login });
    }
  };

  makeProjectLocal = async (projectId: string) => {
    const manager = getResourceManager();
    const details = await manager.ProjectService.getDetailsById(projectId);
    if (details.isOk && details.project) {
      await this.addProjects(details.project, details.lastUpdateId);
    }
  };

  removeDataFromDB = async (id: string) => {
    await Promise.all([await iDBProjectService.removeOne(id), await iDBActService.removeOne(id)]);

    const updatedProjects = await iDBProjectService.getAll();
    this.setState({ projects: updatedProjects });
  };

  processParsedData = async (parsedFile: any) => {
    const processedFile = fileProcessor.process(parsedFile);

    if (fileProcessor.hasError(processedFile)) {
      toast.error(processedFile.error);
      return;
    }

    const {
      value: { project, syncState },
    } = processedFile;

    const [wantedProject, wantedSyncState] = await Promise.all([
      iDBProjectService.getOneById(project.id),
      iDBActService.getOneById(project.id),
    ]);

    if (wantedProject && wantedSyncState) {
      // await Promise.all([
      //   iDBProjectService.saveOne(wantedProject),
      //   iDBActService.saveOne(wantedSyncState),
      // ]);
    } else if (syncState) {
      await Promise.all([iDBProjectService.saveOne(project), iDBActService.saveOne(syncState)]);
    }

    this.props.navigate(getProjectRoute(project.id, project.root));
  };

  mixProjects = () => {
    const { projects: locals } = this.state;
    const { projects: remotes } = this.props.remote;

    const localsLen = locals.length;
    const remotesLen = remotes.length;

    const projectsMap: TMixedProjectDict = {};

    for (let i = 0; i < localsLen; i += 1) {
      const { id } = locals[i];

      if (!projectsMap[id]) {
        projectsMap[id] = { remote: null, local: null, id: locals[i].id };
      }
      projectsMap[id].local = locals[i];
    }

    for (let i = 0; i < remotesLen; i += 1) {
      const { id } = remotes[i];

      if (!projectsMap[id]) {
        projectsMap[id] = { remote: null, local: null, id: remotes[i].id };
      }
      projectsMap[id].remote = remotes[i];
    }

    return Object.keys(projectsMap).map((key) => projectsMap[key]);
  };

  createProject = async (params: ICreateProjectParams) => {
    const newProject = projectBuilder.createProject(params);

    if (!params.isWithSync) {
      await this.addProjects(newProject);

      return newProject;
    }

    const eventId = v4();
    const manager = getResourceManager();
    const response = await manager.SyncService.initSync({ project: newProject, eventId });

    if (response.isOk) {
      await this.addProjects(newProject, response.lastUpdateId);
    } else {
      console.error('Cannot create cloud version of the project');
      await this.addProjects(newProject);
    }

    return newProject;
  };

  addProjects = async (project: TProject, lastUpdateId?: string) => {
    await Promise.all([
      iDBProjectService.saveOne(project),
      iDBActService.saveOne(
        projectBuilder.createSyncState({
          id: project.id,
          list: [],
          isSyncEnabled: Boolean(lastUpdateId),
          lastUpdateId,
        }),
      ),
    ]);

    this.setState((prevState) => ({ projects: prevState.projects.concat([project]) }));
  };

  createProjectAndGo = async (params: ICreateProjectParams) => {
    const newProject = await this.createProject(params);

    this.props.setProject({ project: newProject });
    this.props.navigate(getProjectRoute(newProject.id, newProject.root));
  };

  handleCreateProjectClick = () => {
    const { resolution } = this.props.resolutionContext;
    const Modal = showProjectCreationModal(this.createProject, this.createProjectAndGo, resolution);

    this.props.modalsContext.setModal({
      closeAfterAction: true,
      customView: Modal,
    });
  };

  render() {
    const {
      resolutionContext: { resolution },
    } = this.props;
    const mixed = this.mixProjects();
    const mobile = isMobile(resolution);

    return (
      <div className={styles['project-board-shadow']}>
        <div className={styles['project-board']}>
          <div className={styles['header']}>
            <h1 className={styles['header__title']}>Мои проекты</h1>

            <div className={styles['header__controls']}>
              <FileUpload
                className={styles['header__controls__control']}
                color="thirdary"
                icon={<Upload24 />}
                onChange={handleFileUpload(this.processParsedData, toast.error)}
              >
                {mobile ? 'Загрузить' : 'Загрузить файл'}
              </FileUpload>

              <Button
                className={styles['header__controls__control']}
                onClick={this.handleCreateProjectClick}
                icon={<FolderPlus24 color="other_white" />}
              >
                Новый проект
              </Button>
            </div>
          </div>

          {mixed.length > 0 ? (
            <>
              {mixed.map((project) => (
                <ProjectCard
                  key={project.id}
                  project={project}
                  onDelete={this.removeDataFromDB}
                  onMakeLocal={this.makeProjectLocal}
                />
              ))}
            </>
          ) : (
            <Placeholder onCreateProjectClick={this.handleCreateProjectClick} />
          )}
        </div>
      </div>
    );
  }
}

export function ProjectBoardContainer() {
  const resolutionContext = useContext(ResolutionContext);
  const modalsContext = useContext(ModalsContext);
  const { user, remote } = useSelector((store: TAppState) => store);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <ProjectBoardComponent
      user={user}
      remote={remote}
      getProjectsByUser={
        ((...params) =>
          dispatch(
            remoteActions.getProjectsByUser(...params),
          )) as typeof remoteActions.getProjectsByUser
      }
      setProject={
        ((...params) =>
          dispatch(editorActions.setProject(...params))) as typeof editorActions.setProject
      }
      navigate={navigate}
      resolutionContext={resolutionContext}
      modalsContext={modalsContext}
    />
  );
}
