import { TCollection, TEntityMeta, TEntityRef, TNote, TProjectMeta } from '@ws/shared/types';
import { Component, createContext } from 'react';

export interface INewEntityPlaceConfig {
  cursorId: string;
  placeBefore: boolean;
}

export interface INewNoteParams {
  parentId: string;
  name?: string;
  initialText?: string;
  placeConfig?: INewEntityPlaceConfig;
}

export interface INewCollectionParams {
  parentId: string;
  name?: string;
}

export interface IDropParams {
  movableNodeRef: TEntityRef;
  newParentId: string;
  positionBaseRef?: TEntityRef;
  isLast?: boolean;
}

export type TGetNodeByRef = (ref: TEntityRef) => TCollection | TNote | undefined;
export type TGetNodesByRefs = (refs: TEntityRef[]) => (TCollection | TNote | undefined)[];

export interface IEditabilityContext {
  updateProjectMeta: (updatedMeta: Partial<TProjectMeta>) => void;

  getNode: (id: string) => TCollection | TNote | undefined;
  getNodes: (ids: string[]) => (TCollection | TNote | undefined)[];

  getNodeByRef: TGetNodeByRef;
  getNodesByRefs: TGetNodesByRefs;

  getRootNode: () => TCollection;
  getUnboundHolder: () => TCollection;

  selectNode: (ref: TEntityRef) => void;

  updateNodeMeta: (nodeRef: TEntityRef, updatedMeta: Partial<TEntityMeta>) => void;
  updateNoteContent: (id: string, content: string) => void;

  addNewNote: (params: INewNoteParams) => TEntityRef;
  addNewCollection: (params: INewCollectionParams) => TEntityRef;

  copyNote: (note: TNote, name?: string) => TEntityRef;
  mergeNotes: (newNoteName: string, refs: TEntityRef[]) => TEntityRef;

  deleteNote: (id: string) => void;
  deleteCollection: (id: string, isRecursively: boolean) => void;

  handleDrop: (params: IDropParams) => void;
}

export interface IEditabilityContextProps {
  editability: IEditabilityContext;
}

const defaultValue: IEditabilityContext = {
  updateProjectMeta: () => {},

  getNode: () => undefined,
  getNodes: () => [undefined],

  getNodeByRef: () => undefined,
  getNodesByRefs: () => [undefined],

  getRootNode: () => ({}) as TCollection,
  getUnboundHolder: () => ({}) as TCollection,

  selectNode: () => {},

  updateNodeMeta: () => {},
  updateNoteContent: () => {},

  addNewNote: () => ({}) as TEntityRef,
  addNewCollection: () => ({}) as TEntityRef,

  copyNote: () => ({}) as TEntityRef,
  mergeNotes: () => ({}) as TEntityRef,

  deleteNote: () => {},
  deleteCollection: () => {},

  handleDrop: () => {},
};

export const EditabilityContext = createContext(defaultValue);
export const { Provider: EditabilityCtxProvider, Consumer: EditabilityCtxConsumer } =
  EditabilityContext;

export const withEditContext = (Comp: typeof Component) => {
  return function WithEditContext(props: any) {
    return (
      <EditabilityCtxConsumer>
        {(editability) => <Comp editability={editability} {...props} />}
      </EditabilityCtxConsumer>
    );
  };
};
