import { createReducer } from '@reduxjs/toolkit';

import { IAction } from '../../types/redux';

import {
  CLEAR_BREADCRUMBS,
  IBreadcrumbsSetAction,
  IBreadcrumbsState,
  SET_BREADCRUMBS,
  SET_TARGET_SEARCH,
} from './breadcrumbs.types';

const INITIAL_STATE: IBreadcrumbsState = {
  isTargetSearch: true,
  path: [],
};

export const breadcrumbsReducer = createReducer(INITIAL_STATE, (builder) => {
  builder
    .addCase(
      SET_BREADCRUMBS,
      (state: IBreadcrumbsState, action: IAction<IBreadcrumbsSetAction>) => {
        if (action.payload?.path) {
          state.path = action.payload?.path;
        }
      },
    )
    .addCase(CLEAR_BREADCRUMBS, () => {
      return INITIAL_STATE;
    })
    .addCase(
      SET_TARGET_SEARCH,
      (state: IBreadcrumbsState, action: IAction<{ isTargetSearch: boolean }>) => {
        state.isTargetSearch = action.payload?.isTargetSearch || false;
      },
    );
});
