/* eslint-disable max-len */
import * as React from 'react';

import { createIcon, IIconProps, ISVGProps } from '../../internal';

function IconComponent(props: ISVGProps) {
  return (
    <svg
      {...props}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M6.66732 8.66671H2.00065C1.82384 8.66671 1.65427 8.73695 1.52925 8.86197C1.40422 8.98699 1.33398 9.15656 1.33398 9.33337V14C1.33398 14.1769 1.40422 14.3464 1.52925 14.4714C1.65427 14.5965 1.82384 14.6667 2.00065 14.6667H6.66732C6.84413 14.6667 7.0137 14.5965 7.13872 14.4714C7.26375 14.3464 7.33398 14.1769 7.33398 14V9.33337C7.33398 9.15656 7.26375 8.98699 7.13872 8.86197C7.0137 8.73695 6.84413 8.66671 6.66732 8.66671ZM6.00065 13.3334H2.66732V10H6.00065V13.3334ZM14.0007 1.33337H9.33398C9.15717 1.33337 8.9876 1.40361 8.86258 1.52864C8.73756 1.65366 8.66732 1.82323 8.66732 2.00004V6.66671C8.66732 6.84352 8.73756 7.01309 8.86258 7.13811C8.9876 7.26314 9.15717 7.33337 9.33398 7.33337H14.0007C14.1775 7.33337 14.347 7.26314 14.4721 7.13811C14.5971 7.01309 14.6673 6.84352 14.6673 6.66671V2.00004C14.6673 1.82323 14.5971 1.65366 14.4721 1.52864C14.347 1.40361 14.1775 1.33337 14.0007 1.33337ZM13.334 6.00004H10.0007V2.66671H13.334V6.00004ZM14.0007 8.66671H9.33398C9.15717 8.66671 8.9876 8.73695 8.86258 8.86197C8.73756 8.98699 8.66732 9.15656 8.66732 9.33337V14C8.66732 14.1769 8.73756 14.3464 8.86258 14.4714C8.9876 14.5965 9.15717 14.6667 9.33398 14.6667H14.0007C14.1775 14.6667 14.347 14.5965 14.4721 14.4714C14.5971 14.3464 14.6673 14.1769 14.6673 14V9.33337C14.6673 9.15656 14.5971 8.98699 14.4721 8.86197C14.347 8.73695 14.1775 8.66671 14.0007 8.66671ZM13.334 13.3334H10.0007V10H13.334V13.3334ZM6.66732 1.33337H2.00065C1.82384 1.33337 1.65427 1.40361 1.52925 1.52864C1.40422 1.65366 1.33398 1.82323 1.33398 2.00004V6.66671C1.33398 6.84352 1.40422 7.01309 1.52925 7.13811C1.65427 7.26314 1.82384 7.33337 2.00065 7.33337H6.66732C6.84413 7.33337 7.0137 7.26314 7.13872 7.13811C7.26375 7.01309 7.33398 6.84352 7.33398 6.66671V2.00004C7.33398 1.82323 7.26375 1.65366 7.13872 1.52864C7.0137 1.40361 6.84413 1.33337 6.66732 1.33337ZM6.00065 6.00004H2.66732V2.66671H6.00065V6.00004Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}

IconComponent.displayName = 'Apps';

export const Apps16: React.FunctionComponent<IIconProps> = createIcon(IconComponent);
