import { IContextMenuItem } from '@ws/shared/components';
import { AlignCenterV16, List16 } from '@ws/shared/icons';

import { ISelectionState, TOnChange } from '../../types';

import { prepareParagraphText } from './prepareParagraphText';
import { prepareWord } from './prepareWord';

interface ICreateContextItemsProps {
  selection: ISelectionState;
  value: string;
  onChange: TOnChange;
  onSplitNoteAbove(): void;
  onSplitNoteBelow(): void;
}

export function createContextItems({
  selection,
  value,
  onChange,
  onSplitNoteAbove,
  onSplitNoteBelow,
}: ICreateContextItemsProps): IContextMenuItem[] {
  const prepareHandlersParams = { selection, value };

  return [
    {
      leftIcon: 'H1',
      rightIcon: '#',
      label: 'Заголовок',
      onClick: () => onChange(prepareParagraphText('#', prepareHandlersParams)),
    },
    {
      leftIcon: 'H2',
      rightIcon: '##',
      label: 'Заголовок',
      onClick: () => onChange(prepareParagraphText('##', prepareHandlersParams)),
    },
    {
      leftIcon: 'H3',
      rightIcon: '###',
      label: 'Заголовок',
      onClick: () => onChange(prepareParagraphText('###', prepareHandlersParams)),
    },
    { type: 'divider' },
    {
      leftIcon: <b>B</b>,
      rightIcon: '**',
      label: 'Жирный',
      onClick: () => onChange(prepareWord('**', prepareHandlersParams)),
    },
    {
      leftIcon: <i>I</i>,
      rightIcon: '*',
      label: 'Курсив',
      onClick: () => onChange(prepareWord('*', prepareHandlersParams)),
    },
    { type: 'divider' },
    {
      leftIcon: 'Q',
      rightIcon: '>',
      label: 'Цитата',
      onClick: () => onChange(prepareParagraphText('>', prepareHandlersParams)),
    },
    {
      leftIcon: <List16 />,
      rightIcon: '-',
      label: 'Список',
      onClick: () => onChange(prepareParagraphText('-', prepareHandlersParams)),
    },
    { type: 'divider' },
    {
      leftIcon: <AlignCenterV16 />,
      label: 'Разделить заметку',
      children: [
        { label: 'Отделить верхнюю часть', onClick: onSplitNoteAbove },
        { label: 'Отделить нижнюю часть', onClick: onSplitNoteBelow },
      ],
    },
  ];
}
