import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ModalsContextManager } from '@ws/shared/components';
import { Provider as ReduxProvider } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';

import { App } from './components/App';
import { NotificationManager, ResolutionContextManager } from './context';
import { store as reduxStore } from './redux';

import 'react-toastify/dist/ReactToastify.css';
import '@ws/shared/src/styles/index.css';

const queryClient = new QueryClient();

export function Root() {
  return (
    <ReduxProvider store={reduxStore}>
      <QueryClientProvider client={queryClient}>
        <ResolutionContextManager>
          <NotificationManager>
            <ModalsContextManager>
              <App />
              <ToastContainer
                autoClose={3000}
                draggable
                position={toast.POSITION.BOTTOM_RIGHT}
                className="custom-toaster"
              />
            </ModalsContextManager>
          </NotificationManager>
        </ResolutionContextManager>
      </QueryClientProvider>
    </ReduxProvider>
  );
}
