export function getErrorMessage(error: unknown): string {
  if (error instanceof Error) {
    return error.message;
  }

  if (typeof error === 'object' && error !== null && 'toString' in error) {
    return error.toString();
  }

  return String(error);
}
