/* eslint-disable max-len */
import * as React from 'react';

import { createIcon, IIconProps, ISVGProps } from '../../internal';

function IconComponent(props: ISVGProps) {
  return (
    <svg
      {...props}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_767_14494)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.07806 13.5774C6.4035 13.252 6.93114 13.252 7.25657 13.5774L10.0007 16.3215L12.7447 13.5774C13.0702 13.252 13.5978 13.252 13.9232 13.5774C14.2487 13.9028 14.2487 14.4305 13.9232 14.7559L10.5899 18.0892C10.2645 18.4147 9.73683 18.4147 9.4114 18.0892L6.07806 14.7559C5.75263 14.4305 5.75263 13.9028 6.07806 13.5774Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.99935 9.16669C10.4596 9.16669 10.8327 9.53978 10.8327 10V17.5C10.8327 17.9603 10.4596 18.3334 9.99935 18.3334C9.53911 18.3334 9.16602 17.9603 9.16602 17.5V10C9.16602 9.53978 9.53911 9.16669 9.99935 9.16669Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.22265 1.66183C8.35638 1.62106 9.4846 1.83801 10.5224 2.29636C11.5601 2.75471 12.4804 3.44251 13.2138 4.30798C13.8059 5.00661 14.2638 5.80632 14.5667 6.66669L14.999 6.66669C16.0616 6.666 17.0975 7.00385 17.9551 7.63125C18.8129 8.25878 19.4484 9.14333 19.7694 10.1565C20.0904 11.1697 20.0802 12.2588 19.7403 13.2658C19.4004 14.2728 18.7484 15.1453 17.879 15.7567C17.5025 16.0214 16.9827 15.9308 16.718 15.5544C16.4533 15.1779 16.5438 14.6581 16.9203 14.3934C17.4999 13.9858 17.9346 13.4041 18.1612 12.7328C18.3878 12.0615 18.3946 11.3354 18.1806 10.6599C17.9666 9.98445 17.5429 9.39475 16.971 8.9764C16.3992 8.55805 15.7089 8.33281 15.0003 8.33336H13.9497C13.5692 8.33336 13.237 8.07567 13.1425 7.70715C12.9232 6.85247 12.5128 6.05868 11.9424 5.38554C11.3719 4.71239 10.6561 4.17744 9.84898 3.82094C9.04184 3.46445 8.16434 3.29571 7.28255 3.32742C6.40075 3.35913 5.53764 3.59047 4.75819 4.00402C3.97874 4.41757 3.30326 5.00256 2.78261 5.71494C2.26196 6.42732 1.9097 7.24853 1.75235 8.11675C1.59501 8.98498 1.63667 9.87758 1.87421 10.7274C2.11175 11.5772 2.53898 12.362 3.12373 13.0228C3.42873 13.3674 3.39658 13.8941 3.05191 14.1991C2.70725 14.5041 2.1806 14.4719 1.87559 14.1273C1.12377 13.2777 0.574483 12.2686 0.269074 11.1761C-0.0363347 10.0835 -0.0899036 8.93583 0.1124 7.81955C0.314704 6.70326 0.767609 5.64742 1.43702 4.7315C2.10643 3.81558 2.9749 3.06345 3.97705 2.53174C4.9792 2.00003 6.08891 1.7026 7.22265 1.66183Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_767_14494">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

IconComponent.displayName = 'DownloadCloud';

export const DownloadCloud20: React.FunctionComponent<IIconProps> = createIcon(IconComponent);
