import { Sidebar } from '@ws/shared/components';
import { FolderOpen16 } from '@ws/shared/icons';
import { TEntityRef } from '@ws/shared/types';
import { useCallback, useContext, useState, MouseEvent, ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { EditabilityContext } from '../../../context/EditabilityContext';
import { TAppState } from '../../../redux';
import { appendContentWindow } from '../../../redux/contentWindows';
import { setSelectedSecondaryNode } from '../../../redux/editor';
import { isCollection } from '../../../utils/isCollection';

import { DropDivider } from './Divider';
import { RecursiveNodes } from './RecursiveNodes';

interface ITreeProps {
  nodeId: string;
  rootName?: string;
  mainTitleActions?: ReactElement[];
}

export function Tree({ nodeId, mainTitleActions = [] }: ITreeProps) {
  const dispatch = useDispatch();
  const editability = useContext(EditabilityContext);
  const selected = useSelector((state: TAppState) => state.editor.selected);
  const rootCollection = editability.getNode(nodeId);
  const [isOpen, setIsOpen] = useState(true);

  const toggleIsOpen = () => setIsOpen((prevState) => !prevState);

  const handleContentWindowOpen = useCallback(
    (nodeIdToAppend: string) => {
      dispatch(appendContentWindow({ nodeIdToAppend }));
    },
    [dispatch],
  );

  const handleSecondaryBoardOpen = useCallback(
    (secondaryNode: TEntityRef) => {
      dispatch(setSelectedSecondaryNode({ secondarySelected: secondaryNode }));
    },
    [dispatch],
  );

  const handleNodeClick = useCallback(
    (node: TEntityRef) => (event: MouseEvent<HTMLButtonElement>) => {
      if (event.ctrlKey || event.metaKey) {
        handleSecondaryBoardOpen(node);
        return;
      }

      if (event.shiftKey) {
        if (node.type === 'note') {
          handleContentWindowOpen(node.id);
        }
        return;
      }

      editability.selectNode(node);
    },
    [editability, handleContentWindowOpen, handleSecondaryBoardOpen],
  );

  if (!rootCollection) {
    return null;
  }

  const hasChildren = isCollection(rootCollection) && rootCollection.children.length > 0;
  const handleHideButtonClick = (ref: TEntityRef, prevState: boolean) => () => {
    editability.updateNodeMeta(ref, { isHidden: !prevState });
  };
  const handleWithTitleButtonClick = (ref: TEntityRef, prevState: boolean) => () => {
    editability.updateNodeMeta(ref, { withTitle: !prevState });
  };

  return (
    <Sidebar.Tree>
      <Sidebar.TreeNode>
        <Sidebar.TreeNodeRow
          onOpen={toggleIsOpen}
          isOpen={isOpen}
          hasChildren={hasChildren}
          active={selected?.id === rootCollection.id}
        >
          <Sidebar.TreeNodeTitleWrapper>
            <Sidebar.TreeNodeTitle
              icon={<FolderOpen16 />}
              width="83%"
              onClick={handleNodeClick(rootCollection)}
            >
              {rootCollection.meta.name}
            </Sidebar.TreeNodeTitle>

            {mainTitleActions.length > 0 && (
              <Sidebar.TreeNodeTools>{mainTitleActions}</Sidebar.TreeNodeTools>
            )}
          </Sidebar.TreeNodeTitleWrapper>
        </Sidebar.TreeNodeRow>

        {isOpen && hasChildren && (
          <Sidebar.Tree>
            {rootCollection.children.map((item) => (
              <RecursiveNodes
                key={item.id}
                node={item}
                onNodeClick={handleNodeClick}
                onHideButtonClick={handleHideButtonClick}
                onWithTitleClick={handleWithTitleButtonClick}
                depth={1}
              />
            ))}
            <DropDivider parentId={rootCollection.id} positionBaseRef={rootCollection} isLast />
          </Sidebar.Tree>
        )}
      </Sidebar.TreeNode>
    </Sidebar.Tree>
  );
}
