import { ComponentType, createContext, FC } from 'react';

import { defaultValue } from './constants';
import { IModalsContext, IModalsContextProps } from './types';

export const ModalsContext = createContext<IModalsContext>(defaultValue);
export const { Provider: ModalsContextProvider, Consumer: ModalsContextConsumer } = ModalsContext;

export function withModalsContext<Props>(
  Component: ComponentType<IModalsContextProps>,
): FC<Omit<Props, 'modalsContext'>> {
  return function withModalsContextComponent(props) {
    return (
      <ModalsContextConsumer>
        {(modalsContext) => <Component {...props} modalsContext={modalsContext} />}
      </ModalsContextConsumer>
    );
  };
}
