/* eslint-disable max-len */
import * as React from 'react';

import { createIcon, IIconProps, ISVGProps } from '../../internal';

function IconComponent(props: ISVGProps) {
  return (
    <svg
      {...props}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M2.49935 6.66667H17.4993C17.7204 6.66667 17.9323 6.57887 18.0886 6.42259C18.2449 6.26631 18.3327 6.05435 18.3327 5.83333C18.3327 5.61232 18.2449 5.40036 18.0886 5.24408C17.9323 5.0878 17.7204 5 17.4993 5H2.49935C2.27834 5 2.06637 5.0878 1.91009 5.24408C1.75381 5.40036 1.66602 5.61232 1.66602 5.83333C1.66602 6.05435 1.75381 6.26631 1.91009 6.42259C2.06637 6.57887 2.27834 6.66667 2.49935 6.66667ZM17.4993 13.3333H2.49935C2.27834 13.3333 2.06637 13.4211 1.91009 13.5774C1.75381 13.7337 1.66602 13.9457 1.66602 14.1667C1.66602 14.3877 1.75381 14.5996 1.91009 14.7559C2.06637 14.9122 2.27834 15 2.49935 15H17.4993C17.7204 15 17.9323 14.9122 18.0886 14.7559C18.2449 14.5996 18.3327 14.3877 18.3327 14.1667C18.3327 13.9457 18.2449 13.7337 18.0886 13.5774C17.9323 13.4211 17.7204 13.3333 17.4993 13.3333ZM17.4993 9.16667H2.49935C2.27834 9.16667 2.06637 9.25446 1.91009 9.41074C1.75381 9.56702 1.66602 9.77899 1.66602 10C1.66602 10.221 1.75381 10.433 1.91009 10.5893C2.06637 10.7455 2.27834 10.8333 2.49935 10.8333H17.4993C17.7204 10.8333 17.9323 10.7455 18.0886 10.5893C18.2449 10.433 18.3327 10.221 18.3327 10C18.3327 9.77899 18.2449 9.56702 18.0886 9.41074C17.9323 9.25446 17.7204 9.16667 17.4993 9.16667Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}

IconComponent.displayName = 'Bars';

export const Bars20: React.FunctionComponent<IIconProps> = createIcon(IconComponent);
