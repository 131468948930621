import { TSidebarVariant } from '@ws/shared/components';
import { isMobile } from '@ws/shared/utils';
import { useCallback, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { IS_SIDEBAR_OPEN_LS } from '../../constants/localStorage';
import { ResolutionContext } from '../../context';
import { TAppState } from '../../redux';
import { setGlobalIsSidebarOpen } from '../../redux/general/general.actions';

interface IUseSidebarReturn {
  isOpen: boolean;
  variant: TSidebarVariant;
  onOpen(): void;
  onClose(): void;
  toggleSidebar(): void;
  mobileWidth?: string;
}

export function useSidebar(): IUseSidebarReturn {
  const dispatch = useDispatch();
  const { isSidebarOpen } = useSelector((state: TAppState) => state.general);
  const { resolution } = useContext(ResolutionContext);
  const mobile = isMobile(resolution);
  const sidebarVariant = mobile ? 'drawer' : 'standard';
  const mobileWidth = mobile ? '424px' : undefined;

  const handleSidebarClose = useCallback(() => {
    localStorage.setItem(IS_SIDEBAR_OPEN_LS, 'false');
    dispatch(setGlobalIsSidebarOpen({ isSidebarOpen: false }));
  }, [dispatch]);
  const handleSidebarOpen = useCallback(() => {
    localStorage.setItem(IS_SIDEBAR_OPEN_LS, 'true');
    dispatch(setGlobalIsSidebarOpen({ isSidebarOpen: true }));
  }, [dispatch]);
  const toggleSidebar = useCallback(() => {
    const newState = !isSidebarOpen;

    localStorage.setItem(IS_SIDEBAR_OPEN_LS, newState ? 'true' : 'false');
    dispatch(setGlobalIsSidebarOpen({ isSidebarOpen: newState }));
  }, [dispatch, isSidebarOpen]);

  useEffect(() => {
    if (mobile && isSidebarOpen) {
      handleSidebarClose();
    }
    // Close sidebar for first render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isOpen: isSidebarOpen,
    variant: sidebarVariant,
    onOpen: handleSidebarOpen,
    onClose: handleSidebarClose,
    toggleSidebar,
    mobileWidth,
  };
}
