import { Fetcher } from '@ws/shared/utils';

import {
  BUILD_PROJECT_CHECK_ENDPOINT_CONF,
  BUILD_PROJECT_DELETE_BY_ID_ENDPOINT_CONF,
  BUILD_PROJECT_DETAILS_BY_ID_ENDPOINT_CONF,
  PROJECT_GET_SHORT_LIST_ENDPOINT_CONF,
} from './endpoints';
import {
  ICheckIfExistsRequestParams,
  TCheckIfExistsResponse,
  TProjectDeleteResponse,
  TProjectDetailsResponse,
  TProjectListResponse,
} from './types';

export class ProjectRepository {
  private _fetcher: Fetcher;

  constructor(fetcher: Fetcher) {
    this._fetcher = fetcher;
  }

  public getDetailsById(id: string) {
    return this._fetcher.fetch<TProjectDetailsResponse>({
      url: this._fetcher.buildURL(BUILD_PROJECT_DETAILS_BY_ID_ENDPOINT_CONF(id)),
      method: 'GET',
    });
  }

  public deleteById(id: string) {
    return this._fetcher.fetch<TProjectDeleteResponse>({
      url: this._fetcher.buildURL(BUILD_PROJECT_DELETE_BY_ID_ENDPOINT_CONF(id)),
      method: 'DELETE',
    });
  }

  public getList() {
    return this._fetcher.fetch<TProjectListResponse>({
      url: this._fetcher.buildURL(PROJECT_GET_SHORT_LIST_ENDPOINT_CONF),
      method: 'GET',
    });
  }

  public checkIfExists(params: ICheckIfExistsRequestParams) {
    return this._fetcher.fetch<TCheckIfExistsResponse>({
      url: this._fetcher.buildURL(BUILD_PROJECT_CHECK_ENDPOINT_CONF(params.projectId)),
      method: 'GET',
    });
  }
}
