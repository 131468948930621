export function buildQueryString(queryKit: Record<string, any>, arrayOfProps: string[] = []) {
  if (!queryKit) throw new Error('No filters in "paramsFromFilters" function');
  const params: string[] = [];

  Object.keys(queryKit).forEach((key) => {
    let value = queryKit[key];

    if (Array.isArray(value)) {
      value = value.join(',');
    }

    if (arrayOfProps.length === 0 || arrayOfProps.includes(key)) {
      if (typeof value !== 'undefined' && value !== '' && value !== null) {
        params.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
      }
    }
  });

  return params;
}
