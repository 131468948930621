import { Key } from 'react-aria-components';

import { IListOption, TOption } from '../../types';
import { isSectionOption } from '../../utils';

export const findDisabledKeys = (item: IListOption | IListOption[]) => {
  if (Array.isArray(item)) {
    return item.reduce<Key[]>((acc, curr) => (curr.disabled ? [...acc, curr.value] : acc), []);
  }

  return item.disabled ? [item.value] : [];
};

export const getDisabledKeysFromOptions = (options: TOption[]) =>
  options.reduce<Key[]>((acc, curr) => {
    const listItem = isSectionOption(curr) ? curr.children : curr;

    return [...acc, ...findDisabledKeys(listItem)];
  }, []);
