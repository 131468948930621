import { TViewContainerProps } from '../types';

import styles from './NarrowView.module.css';

export function NarrowView({ width, children }: TViewContainerProps) {
  return (
    <aside style={{ width }} className={styles['narrow-sidebar']}>
      {children}
    </aside>
  );
}
