import { TNote } from '@ws/shared/types';

import { NoteCompiler, WriteBoardEditor } from '../../../components';

import { WriteBoardTemplate } from './WriteBoardTemplate';

interface IWriteBoardContainerProps {
  note: TNote;
  isCompiled: boolean;
}

export function WriteBoardContainer({ note, isCompiled }: IWriteBoardContainerProps) {
  return (
    <WriteBoardTemplate
      contentView={
        isCompiled ? <NoteCompiler note={note} standAlone /> : <WriteBoardEditor note={note} />
      }
    />
  );
}
