import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { ContentWindowsKeeper, WindowTabsPanel } from '../../components';
import { Page } from '../../components/Page';
import { TextatorSplashScreen } from '../../components/TextatorSplashScreen';
import { NOT_FOUND, PROJECTS_ROUTE } from '../../constants';
import { EditabilityContextManager } from '../../context/EditabilityContext';
import { TAppState } from '../../redux';
import { setProject, setSelectedNode } from '../../redux/editor';
import { iDBProjectService } from '../../resources/iDBProject';
import { getEntityRef } from '../../utils/getEntityRef';
import { projectBuilder, projectProcessor } from '../../utils/projectProcessing';
import { getProjectRoute } from '../../utils/routes';

import styles from './EditorPage.module.css';
import { PlayGround } from './PlayGround';
import { Sidebar } from './Sidebar';
import { SplitManager } from './SplitManager';

export function EditorPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { projectId, nodeId } = useParams<{ projectId: string; nodeId: string }>();

  const { project, selected } = useSelector((state: TAppState) => state.editor);

  useEffect(() => {
    const check = async () => {
      if (!projectId) {
        navigate(PROJECTS_ROUTE);
        return;
      }

      if (projectId !== project?.id) {
        if (projectId === 'new') {
          const newProject = projectBuilder.createProject({});

          await iDBProjectService.saveOne(newProject);
          dispatch(setProject({ project: newProject }));
          navigate(getProjectRoute(newProject.id, nodeId));

          return;
        }

        const storedProject = await iDBProjectService.getOneById(projectId);
        const processedProject = projectProcessor.process(storedProject);

        if (projectProcessor.hasError(processedProject)) {
          toast.error(`Can't open this project`);
          navigate(NOT_FOUND);
          return;
        }

        const { value: validProject } = processedProject;

        dispatch(setProject({ project: validProject }));
        return;
      }

      if (!project) {
        return;
      }

      if (selected?.id !== nodeId && nodeId) {
        const selectedNode = project.collections[nodeId] || project.notes[nodeId];

        if (selectedNode) {
          dispatch(setSelectedNode({ selected: getEntityRef(selectedNode) }));
        }
      }
    };

    check();
  }, [projectId, nodeId, dispatch, project, selected?.id, navigate]);

  if (!project || !selected || !project[`${selected.type}s`][selected.id]) {
    return <TextatorSplashScreen />;
  }

  return (
    <EditabilityContextManager>
      <Page className={styles['editor-page']}>
        <Sidebar />

        <div className={styles['editor-page__group-wrapper']}>
          <SplitManager>
            <PlayGround />
          </SplitManager>

          <WindowTabsPanel />
          <ContentWindowsKeeper />
        </div>
      </Page>
    </EditabilityContextManager>
  );
}
