import { PropsWithChildren, useMemo } from 'react';

import { DrawerView, NarrowView, StandardView, TViewContainerProps } from '../ViewContainers';
import { SidebarContextProvider } from '../context';
import { DEFAULT_POSITION, DEFAULT_VARIANT } from '../internal/constants';
import { TSidebarPosition, TSidebarVariant } from '../types';

interface IContainerProps extends PropsWithChildren {
  variant?: TSidebarVariant;
  position?: TSidebarPosition;
  isOpen?: boolean;
  onOpen?(): void;
  onClose?(): void;
  width?: string;
}

type TViewContainer = (props: TViewContainerProps) => JSX.Element;

const containerMap: Record<TSidebarVariant, TViewContainer> = {
  standard: StandardView,
  drawer: DrawerView,
  narrow: NarrowView,
};

export function Container({
  children,
  isOpen = false,
  onOpen,
  onClose,
  variant = DEFAULT_VARIANT,
  position = DEFAULT_POSITION,
  width,
}: IContainerProps) {
  const ChildrenContainer = useMemo(() => containerMap[variant], [variant]);

  return (
    <SidebarContextProvider value={{ variant, isOpen, onOpen, onClose }}>
      <ChildrenContainer isOpen={isOpen} onClose={onClose} position={position} width={width}>
        {children}
      </ChildrenContainer>
    </SidebarContextProvider>
  );
}
