/* eslint-disable max-len */
import * as React from 'react';

import { createIcon, IIconProps, ISVGProps } from '../../internal';

function IconComponent(props: ISVGProps) {
  return (
    <svg
      {...props}
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M6.70595 5.99997L9.85595 2.85497C9.9501 2.76082 10.003 2.63312 10.003 2.49997C10.003 2.36682 9.9501 2.23912 9.85595 2.14497C9.76179 2.05082 9.6341 1.99792 9.50095 1.99792C9.36779 1.99792 9.2401 2.05082 9.14595 2.14497L6.00095 5.29497L2.85595 2.14497C2.76179 2.05082 2.6341 1.99792 2.50095 1.99792C2.3678 1.99792 2.2401 2.05082 2.14595 2.14497C2.05179 2.23912 1.9989 2.36682 1.9989 2.49997C1.9989 2.63312 2.05179 2.76082 2.14595 2.85497L5.29595 5.99997L2.14595 9.14497C2.09908 9.19145 2.06188 9.24675 2.0365 9.30768C2.01112 9.36861 1.99805 9.43396 1.99805 9.49997C1.99805 9.56598 2.01112 9.63133 2.0365 9.69226C2.06188 9.75319 2.09908 9.80849 2.14595 9.85497C2.19243 9.90183 2.24773 9.93903 2.30866 9.96442C2.36959 9.9898 2.43494 10.0029 2.50095 10.0029C2.56695 10.0029 2.6323 9.9898 2.69323 9.96442C2.75416 9.93903 2.80946 9.90183 2.85595 9.85497L6.00095 6.70497L9.14595 9.85497C9.19243 9.90183 9.24773 9.93903 9.30866 9.96442C9.36959 9.9898 9.43494 10.0029 9.50095 10.0029C9.56695 10.0029 9.6323 9.9898 9.69323 9.96442C9.75416 9.93903 9.80946 9.90183 9.85595 9.85497C9.90281 9.80849 9.94001 9.75319 9.96539 9.69226C9.99078 9.63133 10.0038 9.56598 10.0038 9.49997C10.0038 9.43396 9.99078 9.36861 9.96539 9.30768C9.94001 9.24675 9.90281 9.19145 9.85595 9.14497L6.70595 5.99997Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}

IconComponent.displayName = 'Close';

export const Close12: React.FunctionComponent<IIconProps> = createIcon(IconComponent);
