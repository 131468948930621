import TextareaAutosize from 'react-autosize-textarea';

import styles from './MarkdownEditor.module.css';

export interface IMarkdownEditor extends Omit<TextareaAutosize.Props, 'onChange'> {
  onChange(content: string): void;
}

// TODO add button handlers. Like shift+b, shift+i and etc
export function MarkdownEditor({
  onChange,
  placeholder = 'Время творить...',
  ...rest
}: IMarkdownEditor) {
  function handleChange(event: React.FormEvent<HTMLTextAreaElement>) {
    const content = (event.target as HTMLTextAreaElement).value;

    if (typeof onChange === 'function') {
      onChange(content);
    }
  }

  return (
    <TextareaAutosize
      id="markdown-editor"
      name="markdown-editor"
      onChange={handleChange}
      className={styles['textarea']}
      placeholder={placeholder}
      {...rest}
    />
  );
}
