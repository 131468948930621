/* eslint-disable max-len */
import * as React from 'react';

import { createIcon, IIconProps, ISVGProps } from '../../internal';

function IconComponent(props: ISVGProps) {
  return (
    <svg
      {...props}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.66602 7.99996C6.66602 7.26358 7.26297 6.66663 7.99935 6.66663C8.73573 6.66663 9.33268 7.26358 9.33268 7.99996C9.33268 8.73634 8.73573 9.33329 7.99935 9.33329C7.26297 9.33329 6.66602 8.73634 6.66602 7.99996Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.66602 3.33333C6.66602 2.59695 7.26297 2 7.99935 2C8.73573 2 9.33268 2.59695 9.33268 3.33333C9.33268 4.06971 8.73573 4.66667 7.99935 4.66667C7.26297 4.66667 6.66602 4.06971 6.66602 3.33333Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.66602 12.6667C6.66602 11.9303 7.26297 11.3334 7.99935 11.3334C8.73573 11.3334 9.33268 11.9303 9.33268 12.6667C9.33268 13.4031 8.73573 14 7.99935 14C7.26297 14 6.66602 13.4031 6.66602 12.6667Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}

IconComponent.displayName = 'MoreVertical';

export const MoreVertical16: React.FunctionComponent<IIconProps> = createIcon(IconComponent);
