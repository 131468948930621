import { call, put, select, takeEvery } from 'typed-redux-saga';

import { iDBProjectService } from '../../resources/iDBProject';
import { IAction } from '../../types/redux';
import { cleanSyncProcess, getEventTrain, getSyncState } from '../../utils/SyncGroup/SyncGroup';
import { startSync } from '../sync/sync.actions';
import { TAppState } from '../types';

import { setSelectedSecondaryNode } from './editor.actions';
import {
  APPLY_EVENTS,
  IEventsApplyAction,
  INNER_APPLY_EVENTS,
  IProjectSetAction,
  PROJECT_CLEAN,
  PROJECT_SET,
} from './editor.types';

function* setProjectEffect(action: IAction<IProjectSetAction>) {
  console.log('setProjectEffect', action);

  const projectId = action.payload?.project.id;
  if (!projectId) {
    return;
  }

  yield* put(startSync());
}

function* applyEventsEffect(action: IAction<IEventsApplyAction>) {
  console.log('applyEventsEffect', action);
  try {
    if (!action.payload) {
      return;
    }

    const { project } = yield* select((state: TAppState) => state.editor);

    if (!project) {
      return;
    }
    const projectId = project.id;

    yield* put({ ...action, type: INNER_APPLY_EVENTS });

    const actualSyncState = yield* call(getSyncState, projectId);
    const eventTrain = yield* call(getEventTrain, projectId);

    if (!actualSyncState?.isSyncEnabled) {
      return;
    }

    eventTrain?.charge(action.payload);
  } catch (error) {
    console.log('Error during applyEventsEffect', error);
  }
}

function* innerApplyEventsEffect(action: IAction<IEventsApplyAction>) {
  console.log('applyEventsEffect', action);
  try {
    if (!action.payload) {
      return;
    }

    const { project } = yield* select((state: TAppState) => state.editor);

    if (!project) {
      return;
    }
    yield* call(iDBProjectService.saveOne, project);
  } catch (error) {
    console.log('Error during applyEventsEffect', error);
  }
}

function* cleanProjectEffect() {
  cleanSyncProcess();

  yield* put(setSelectedSecondaryNode({ secondarySelected: null }));
}

export function* editorSagaWatch() {
  yield* takeEvery(PROJECT_SET, setProjectEffect);
  yield* takeEvery(PROJECT_CLEAN, cleanProjectEffect);
  yield* takeEvery(APPLY_EVENTS, applyEventsEffect);
  yield* takeEvery(INNER_APPLY_EVENTS, innerApplyEventsEffect);
}
