/* eslint-disable max-len */
import * as React from 'react';

import { createIcon, IIconProps, ISVGProps } from '../../internal';

function IconComponent(props: ISVGProps) {
  return (
    <svg
      {...props}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.9219 2.74408C19.2474 3.06951 19.2474 3.59715 18.9219 3.92259L10.5886 12.2559C10.2632 12.5814 9.73553 12.5814 9.41009 12.2559L6.91009 9.75592C6.58466 9.43049 6.58466 8.90285 6.91009 8.57741C7.23553 8.25197 7.76317 8.25197 8.0886 8.57741L9.99935 10.4882L17.7434 2.74408C18.0689 2.41864 18.5965 2.41864 18.9219 2.74408Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.16602 3.33329C3.945 3.33329 3.73304 3.42109 3.57676 3.57737C3.42048 3.73365 3.33268 3.94561 3.33268 4.16663V15.8333C3.33268 16.0543 3.42048 16.2663 3.57676 16.4225C3.73304 16.5788 3.945 16.6666 4.16602 16.6666H15.8327C16.0537 16.6666 16.2657 16.5788 16.4219 16.4225C16.5782 16.2663 16.666 16.0543 16.666 15.8333V9.99996C16.666 9.53972 17.0391 9.16663 17.4993 9.16663C17.9596 9.16663 18.3327 9.53972 18.3327 9.99996V15.8333C18.3327 16.4963 18.0693 17.1322 17.6004 17.6011C17.1316 18.0699 16.4957 18.3333 15.8327 18.3333H4.16602C3.50297 18.3333 2.86709 18.0699 2.39825 17.6011C1.92941 17.1322 1.66602 16.4963 1.66602 15.8333V4.16663C1.66602 3.50358 1.92941 2.8677 2.39825 2.39886C2.86709 1.93002 3.50297 1.66663 4.16602 1.66663H13.3327C13.7929 1.66663 14.166 2.03972 14.166 2.49996C14.166 2.9602 13.7929 3.33329 13.3327 3.33329H4.16602Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}

IconComponent.displayName = 'CheckSquare';

export const CheckSquare20: React.FunctionComponent<IIconProps> = createIcon(IconComponent);
