import { Notifier, TResponse, TWithIsOk } from '@ws/shared/types';
import { Fetcher, getErrorMessage } from '@ws/shared/utils';

import { ProjectRepository } from './Project.repository';
import {
  ICheckIfExistsRequestParams,
  TCheckIfExistsResponse,
  TProjectDeleteResponse,
  TProjectDetailsResponse,
  TProjectListResponse,
} from './types';

export class ProjectService {
  private _repository: ProjectRepository;
  private _notifier: Notifier;

  constructor(fetcher: Fetcher, notifier: Notifier) {
    this._repository = new ProjectRepository(fetcher);
    this._notifier = notifier;
  }

  public async getDetailsById(id: string): Promise<TWithIsOk<Partial<TProjectDetailsResponse>>> {
    let response: TResponse<TProjectDetailsResponse>;

    try {
      response = await this._repository.getDetailsById(id);
    } catch (err) {
      this._notifier.error(getErrorMessage(err));
      return { isOk: false };
    }

    if (response.error) {
      this._notifier.error(response.error.toString());
      return { isOk: false };
    }

    if (!response.data) {
      this._notifier.error('Error during project details request');
      return { isOk: false };
    }

    return { isOk: true, ...response.data };
  }

  public async deleteOneById(id: string): Promise<TWithIsOk<Partial<TProjectDeleteResponse>>> {
    let response: TResponse<TProjectDeleteResponse>;

    try {
      response = await this._repository.deleteById(id);
    } catch (err) {
      this._notifier.error(getErrorMessage(err));
      return { isOk: false };
    }

    if (response.error) {
      this._notifier.error(response.error.toString());
      return { isOk: false };
    }

    if (!response.data) {
      this._notifier.error('Error during project deleting request');
      return { isOk: false };
    }

    return { isOk: true, ...response.data };
  }

  public async getList(): Promise<TWithIsOk<TProjectListResponse>> {
    let response: TResponse<TProjectListResponse>;

    try {
      response = await this._repository.getList();
    } catch (err) {
      this._notifier.error(getErrorMessage(err));
      return { isOk: false, projects: [] };
    }

    if (response.error) {
      this._notifier.error(response.error.toString());
      return { isOk: false, status: response.status, projects: [] };
    }

    if (!response.data) {
      this._notifier.error('Error during project list request');
      return { isOk: false, status: response.status, projects: [] };
    }

    return { isOk: true, status: response.status, projects: response.data.projects };
  }

  public async checkIfExists(
    params: ICheckIfExistsRequestParams,
  ): Promise<TWithIsOk<Partial<TCheckIfExistsResponse>>> {
    let response: TResponse<TCheckIfExistsResponse>;

    try {
      response = await this._repository.checkIfExists(params);
    } catch (err) {
      console.log('check if exists error', err, getErrorMessage(err));
      this._notifier.error(getErrorMessage(err));
      return { isOk: false };
    }

    if (response.error) {
      this._notifier.error(response.error.toString());
      return { isOk: false };
    }

    if (!response.data) {
      this._notifier.error('Error during check if exists request');
      return { isOk: false };
    }

    return { isOk: true, ...response.data };
  }
}
