import { classes } from 'classifizer';
import { ReactNode, useContext } from 'react';

import { sidebarContext } from '../context';

import styles from './HeaderWrapper.module.css';

interface IHeaderWrapperProps {
  children: ReactNode;
}

export function HeaderWrapper({ children }: IHeaderWrapperProps) {
  const { isOpen } = useContext(sidebarContext);

  return (
    <div className={classes(styles['wrapper'], isOpen && styles['wrapper_opened'])}>{children}</div>
  );
}
