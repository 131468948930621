import { ModalsContext } from '@ws/shared/components';
import { TEntityRef, TNote } from '@ws/shared/types';
import { ChangeEvent, useContext, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useList } from 'react-use';

import { ResolutionContext } from '../../../../context';
import { EditabilityContext } from '../../../../context/EditabilityContext';
import { getEntityRef } from '../../../../utils/getEntityRef';
import { getProjectRoute } from '../../../../utils/routes';
import { showMergeNotesModal } from '../MergeNotesModal';

interface ISelectModeManagerChildrenProps {
  isSelectModeActive: boolean;
  onSelectModeToggle: () => void;
  onMergeClick: () => void;
  onCheckboxClick: (item: TNote) => (event: ChangeEvent<HTMLInputElement>) => void;
  checkIfMoreThen: (amount: number) => boolean;
}

interface ISelectModeManagerProps {
  children: (params: ISelectModeManagerChildrenProps) => any;
}

export function SelectModeManager({ children }: ISelectModeManagerProps) {
  const navigate = useNavigate();
  const routerParams = useParams<{ projectId: string }>();

  const { setModal } = useContext(ModalsContext);
  const editability = useContext(EditabilityContext);
  const { resolution } = useContext(ResolutionContext);

  const [isSelectModeActive, setIsSelectModeActive] = useState(false);
  const [list, { push, removeAt, reset }] = useList<TEntityRef>([]);

  useEffect(() => {
    if (!isSelectModeActive) {
      reset();
    }
  }, [isSelectModeActive, reset]);

  const onSelectModeToggle = () => {
    setIsSelectModeActive((prevState) => !prevState);
  };

  const go = (id: string) => {
    if (routerParams.projectId) {
      navigate(getProjectRoute(routerParams.projectId, id));
    }
  };

  const mergeNotes = (text: string) => {
    setIsSelectModeActive(false);
    return editability.mergeNotes(text, list);
  };

  const mergeAndGo = (text: string) => {
    const ref = mergeNotes(text);
    go(ref.id);
  };

  const onMergeClick = () => {
    const Modal = showMergeNotesModal(mergeNotes, mergeAndGo, resolution);
    setModal({
      closeAfterAction: true,
      customView: Modal,
    });
  };

  const onCheckboxClick = (item: TNote) => (event: ChangeEvent<HTMLInputElement>) => {
    const idx = list.findIndex((ref) => ref.id === item.id);
    const isChecked = event.target.checked;
    const isInList = idx !== -1;

    if (isChecked) {
      if (!isInList) {
        push(getEntityRef(item));
      }
    } else if (isInList) {
      removeAt(idx);
    }
  };

  const checkIfMoreThen = (amount: number) => {
    return list.length > amount;
  };

  return children({
    isSelectModeActive,
    onSelectModeToggle,
    onMergeClick,
    onCheckboxClick,
    checkIfMoreThen,
  });
}
