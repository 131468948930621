import { ModalsContext } from '@ws/shared/components';
import { TNote } from '@ws/shared/types';
import { useCallback, useContext, useMemo } from 'react';

import { EditabilityContext } from '../../../context/EditabilityContext';
import { INoteSplitterChildrenParams, ISelectionState, TChildren, TOnChange } from '../types';

import { showSplitModal } from './SplitModal';
import { splitTextInToParts } from './helpers';

interface INoteSplitterExtensionProps {
  note: TNote;
  children: TChildren<INoteSplitterChildrenParams>;
  onChange: TOnChange;
  selection: ISelectionState;
  value: string;
}

export function NoteSplitterExtension({
  children,
  note,
  onChange,
  selection,
  value,
}: INoteSplitterExtensionProps) {
  const modalsContext = useContext(ModalsContext);
  const { addNewNote } = useContext(EditabilityContext);
  const { isInStart, isInEnd } = selection;

  const handleShowSplitModal = useCallback(
    (splitFunc: (text: string) => void) => {
      const Modal = showSplitModal(splitFunc);

      modalsContext.setModal({
        closeAfterAction: true,
        customView: Modal,
      });
    },
    [modalsContext],
  );

  const splitNoteIntoTwoParts = useCallback(
    (toBottom: boolean, placeBefore: boolean, customName: string) => {
      if (!note) {
        return;
      }

      const parts = splitTextInToParts(
        value,
        toBottom ? selection.selectionEnd : selection.selectionStart,
      );

      const newContent = toBottom ? parts.first : parts.second;

      onChange(newContent, { saveImmediately: true });

      addNewNote({
        parentId: note.parentId,
        name: customName,
        initialText: toBottom ? parts.second : parts.first,
        placeConfig: {
          cursorId: note.id,
          placeBefore,
        },
      });
    },
    [note, value, addNewNote, onChange, selection],
  );

  const handleSplitNoteAbove = useCallback(() => {
    if (isInStart || isInEnd) {
      return;
    }

    handleShowSplitModal((name) => splitNoteIntoTwoParts(false, true, name));
  }, [handleShowSplitModal, isInEnd, isInStart, splitNoteIntoTwoParts]);

  const handleSplitNoteBelow = useCallback(() => {
    if (isInStart || isInEnd) {
      return;
    }

    handleShowSplitModal((name) => splitNoteIntoTwoParts(true, false, name));
  }, [handleShowSplitModal, isInEnd, isInStart, splitNoteIntoTwoParts]);

  const params: INoteSplitterChildrenParams = useMemo(
    () => ({
      onSplitNoteAbove: handleSplitNoteAbove,
      onSplitNoteBelow: handleSplitNoteBelow,
    }),
    [handleSplitNoteAbove, handleSplitNoteBelow],
  );

  return children(params);
}
