import { GoogleLogin, GoogleLoginResponse, GoogleLoginResponseOffline } from 'react-google-login';

export interface IGoogleLoginButtonProps {
  clientId: string;
  buttonText: string;
  onSuccess: (response: GoogleLoginResponse | GoogleLoginResponseOffline) => void;
  onFailure: (error: any) => void;
  onRequest: () => void;
}

export function GoogleLoginButton(props: IGoogleLoginButtonProps) {
  const { clientId, buttonText, onSuccess, onFailure, onRequest } = props;

  return (
    <GoogleLogin
      clientId={clientId}
      buttonText={buttonText || 'Login'}
      onSuccess={onSuccess}
      onFailure={onFailure}
      onRequest={onRequest}
      cookiePolicy="single_host_origin"
      isSignedIn={false}
    />
  );
}
