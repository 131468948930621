/* eslint-disable max-len */
import * as React from 'react';

import { createIcon, IIconProps, ISVGProps } from '../../internal';

function IconComponent(props: ISVGProps) {
  return (
    <svg
      {...props}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M8.33268 10.8333H2.49935C2.27834 10.8333 2.06637 10.9211 1.91009 11.0774C1.75381 11.2337 1.66602 11.4456 1.66602 11.6666V17.5C1.66602 17.721 1.75381 17.9329 1.91009 18.0892C2.06637 18.2455 2.27834 18.3333 2.49935 18.3333H8.33268C8.5537 18.3333 8.76566 18.2455 8.92194 18.0892C9.07822 17.9329 9.16602 17.721 9.16602 17.5V11.6666C9.16602 11.4456 9.07822 11.2337 8.92194 11.0774C8.76566 10.9211 8.5537 10.8333 8.33268 10.8333ZM7.49935 16.6666H3.33268V12.5H7.49935V16.6666ZM17.4993 1.66663H11.666C11.445 1.66663 11.233 1.75442 11.0768 1.9107C10.9205 2.06698 10.8327 2.27895 10.8327 2.49996V8.33329C10.8327 8.55431 10.9205 8.76627 11.0768 8.92255C11.233 9.07883 11.445 9.16663 11.666 9.16663H17.4993C17.7204 9.16663 17.9323 9.07883 18.0886 8.92255C18.2449 8.76627 18.3327 8.55431 18.3327 8.33329V2.49996C18.3327 2.27895 18.2449 2.06698 18.0886 1.9107C17.9323 1.75442 17.7204 1.66663 17.4993 1.66663ZM16.666 7.49996H12.4993V3.33329H16.666V7.49996ZM17.4993 10.8333H11.666C11.445 10.8333 11.233 10.9211 11.0768 11.0774C10.9205 11.2337 10.8327 11.4456 10.8327 11.6666V17.5C10.8327 17.721 10.9205 17.9329 11.0768 18.0892C11.233 18.2455 11.445 18.3333 11.666 18.3333H17.4993C17.7204 18.3333 17.9323 18.2455 18.0886 18.0892C18.2449 17.9329 18.3327 17.721 18.3327 17.5V11.6666C18.3327 11.4456 18.2449 11.2337 18.0886 11.0774C17.9323 10.9211 17.7204 10.8333 17.4993 10.8333ZM16.666 16.6666H12.4993V12.5H16.666V16.6666ZM8.33268 1.66663H2.49935C2.27834 1.66663 2.06637 1.75442 1.91009 1.9107C1.75381 2.06698 1.66602 2.27895 1.66602 2.49996V8.33329C1.66602 8.55431 1.75381 8.76627 1.91009 8.92255C2.06637 9.07883 2.27834 9.16663 2.49935 9.16663H8.33268C8.5537 9.16663 8.76566 9.07883 8.92194 8.92255C9.07822 8.76627 9.16602 8.55431 9.16602 8.33329V2.49996C9.16602 2.27895 9.07822 2.06698 8.92194 1.9107C8.76566 1.75442 8.5537 1.66663 8.33268 1.66663ZM7.49935 7.49996H3.33268V3.33329H7.49935V7.49996Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}

IconComponent.displayName = 'Apps';

export const Apps20: React.FunctionComponent<IIconProps> = createIcon(IconComponent);
