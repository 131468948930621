import { Button, IModalsContextProps, ModalsContext } from '@ws/shared/components';
import { ArrowsMerge24, CheckSquare20, Close20, FilePlus24, FolderPlus24 } from '@ws/shared/icons';
import { TCollection, TEntityRef, TNote } from '@ws/shared/types';
import { Component, useContext } from 'react';
import { NavigateFunction, useNavigate, useParams } from 'react-router-dom';

import { CollectionCompiler } from '../../../components';
import { IResolutionContextProps, ResolutionContext } from '../../../context';
import { EditabilityContext, IEditabilityContextProps } from '../../../context/EditabilityContext';
import { isNotDesktop } from '../../../modules/getResolution';
import { TRouterKeys, TRouterParams } from '../../../types/router';
import { getProjectRoute } from '../../../utils/routes';

import styles from './ContentBoard.module.css';
import { showEntityCreationModal } from './EntityCreationModal';
import { ItemCard } from './ItemCard';
import { SelectModeManager } from './SelectModeManager';

interface IContentBoardContainerProps {
  entity: TCollection;
  isCompiled: boolean;
}

interface IContentBoardProps
  extends IModalsContextProps,
    IResolutionContextProps,
    IEditabilityContextProps,
    IContentBoardContainerProps {
  routerParams: TRouterParams;
  navigate: NavigateFunction;
  isCompiled: boolean;
}

interface IContentBoardState {
  collection: TCollection | null;
}

class ContentBoardComponent extends Component<IContentBoardProps, IContentBoardState> {
  constructor(props: IContentBoardProps) {
    super(props);

    this.state = {
      collection: props.entity,
    };
  }

  static getDerivedStateFromProps(nextProps: IContentBoardProps, prevState: IContentBoardState) {
    if (
      !prevState.collection ||
      prevState.collection?.id !== nextProps.entity.id ||
      prevState.collection?.children.length !== nextProps.entity.children.length ||
      prevState.collection?.updatedAt !== nextProps.entity.updatedAt
    ) {
      return {
        collection: nextProps.entity,
      };
    }

    return null;
  }

  createNewNote = (name: string) => {
    const { id } = this.props.entity;
    const noteRef = this.props.editability.addNewNote({ parentId: id, name });

    return noteRef.id;
  };

  handleCreateNoteClick = () => {
    const { resolution } = this.props.resolutionContext;
    const { projectId } = this.props.routerParams;

    const createAndGo = (name: string) => {
      const id = this.createNewNote(name);

      if (projectId) {
        this.props.navigate(getProjectRoute(projectId, id));
      }
    };
    const Modal = showEntityCreationModal('note', this.createNewNote, createAndGo, resolution);

    this.props.modalsContext.setModal({
      closeAfterAction: true,
      customView: Modal,
    });
  };

  createNewCollection = (name: string) => {
    const { id } = this.props.entity;
    const collectionRef = this.props.editability.addNewCollection({ parentId: id, name });

    return collectionRef.id;
  };

  handleCreateCollectionClick = () => {
    const { resolution } = this.props.resolutionContext;
    const { projectId } = this.props.routerParams;

    const createAndGo = (name: string) => {
      const id = this.createNewCollection(name);

      if (projectId) {
        this.props.navigate(getProjectRoute(projectId, id));
      }
    };
    const Modal = showEntityCreationModal(
      'collection',
      this.createNewCollection,
      createAndGo,
      resolution,
    );

    this.props.modalsContext.setModal({
      closeAfterAction: true,
      customView: Modal,
    });
  };

  collectChildren = (list: TEntityRef[]) => {
    const entities = this.props.editability.getNodesByRefs(list);
    return entities.filter(Boolean) as (TNote | TCollection)[];
  };

  render() {
    const { collection } = this.state;
    const { isCompiled } = this.props;
    if (!collection) {
      return null;
    }

    const {
      resolutionContext: { resolution },
      routerParams: { projectId },
    } = this.props;

    const isMobile = isNotDesktop(resolution);
    const colChildren = this.collectChildren(collection.children);

    if (!projectId) {
      return null;
    }

    if (!collection || !collection.id) {
      return <div>Loading</div>;
    }

    return (
      <div className={styles['content-board']}>
        {isCompiled ? (
          <CollectionCompiler collection={collection} />
        ) : (
          <div className={styles['content-board__wrapper']}>
            <SelectModeManager>
              {(selectModeParams) => (
                <>
                  <div className={styles['content-board__controls']}>
                    <Button
                      onClick={selectModeParams.onSelectModeToggle}
                      color="thirdary"
                      icon={selectModeParams.isSelectModeActive ? <Close20 /> : <CheckSquare20 />}
                    >
                      {selectModeParams.isSelectModeActive ? 'Отмена' : 'Выбрать'}
                    </Button>

                    {selectModeParams.isSelectModeActive && (
                      <Button
                        color="primary"
                        onClick={selectModeParams.onMergeClick}
                        disabled={!selectModeParams.checkIfMoreThen(1)}
                        icon={<ArrowsMerge24 />}
                      >
                        {!isMobile && 'Соединить заметки'}
                      </Button>
                    )}

                    {!selectModeParams.isSelectModeActive && (
                      <div className={styles['content-board__creation-buttons']}>
                        <Button onClick={this.handleCreateNoteClick} icon={<FilePlus24 />}>
                          {!isMobile && 'Заметка'}
                        </Button>
                        <Button onClick={this.handleCreateCollectionClick} icon={<FolderPlus24 />}>
                          {!isMobile && 'Коллекция'}
                        </Button>
                      </div>
                    )}
                  </div>

                  <div className={styles['content-board__table']}>
                    {colChildren.map((entity) => (
                      <ItemCard
                        key={entity.id}
                        entity={entity}
                        projectId={projectId}
                        isSelected={selectModeParams.isSelectModeActive}
                        onCheckboxClick={selectModeParams.onCheckboxClick}
                      />
                    ))}
                  </div>
                </>
              )}
            </SelectModeManager>
          </div>
        )}
      </div>
    );
  }
}

export function ContentBoardContainer({ entity, isCompiled }: IContentBoardContainerProps) {
  const modalsContext = useContext(ModalsContext);
  const editability = useContext(EditabilityContext);
  const resolutionContext = useContext(ResolutionContext);
  const navigate = useNavigate();
  const routerParams = useParams<TRouterKeys>();

  return (
    <ContentBoardComponent
      isCompiled={isCompiled}
      entity={entity}
      navigate={navigate}
      routerParams={routerParams}
      modalsContext={modalsContext}
      editability={editability}
      resolutionContext={resolutionContext}
    />
  );
}
