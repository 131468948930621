/* eslint-disable max-len */
import * as React from 'react';

import { createIcon, IIconProps, ISVGProps } from '../../internal';

function IconComponent(props: ISVGProps) {
  return (
    <svg
      {...props}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2189_20900)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.66679 1.99416C10.0273 1.94523 11.3811 2.20558 12.6264 2.7556C13.8717 3.30562 14.976 4.13098 15.8562 5.16954C16.5667 6.0079 17.1162 6.96755 17.4796 7.99999L17.9984 7.99999C19.2735 7.99916 20.5166 8.40459 21.5457 9.15747C22.5751 9.9105 23.3377 10.972 23.7229 12.1878C24.1081 13.4036 24.0959 14.7106 23.688 15.919C23.2801 17.1274 22.4977 18.1744 21.4544 18.908C21.0026 19.2257 20.3789 19.117 20.0612 18.6652C19.7435 18.2134 19.8522 17.5897 20.304 17.272C20.9995 16.7829 21.5211 16.0849 21.793 15.2793C22.0649 14.4737 22.0731 13.6024 21.8163 12.7919C21.5595 11.9813 21.0511 11.2737 20.3648 10.7716C19.6786 10.2696 18.8502 9.99933 18 9.99999H16.7392C16.2826 9.99999 15.8841 9.69077 15.7706 9.24854C15.5074 8.22293 15.015 7.27038 14.3304 6.46261C13.6459 5.65484 12.787 5.01289 11.8184 4.5851C10.8498 4.1573 9.79682 3.95481 8.73867 3.99286C7.68051 4.03092 6.64478 4.30852 5.70944 4.80479C4.7741 5.30105 3.96353 6.00303 3.33874 6.85789C2.71396 7.71275 2.29125 8.6982 2.10243 9.74007C1.91362 10.7819 1.96361 11.8531 2.24866 12.8728C2.53371 13.8926 3.04638 14.8343 3.74808 15.6273C4.11408 16.0409 4.0755 16.6729 3.66191 17.0389C3.24831 17.4049 2.61632 17.3663 2.25032 16.9527C1.34814 15.9332 0.688989 14.7223 0.322499 13.4112C-0.0439923 12.1001 -0.108275 10.723 0.13449 9.38342C0.377255 8.04388 0.92074 6.77686 1.72403 5.67776C2.52732 4.57865 3.56949 3.67611 4.77207 3.03806C5.97465 2.40001 7.30631 2.04308 8.66679 1.99416Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.66602 12.0038C9.2183 12.0038 9.66602 12.4108 9.66602 12.9129V21.0947C9.66602 21.5968 9.2183 22.0038 8.66602 22.0038C8.11373 22.0038 7.66602 21.5968 7.66602 21.0947V12.9129C7.66602 12.4108 8.11373 12.0038 8.66602 12.0038Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.1908 15.8758C11.8983 16.1545 11.4241 16.1545 11.1316 15.8758L8.66536 13.5258L6.19912 15.8758C5.90664 16.1545 5.43242 16.1545 5.13994 15.8758C4.84745 15.5971 4.84745 15.1453 5.13994 14.8666L8.13577 12.0119C8.42826 11.7332 8.90247 11.7332 9.19496 12.0119L12.1908 14.8666C12.4833 15.1453 12.4833 15.5971 12.1908 15.8758Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.0605 11.803C15.6128 11.803 16.0605 12.21 16.0605 12.7121V20.8939C16.0605 21.396 15.6128 21.803 15.0605 21.803C14.5083 21.803 14.0605 21.396 14.0605 20.8939V12.7121C14.0605 12.21 14.5083 11.803 15.0605 11.803Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.5338 17.9309C11.8263 17.6522 12.3005 17.6522 12.593 17.9309L15.0592 20.2809L17.5255 17.9309C17.818 17.6522 18.2922 17.6522 18.5847 17.9309C18.8772 18.2096 18.8772 18.6615 18.5847 18.9402L15.5888 21.7948C15.2964 22.0735 14.8221 22.0735 14.5297 21.7948L11.5338 18.9402C11.2413 18.6615 11.2413 18.2096 11.5338 17.9309Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_2189_20900">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

IconComponent.displayName = 'CloudArrows';

export const CloudArrows24: React.FunctionComponent<IIconProps> = createIcon(IconComponent);
