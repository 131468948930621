import { TSyncState } from '@ws/shared/types';

import { openDB, WST_DB_SYNC_STATE_KEY } from '../../modules';

const DB_ACCESS_LEVEL = 'readwrite';

export class iDBActService {
  public static saveOne(syncState: TSyncState, afterSave?: () => void) {
    return openDB()
      .then((db) => {
        const transaction = db.transaction(WST_DB_SYNC_STATE_KEY, DB_ACCESS_LEVEL);
        const store = transaction.objectStore(WST_DB_SYNC_STATE_KEY);

        store.put(syncState);

        return transaction.commit();
      })
      .then((res) => {
        if (typeof afterSave === 'function') {
          afterSave();
        }

        return res;
      });
  }

  public static getOneById(id: string, callback?: (syncState?: TSyncState) => void) {
    return openDB()
      .then((db) => {
        const transaction = db.transaction([WST_DB_SYNC_STATE_KEY], DB_ACCESS_LEVEL);
        const store = transaction.objectStore(WST_DB_SYNC_STATE_KEY);

        return store.get(id) as unknown as TSyncState | undefined;
      })
      .then((res) => {
        if (typeof callback === 'function') {
          callback(res);
        }

        return res as TSyncState | undefined;
      });
  }

  public static removeOne(id: string, callback?: (res?: any) => void) {
    return openDB()
      .then((db) => {
        const transaction = db.transaction([WST_DB_SYNC_STATE_KEY], DB_ACCESS_LEVEL);
        const store = transaction.objectStore(WST_DB_SYNC_STATE_KEY);

        return store.delete(id);
      })
      .then((res) => {
        if (typeof callback === 'function') {
          callback(res);
        }

        return res;
      });
  }
}
