import { Button, ModalsContext } from '@ws/shared/components';
import {
  DownloadCloud20,
  FolderOpen24,
  Download20,
  Trash20,
  CloudArrows20,
} from '@ws/shared/icons';
import { TMixedProject, TProject, TShortProject } from '@ws/shared/types';
import { ComponentType, FC, useCallback, useContext, useMemo } from 'react';
import { toast } from 'react-toastify';

import { iDBActService } from '../../../resources/iDBAct';
import { iDBProjectService } from '../../../resources/iDBProject';
import { packToFile } from '../../../utils/packToFile';
import { EntityCard } from '../../EntityCard';

interface IProjectEntityCardProps {
  project: TMixedProject;

  onDelete(id: string): void;

  onMakeLocal(id: string): void;
}

export const ProjectCard = projectsExistChecker(
  ({ project, isWithRemote, onDelete }) => {
    const { id } = project;
    const { setModal } = useContext(ModalsContext);
    const handleDelete = useCallback(() => {
      setModal({
        title: 'Удаление проекта',
        subTitle: `Вы действительно хотите удалить "${project.meta.name}"?`,
        closeAfterAction: true,
        actions: [
          { text: 'Оставить', color: 'thirdary' },
          { text: 'Удалить', color: 'error', onClick: () => onDelete(id) },
        ],
      });
    }, [id, onDelete, project, setModal]);
    const handleDownloadProject = useCallback(async () => {
      const [wantedProject, wantedSyncState] = await Promise.all([
        iDBProjectService.getOneById(id),
        iDBActService.getOneById(id),
      ]);
      if (wantedProject && wantedSyncState) {
        packToFile(wantedProject, wantedSyncState);
      } else {
        toast.error(`Can't pack a file for this project`);
      }
    }, [id]);

    const beforeActions = useMemo(
      () => [
        <Button
          key="Sync"
          variant="shapeless"
          icon={<CloudArrows20 color={isWithRemote ? 'other_success' : 'other_grey'} />}
          disabled
        />,
      ],
      [isWithRemote],
    );
    const afterActions = useMemo(
      () => [
        <Button
          key="download"
          variant="shapeless"
          icon={<Download20 />}
          onClick={handleDownloadProject}
        />,
        <Button
          key="delete"
          variant="shapeless"
          icon={<Trash20 color="other_red" />}
          onClick={handleDelete}
        />,
      ],
      [handleDownloadProject, handleDelete],
    );

    const setGoToPath = useCallback(
      () => `/project/${id}/node/${project.root}`,
      [project.root, id],
    );

    return (
      <EntityCard
        title={project.meta.name}
        titleIcon={<FolderOpen24 />}
        beforeActions={beforeActions}
        afterActions={afterActions}
        setGoTo={setGoToPath}
      />
    );
  },
  ({ project, onMakeLocal }) => {
    const beforeActions = useMemo(
      () => [
        <Button
          key="Sync"
          variant="shapeless"
          icon={<DownloadCloud20 color="textator_primary" />}
          onClick={() => {
            onMakeLocal(project.id);
          }}
        />,
      ],
      [onMakeLocal, project.id],
    );

    return (
      <EntityCard
        title={project.meta.name}
        titleIcon={<FolderOpen24 />}
        beforeActions={beforeActions}
      />
    );
  },
);

interface ILocalProjectProps extends Omit<IProjectEntityCardProps, 'project' | 'onMakeLocal'> {
  project: TProject;
  isWithRemote: boolean;
}

interface IRemoteProjectProps extends Omit<IProjectEntityCardProps, 'project' | 'onDelete'> {
  project: TShortProject;
}

function projectsExistChecker(
  LocalCard: ComponentType<ILocalProjectProps>,
  RemoteCard: ComponentType<IRemoteProjectProps>,
): FC<IProjectEntityCardProps> {
  return function EntityExistChecker({ project, ...rest }) {
    if (project.local) {
      return <LocalCard {...rest} isWithRemote={Boolean(project.remote)} project={project.local} />;
    }

    if (project.remote) {
      return <RemoteCard {...rest} project={project.remote} />;
    }

    return null;
  };
}
