import { forwardRef, ReactNode } from 'react';

import styles from './TreeNode.module.css';

interface ITreeNodeProps {
  children: ReactNode;
}

export const TreeNode = forwardRef<HTMLLIElement, ITreeNodeProps>(
  ({ children }: ITreeNodeProps, ref) => {
    return (
      <li ref={ref} className={styles['node']}>
        {children}
      </li>
    );
  },
);

TreeNode.displayName = 'TreeNode';
