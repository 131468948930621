import { Button, ModalsContext } from '@ws/shared/components';
import { CloudArrows20, UploadCloud20 } from '@ws/shared/icons';
import { useContext } from 'react';
import { useSelector } from 'react-redux';

import { TAppState } from '../../../../redux';
import { SyncStatus } from '../../../../redux/sync';

import { SyncModal } from './SyncModal';

export function SyncButton() {
  const { status: syncStatus, isEnabled } = useSelector((state: TAppState) => state.sync.sync);
  const { setModal } = useContext(ModalsContext);

  function handleClick() {
    console.log('SYNC', syncStatus);

    setModal({
      id: 'sync-modal',
      customView: SyncModal,
    });
  }

  if (!isEnabled) {
    return (
      <Button
        onClick={handleClick}
        variant="shapeless"
        icon={<UploadCloud20 color="other_grey" />}
      />
    );
  }

  if (syncStatus === SyncStatus.CONFLICT) {
    return (
      <Button
        onClick={handleClick}
        variant="shapeless"
        icon={<CloudArrows20 color="other_red" />}
      />
    );
  }

  return (
    <Button
      onClick={handleClick}
      variant="shapeless"
      icon={<CloudArrows20 color="other_success" />}
    />
  );
}
