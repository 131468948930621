/* eslint-disable max-len */
import * as React from 'react';

import { createIcon, IIconProps, ISVGProps } from '../../internal';

function IconComponent(props: ISVGProps) {
  return (
    <svg
      {...props}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_227_2433)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23 3C23.5523 3 24 3.44772 24 4V10C24 10.5523 23.5523 11 23 11H17C16.4477 11 16 10.5523 16 10C16 9.44772 16.4477 9 17 9H22V4C22 3.44772 22.4477 3 23 3Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 14C0 13.4477 0.447715 13 1 13H7C7.55228 13 8 13.4477 8 14C8 14.5523 7.55228 15 7 15H2V20C2 20.5523 1.55228 21 1 21C0.447715 21 0 20.5523 0 20V14Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.2173 4.31741C12.9192 3.94191 11.547 3.90139 10.229 4.19962C8.91091 4.49785 7.68987 5.12511 6.6798 6.02287C5.66973 6.92064 4.90353 8.05964 4.45272 9.33361C4.26848 9.85426 3.69705 10.127 3.17641 9.94273C2.65576 9.75849 2.38305 9.18707 2.56729 8.66642C3.13081 7.07396 4.08855 5.6502 5.35114 4.52799C6.61373 3.40579 8.14003 2.62171 9.78761 2.24893C11.4352 1.87614 13.1503 1.9268 14.7731 2.39617C16.3906 2.86405 17.8637 3.73278 19.0559 4.92171L23.6848 9.27126C24.0873 9.64945 24.1069 10.2823 23.7288 10.6848C23.3506 11.0873 22.7177 11.107 22.3152 10.7288L17.6752 6.36877C17.6676 6.36161 17.6601 6.35434 17.6527 6.34694C16.6974 5.39113 15.5155 4.69291 14.2173 4.31741ZM0.27125 13.3152C0.649442 12.9128 1.2823 12.8931 1.68478 13.2713L6.32478 17.6313C6.3324 17.6384 6.3399 17.6457 6.34729 17.6531C7.30262 18.6089 8.48451 19.3071 9.78267 19.6826C11.0808 20.0581 12.453 20.0986 13.771 19.8004C15.0891 19.5022 16.3101 18.8749 17.3202 17.9772C18.3303 17.0794 19.0965 15.9404 19.5473 14.6664C19.7315 14.1458 20.303 13.8731 20.8236 14.0573C21.3442 14.2415 21.617 14.813 21.4327 15.3336C20.8692 16.9261 19.9115 18.3498 18.6489 19.472C17.3863 20.5942 15.86 21.3783 14.2124 21.7511C12.5648 22.1239 10.8497 22.0732 9.22695 21.6039C7.6094 21.136 6.13627 20.2672 4.94411 19.0783L0.315226 14.7288C-0.0872528 14.3506 -0.106942 13.7177 0.27125 13.3152Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_227_2433">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

IconComponent.displayName = 'RefreshCw';

export const RefreshCw24: React.FunctionComponent<IIconProps> = createIcon(IconComponent);
