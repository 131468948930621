import { classes } from 'classifizer';
import { forwardRef } from 'react';

import styles from './DropDivider.module.css';

interface IDropDividerProps {
  isOver: boolean;
  isDragging: boolean;
}

export const DropDivider = forwardRef<HTMLDivElement, IDropDividerProps>(({ isOver }, ref) => {
  return (
    <div className={classes(styles['divider'], isOver && styles['divider_hovered'])}>
      <div ref={ref} className={classes(styles['divider__hover-zone'])} />
    </div>
  );
});

DropDivider.displayName = 'DropDivider';
