import { Button, Sidebar as UiKitSidebar } from '@ws/shared/components';
import { AngleLeft16 } from '@ws/shared/icons';
import { useCallback, useMemo } from 'react';
import { DndProvider } from 'react-dnd';
// eslint-disable-next-line import/no-named-as-default
import MultiBackend from 'react-dnd-multi-backend';
import HTML5toTouch from 'react-dnd-multi-backend/dist/esm/HTML5toTouch';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { AuthContainer } from '../../../components';
import { TextatorLogo } from '../../../components/TextatorLogo';
import { PROJECTS_ROUTE } from '../../../constants';
import { useSidebar } from '../../../hooks';
import { TAppState } from '../../../redux';
import { cleanProject } from '../../../redux/editor';

import styles from './Sidebar.module.css';
import { SyncButton } from './Sync';
import { Tree } from './Tree';

export function Sidebar() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    editor: { project },
  } = useSelector((store: TAppState) => store);
  const { isOpen, variant, onOpen, onClose, mobileWidth } = useSidebar();

  const handleBackButtonClick = useCallback(() => {
    dispatch(cleanProject());
    navigate(PROJECTS_ROUTE);
  }, [dispatch, navigate]);

  const projectTitleActions = useMemo(() => {
    return [<SyncButton key="sync-button" />];
  }, []);

  return (
    <UiKitSidebar.Container
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      variant={variant}
      width={mobileWidth}
    >
      <UiKitSidebar.Header>
        <UiKitSidebar.HeaderWrapper>
          <div className={styles['header__logo-wrapper']}>
            <TextatorLogo />
          </div>

          <Button
            className={styles['header__back']}
            variant="shapeless"
            icon={<AngleLeft16 />}
            onClick={handleBackButtonClick}
          >
            К проектам
          </Button>
        </UiKitSidebar.HeaderWrapper>
      </UiKitSidebar.Header>

      {project?.root && (
        <UiKitSidebar.Body>
          <UiKitSidebar.TreesContainer>
            <DndProvider backend={MultiBackend} options={HTML5toTouch}>
              <Tree nodeId={project.root} mainTitleActions={projectTitleActions} />
              <Tree nodeId={project.drafts} />
            </DndProvider>
          </UiKitSidebar.TreesContainer>
        </UiKitSidebar.Body>
      )}

      <UiKitSidebar.Footer>
        <AuthContainer variant="standard" />
      </UiKitSidebar.Footer>
    </UiKitSidebar.Container>
  );
}
