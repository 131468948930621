/* eslint-disable max-len */
import * as React from 'react';

import { createIcon, IIconProps, ISVGProps } from '../../internal';

function IconComponent(props: ISVGProps) {
  return (
    <svg
      {...props}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M7.52655 8.00001L9.88655 5.64001C10.0107 5.5151 10.0804 5.34613 10.0804 5.17001C10.0804 4.99388 10.0107 4.82491 9.88655 4.70001C9.82457 4.63752 9.75084 4.58792 9.6696 4.55408C9.58836 4.52023 9.50122 4.50281 9.41321 4.50281C9.32521 4.50281 9.23807 4.52023 9.15683 4.55408C9.07559 4.58792 9.00186 4.63752 8.93988 4.70001L6.11321 7.52667C6.05073 7.58865 6.00113 7.66238 5.96729 7.74362C5.93344 7.82486 5.91602 7.912 5.91602 8.00001C5.91602 8.08801 5.93344 8.17515 5.96729 8.25639C6.00113 8.33763 6.05073 8.41136 6.11321 8.47334L8.93988 11.3333C9.00217 11.3951 9.07605 11.444 9.15728 11.4772C9.2385 11.5104 9.32548 11.5272 9.41321 11.5267C9.50095 11.5272 9.58793 11.5104 9.66915 11.4772C9.75038 11.444 9.82425 11.3951 9.88655 11.3333C10.0107 11.2084 10.0804 11.0395 10.0804 10.8633C10.0804 10.6872 10.0107 10.5182 9.88655 10.3933L7.52655 8.00001Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}

IconComponent.displayName = 'AngleLeft';

export const AngleLeft16: React.FunctionComponent<IIconProps> = createIcon(IconComponent);
