import { useSelector } from 'react-redux';

import { TAppState } from '../../../../redux';
import { BoardManager } from '../../BoardManager';

export function SplitBoardContainer() {
  const { secondarySelected } = useSelector((state: TAppState) => state.editor);
  const { isSecondaryCompiled } = useSelector((state: TAppState) => state.general);

  if (!secondarySelected) {
    return null;
  }
  return <BoardManager isCompiled={isSecondaryCompiled} entityRef={secondarySelected} />;
}
