import { TCustomer } from '@ws/shared/types';

import { Button } from '../Button';
import { AngleRight20, User20 } from '../Icons';
import { IIconProps } from '../Icons/internal';

import styles from './User.module.css';
import { TUserVariant } from './types';

interface IUserIconProps extends IIconProps {
  user?: TCustomer | null;
}

function UserIcon({ user }: IUserIconProps) {
  if (!user) {
    return <User20 />;
  }

  if (!user.first_name && !user.last_name) {
    return <User20 />;
  }

  const short = (
    user.first_name.slice(0, 1).trim() + user.last_name.slice(0, 1).trim()
  ).toUpperCase();

  if (short) {
    return <div className={styles['user-icon']}>{short}</div>;
  }

  if (user.login) {
    const capital = user.login.slice(0, 1).trim();
    return <div className={styles['user-icon']}>{capital}</div>;
  }

  return <User20 />;
}

interface IUserProps {
  user: TCustomer | null;

  onClick(): void;

  variant?: TUserVariant;
}

export function User({ user, onClick, variant = 'standard' }: IUserProps) {
  switch (variant) {
    case 'standard':
      return <StandardUser user={user} onClick={onClick} />;

    case 'compressed':
      return <CompressedUser user={user} onClick={onClick} />;

    case 'compressed_primary':
      return <CompressedPrimaryUser user={user} onClick={onClick} />;

    default:
      throw Error('Invalid variant');
  }
}

interface ICompressedPrimaryUserProps {
  user?: TCustomer | null;

  onClick(): void;
}

function CompressedPrimaryUser({ user, onClick }: ICompressedPrimaryUserProps) {
  return (
    <Button
      className={styles['compressed-primary']}
      onClick={onClick}
      variant="shapeless"
      icon={<UserIcon user={user} color="textator_primary" />}
    />
  );
}

interface ICompressedUserProps {
  user?: TCustomer | null;

  onClick(): void;
}

function CompressedUser({ user, onClick }: ICompressedUserProps) {
  return (
    <Button
      onClick={onClick}
      variant="shapeless"
      icon={
        <span className={styles['standard__avatar']}>
          <UserIcon user={user} />
        </span>
      }
    />
  );
}

interface IStandardUserProps {
  user?: TCustomer | null;

  onClick(): void;
}

function StandardUser({ user, onClick }: IStandardUserProps) {
  return (
    <button className={styles['standard']} onClick={onClick}>
      <span className={styles['standard__avatar']}>
        <UserIcon user={user} />
      </span>

      <span className={styles['standard__name']}>{getUserName(user)}</span>

      <div className={styles['standard__angle']}>
        <AngleRight20 />
      </div>
    </button>
  );
}

function getUserName(user?: TCustomer | null): string {
  if (user) {
    const { login, first_name: firstName, last_name: lastName } = user;

    if (firstName && lastName) {
      return `${firstName} ${lastName}`;
    }

    if (firstName) {
      return firstName;
    }

    if (lastName) {
      return lastName;
    }

    if (login) {
      return login;
    }

    return 'Аноним';
  }

  return 'Авторизоваться';
}
