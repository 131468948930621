/* eslint-disable max-len */
import * as React from 'react';

import { createIcon, IIconProps, ISVGProps } from '../../internal';

function IconComponent(props: ISVGProps) {
  return (
    <svg
      {...props}
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M5.6454 5.99997L7.4154 4.22997C7.50852 4.13629 7.5608 4.00957 7.5608 3.87747C7.5608 3.74538 7.50852 3.61866 7.4154 3.52497C7.36892 3.47811 7.31362 3.44091 7.25269 3.41553C7.19176 3.39014 7.12641 3.37708 7.0604 3.37708C6.99439 3.37708 6.92904 3.39014 6.86811 3.41553C6.80718 3.44091 6.75188 3.47811 6.7054 3.52497L4.5854 5.64497C4.53853 5.69146 4.50134 5.74676 4.47595 5.80769C4.45057 5.86862 4.4375 5.93397 4.4375 5.99997C4.4375 6.06598 4.45057 6.13133 4.47595 6.19226C4.50134 6.25319 4.53853 6.30849 4.5854 6.35497L6.7054 8.49997C6.75212 8.54631 6.80753 8.58298 6.86845 8.60786C6.92936 8.63274 6.9946 8.64536 7.0604 8.64497C7.1262 8.64536 7.19143 8.63274 7.25235 8.60786C7.31327 8.58298 7.36868 8.54631 7.4154 8.49997C7.50852 8.40629 7.5608 8.27957 7.5608 8.14747C7.5608 8.01538 7.50852 7.88866 7.4154 7.79497L5.6454 5.99997Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}

IconComponent.displayName = 'AngleLeft';

export const AngleLeft12: React.FunctionComponent<IIconProps> = createIcon(IconComponent);
