import { classes } from 'classifizer';
import { ReactNode } from 'react';

import styles from './List.module.css';

interface IListProps {
  children: ReactNode;
  width?: string;
  height?: string;
  withoutShadow?: boolean;
}

export function List({ children, width, height, withoutShadow }: IListProps) {
  return (
    <ul
      style={{ width, height }}
      className={classes(styles.list, withoutShadow && styles['list_without-shadow'])}
    >
      {children}
    </ul>
  );
}
