import { TFormatted, TCollection, TCompiled, TEntity, TNote } from '../../types';
import { getSha1Hash } from '../getSha1Hash';

import { parser } from './markdown';

export type TGetNodesByRefs = (refs: any[]) => (TCollection | TNote | undefined)[];

export function getCompiledContentOfEntity(
  entity: TCollection | TNote,
  getNodesByRefs: TGetNodesByRefs,
): TCompiled {
  let compiledText: TFormatted[] = [];
  if (entity.type === 'note') {
    compiledText = [compileNote(entity)];
  } else if (entity.type === 'collection') {
    compiledText = compileCollection({ collection: entity, getNodesByRefs });
  }

  return {
    content: compiledText,
    hash: getSha1Hash(JSON.stringify(compiledText)),
  };
}

function formatData(entity: TEntity): TFormatted {
  const {
    id,
    meta: { name, withTitle },
  } = entity;

  return {
    id,
    withTitle,
    originalName: name,
    name: parser(name),
    text: '',
    originalContent: '',
  };
}

export function compileNote(note: TNote): TFormatted {
  return {
    ...formatData(note),
    text: parser(note.content),
    originalContent: note.content,
  };
}

interface ICollectionCompileParams {
  collection: TCollection;
  getNodesByRefs: TGetNodesByRefs;
}

export function compileCollection({ collection, getNodesByRefs }: ICollectionCompileParams) {
  const compiled: TFormatted[] = [];

  if (collection.meta.withTitle) {
    const metaData = formatData(collection);
    compiled.push(metaData);
  }

  const children = getNodesByRefs(collection.children);

  children.forEach((child) => {
    if (!child) {
      return;
    }

    if (child.meta.isHidden) {
      return;
    }

    if (child.type === 'note') {
      const compiledNote = compileNote(child);
      compiled.push(compiledNote);

      return;
    }

    if (child.type === 'collection') {
      const compiledChildren = compileCollection({ collection: child, getNodesByRefs });
      compiled.push(...compiledChildren);
    }
  });

  return compiled;
}
