import { classes } from 'classifizer';
import { useLayoutEffect, useMemo, useRef } from 'react';

import { TViewContainerProps } from '../types';

import styles from './StandardView.module.css';

export function StandardView({ isOpen, width, children }: TViewContainerProps) {
  const navRef = useRef<HTMLElement | null>(null);
  const sidebarWidthStyle = useMemo(() => {
    if (width) {
      return { ['--sidebar-width' as string]: width };
    }

    return {};
  }, [width]);

  useLayoutEffect(() => {
    if (!isOpen && navRef.current) {
      navRef.current.style.pointerEvents = 'none';

      const timer = setTimeout(() => {
        navRef.current!.style.transitionDelay = 'var(--transition-delay)';
        navRef.current!.style.pointerEvents = 'auto';
        navRef.current!.style.top = 'var(--dynamic-sidebar-top)';
        navRef.current!.style.height = 'var(--dynamic-sidebar-height)';
      }, 300);

      return () => clearTimeout(timer);
    }

    if (navRef.current) {
      navRef.current.style.transitionDelay = '0s';
      navRef.current.style.top = '0';
      navRef.current.style.height = '100%';
    }

    return;
  }, [isOpen]);

  return (
    <aside
      style={sidebarWidthStyle}
      className={classes(styles['standard-sidebar'], isOpen && styles['standard-sidebar_opened'])}
    >
      <div className={classes(styles['standard-sidebar__overlay'])} />

      <nav
        ref={navRef}
        className={classes(
          styles['standard-sidebar__navigation'],
          isOpen && styles['standard-sidebar__navigation_opened'],
        )}
      >
        {children}
      </nav>
    </aside>
  );
}
