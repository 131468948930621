import { Button, Header, Input } from '@ws/shared/components';
import { Close24, Sync20, SyncSlash20 } from '@ws/shared/icons';
import { useCallback, useContext, useEffect, useMemo, useRef, useState, FormEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { EditabilityContext } from '../../../../context/EditabilityContext';
import { TAppState } from '../../../../redux';
import { setSelectedSecondaryNode } from '../../../../redux/editor';
import { setGlobalIsSecondaryCompiled } from '../../../../redux/general/general.actions';
import { transliterate } from '../../../../utils/transliterate';

const UPDATE_TIMEOUT = 1500;

export function HeaderContainer() {
  const dispatch = useDispatch();
  const { isSecondaryCompiled } = useSelector((state: TAppState) => state.general);
  const { getNodeByRef, updateNodeMeta, updateProjectMeta } = useContext(EditabilityContext);

  const updateTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);
  const { secondarySelected, project } = useSelector((state: TAppState) => state.editor);

  const currentNode = useMemo(
    () => (secondarySelected ? getNodeByRef(secondarySelected) : secondarySelected),
    [getNodeByRef, secondarySelected],
  );

  const [name, setName] = useState(currentNode?.meta.name || '');

  const handleCompilationClick = useCallback(
    () => dispatch(setGlobalIsSecondaryCompiled({ isSecondaryCompiled: !isSecondaryCompiled })),
    [dispatch, isSecondaryCompiled],
  );

  function sendNameChangeEvent(nameToSave: string) {
    if (secondarySelected) {
      updateNodeMeta(secondarySelected, { name: nameToSave });

      if (secondarySelected.id === project?.root) {
        updateProjectMeta({ name: nameToSave, slug: transliterate(nameToSave) });
      }
    }
  }

  function handleNameChange(event: FormEvent<HTMLInputElement>) {
    const newName = (event.target as HTMLInputElement).value;
    setName(newName);

    clearTimeout(updateTimeout.current as any);

    updateTimeout.current = setTimeout(() => {
      sendNameChangeEvent(newName);
    }, UPDATE_TIMEOUT);
  }

  function handleNameInputBlur() {
    clearTimeout(updateTimeout.current as any);

    if (currentNode?.meta.name !== name) {
      sendNameChangeEvent(name);
    }
  }

  function handleSecondaryBoardClose() {
    dispatch(setSelectedSecondaryNode({ secondarySelected: null }));
  }

  useEffect(() => {
    setName(currentNode?.meta.name || '');
  }, [secondarySelected, currentNode?.meta.name]);

  if (!secondarySelected || !currentNode) {
    return null;
  }

  return (
    <Header
      title={
        <Input
          variant="shapeless"
          value={name}
          onChange={handleNameChange}
          onBlur={handleNameInputBlur}
        />
      }
      after={
        <Button
          style={{ display: 'inline-flex' }}
          key="compile_btn"
          color={isSecondaryCompiled ? 'secondary' : 'primary'}
          onClick={handleCompilationClick}
          icon={isSecondaryCompiled ? <SyncSlash20 /> : <Sync20 />}
        />
      }
      ending={
        <Button
          style={{ display: 'inline-flex' }}
          key="close_btn"
          variant="shapeless"
          onClick={handleSecondaryBoardClose}
          icon={<Close24 />}
        />
      }
    />
  );
}
