import { CompilerContent } from '@ws/shared/components';
import { TNote } from '@ws/shared/types';
import { compileNote } from '@ws/shared/utils';

import { CompilerContainer } from '../CompilerContainer';
import { useCompilerHandlers } from '../hooks';

export interface IFlatCompilerProps {
  note: TNote;
  standAlone?: boolean;
}

export function NoteCompiler({ note, standAlone }: IFlatCompilerProps) {
  const compiledEntity = compileNote(note);
  const { selectedEntity, editEntityId, handleEditClick, handleEntityClick } =
    useCompilerHandlers();

  if (standAlone) {
    return <CompilerContent entity={compiledEntity} onClick={handleEntityClick(note.id)} />;
  }

  return (
    <CompilerContainer
      entity={compiledEntity}
      showEditButton={selectedEntity === note.id}
      onEntityClick={handleEntityClick(note.id)}
      onEdit={handleEditClick(note.id)}
      isEditing={editEntityId === note.id}
    />
  );
}
