import { createAction } from '../../utils/createAction';

import {
  CONTENT_WINDOWS_APPEND,
  CONTENT_WINDOWS_SET_IS_OPEN,
  CONTENT_WINDOWS_REMOVE,
  CONTENT_WINDOWS_RESET,
  IContentWindowsAppendAction,
  IContentWindowsRemoveAction,
  IContentWindowsSetIsOpenAction,
  CONTENT_WINDOWS_SET_SETTINGS,
  IContentWindowsSetSettingsAction,
} from './contentWindows.types';

export const resetContentWindow = createAction<IContentWindowsAppendAction>(CONTENT_WINDOWS_RESET);

export const appendContentWindow =
  createAction<IContentWindowsAppendAction>(CONTENT_WINDOWS_APPEND);

export const removeContentWindow =
  createAction<IContentWindowsRemoveAction>(CONTENT_WINDOWS_REMOVE);

export const setIsContentWindowOpen = createAction<IContentWindowsSetIsOpenAction>(
  CONTENT_WINDOWS_SET_IS_OPEN,
);

export const setContentWindowSettings = createAction<IContentWindowsSetSettingsAction>(
  CONTENT_WINDOWS_SET_SETTINGS,
);
