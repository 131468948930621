/* eslint-disable max-len */
import * as React from 'react';

import { createIcon, IIconProps, ISVGProps } from '../../internal';

function IconComponent(props: ISVGProps) {
  return (
    <svg
      {...props}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.33398 13.3333C7.33398 12.9651 7.63246 12.6666 8.00065 12.6666H14.0007C14.3688 12.6666 14.6673 12.9651 14.6673 13.3333C14.6673 13.7015 14.3688 14 14.0007 14H8.00065C7.63246 14 7.33398 13.7015 7.33398 13.3333Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.0007 2.58577C11.8024 2.58577 11.6123 2.66453 11.4721 2.80473L3.26931 11.0075L2.91691 12.4171L4.3265 12.0647L12.5293 3.86192C12.5987 3.7925 12.6537 3.71009 12.6913 3.6194C12.7289 3.5287 12.7482 3.43149 12.7482 3.33332C12.7482 3.23515 12.7289 3.13794 12.6913 3.04725C12.6537 2.95655 12.5987 2.87414 12.5293 2.80473C12.4598 2.73531 12.3774 2.68025 12.2867 2.64268C12.196 2.60511 12.0988 2.58577 12.0007 2.58577ZM10.5293 1.86192C10.9195 1.47168 11.4488 1.25244 12.0007 1.25244C12.2739 1.25244 12.5445 1.30626 12.797 1.41084C13.0494 1.51541 13.2788 1.66869 13.4721 1.86192C13.6653 2.05514 13.8186 2.28454 13.9231 2.537C14.0277 2.78947 14.0815 3.06006 14.0815 3.33332C14.0815 3.60659 14.0277 3.87718 13.9231 4.12964C13.8186 4.3821 13.6653 4.6115 13.4721 4.80473L5.13874 13.1381C5.0533 13.2235 4.94624 13.2841 4.82902 13.3134L2.16236 13.9801C1.93517 14.0369 1.69485 13.9703 1.52926 13.8047C1.36367 13.6391 1.29711 13.3988 1.3539 13.1716L2.02057 10.505C2.04988 10.3877 2.11049 10.2807 2.19593 10.1953L10.5293 1.86192Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}

IconComponent.displayName = 'Edit';

export const Edit16: React.FunctionComponent<IIconProps> = createIcon(IconComponent);
