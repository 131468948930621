import { BrowserSupport } from '../../components/BrowserSupport';
import { GoogleLoginButton } from '../../components/GoogleLoginButton/GoogleLoginButton';

import styles from './AuthPage.module.css';

export function AuthPage() {
  const handleSuccess = () => {};

  const handleFailure = () => {};

  const handleRequest = () => {};

  return (
    <div className={styles.auth}>
      AuthPage
      <GoogleLoginButton
        clientId=""
        buttonText="Employee login"
        onSuccess={handleSuccess}
        onFailure={handleFailure}
        onRequest={handleRequest}
      />
      <BrowserSupport />
    </div>
  );
}
