/* eslint-disable max-len */
import * as React from 'react';

import { createIcon, IIconProps, ISVGProps } from '../../internal';

function IconComponent(props: ISVGProps) {
  return (
    <svg
      {...props}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_767_14774)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.52794 7.52864C7.78829 7.26829 8.2104 7.26829 8.47075 7.52864L11.1374 10.1953C11.3978 10.4557 11.3978 10.8778 11.1374 11.1381C10.8771 11.3985 10.455 11.3985 10.1946 11.1381L7.99935 8.94285L5.80409 11.1381C5.54374 11.3985 5.12163 11.3985 4.86128 11.1381C4.60093 10.8778 4.60093 10.4557 4.86128 10.1953L7.52794 7.52864Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.00065 7.33337C8.36884 7.33337 8.66732 7.63185 8.66732 8.00004V14C8.66732 14.3682 8.36884 14.6667 8.00065 14.6667C7.63246 14.6667 7.33398 14.3682 7.33398 14V8.00004C7.33398 7.63185 7.63246 7.33337 8.00065 7.33337Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.77518 1.33903C6.68151 1.30564 7.58361 1.47814 8.41367 1.84357C9.24373 2.20901 9.98014 2.75786 10.5676 3.44886C11.0419 4.00681 11.409 4.64572 11.6524 5.33332H11.9996C12.8883 5.33391 13.752 5.63043 14.4534 6.17608C15.1549 6.72173 15.6546 7.48544 15.8739 8.34667C16.0931 9.20791 16.0192 10.1176 15.664 10.9322C15.3088 11.7468 14.6924 12.4199 13.9121 12.8453C13.5888 13.0216 13.1839 12.9024 13.0076 12.5791C12.8314 12.2558 12.9506 11.8509 13.2739 11.6747C13.7941 11.3911 14.205 10.9423 14.4418 10.3993C14.6786 9.85618 14.7279 9.24971 14.5817 8.67556C14.4356 8.1014 14.1024 7.59226 13.6348 7.22849C13.1671 6.86473 12.5917 6.66705 11.9992 6.66666H11.1596C10.8557 6.66666 10.5903 6.46111 10.5142 6.16686C10.3376 5.48392 10.0085 4.84989 9.55167 4.31244C9.0948 3.77499 8.52203 3.34811 7.87643 3.06388C7.23083 2.77966 6.52919 2.64549 5.82427 2.67146C5.11935 2.69743 4.42949 2.88287 3.80656 3.21383C3.18362 3.5448 2.64382 4.01267 2.22773 4.58228C1.81165 5.1519 1.53011 5.80842 1.40428 6.50251C1.27844 7.19659 1.3116 7.91017 1.50124 8.5896C1.69089 9.26902 2.03209 9.89662 2.4992 10.4252C2.74302 10.7011 2.71701 11.1224 2.44111 11.3662C2.16521 11.61 1.74391 11.584 1.50009 11.3081C0.899524 10.6285 0.460833 9.82161 0.217002 8.94806C-0.0268288 8.07451 -0.0694554 7.15706 0.0923272 6.26466C0.25411 5.37227 0.616092 4.52816 1.15106 3.7958C1.68603 3.06344 2.38006 2.46189 3.18097 2.03637C3.98189 1.61084 4.86886 1.37242 5.77518 1.33903Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.52794 7.52864C7.78829 7.26829 8.2104 7.26829 8.47075 7.52864L11.1374 10.1953C11.3978 10.4557 11.3978 10.8778 11.1374 11.1381C10.8771 11.3985 10.455 11.3985 10.1946 11.1381L7.99935 8.94285L5.80409 11.1381C5.54374 11.3985 5.12163 11.3985 4.86128 11.1381C4.60093 10.8778 4.60093 10.4557 4.86128 10.1953L7.52794 7.52864Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_767_14774">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

IconComponent.displayName = 'UploadCloud';

export const UploadCloud16: React.FunctionComponent<IIconProps> = createIcon(IconComponent);
