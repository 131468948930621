/* eslint-disable max-len */
import * as React from 'react';

import { createIcon, IIconProps, ISVGProps } from '../../internal';

function IconComponent(props: ISVGProps) {
  return (
    <svg
      {...props}
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.3536 1.64645C11.5488 1.84171 11.5488 2.15829 11.3536 2.35355L6.35355 7.35355C6.15829 7.54882 5.84171 7.54882 5.64645 7.35355L4.14645 5.85355C3.95118 5.65829 3.95118 5.34171 4.14645 5.14645C4.34171 4.95118 4.65829 4.95118 4.85355 5.14645L6 6.29289L10.6464 1.64645C10.8417 1.45118 11.1583 1.45118 11.3536 1.64645Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.5 2C2.36739 2 2.24021 2.05268 2.14645 2.14645C2.05268 2.24021 2 2.36739 2 2.5V9.5C2 9.63261 2.05268 9.75978 2.14645 9.85355C2.24021 9.94732 2.36739 10 2.5 10H9.5C9.63261 10 9.75978 9.94732 9.85355 9.85355C9.94732 9.75978 10 9.63261 10 9.5V6C10 5.72386 10.2239 5.5 10.5 5.5C10.7761 5.5 11 5.72386 11 6V9.5C11 9.89783 10.842 10.2794 10.5607 10.5607C10.2794 10.842 9.89783 11 9.5 11H2.5C2.10217 11 1.72064 10.842 1.43934 10.5607C1.15804 10.2794 1 9.89783 1 9.5V2.5C1 2.10218 1.15804 1.72064 1.43934 1.43934C1.72064 1.15804 2.10218 1 2.5 1H8C8.27614 1 8.5 1.22386 8.5 1.5C8.5 1.77614 8.27614 2 8 2H2.5Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}

IconComponent.displayName = 'CheckSquare';

export const CheckSquare12: React.FunctionComponent<IIconProps> = createIcon(IconComponent);
