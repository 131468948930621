import { useDispatch, useSelector } from 'react-redux';

import { TAppState } from '../../../redux';
import { removeContentWindow, setIsContentWindowOpen } from '../../../redux/contentWindows';

import { WindowTabContainer } from './WindowTabContainer';
import styles from './WindowTabs.module.css';

export function WindowTabsPanel() {
  const dispatch = useDispatch();
  const { list, settings } = useSelector((state: TAppState) => state.contentWindows);

  function handleCloseClick(nodeId: string) {
    return () => dispatch(removeContentWindow({ nodeIdToRemove: nodeId }));
  }

  function handleExposeClick(nodeId: string) {
    return () => dispatch(setIsContentWindowOpen({ nodeId, isOpen: true }));
  }

  return (
    <div className={styles['window-tab-panel']}>
      {list.map((nodeId) => (
        <WindowTabContainer
          className={styles['window-tab-panel__tab']}
          key={nodeId}
          nodeId={nodeId}
          onCloseClick={handleCloseClick(nodeId)}
          onExposeClick={handleExposeClick(nodeId)}
          isOpen={settings[nodeId].isOpen}
        />
      ))}
    </div>
  );
}
