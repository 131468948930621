import { useOutsideClick } from '@ws/shared/hooks';
import { ReactNode, useRef } from 'react';

interface IClickAwayListenerProps {
  onClickAway: () => void;
  children: ReactNode;
  className?: string;
}

export function ClickAwayListener({ onClickAway, children, className }: IClickAwayListenerProps) {
  const ref = useRef<HTMLDivElement | null>(null);

  useOutsideClick(ref, onClickAway);

  return (
    <div ref={ref} className={className}>
      {children}
    </div>
  );
}
