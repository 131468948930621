import { Button } from '@ws/shared/components';
import { FolderPlus24 } from '@ws/shared/icons';
import { isMobile } from '@ws/shared/utils';
import { useContext } from 'react';

import { ResolutionContext } from '../../../context';

import styles from './Placeholder.module.css';

interface IPlaceholderProps {
  onCreateProjectClick(): void;
}

export function Placeholder({ onCreateProjectClick }: IPlaceholderProps) {
  const { resolution } = useContext(ResolutionContext);
  const mobile = isMobile(resolution);

  return (
    <div className={styles['placeholder']}>
      <span className={styles['placeholder__emoji']}>😔</span>
      <p className={styles['placeholder__title']}>Пока нет ни одного проекта</p>
      <span className={styles['placeholder__description']}>
        Нажмите на кнопку “Новый проект”, чтобы начать писать
      </span>
      {!mobile && (
        <Button
          className={styles['placeholder__create-button']}
          onClick={onCreateProjectClick}
          icon={<FolderPlus24 color="other_white" />}
        >
          Новый проект
        </Button>
      )}
    </div>
  );
}
