import { EMcsName } from '@ws/shared/utils';

const SYNC_ENDPOINT_BASE = 'v1/sync/project';

export const SYNC_INIT_ENDPOINT_CONF = {
  base: `${SYNC_ENDPOINT_BASE}/init`,
  mcsName: EMcsName.TEXT,
  access: 'private',
};

export function BUILD_SYNC_APPLY_EVENT_ENDPOINT_CONF(projectId: string) {
  return {
    base: `${SYNC_ENDPOINT_BASE}/${projectId}/acts/apply`,
    mcsName: EMcsName.TEXT,
    access: 'private',
  };
}

export function BUILD_SYNC_GET_DELTA_ENDPOINT_CONF(projectId: string) {
  return {
    base: `${SYNC_ENDPOINT_BASE}/${projectId}/acts/delta`,
    mcsName: EMcsName.TEXT,
    access: 'private',
  };
}
