import { TEvent } from '@ws/shared/types';

import { TShortNoteDict } from '../../resources/syncService';

export enum SyncStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  CONFLICT = 'CONFLICT',
  PAUSE = 'PAUSE',
}

export interface ISyncState {
  sync: {
    isEnabled: boolean;
    status: SyncStatus;
  };
  conflictState: {
    dispenser: TDispenser;
    registry: TEventRegistry;
    conflicts: TConflictDict;
    remoteEvents: TEvent[];
    localEvents: TEvent[];
    applyOnClient: TEvent[];
    applyOnServer: TEvent[];
  };
}

export type TEventId = string;
export type TEventType = string;
export type TConflictId = string;
export type TSourceIdxMark = string;
export type TResolveDirection = 'remote' | 'local' | 'no_moves';

export type TSyncState = {
  processedRemotesIdx: 0;
  processedLocalsIdx: 0;
};

/*
    key: entityId
        key: eventType
            conflictId: number | null
            isUpdated: bool
            remotes: [event idx]
            locals: [event idx]
 */
export type TEventRegistry = {
  [key: TEventId]: {
    [key: TEventType]: {
      conflictId: TConflictId | null;
      isUpdated: boolean;
      idxsOfRemotes: number[];
      idxsOfLocals: number[];
    };
  };
};

/*
    key: {source}_{idx}
        conflictId
 */
export type TDispenser = {
  [key: TSourceIdxMark]: TConflictId;
};

/*
    key: conflictId
        eventType
        remotes [events]
        locals [events]
        resolvedTo: remote | local | null
 */
export type TConflict = {
  id: TConflictId;
  eventType: TEventType;
  remoteEvents: TEvent[];
  localEvents: TEvent[];
  resolvedTo: TResolveDirection | null;
};

export type TConflictDict = {
  [key: TConflictId]: TConflict;
};

export interface ISyncStatusAction {
  status: SyncStatus;
}

export const SET_SYNC_STATUS = 'SET_SYNC_STATUS';

export interface ISyncEnableAction {
  isEnable: boolean;
}

export const SET_SYNC_ENABLE = 'SET_SYNC_ENABLE';

export const SYNC_STATE_ASK_DELTA = 'SYNC_STATE_ASK_DELTA';
export const START_SYNC = 'START_SYNC';
export const DESTROY_SYNC = 'DESTROY_SYNC';
export const INIT_SYNC = 'INIT_SYNC';
export const SET_NO_AUTH = 'SET_NO_AUTH';

export interface IOnDeltaReceivedAction {
  remote: TEvent[];
  local: TEvent[];
  notes: TShortNoteDict;
}

export const ON_DELTA_RECEIVED = 'ON_DELTA_RECEIVED';

export interface IUpdateConflictStateAction {
  dispenser?: TDispenser;
  registry?: TEventRegistry;
  conflicts?: TConflictDict;
  remoteEvents?: TEvent[];
  localEvents?: TEvent[];
  applyOnClient?: TEvent[];
  applyOnServer?: TEvent[];
}

export const UPDATE_CONFLICT_STATE = 'UPDATE_CONFLICT_STATE';
