import { Fetcher } from '@ws/shared/utils';

import {
  BUILD_REVIEW_BY_ID_ENDPOINT_CONF,
  BUILD_REVIEW_LIST_BY_PREVIEW_ID_ENDPOINT_CONF,
} from './endpoints';
import {
  ICreateReviewRequestParams,
  TCreateReviewResponse,
  TGetReviewByIdResponse,
  TGetReviewsByPreviewIdResponse,
} from './types';

const userId = 'aae27319-c841-4f8e-8ed4-3ae878b64c6b';

export class ReviewRepository {
  private _fetcher: Fetcher;

  constructor(fetcher: Fetcher) {
    this._fetcher = fetcher;
  }

  public create(params: ICreateReviewRequestParams) {
    return this._fetcher.fetch<TCreateReviewResponse>({
      isExternal: true,
      // url: this._fetcher.buildURL(PROJECT_INIT_ENDPOINT_CONF),
      url: `http://localhost:7070/v1/user/${userId}/review/create`,
      method: 'POST',
      data: params,
    });
  }

  public getById(id: string) {
    return this._fetcher.fetch<TGetReviewByIdResponse>({
      url: this._fetcher.buildURL(BUILD_REVIEW_BY_ID_ENDPOINT_CONF({ reviewId: id })),
      method: 'GET',
    });
  }

  public getListByPreviewId(id: string) {
    return this._fetcher.fetch<TGetReviewsByPreviewIdResponse>({
      isExternal: true,
      url: this._fetcher.buildURL(BUILD_REVIEW_LIST_BY_PREVIEW_ID_ENDPOINT_CONF({ previewId: id })),
      method: 'GET',
    });
  }
}
