import { classes } from 'classifizer';
import { ReactNode, useCallback, useMemo, useState } from 'react';

import { MoreVertical24 } from '@ws/shared/icons';

import { ContextMenu, IContextMenuItem } from '../ContextMenu';
import { Popover } from '../Popover';

import styles from './Header.module.css';

export interface IHeaderKebabItem extends IContextMenuItem {
  closeAfterClick?: boolean;
}

interface IHeaderProps {
  before?: ReactNode;
  title?: ReactNode;
  after?: ReactNode;
  kebab?: IHeaderKebabItem[];
  ending?: ReactNode;
  className?: string;
}

export function Header({ before, title, after, kebab, ending, className }: IHeaderProps) {
  const [open, setOpen] = useState(false);

  const kebabItems = useMemo(
    () =>
      (kebab || []).map((item) => {
        if (item.closeAfterClick) {
          return {
            ...item,
            onClick: () => {
              if (typeof item.onClick === 'function') {
                item.onClick();
              }
              setOpen(false);
            },
          };
        }

        return item;
      }),
    [kebab],
  );

  const toggleKebab = useCallback(() => setOpen((prevState) => !prevState), []);

  return (
    <header className={classes(styles['header'], className)}>
      {before && <div className={styles['header__before']}>{before}</div>}
      {title && <h1 className={classes(styles['header__title'], 'text-ellipsis')}>{title}</h1>}
      {after && <div className={styles['header__after']}>{after}</div>}
      {kebab && (
        <div className={styles['header__kebab']}>
          <Popover.Container open={open} onOpenChange={setOpen}>
            <Popover.Trigger onClick={toggleKebab}>
              <MoreVertical24 />
            </Popover.Trigger>
            <Popover.Content withoutPaddings>
              <ContextMenu items={kebabItems} />
            </Popover.Content>
          </Popover.Container>
        </div>
      )}
      {ending && <div className={styles['header__ending']}>{ending}</div>}
    </header>
  );
}
