import { Fetcher } from '@ws/shared/utils';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';

import { Root } from './Root';
import { SWRegister } from './SWRegister';
import { notSupportedReason } from './components/BrowserSupport/checkFeatures';
import { ErrorScreen } from './components/ErrorScreen';
import { initConfig, TConfig } from './config/config';
import { store } from './redux';
import { setUserAuthStatus } from './redux/user/user.actions';
import { initResources } from './resources/ResourceManager';

let root: ReactDOM.Root | null = null;

function fetchConfig() {
  const fetcher = new Fetcher();
  return fetcher
    .fetch<TConfig>({ url: '/config/config.json', isExternal: true })
    .then((fileData) => {
      initConfig(fileData.data);
    });
}

const renderApp = (Component: () => JSX.Element) => {
  const notSupportedMessage = notSupportedReason();
  const container = document.getElementById('root') as HTMLElement;

  if (notSupportedMessage) {
    // not included in translation json because this screen appears before language check and before any screen
    const description = [
      'Apparently, Textator does not work with this browser.',
      'Please try another browser or contact your technical support.',
    ].join('\n');

    const renderedElement = ErrorScreen({ error: new Error(notSupportedMessage), description });

    if (!root) {
      root = ReactDOM.createRoot(container);
    }

    root.render(renderedElement);
    return;
  }

  const renderedElement = (
    <StrictMode>
      <Component />
    </StrictMode>
  );

  if (!root) {
    root = ReactDOM.createRoot(container);
  }

  root.render(renderedElement);
};

function handleUnAuth() {
  if (store.getState().user.isAuth) {
    store.dispatch(setUserAuthStatus({ isAuth: false }));
  }
}

async function initApp() {
  await fetchConfig();

  initResources(handleUnAuth);

  renderApp(Root);
}

void initApp();

SWRegister();
