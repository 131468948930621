import { ISelectionState } from '../../types';

interface IPrepareParagraphTextProps {
  value: string;
  selection: ISelectionState;
}
// TODO написать тесты на преобразования
export const prepareParagraphText = (
  symbol: string,
  { selection, value }: IPrepareParagraphTextProps,
) => {
  const newString: Array<string> = value.split('\n');
  let start: number = selection.selectionStart;
  let end: number = selection.selectionEnd;

  const positionsForSelection = [];
  let sum = 0;
  for (let i = 0; i < newString.length; i++) {
    sum += newString[i].length;
    positionsForSelection.push([sum - newString[i].length, sum]);
  }

  // Applying symbol to start of text
  for (let i = 0; i < positionsForSelection.length; i++) {
    // because of the '\n' split start and end should decrease
    if (i > 0) {
      start = start - 1;
      end = end - 1;
    }
    if (start >= positionsForSelection[i][0] && start <= positionsForSelection[i][1]) {
      newString[i] = symbol + ' ' + newString[i];
      continue;
    }
    if (end >= positionsForSelection[i][0] && end <= positionsForSelection[i][1]) {
      newString[i] = symbol + ' ' + newString[i];
      continue;
    }
    if (start <= positionsForSelection[i][0] && end >= positionsForSelection[i][1]) {
      newString[i] = symbol + ' ' + newString[i];
    }
  }

  return newString.join('\n');
};
