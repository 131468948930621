// import DOMPurify from 'dompurify';
import sha1 from 'js-sha1';
import { marked } from 'marked';

marked.setOptions({
  pedantic: false,
  gfm: true,
  breaks: true,
  smartLists: true,
  xhtml: true,
  // remove because deprecated
  sanitize: true,
  silent: true,
});

const getRenderer = (isForReview?: boolean) => {
  const settings: Record<string, any> = {
    link(href: string, title: string, text: string) {
      const escapedText = text.toLowerCase().replace(/http(s):\/\//, '');

      return `
      <a title="${
        title ? `title="${title}"` : ''
      }" target="_blank" name="${escapedText}" class="anchor" href="${href}">${text}</a>`;
    },
  };

  if (isForReview) {
    settings['paragraph'] = function (text: string) {
      // return `${text}<br /><br />`;
      return `<p id="${sha1(text)}">${text}</p>`;
    };
  }

  return settings;
};

export function parser(text: string, isForReview?: boolean) {
  // Figure out how to return html as a text string, not elements
  // const clean = DOMPurify.sanitize(text);

  marked.use({ renderer: getRenderer(isForReview) });

  return marked(text);
}
