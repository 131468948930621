/* eslint-disable max-len */
import * as React from 'react';

import { createIcon, IIconProps, ISVGProps } from '../../internal';

function IconComponent(props: ISVGProps) {
  return (
    <svg
      {...props}
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 4C2 2.89543 2.89543 2 4 2H8C9.10457 2 10 2.89543 10 4V8C10 9.10457 9.10457 10 8 10H4C2.89543 10 2 9.10457 2 8V4ZM8.56274 4.75909C8.78589 4.98224 8.78589 5.34405 8.56274 5.56721L6.13837 7.99157C5.91521 8.21473 5.5534 8.21473 5.33025 7.99157L4.92663 7.58796C4.92639 7.58771 4.92639 7.58731 4.92663 7.58707C4.92688 7.58682 4.92688 7.58642 4.92663 7.58618L4.11985 6.77939C3.89669 6.55624 3.89669 6.19443 4.11985 5.97127C4.343 5.74812 4.70481 5.74812 4.92797 5.97127L5.12955 6.17286C5.46404 6.50734 6.00636 6.50734 6.34084 6.17286L7.75461 4.75909C7.97777 4.53593 8.33958 4.53593 8.56274 4.75909Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}

IconComponent.displayName = 'CheckboxChecked';

export const CheckboxChecked12: React.FunctionComponent<IIconProps> = createIcon(IconComponent);
