/* eslint-disable max-len */
import * as React from 'react';

import { createIcon, IIconProps, ISVGProps } from '../../internal';

function IconComponent(props: ISVGProps) {
  return (
    <svg
      {...props}
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_768_9897)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.5 5C5.22386 5 5 5.22386 5 5.5V10C5 10.2761 5.22386 10.5 5.5 10.5H10C10.2761 10.5 10.5 10.2761 10.5 10V5.5C10.5 5.22386 10.2761 5 10 5H5.5ZM4 5.5C4 4.67157 4.67157 4 5.5 4H10C10.8284 4 11.5 4.67157 11.5 5.5V10C11.5 10.8284 10.8284 11.5 10 11.5H5.5C4.67157 11.5 4 10.8284 4 10V5.5Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 1.5C1.86739 1.5 1.74021 1.55268 1.64645 1.64645C1.55268 1.74021 1.5 1.86739 1.5 2V6.5C1.5 6.63261 1.55268 6.75979 1.64645 6.85355C1.74021 6.94732 1.86739 7 2 7H2.5C2.77614 7 3 7.22386 3 7.5C3 7.77614 2.77614 8 2.5 8H2C1.60217 8 1.22064 7.84196 0.93934 7.56066C0.658035 7.27936 0.5 6.89782 0.5 6.5V2C0.5 1.60218 0.658035 1.22064 0.93934 0.93934C1.22064 0.658035 1.60218 0.5 2 0.5H6.5C6.89782 0.5 7.27936 0.658035 7.56066 0.93934C7.84196 1.22064 8 1.60217 8 2V2.5C8 2.77614 7.77614 3 7.5 3C7.22386 3 7 2.77614 7 2.5V2C7 1.86739 6.94732 1.74021 6.85355 1.64645C6.75979 1.55268 6.63261 1.5 6.5 1.5H2Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_768_9897">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

IconComponent.displayName = 'Copy';

export const Copy12: React.FunctionComponent<IIconProps> = createIcon(IconComponent);
