import { IAction, TEvent } from '@ws/shared/types';

import { getChannel } from '../../redux/middlewares/sagaChannel';
import { getResourceManager } from '../../resources';
import { iDBActService } from '../../resources/iDBAct';
import { EventQueue } from '../EventTrain/EventQueue';
import { EventTrain } from '../EventTrain/EventTrain';
import { SyncState } from '../EventTrain/SyncState';
import { LastUpdateIdKeeper } from '../EventTrain/lastUpdateIdKeeper';
import { syncStateValidator } from '../projectProcessing/SyncStateValidator';

let currentProjectId: string | null = null;
let actualSyncState: SyncState<TEvent> | null = null;
let eventTrain: EventTrain<TEvent> | null = null;

export function cleanSyncProcess() {
  if (eventTrain) {
    eventTrain.stopRun();
    eventTrain.stopWatch();
  }

  currentProjectId = null;
  actualSyncState = null;
  eventTrain = null;
}

export async function initSyncProcess(projectId: string) {
  try {
    if (!actualSyncState) {
      await initSyncState(projectId);
    }

    if (actualSyncState) {
      if (!eventTrain) {
        await initEventTrain(projectId);
      }
    }
  } catch (error) {
    console.log('Error during setProjectEffect', error);
  }
}

export async function initSyncState(projectId: string) {
  const savedSyncState = await iDBActService.getOneById(projectId);

  if (!savedSyncState) {
    const preparedSyncState = new SyncState<TEvent>({
      isSyncEnabled: true,
      projectId,
      lastUpdateIdKeeper: new LastUpdateIdKeeper(null),
      eventQueue: new EventQueue<TEvent>([]),
    });

    const stateToSave = preparedSyncState.toStorage();
    iDBActService.saveOne(stateToSave);

    actualSyncState = preparedSyncState;
  } else {
    const { isOk, value: validSyncState } = syncStateValidator.validate(savedSyncState);

    if (isOk && validSyncState) {
      const preparedSyncState = new SyncState<TEvent>({
        isSyncEnabled: validSyncState.isSyncEnabled,
        projectId: validSyncState.id,
        lastUpdateIdKeeper: new LastUpdateIdKeeper(validSyncState.lastUpdateId),
        eventQueue: new EventQueue<TEvent>(validSyncState.list),
      });

      actualSyncState = preparedSyncState;
    }
  }
}

export async function initEventTrain(projectId: string) {
  if (actualSyncState) {
    console.log('SET EVENT_TRAIN');
    eventTrain = new EventTrain<TEvent>({
      isBlocked: true,
      syncState: actualSyncState,
      onCharge: async (tempState) => {
        const syncStateToSave = tempState.toStorage();
        console.log('onCharge syncStateToSave', syncStateToSave);
        await iDBActService.saveOne(syncStateToSave);
      },
      onApplySuccess: async (tempState) => {
        const syncStateToSave = tempState.toStorage();
        console.log('onApplySuccess syncStateToSave', syncStateToSave);
        await iDBActService.saveOne(syncStateToSave);
      },
      onSyncStateChange: async (tempState) => {
        const syncStateToSave = tempState.toStorage();
        console.log('onSyncStateChange syncStateToSave', syncStateToSave);
        await iDBActService.saveOne(syncStateToSave);
      },
      fetcherFunc: async (event: TEvent, lastUpdateId: string) => {
        const manager = getResourceManager();
        const response = await manager.SyncService.applyEvent({ projectId, event, lastUpdateId });

        return response;
      },
      deltaFunc: async (lastUpdateId: string) => {
        const manager = getResourceManager();
        const response = await manager.SyncService.getDelta({ projectId, lastUpdateId });

        return response;
      },
      communicate: async (action: IAction<any>) => {
        const channel = getChannel();
        channel.put(action);
      },
    });
  }
}

async function checkSyncProject(projectId: string) {
  if (projectId !== currentProjectId) {
    await initSyncProcess(projectId);
    currentProjectId = projectId;
  }
}

export async function getSyncState(projectId: string) {
  await checkSyncProject(projectId);

  if (actualSyncState) {
    return actualSyncState;
  }

  await initSyncState(projectId);

  if (!actualSyncState) {
    throw new Error('No event sync state after init');
  }

  return actualSyncState;
}

export async function getEventTrain(projectId: string) {
  await checkSyncProject(projectId);

  if (eventTrain) {
    return eventTrain;
  }

  await initEventTrain(projectId);

  if (!eventTrain) {
    throw new Error('No event train even after init');
  }

  return eventTrain;
}
