/* eslint-disable max-len */
import * as React from 'react';

import { createIcon, IIconProps, ISVGProps } from '../../internal';

function IconComponent(props: ISVGProps) {
  return (
    <svg
      {...props}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_767_14767)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.8613 0.195262C11.1216 -0.0650874 11.5437 -0.0650874 11.8041 0.195262L14.4708 2.86193C14.7311 3.12228 14.7311 3.54439 14.4708 3.80474L11.8041 6.4714C11.5437 6.73175 11.1216 6.73175 10.8613 6.4714C10.6009 6.21105 10.6009 5.78894 10.8613 5.5286L13.0565 3.33333L10.8613 1.13807C10.6009 0.877722 10.6009 0.455612 10.8613 0.195262Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.66732 3.99996C4.13688 3.99996 3.62818 4.21067 3.2531 4.58575C2.87803 4.96082 2.66732 5.46953 2.66732 5.99996V7.33329C2.66732 7.70148 2.36884 7.99996 2.00065 7.99996C1.63246 7.99996 1.33398 7.70148 1.33398 7.33329V5.99996C1.33398 5.1159 1.68517 4.26806 2.31029 3.64294C2.93542 3.01782 3.78326 2.66663 4.66732 2.66663H14.0007C14.3688 2.66663 14.6673 2.9651 14.6673 3.33329C14.6673 3.70148 14.3688 3.99996 14.0007 3.99996H4.66732Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.13872 9.52864C5.39907 9.78899 5.39907 10.2111 5.13872 10.4714L2.94346 12.6667L5.13872 14.862C5.39907 15.1223 5.39907 15.5444 5.13872 15.8048C4.87837 16.0651 4.45626 16.0651 4.19591 15.8048L1.52925 13.1381C1.2689 12.8778 1.2689 12.4557 1.52925 12.1953L4.19591 9.52864C4.45626 9.26829 4.87837 9.26829 5.13872 9.52864Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.0007 8C14.3688 8 14.6673 8.29848 14.6673 8.66667V10C14.6673 10.8841 14.3161 11.7319 13.691 12.357C13.0659 12.9821 12.218 13.3333 11.334 13.3333H2.00065C1.63246 13.3333 1.33398 13.0349 1.33398 12.6667C1.33398 12.2985 1.63246 12 2.00065 12H11.334C11.8644 12 12.3731 11.7893 12.7482 11.4142C13.1233 11.0391 13.334 10.5304 13.334 10V8.66667C13.334 8.29848 13.6325 8 14.0007 8Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_767_14767">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

IconComponent.displayName = 'Repeat';

export const Repeat16: React.FunctionComponent<IIconProps> = createIcon(IconComponent);
