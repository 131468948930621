/* eslint-disable max-len */
import * as React from 'react';

import { createIcon, IIconProps, ISVGProps } from '../../internal';

function IconComponent(props: ISVGProps) {
  return (
    <svg
      {...props}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M12.7423 13.575L10.834 15.4916V5.83329C10.834 5.61228 10.7462 5.40032 10.5899 5.24404C10.4336 5.08776 10.2217 4.99996 10.0007 4.99996C9.77964 4.99996 9.56768 5.08776 9.4114 5.24404C9.25512 5.40032 9.16732 5.61228 9.16732 5.83329V15.4916L7.25898 13.575C7.10206 13.418 6.88924 13.3299 6.66732 13.3299C6.4454 13.3299 6.23257 13.418 6.07565 13.575C5.91873 13.7319 5.83057 13.9447 5.83057 14.1666C5.83057 14.3885 5.91873 14.6014 6.07565 14.7583L9.40898 18.0916C9.48824 18.1675 9.58169 18.227 9.68398 18.2666C9.78373 18.3107 9.89159 18.3335 10.0007 18.3335C10.1097 18.3335 10.2176 18.3107 10.3173 18.2666C10.4196 18.227 10.5131 18.1675 10.5923 18.0916L13.9256 14.7583C14.0826 14.6014 14.1707 14.3885 14.1707 14.1666C14.1707 13.9447 14.0826 13.7319 13.9256 13.575C13.848 13.4973 13.7557 13.4356 13.6542 13.3936C13.5527 13.3515 13.4439 13.3299 13.334 13.3299C13.1121 13.3299 12.8992 13.418 12.7423 13.575ZM15.834 1.66663H4.16732C3.9463 1.66663 3.73434 1.75442 3.57806 1.9107C3.42178 2.06698 3.33398 2.27895 3.33398 2.49996C3.33398 2.72097 3.42178 2.93293 3.57806 3.08921C3.73434 3.2455 3.9463 3.33329 4.16732 3.33329H15.834C16.055 3.33329 16.267 3.2455 16.4232 3.08921C16.5795 2.93293 16.6673 2.72097 16.6673 2.49996C16.6673 2.27895 16.5795 2.06698 16.4232 1.9107C16.267 1.75442 16.055 1.66663 15.834 1.66663Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}

IconComponent.displayName = 'ArrowFromTop';

export const ArrowFromTop20: React.FunctionComponent<IIconProps> = createIcon(IconComponent);
