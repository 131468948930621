import { Sidebar } from '@ws/shared/components';
import { FileBlank24, FolderOpen24 } from '@ws/shared/icons';
import { usePreview } from 'react-dnd-preview';

interface IItem {
  ref: {
    id: string;
    type: 'note' | 'collection';
  };
}

interface INodePreviewProps {
  name: string;
}

export function NodePreview({ name }: INodePreviewProps) {
  const preview = usePreview<IItem>();

  if (!preview.display) {
    return null;
  }

  const { item, style } = preview;

  return (
    <Sidebar.TreeNodeRow style={style}>
      <Sidebar.TreeNodeTitleWrapper>
        <Sidebar.TreeNodeTitle
          width="100%"
          icon={item.ref.type === 'note' ? <FileBlank24 /> : <FolderOpen24 />}
        >
          {name}
        </Sidebar.TreeNodeTitle>
      </Sidebar.TreeNodeTitleWrapper>
    </Sidebar.TreeNodeRow>
  );
}
