import { ReactNode, useState } from 'react';

import { ModalsManager } from '../ModalsManager';
import { IModal, TCloseAllModals, TCloseModal, TSetModal } from '../types';

import { ModalsContextProvider } from './context';

interface IModalsContextManager {
  children: ReactNode;
}

export function ModalsContextManager({ children }: IModalsContextManager) {
  const [modals, setModals] = useState<IModal[]>([]);
  const setModal: TSetModal = (newModal) => {
    setModals((prevModals) => prevModals.concat(newModal));
  };

  const closeLastModal = () => setModals((prevModals) => prevModals.slice(0, -1));

  const closeModal: TCloseModal = (mark, callback) => {
    if (typeof callback === 'function') {
      callback();
    }

    setTimeout(() => {
      if (mark === null) {
        closeLastModal();
        return;
      }

      setModals((prevModals) => prevModals.slice(0, -Math.abs(mark)));
    }, 0);
  };

  const closeAllModals: TCloseAllModals = () => setModals([]);

  return (
    <ModalsContextProvider
      value={{
        setModal,
        closeModal,
        closeAllModals,
      }}
    >
      {children}
      {modals.length > 0 && (
        <ModalsManager modals={modals} closeModal={closeModal} closeAllModals={closeAllModals} />
      )}
    </ModalsContextProvider>
  );
}
