/* eslint-disable max-len */
import * as React from 'react';

import { createIcon, IIconProps, ISVGProps } from '../../internal';

function IconComponent(props: ISVGProps) {
  return (
    <svg
      {...props}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M2.00065 10H8.66732C8.84413 10 9.0137 9.9298 9.13872 9.80478C9.26375 9.67975 9.33398 9.51019 9.33398 9.33337C9.33398 9.15656 9.26375 8.98699 9.13872 8.86197C9.0137 8.73695 8.84413 8.66671 8.66732 8.66671H2.00065C1.82384 8.66671 1.65427 8.73695 1.52925 8.86197C1.40422 8.98699 1.33398 9.15656 1.33398 9.33337C1.33398 9.51019 1.40422 9.67975 1.52925 9.80478C1.65427 9.9298 1.82384 10 2.00065 10ZM2.00065 7.33337H8.66732C8.84413 7.33337 9.0137 7.26314 9.13872 7.13811C9.26375 7.01309 9.33398 6.84352 9.33398 6.66671C9.33398 6.4899 9.26375 6.32033 9.13872 6.1953C9.0137 6.07028 8.84413 6.00004 8.66732 6.00004H2.00065C1.82384 6.00004 1.65427 6.07028 1.52925 6.1953C1.40422 6.32033 1.33398 6.4899 1.33398 6.66671C1.33398 6.84352 1.40422 7.01309 1.52925 7.13811C1.65427 7.26314 1.82384 7.33337 2.00065 7.33337ZM2.00065 4.66671H14.0007C14.1775 4.66671 14.347 4.59647 14.4721 4.47145C14.5971 4.34642 14.6673 4.17685 14.6673 4.00004C14.6673 3.82323 14.5971 3.65366 14.4721 3.52864C14.347 3.40361 14.1775 3.33337 14.0007 3.33337H2.00065C1.82384 3.33337 1.65427 3.40361 1.52925 3.52864C1.40422 3.65366 1.33398 3.82323 1.33398 4.00004C1.33398 4.17685 1.40422 4.34642 1.52925 4.47145C1.65427 4.59647 1.82384 4.66671 2.00065 4.66671ZM14.0007 11.3334H2.00065C1.82384 11.3334 1.65427 11.4036 1.52925 11.5286C1.40422 11.6537 1.33398 11.8232 1.33398 12C1.33398 12.1769 1.40422 12.3464 1.52925 12.4714C1.65427 12.5965 1.82384 12.6667 2.00065 12.6667H14.0007C14.1775 12.6667 14.347 12.5965 14.4721 12.4714C14.5971 12.3464 14.6673 12.1769 14.6673 12C14.6673 11.8232 14.5971 11.6537 14.4721 11.5286C14.347 11.4036 14.1775 11.3334 14.0007 11.3334Z"
          fill="currentColor"
        />
        <path
          d="M13.5379 9.60626C13.6037 9.66993 13.6819 9.71935 13.7677 9.75144C13.8535 9.78353 13.945 9.7976 14.0364 9.79277C14.1279 9.78795 14.2174 9.76433 14.2993 9.72338C14.3812 9.68244 14.4539 9.62506 14.5126 9.55481C14.5714 9.48456 14.6151 9.40296 14.6409 9.31509C14.6667 9.22722 14.6742 9.13497 14.6628 9.04409C14.6514 8.95322 14.6214 8.86566 14.5747 8.78689C14.5279 8.70812 14.4655 8.63982 14.3912 8.58626L13.6712 7.98626L14.3912 7.38626C14.4587 7.3303 14.5145 7.26156 14.5554 7.184C14.5963 7.10643 14.6215 7.02156 14.6295 6.93424C14.6376 6.84692 14.6283 6.75888 14.6023 6.67514C14.5763 6.5914 14.534 6.51363 14.4779 6.44626C14.4152 6.37101 14.3367 6.31049 14.248 6.26901C14.1592 6.22752 14.0625 6.2061 13.9645 6.20626C13.8079 6.20782 13.6569 6.26447 13.5379 6.36626L12.2045 7.47293C12.129 7.53548 12.0683 7.61392 12.0266 7.70265C11.9848 7.79138 11.9632 7.88822 11.9632 7.98626C11.9632 8.08431 11.9848 8.18114 12.0266 8.26987C12.0683 8.3586 12.129 8.43704 12.2045 8.4996L13.5379 9.60626Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}

IconComponent.displayName = 'AlignLess';

export const AlignLess16: React.FunctionComponent<IIconProps> = createIcon(IconComponent);
