import { TResponse, Notifier, TIsOk } from '../../types';
import { Fetcher, getErrorMessage } from '../../utils';

import { AuthRepository } from './Auth.repository';
import { IAuthLoginUser, IAuthSignUpUser } from './types';

export class AuthService {
  private repository: AuthRepository;
  private notifier: Notifier;

  constructor(fetcher: Fetcher, notifier: Notifier) {
    this.repository = new AuthRepository(fetcher);
    this.notifier = notifier;
  }

  public async signUp(user: IAuthSignUpUser): Promise<TIsOk> {
    let response: TResponse<{ id: string }>;

    try {
      response = await this.repository.signUp(user);
    } catch (err) {
      this.notifier.error(getErrorMessage(err));
      return { isOk: false };
    }

    if (response.error) {
      this.notifier.error(response.error.toString());
      return { isOk: false };
    }

    return { isOk: true };
  }

  public async login(user: IAuthLoginUser): Promise<TIsOk> {
    let response: TResponse<void>;

    try {
      response = await this.repository.login(user);
    } catch (err) {
      this.notifier.error(getErrorMessage(err));
      return { isOk: false };
    }

    if (response.error) {
      this.notifier.error(response.error.toString());
      return { isOk: false };
    }

    return { isOk: true };
  }

  public async logout(): Promise<TIsOk> {
    let response: TResponse<{ id: string }>;

    try {
      response = await this.repository.logout();
    } catch (err) {
      this.notifier.error(getErrorMessage(err));
      return { isOk: false };
    }

    if (response.error) {
      this.notifier.error(response.error.toString());
      return { isOk: false };
    }

    return { isOk: true };
  }
}
