import { EMcsName } from '@ws/shared/utils';

const PROJECT_ENDPOINT_BASE = 'v1/project';

export function BUILD_PROJECT_DETAILS_BY_ID_ENDPOINT_CONF(projectId: string) {
  return {
    base: `${PROJECT_ENDPOINT_BASE}/${projectId}/details`,
    mcsName: EMcsName.TEXT,
    access: 'private',
  };
}

export function BUILD_PROJECT_DELETE_BY_ID_ENDPOINT_CONF(projectId: string) {
  return {
    base: `${PROJECT_ENDPOINT_BASE}/${projectId}/delete`,
    mcsName: EMcsName.TEXT,
    access: 'private',
  };
}

export const PROJECT_GET_SHORT_LIST_ENDPOINT_CONF = {
  base: `${PROJECT_ENDPOINT_BASE}/short-list/get`,
  mcsName: EMcsName.TEXT,
  access: 'private',
};

export function BUILD_PROJECT_CHECK_ENDPOINT_CONF(projectId: string) {
  return {
    base: `${PROJECT_ENDPOINT_BASE}/${projectId}/check`,
    mcsName: EMcsName.TEXT,
    access: 'private',
  };
}
