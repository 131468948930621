import { call, put, takeLeading } from 'typed-redux-saga';

import { getResourceManager } from '../../resources';
import { IAction } from '../../types/redux';
import { createApiActionError } from '../../utils/createApiActionError';

import { getProjectsByUser } from './remote.actions';
import { IProjectsGetByUserSuccessPayload, PROJECTS_GET_BY_USER } from './remote.types';

function* getProjectsByUserWorker(action: IAction<{ login: string }>) {
  console.log('getProjectsByUserWorker');
  if (!action || !action.payload) {
    return;
  }

  yield* put(getProjectsByUser.request());

  try {
    const manager = getResourceManager();
    const response = yield* call(manager.ProjectService.getList.bind(manager.ProjectService));

    if (response.status === 200 && response.projects) {
      const { projects } = response;
      yield* put(getProjectsByUser.success<IProjectsGetByUserSuccessPayload>({ projects }));
    } else {
      yield* put(getProjectsByUser.failure());
    }
  } catch (error) {
    yield* put(getProjectsByUser.failure(null, createApiActionError(error)));
  }
}

export function* remoteProjectsWatcher() {
  yield* takeLeading(PROJECTS_GET_BY_USER.DEFAULT, getProjectsByUserWorker);
}
