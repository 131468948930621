/* eslint-disable max-len */
import * as React from 'react';

import { createIcon, IIconProps, ISVGProps } from '../../internal';

function IconComponent(props: ISVGProps) {
  return (
    <svg
      {...props}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.16602 16.6667C9.16602 16.2065 9.53911 15.8334 9.99935 15.8334H17.4993C17.9596 15.8334 18.3327 16.2065 18.3327 16.6667C18.3327 17.1269 17.9596 17.5 17.4993 17.5H9.99935C9.53911 17.5 9.16602 17.1269 9.16602 16.6667Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.9994 3.23222C14.7515 3.23222 14.5139 3.33067 14.3386 3.50591L4.08517 13.7594L3.64467 15.5213L5.40666 15.0809L15.6601 4.8274C15.7469 4.74063 15.8157 4.63761 15.8627 4.52424C15.9096 4.41087 15.9338 4.28936 15.9338 4.16665C15.9338 4.04394 15.9096 3.92243 15.8627 3.80906C15.8157 3.69569 15.7469 3.59268 15.6601 3.50591C15.5733 3.41914 15.4703 3.35031 15.357 3.30335C15.2436 3.25639 15.1221 3.23222 14.9994 3.23222ZM13.1601 2.3274C13.6479 1.8396 14.3095 1.56555 14.9994 1.56555C15.3409 1.56555 15.6792 1.63283 15.9948 1.76355C16.3103 1.89427 16.5971 2.08586 16.8386 2.3274C17.0802 2.56893 17.2718 2.85567 17.4025 3.17125C17.5332 3.48683 17.6005 3.82507 17.6005 4.16665C17.6005 4.50823 17.5332 4.84647 17.4025 5.16205C17.2718 5.47763 17.0802 5.76437 16.8386 6.00591L6.42196 16.4226C6.31516 16.5294 6.18134 16.6051 6.03481 16.6418L2.70148 17.4751C2.4175 17.5461 2.11709 17.4629 1.91011 17.2559C1.70313 17.0489 1.61992 16.7485 1.69091 16.4645L2.52425 13.1312C2.56088 12.9847 2.63665 12.8509 2.74344 12.7441L13.1601 2.3274Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}

IconComponent.displayName = 'Edit';

export const Edit20: React.FunctionComponent<IIconProps> = createIcon(IconComponent);
