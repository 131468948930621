import { Button, ContextMenu, Popover, TContextMenu } from '@ws/shared/components';
import { Eye16, EyeOff16, MoreHorizontal16, Type16 } from '@ws/shared/icons';
import { TEntity } from '@ws/shared/types';
import { isNotDesktop } from '@ws/shared/utils';
import { classes } from 'classifizer';
import { useCallback, useContext, useMemo, useState } from 'react';

import { ResolutionContext } from '../../../../context';
import { TOnHideButtonClick, TOnWithTitleButtonClick } from '../types';

import styles from './RecursiveNodes.module.css';

interface INodeTools {
  node: TEntity;
  onHideButtonClick: TOnHideButtonClick;
  onWithTitleClick: TOnWithTitleButtonClick;
}

export function NodeTools({ node, onWithTitleClick, onHideButtonClick }: INodeTools) {
  const { resolution } = useContext(ResolutionContext);
  const [open, setOpen] = useState(false);
  const showPopup = isNotDesktop(resolution);

  const toggleKebab = useCallback(() => setOpen((prevState) => !prevState), []);

  const items = useMemo<TContextMenu>(
    () => [
      {
        leftIcon: <Type16 />,
        onClick: onWithTitleClick(node, node.meta.withTitle),
        label: 'Отобразить заголовок',
        className: node.meta.withTitle ? styles['tool-item_with-title_active'] : undefined,
      },
      {
        leftIcon: node.meta.isHidden ? <EyeOff16 /> : <Eye16 />,
        onClick: onHideButtonClick(node, node.meta.isHidden),
        label: `Отобразить ${node.type === 'note' ? 'заметку' : 'коллекцию'}`,
        className: node.meta.isHidden ? styles['tool-item_is-hidden_active'] : undefined,
      },
    ],
    [node, onHideButtonClick, onWithTitleClick],
  );

  if (showPopup) {
    return (
      <Popover.Container open={open}>
        <Popover.Trigger onClick={toggleKebab}>
          <MoreHorizontal16 />
        </Popover.Trigger>

        <Popover.Content withoutPaddings>
          <ContextMenu items={items} />
        </Popover.Content>
      </Popover.Container>
    );
  }

  return (
    <>
      <Button
        className={classes(
          styles['tool-item'],
          styles['with-title'],
          node.meta.withTitle && styles['tool-item_active'],
          node.meta.withTitle && styles['tool-item_with-title_active'],
        )}
        onClick={onWithTitleClick(node, node.meta.withTitle)}
        variant="shapeless"
        icon={<Type16 />}
      />
      <Button
        className={classes(
          styles['tool-item'],
          node.meta.isHidden && styles['tool-item_active'],
          node.meta.isHidden && styles['tool-item_is-hidden_active'],
        )}
        onClick={onHideButtonClick(node, node.meta.isHidden)}
        variant="shapeless"
        icon={node.meta.isHidden ? <EyeOff16 /> : <Eye16 />}
      />
    </>
  );
}
