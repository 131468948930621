import { useState } from 'react';

import { IModalCustomViewProps } from '../../Modals';
import { Spinner } from '../../Spinner';
import { FrameMessageReceiver } from '../FrameMessageReceiver';

import styles from './EmbeddedAuthServiceModal.module.css';

export function showEmbeddedAuthServiceModal({
  authFrameOrigin,
  serviceName,
  getUserInfo,
}: {
  authFrameOrigin: string;
  serviceName: string;
  getUserInfo(): void;
}) {
  return function EmbeddedAuthServiceModal({
    closeModal,
    components: { ModalCloseButton },
  }: IModalCustomViewProps) {
    const [isLoading, setIsLoading] = useState(true);
    const { origin } = window.location;

    const handleCloseModal = () => closeModal(null);
    const handleLoadingOff = () => setIsLoading(false);

    return (
      <div className={styles['embedded-auth']}>
        {isLoading && (
          <div className={styles['embedded-auth__loader-wrapper']}>
            <Spinner />
          </div>
        )}

        <ModalCloseButton onClose={handleCloseModal} />

        <FrameMessageReceiver authFrameOrigin={authFrameOrigin} getUserInfo={getUserInfo} />

        <iframe
          onLoad={handleLoadingOff}
          className={styles['embedded-auth__iframe']}
          title="auth_service"
          src={`${authFrameOrigin}?mode=EMBEDDED&service=${serviceName}&coords=${origin}&time=${Date.now()}`}
        />
      </div>
    );
  };
}
