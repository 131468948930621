import { IAction } from '@ws/shared/types';
import { toast } from 'react-toastify';
import { call, delay, put, takeEvery } from 'typed-redux-saga';

import { getResourceManager } from '../../resources/ResourceManager';
import { createApiActionError } from '../../utils/createApiActionError';
import { setNoAuthForEventTrain } from '../sync/sync.actions';
import { START_SYNC } from '../sync/sync.types';

import { getUserInfo, logoutUser } from './user.actions';
import {
  IAuthStatusAction,
  IUserGetInfoSuccess,
  USER_GET_INFO,
  USER_LOGOUT,
  USER_SET_AUTH_STATUS,
} from './user.types';

function* getUserInfoSaga() {
  yield* put(getUserInfo.request());

  try {
    const resourceManager = getResourceManager();
    const getInfo = resourceManager.UserService.getInfo.bind(resourceManager.UserService);
    const checkData = yield* call(getInfo);

    if (checkData.isOk && checkData.customer) {
      yield* put(getUserInfo.success<IUserGetInfoSuccess>({ customer: checkData.customer }));
      yield* put({ type: START_SYNC });
    } else {
      yield* put(
        getUserInfo.failure(null, { errorMessage: 'Error occurred during user data fetch!' }),
      );
    }
  } catch (error) {
    yield* put(getUserInfo.failure(null, createApiActionError(error)));
  }
}

function* logoutSaga() {
  yield* put(logoutUser.request());

  try {
    const resourceManager = getResourceManager();
    const logoutFunc = resourceManager.AuthService.logout.bind(resourceManager.AuthService);
    const { isOk } = yield* call(logoutFunc);

    if (isOk) {
      toast.success('Successfully logged out!');
    } else {
      toast.error('Something went wrong!');
    }

    yield* delay(300);
    yield* put(getUserInfo());
  } catch (error) {
    yield* put(logoutUser.failure(null, createApiActionError(error)));
  }
}

function* changeUserAuthStatusEffect(action: IAction<IAuthStatusAction>) {
  if (action.payload?.isAuth) {
    yield* put({ type: START_SYNC });
  } else {
    yield* put(setNoAuthForEventTrain());
  }
}

export function* userWatch() {
  yield* takeEvery(USER_GET_INFO.DEFAULT, getUserInfoSaga);
  yield* takeEvery(USER_LOGOUT.DEFAULT, logoutSaga);
  yield* takeEvery(USER_SET_AUTH_STATUS, changeUserAuthStatusEffect);
}
