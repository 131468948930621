/* eslint-disable max-len */
import * as React from 'react';

import { createIcon, IIconProps, ISVGProps } from '../../internal';

function IconComponent(props: ISVGProps) {
  return (
    <svg
      {...props}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.66602 16.6667C6.66602 16.2065 7.03911 15.8334 7.49935 15.8334H12.4993C12.9596 15.8334 13.3327 16.2065 13.3327 16.6667C13.3327 17.1269 12.9596 17.5 12.4993 17.5H7.49935C7.03911 17.5 6.66602 17.1269 6.66602 16.6667Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.5 3.33333C2.5 2.8731 2.8731 2.5 3.33333 2.5H16.6667C17.1269 2.5 17.5 2.8731 17.5 3.33333V5.83333C17.5 6.29357 17.1269 6.66667 16.6667 6.66667C16.2064 6.66667 15.8333 6.29357 15.8333 5.83333V4.16667H4.16667V5.83333C4.16667 6.29357 3.79357 6.66667 3.33333 6.66667C2.8731 6.66667 2.5 6.29357 2.5 5.83333V3.33333Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.99935 2.5C10.4596 2.5 10.8327 2.8731 10.8327 3.33333V16.6667C10.8327 17.1269 10.4596 17.5 9.99935 17.5C9.53911 17.5 9.16602 17.1269 9.16602 16.6667V3.33333C9.16602 2.8731 9.53911 2.5 9.99935 2.5Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}

IconComponent.displayName = 'Type';

export const Type20: React.FunctionComponent<IIconProps> = createIcon(IconComponent);
