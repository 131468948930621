import { classes } from 'classifizer';
import { Collection, Header, Section, ListBox, ListBoxProps, Text } from 'react-aria-components';

import { TOption } from '../../types';
import { isSectionOption } from '../../utils';

import styles from './List.module.css';
import { ListItem } from './ListItem';

interface IListProps extends Omit<ListBoxProps<TOption>, 'items' | 'children'> {
  options: TOption[];
}

export function List({ options, className, ...props }: IListProps) {
  return (
    <ListBox {...props} items={options} className={classes(styles.list, className)}>
      {(item) => {
        if (isSectionOption(item)) {
          return (
            <Section id={item.name} items={item.children}>
              <Header>{item.name}</Header>
              <Collection items={item.children}>
                {(children) => (
                  <ListItem id={children.value} textValue={children.label}>
                    <Text slot="label">{children.label}</Text>
                    {children.description && <Text slot="description">{children.description}</Text>}
                  </ListItem>
                )}
              </Collection>
            </Section>
          );
        }
        return (
          <ListItem id={item.value} textValue={item.label}>
            <Text slot="label">{item.label}</Text>
            {item.description && <Text slot="description">{item.description}</Text>}
          </ListItem>
        );
      }}
    </ListBox>
  );
}
