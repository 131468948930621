import { TProjects, TProjectWithVersion, TProject } from '@ws/shared/types';

import { projectAdaptor } from './ProjectAdaptor';
import { projectValidator } from './ProjectValidator';
import { Processor } from './types';

export class ProjectProcessor extends Processor<TProject> {
  private hasVersion(project: TProjects): project is TProjectWithVersion<TProjects> {
    return 'version' in project && project.version !== undefined;
  }

  private extendProjectWithVersion(project: TProjects): TProjectWithVersion<TProjects> {
    if (this.hasVersion(project)) {
      return project;
    }

    // Only first project version didn't have version field
    return Object.assign(project, { version: 1 });
  }

  public process(project?: TProjects | null) {
    if (!project) {
      return this.createFailureResponse(this.createProjectMissingMessage());
    }

    const projectWithVersion = this.extendProjectWithVersion(project);
    const validatedProject = projectValidator.validate(projectWithVersion);

    if (this.hasError(validatedProject)) {
      return this.createFailureResponse(validatedProject.error);
    }

    const adaptedProject = projectAdaptor.adapt(projectWithVersion);

    if (this.hasError(adaptedProject)) {
      return this.createFailureResponse(adaptedProject.error);
    }

    return this.createSuccessResponse(adaptedProject.value);
  }
}

export const projectProcessor = new ProjectProcessor();
