import { useMutation } from '@tanstack/react-query';
import { Button } from '@ws/shared/components';
import { External16, Split16, Sync12 } from '@ws/shared/icons';
import { ICreatePreviewProps } from '@ws/shared/resources';
import { TPreview } from '@ws/shared/types';
import { getCompiledContentOfEntity } from '@ws/shared/utils';
import { useCallback, useContext, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { getConfig } from '../../../config/config';
import { EditabilityContext } from '../../../context';
import { TAppState } from '../../../redux';
import { setSelectedSecondaryNode } from '../../../redux/editor';
import { setGlobalIsPreviewsOpen } from '../../../redux/general/general.actions';
import { getResourceManager } from '../../../resources';
import { Tools } from '../Tools';

import styles from './PreviewListItem.module.css';

interface PreviewListItemProps {
  preview: TPreview;
  currentHash: string;
}

export function PreviewListItem({ preview, currentHash }: PreviewListItemProps) {
  const { mutate } = useMutation(
    (params: ICreatePreviewProps) => {
      return getResourceManager().PreviewService.createPreview(params);
    },
    {
      networkMode: 'offlineFirst',
      onSuccess() {
        toast.success('Предпросмотр перезаписан успешно!');
      },
    },
  );
  const dispatch = useDispatch();
  const { project } = useSelector((state: TAppState) => state.editor);
  const { selected } = useSelector((state: TAppState) => state.editor);
  const { getNodesByRefs, getNodeByRef } = useContext(EditabilityContext);
  const entity = useMemo(
    () => (selected ? getNodeByRef(selected) : selected),
    [getNodeByRef, selected],
  );
  const compiled = useMemo(
    () => (entity ? getCompiledContentOfEntity(entity, getNodesByRefs) : null),
    [getNodesByRefs, entity],
  );
  const previewUrl = `${getConfig().previewsClientOrigin}/link/${preview.linkKey}`;

  const handleDrawerClose = useCallback(() => {
    dispatch(setGlobalIsPreviewsOpen({ isPreviewsOpen: false }));
  }, [dispatch]);

  const handleSecondaryBoardOpen = useCallback(() => {
    handleDrawerClose();
    dispatch(setSelectedSecondaryNode({ secondarySelected: selected }));
  }, [dispatch, handleDrawerClose, selected]);

  const handleUpdatePreview = () => {
    if (!entity || !compiled || !project) {
      return;
    }

    mutate(
      {
        projectId: project.id,
        entityId: entity.id,
        title: preview.title,
        accessType: 'public',
        accessGroup: 'read_only',
        ...compiled,
      },
      {
        onSuccess() {
          toast.success('mutate sucs');
        },
      },
    );
  };

  return (
    <div className={styles['preview']}>
      <div className={styles['preview__header']}>
        <div className={styles['preview__title-wrapper']}>
          <div className={styles['preview__title']}>{preview.title}</div>

          <Tools previewId={preview.id} />
        </div>

        <div className={styles['preview__subtitle']}>
          <span className={styles['preview__text']}>Нет рецензий</span>
          {preview.hash !== currentHash && (
            <Button
              icon={<Sync12 />}
              variant="shapeless"
              color="secondary"
              className={styles['preview__update']}
              onClick={handleUpdatePreview}
            >
              Перезаписать
            </Button>
          )}
        </div>
      </div>

      <div className={styles['preview__content']}>
        <div className={styles['preview__actions-group']}>
          <Button
            className={styles['preview__action']}
            icon={<Split16 />}
            color="secondary"
            size="m"
            onClick={handleSecondaryBoardOpen}
          >
            Открыть на этом экране
          </Button>
          <Button
            className={styles['preview__action']}
            icon={<External16 />}
            color="secondary"
            size="m"
            href={previewUrl}
            target="_blank"
          >
            Перейти к предпоказу
          </Button>
        </div>
      </div>
    </div>
  );
}
