/* eslint-disable max-len */
import * as React from 'react';

import { createIcon, IIconProps, ISVGProps } from '../../internal';

function IconComponent(props: ISVGProps) {
  return (
    <svg
      {...props}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M12.6673 3.66664H8.48065L8.26732 2.99997C8.12902 2.60879 7.87248 2.27031 7.53325 2.03141C7.19402 1.79252 6.78889 1.66504 6.37398 1.66664H3.33398C2.80355 1.66664 2.29484 1.87735 1.91977 2.25243C1.5447 2.6275 1.33398 3.13621 1.33398 3.66664V12.3333C1.33398 12.8637 1.5447 13.3724 1.91977 13.7475C2.29484 14.1226 2.80355 14.3333 3.33398 14.3333H12.6673C13.1978 14.3333 13.7065 14.1226 14.0815 13.7475C14.4566 13.3724 14.6673 12.8637 14.6673 12.3333V5.66664C14.6673 5.13621 14.4566 4.6275 14.0815 4.25243C13.7065 3.87735 13.1978 3.66664 12.6673 3.66664ZM13.334 12.3333C13.334 12.5101 13.2637 12.6797 13.1387 12.8047C13.0137 12.9297 12.8441 13 12.6673 13H3.33398C3.15717 13 2.9876 12.9297 2.86258 12.8047C2.73756 12.6797 2.66732 12.5101 2.66732 12.3333V3.66664C2.66732 3.48983 2.73756 3.32026 2.86258 3.19524C2.9876 3.07021 3.15717 2.99997 3.33398 2.99997H6.37398C6.51375 2.99961 6.65009 3.04319 6.76374 3.12454C6.87739 3.20588 6.96259 3.32089 7.00732 3.45331L7.36732 4.54664C7.41204 4.67905 7.49725 4.79406 7.6109 4.87541C7.72455 4.95676 7.86089 5.00034 8.00065 4.99997H12.6673C12.8441 4.99997 13.0137 5.07021 13.1387 5.19524C13.2637 5.32026 13.334 5.48983 13.334 5.66664V12.3333Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}

IconComponent.displayName = 'Folder';

export const Folder16: React.FunctionComponent<IIconProps> = createIcon(IconComponent);
