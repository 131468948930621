import { TEntityRef } from '@ws/shared/types';

export function removeFromArrayIfItemsExist(
  arrayToClean: TEntityRef[],
  itemsToRemove: TEntityRef[],
) {
  const cleanArray: TEntityRef[] = [];

  for (let i = 0; i < arrayToClean.length; i += 1) {
    const itemToCheck = arrayToClean[i];

    const idx = itemsToRemove.findIndex((item) => item.id === itemToCheck.id);
    if (idx === -1) {
      cleanArray.push(itemToCheck);
    }
  }

  return cleanArray;
}
