/* eslint-disable max-len */
import * as React from 'react';

import { createIcon, IIconProps, ISVGProps } from '../../internal';

function IconComponent(props: ISVGProps) {
  return (
    <svg
      {...props}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.33398 3.33335C3.11297 3.33335 2.90101 3.42115 2.74473 3.57743C2.58845 3.73371 2.50065 3.94567 2.50065 4.16669V15.8334C2.50065 16.0544 2.58845 16.2663 2.74473 16.4226C2.90101 16.5789 3.11297 16.6667 3.33398 16.6667H16.6673C16.8883 16.6667 17.1003 16.5789 17.2566 16.4226C17.4129 16.2663 17.5006 16.0544 17.5006 15.8334V6.66669C17.5006 6.44567 17.4129 6.23371 17.2566 6.07743C17.1003 5.92115 16.8883 5.83335 16.6673 5.83335H9.16732C8.88869 5.83335 8.6285 5.6941 8.47394 5.46227L7.05466 3.33335H3.33398ZM1.56622 2.39892C2.03506 1.93008 2.67094 1.66669 3.33398 1.66669H7.50065C7.77928 1.66669 8.03947 1.80594 8.19403 2.03777L9.6133 4.16669H16.6673C17.3304 4.16669 17.9662 4.43008 18.4351 4.89892C18.9039 5.36776 19.1673 6.00365 19.1673 6.66669V15.8334C19.1673 16.4964 18.9039 17.1323 18.4351 17.6011C17.9662 18.07 17.3304 18.3334 16.6673 18.3334H3.33398C2.67094 18.3334 2.03506 18.07 1.56622 17.6011C1.09738 17.1323 0.833984 16.4964 0.833984 15.8334V4.16669C0.833984 3.50365 1.09738 2.86776 1.56622 2.39892Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.99935 8.33331C10.4596 8.33331 10.8327 8.70641 10.8327 9.16665V14.1666C10.8327 14.6269 10.4596 15 9.99935 15C9.53911 15 9.16602 14.6269 9.16602 14.1666V9.16665C9.16602 8.70641 9.53911 8.33331 9.99935 8.33331Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.66602 11.6666C6.66602 11.2064 7.03911 10.8333 7.49935 10.8333H12.4993C12.9596 10.8333 13.3327 11.2064 13.3327 11.6666C13.3327 12.1269 12.9596 12.5 12.4993 12.5H7.49935C7.03911 12.5 6.66602 12.1269 6.66602 11.6666Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}

IconComponent.displayName = 'FolderPlus';

export const FolderPlus20: React.FunctionComponent<IIconProps> = createIcon(IconComponent);
