/* eslint-disable max-len */
import * as React from 'react';

import { createIcon, IIconProps, ISVGProps } from '../../internal';

function IconComponent(props: ISVGProps) {
  return (
    <svg
      {...props}
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_768_9953)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.64645 8.14645C3.84171 7.95118 4.15829 7.95118 4.35355 8.14645L6 9.79289L7.64645 8.14645C7.84171 7.95118 8.15829 7.95118 8.35355 8.14645C8.54882 8.34171 8.54882 8.65829 8.35355 8.85355L6.35355 10.8536C6.15829 11.0488 5.84171 11.0488 5.64645 10.8536L3.64645 8.85355C3.45118 8.65829 3.45118 8.34171 3.64645 8.14645Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6 5.5C6.27614 5.5 6.5 5.72386 6.5 6V10.5C6.5 10.7761 6.27614 11 6 11C5.72386 11 5.5 10.7761 5.5 10.5V6C5.5 5.72386 5.72386 5.5 6 5.5Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.33437 0.997048C5.01461 0.972585 5.69154 1.10276 6.31419 1.37777C6.93684 1.65278 7.489 2.06546 7.92908 2.58474C8.28433 3.00392 8.55908 3.48374 8.74079 3.99997L9.00019 3.99997C9.63775 3.99955 10.2593 4.20226 10.7738 4.5787C11.2885 4.95522 11.6698 5.48595 11.8624 6.09386C12.055 6.70178 12.0489 7.35525 11.845 7.95946C11.641 8.56366 11.2498 9.08715 10.7282 9.45397C10.5023 9.61281 10.1904 9.55846 10.0316 9.33258C9.87273 9.10669 9.92708 8.79481 10.153 8.63597C10.5007 8.39142 10.7615 8.04243 10.8975 7.63963C11.0335 7.23682 11.0375 6.80117 10.9091 6.3959C10.7807 5.99062 10.5265 5.6368 10.1834 5.38579C9.84028 5.13478 9.4261 4.99963 9.00097 4.99997H8.37058C8.1423 4.99997 7.943 4.84535 7.88627 4.62424C7.75468 4.11143 7.50848 3.63516 7.16619 3.23127C6.82391 2.82739 6.39446 2.50641 5.91017 2.29252C5.42588 2.07862 4.89939 1.97738 4.37031 1.9964C3.84123 2.01543 3.32337 2.15423 2.8557 2.40236C2.38803 2.65049 1.98274 3.00148 1.67035 3.42891C1.35796 3.85634 1.1466 4.34907 1.05219 4.87C0.957785 5.39094 0.982783 5.9265 1.12531 6.43637C1.26783 6.94625 1.52417 7.41714 1.87502 7.81361C2.05802 8.02041 2.03873 8.33641 1.83193 8.51941C1.62513 8.70241 1.30914 8.68312 1.12614 8.47632C0.675045 7.96657 0.345471 7.36113 0.162226 6.70558C-0.0210196 6.05003 -0.0531609 5.36145 0.0682215 4.69168C0.189604 4.02191 0.461347 3.3884 0.862993 2.83885C1.26464 2.2893 1.78572 1.83802 2.38701 1.519C2.9883 1.19997 3.65413 1.02151 4.33437 0.997048Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_768_9953">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

IconComponent.displayName = 'DownloadCloud';

export const DownloadCloud12: React.FunctionComponent<IIconProps> = createIcon(IconComponent);
