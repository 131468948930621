import { NonNullableFile, TFiles, TFileWithVersion, TProcessingResult } from '@ws/shared/types';

import { fileAdaptor } from './FileAdaptor';
import { fileValidator } from './FileValidator';
import { projectProcessor } from './ProjectProcessor';
import { syncStateProcessor } from './SyncStateProcessor';
import { Processor } from './types';

export class FileProcessor extends Processor<NonNullableFile> {
  private hasFileVersion(file: TFiles): file is TFileWithVersion<TFiles> {
    return 'fileVersion' in file && file.fileVersion !== undefined;
  }

  private extendProjectWithVersion(file: TFiles): TFileWithVersion<TFiles> {
    if (this.hasFileVersion(file)) {
      return file;
    }

    // Only first file version didn't have version field
    return Object.assign(file, { fileVersion: 1 });
  }

  public process(file: TFiles) {
    if (!file) {
      return this.createFailureResponse(this.createFileMissingMessage());
    }

    const fileWithVersion = this.extendProjectWithVersion(file);
    const validatedFile = fileValidator.validate(fileWithVersion) as TProcessingResult<
      TFileWithVersion<TFiles>
    >;

    if (this.hasError(validatedFile)) {
      return this.createFailureResponse(validatedFile.error);
    }

    const adaptedFile = fileAdaptor.adapt(fileWithVersion); // {isOk, error, project}

    if (this.hasError(adaptedFile)) {
      return this.createFailureResponse(adaptedFile.error);
    }

    const processedProject = projectProcessor.process(adaptedFile.value.project);
    const processedSyncState = syncStateProcessor.process(adaptedFile.value.syncState);

    if (this.hasError(processedProject)) {
      return this.createFailureResponse(processedProject.error);
    }

    if (this.hasError(processedSyncState)) {
      return this.createFailureResponse(processedSyncState.error);
    }

    const preparedFile: NonNullableFile = {
      fileVersion: adaptedFile.value.fileVersion,
      project: processedProject.value,
      // TODO Delete hardcode
      syncState: {
        version: 1,
        isSyncEnabled: false,
        list: [],
        lastUpdateId: null,
        id: processedProject.value.id,
      },
    };

    return this.createSuccessResponse(preparedFile);
  }
}

export const fileProcessor = new FileProcessor();
