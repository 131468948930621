import { FunctionComponent } from 'react';

import { colorsClassesMap } from '../../../styles/colors';
import colorsStyles from '../../../styles/colors/colors.module.css';

import { IIconProps, ISVGProps } from './types';

export const createIcon = (IconComponent: FunctionComponent<ISVGProps>) => {
  function Icon(props: IIconProps) {
    const { color = '', ...svgProps } = props;

    return (
      <IconComponent {...svgProps} className={color && colorsStyles[colorsClassesMap[color]]} />
    );
  }

  Icon.displayName = `${IconComponent.displayName}Icon`;

  return Icon;
};
