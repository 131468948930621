/* eslint-disable max-len */
import * as React from 'react';

import { createIcon, IIconProps, ISVGProps } from '../../internal';

function IconComponent(props: ISVGProps) {
  return (
    <svg
      {...props}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.33398 4.00004C1.33398 3.63185 1.63246 3.33337 2.00065 3.33337H14.0007C14.3688 3.33337 14.6673 3.63185 14.6673 4.00004C14.6673 4.36823 14.3688 4.66671 14.0007 4.66671H2.00065C1.63246 4.66671 1.33398 4.36823 1.33398 4.00004Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.66602 1.99996C6.4892 1.99996 6.31964 2.0702 6.19461 2.19522C6.06959 2.32025 5.99935 2.48982 5.99935 2.66663V3.99996C5.99935 4.36815 5.70087 4.66663 5.33268 4.66663C4.96449 4.66663 4.66602 4.36815 4.66602 3.99996V2.66663C4.66602 2.13619 4.87673 1.62748 5.2518 1.25241C5.62687 0.87734 6.13558 0.666626 6.66602 0.666626H9.33268C9.86311 0.666626 10.3718 0.87734 10.7469 1.25241C11.122 1.62748 11.3327 2.13619 11.3327 2.66663V3.99996C11.3327 4.36815 11.0342 4.66663 10.666 4.66663C10.2978 4.66663 9.99935 4.36815 9.99935 3.99996V2.66663C9.99935 2.48982 9.92911 2.32025 9.80409 2.19522C9.67906 2.0702 9.50949 1.99996 9.33268 1.99996H6.66602ZM3.33268 3.33329C3.70087 3.33329 3.99935 3.63177 3.99935 3.99996V13.3333C3.99935 13.5101 4.06959 13.6797 4.19461 13.8047C4.31964 13.9297 4.4892 14 4.66602 14H11.3327C11.5095 14 11.6791 13.9297 11.8041 13.8047C11.9291 13.6797 11.9993 13.5101 11.9993 13.3333V3.99996C11.9993 3.63177 12.2978 3.33329 12.666 3.33329C13.0342 3.33329 13.3327 3.63177 13.3327 3.99996V13.3333C13.3327 13.8637 13.122 14.3724 12.7469 14.7475C12.3718 15.1226 11.8631 15.3333 11.3327 15.3333H4.66602C4.13558 15.3333 3.62687 15.1226 3.2518 14.7475C2.87673 14.3724 2.66602 13.8637 2.66602 13.3333V3.99996C2.66602 3.63177 2.96449 3.33329 3.33268 3.33329Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}

IconComponent.displayName = 'Trash';

export const Trash16: React.FunctionComponent<IIconProps> = createIcon(IconComponent);
