/* eslint-disable react-hooks/exhaustive-deps */

import { TActExt, TEvent } from '@ws/shared/types';
import { getDeepCopy, diff_match_patch } from '@ws/shared/utils';
import { classes } from 'classifizer';
import { useEffect } from 'react';
import { useSetState } from 'react-use';

import styles from '../../confilictDrafts/TestPage.module.css';
import {
  additionalLocalEvents,
  additionalRemoteEvents,
} from '../../confilictDrafts/data/additionalEvents';
import { localEvents as mockLocalEvents } from '../../confilictDrafts/data/localEvents';
import { remoteEvents as mockRemoteEvents } from '../../confilictDrafts/data/remoteEvents';
import { getPrettyView } from '../../confilictDrafts/getPrettyView';
import {
  TConflict,
  TConflictDict,
  TDispenser,
  TEventRegistry,
  TResolveDirection,
} from '../../redux/sync/sync.types';
import {
  countDeltaToApply,
  transformConflictsToApply,
} from '../../utils/EventTrain/countDeltaToApply';
import { NODE_PARENT_UPDATE, NOTE_UPDATE_CONTENT } from '../../utils/events/act.types';

function matchFacade(localText: string, remoteText: string) {
  const dmp = new diff_match_patch();
  dmp.Diff_Timeout = 1;
  dmp.Diff_EditCost = 3;

  const diffs = dmp.diff_main(localText, remoteText);
  dmp.diff_cleanupEfficiency(diffs);

  console.log('diffs', diffs);

  return getPrettyView(diffs);
}

function checkIfAllResolved(conflicts: TConflictDict) {
  return Object.values(conflicts).every((conflict) => !!conflict.resolvedTo);
}

function getGreeting(name: string) {
  return `Hello ${name}!`;
}

export interface IGreetingProps {
  name: string;
  className?: string;

  onClick?(name: string): void;
}

export function Greeting({ name = 'World', className, onClick }: IGreetingProps) {
  const greeting = getGreeting(name);

  function handleClick() {
    alert(greeting);

    if (typeof onClick === 'function') {
      onClick(greeting);
    }
  }

  return (
    <button type="button" className={classes(styles['greeting'], className)} onClick={handleClick}>
      Press me!
    </button>
  );
}

function getDataToShow(conflict: TConflict) {
  const { eventType, remoteEvents: re, localEvents: le } = conflict;
  console.log('eventType', eventType);

  let remoteDataToShow: TActExt = {} as TActExt;
  re.forEach((one) => {
    one.acts.forEach((act) => {
      if (act.type === eventType) {
        remoteDataToShow = act;
      }
    });
  });

  let localDataToShow: TActExt = {} as TActExt;
  le.forEach((one) => {
    one.acts.forEach((act) => {
      if (act.type === eventType) {
        localDataToShow = act;
      }
    });
  });

  switch (eventType) {
    case NOTE_UPDATE_CONTENT: {
      return {
        remote: remoteDataToShow?.payload?.content,
        local: localDataToShow?.payload?.content,
      };
    }

    case NODE_PARENT_UPDATE: {
      return {
        remote: remoteDataToShow?.payload?.parentId,
        local: localDataToShow?.payload?.parentId,
      };
    }

    default: {
      return {
        remote: null,
        local: null,
      };
    }
  }
}

export function TestPage() {
  const [state, setState] = useSetState<{
    areAllResolved: boolean;
    dispenser: TDispenser;
    registry: TEventRegistry;
    conflicts: TConflictDict;
    remoteEvents: TEvent[];
    localEvents: TEvent[];
  }>({
    areAllResolved: false,
    dispenser: {},
    registry: {},
    conflicts: {},
    remoteEvents: mockRemoteEvents,
    localEvents: mockLocalEvents,
  });

  function handleVariantClick(conflictId: string, resolveTo: string | null) {
    return function () {
      console.log('FIRE');
      setState((prev) => {
        prev.conflicts[conflictId].resolvedTo = resolveTo as TResolveDirection;
        prev.areAllResolved = checkIfAllResolved(prev.conflicts);
        return prev;
      });
    };
  }

  function finishResolving() {
    console.log('Finished');
    const { conflicts } = state;
    const areAllResolved = checkIfAllResolved(conflicts);

    if (!areAllResolved) {
      return;
    }

    const results = transformConflictsToApply(conflicts);

    console.dir({ results });
  }

  useEffect(() => {
    const dispenser = getDeepCopy(state.dispenser);
    const conflicts = getDeepCopy(state.conflicts);
    const registry = getDeepCopy(state.registry);

    const results = countDeltaToApply({
      dispenser,
      registry,
      conflicts,
      remoteEvents: state.remoteEvents,
      localEvents: state.localEvents,
    });

    console.dir({ results });

    setState({
      conflicts: results.conflicts,
      registry: results.registry,
      areAllResolved: checkIfAllResolved(results.conflicts),
    });
  }, [setState, state.localEvents.length, state.remoteEvents.length]);

  function addRemoteEvent() {
    const [event] = additionalRemoteEvents;
    setState((prevState) => ({
      ...prevState,
      remoteEvents: [...prevState.remoteEvents, event],
    }));
  }

  function addLocalEvent() {
    const [event] = additionalLocalEvents;
    setState((prevState) => ({
      ...prevState,
      localEvents: [...prevState.localEvents, event],
    }));
  }

  return (
    <div>
      <Greeting name="Ezekiel" />

      {/*<button type="button" onClick={process}>*/}
      {/*  Process!*/}
      {/*</button>*/}

      <button type="button" onClick={addLocalEvent}>
        Add local event
      </button>

      <button type="button" onClick={addRemoteEvent}>
        Add remote event
      </button>

      {Object.keys(state.conflicts).length > 0 && (
        <div className={styles['conflicts']}>
          <button
            disabled={!state.areAllResolved}
            className={classes(
              styles['resolve-button'],
              state.areAllResolved && styles['resolve-button_ready'],
            )}
            onClick={finishResolving}
          >
            Finish
          </button>

          <div className={styles['conflict-list']}>
            {Object.entries(state.conflicts).map((entry) => {
              if (!entry) {
                return;
              }

              const [conflictId, conflictData] = entry;

              const dataToShow = getDataToShow(conflictData);

              return (
                <div key={conflictId} className={styles['conflict']}>
                  <div className={styles['conflict-type']}>{conflictData.eventType}</div>

                  {conflictData.eventType === 'NOTE_UPDATE_CONTENT' ? (
                    <div
                      className={styles['text-frame']}
                      dangerouslySetInnerHTML={{
                        __html: matchFacade(dataToShow.local, dataToShow.remote),
                      }}
                    >
                      {/*{matchFacade(dataToShow.local, dataToShow.remote)}*/}
                    </div>
                  ) : (
                    <div className={styles['conflict__variants']}>
                      <div
                        className={classes(
                          styles['conflict__resolve-variant'],
                          conflictData.resolvedTo === 'remote' &&
                            styles['conflict__resolve-variant_rejected'],
                        )}
                      >
                        <div className={styles['variant__label']}>From server</div>
                        <div>{dataToShow.remote}</div>
                        <button
                          onClick={handleVariantClick(
                            conflictId,
                            conflictData.resolvedTo ? null : 'remote',
                          )}
                        >
                          {conflictData.resolvedTo === 'remote' ? 'Cancel' : 'Select'}
                        </button>
                      </div>

                      <div
                        className={classes(
                          styles['conflict__resolve-variant'],
                          conflictData.resolvedTo === 'local' &&
                            styles['conflict__resolve-variant_rejected'],
                        )}
                      >
                        <div>Local</div>
                        <div>{dataToShow.local}</div>
                        <button
                          onClick={handleVariantClick(
                            conflictId,
                            conflictData.resolvedTo ? null : 'local',
                          )}
                        >
                          {conflictData.resolvedTo === 'local' ? 'Cancel' : 'Select'}
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
}
