/* eslint-disable max-len */
import * as React from 'react';

import { createIcon, IIconProps, ISVGProps } from '../../internal';

function IconComponent(props: ISVGProps) {
  return (
    <svg
      {...props}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.0008 3.79999C12.6635 3.79999 13.2008 4.33725 13.2008 4.99999V19C13.2008 19.6627 12.6635 20.2 12.0008 20.2C11.338 20.2 10.8008 19.6627 10.8008 19V4.99999C10.8008 4.33725 11.338 3.79999 12.0008 3.79999Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.1523 4.15146C11.6209 3.68283 12.3807 3.68283 12.8493 4.15146L19.8493 11.1515C20.3179 11.6201 20.3179 12.3799 19.8493 12.8485C19.3807 13.3171 18.6209 13.3171 18.1523 12.8485L12.0008 6.69704L5.84931 12.8485C5.38068 13.3171 4.62088 13.3171 4.15225 12.8485C3.68362 12.3799 3.68362 11.6201 4.15225 11.1515L11.1523 4.15146Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}

IconComponent.displayName = 'ArrowUp';

export const ArrowUp24: React.FunctionComponent<IIconProps> = createIcon(IconComponent);
