import { TSyncState } from '@ws/shared/types';

import { Validator } from './types';
import { syncStateValidatorV1 } from './v1';

export class SyncStateValidator extends Validator<TSyncState> {
  public validate(syncState: TSyncState) {
    switch (syncState.version) {
      case 1:
        return syncStateValidatorV1.validate(syncState);

      default:
        return this.createFailureResponse(this.createUnexpectedSyncStateVersionMessage());
    }
  }
}

export const syncStateValidator = new SyncStateValidator();
