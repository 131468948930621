export enum EMcsName {
  TEXT = 'text-mcs',
  NOTE = 'note-mcs',
  USER = 'user-mcs',
  PUBLISHER = 'publisher-mcs',
  PREVIEWS = 'previews-mcs',
}

export function getMcsAddress(mcsName?: string) {
  switch (mcsName) {
    case EMcsName.NOTE:
      return `mcs/${EMcsName.NOTE}`;
    case EMcsName.USER:
      return `mcs/${EMcsName.USER}`;
    case EMcsName.TEXT:
      return `mcs/${EMcsName.TEXT}`;
    case EMcsName.PUBLISHER:
      return `mcs/${EMcsName.PUBLISHER}`;
    case EMcsName.PREVIEWS:
      return `mcs/${EMcsName.PREVIEWS}`;
    default:
      return '';
  }
}
