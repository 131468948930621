import { classes } from 'classifizer';
import { useCallback, useEffect } from 'react';

import { TViewContainerProps } from '../types';

import styles from './DrawerView.module.css';

export function DrawerView({
  onClose,
  position = 'left',
  isOpen,
  width,
  children,
}: TViewContainerProps) {
  const handleOverlayClick = useCallback(() => {
    if (typeof onClose === 'function') {
      onClose();
    }
  }, [onClose]);

  const handleEscapePress = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape' && typeof onClose === 'function') {
        onClose();
      }
    },
    [onClose],
  );

  useEffect(() => {
    document.addEventListener('keydown', handleEscapePress);

    return () => document.removeEventListener('keydown', handleEscapePress);
  }, [handleEscapePress]);

  return (
    <aside
      className={classes(
        styles['drawer-sidebar'],
        isOpen && styles['drawer-sidebar_opened'],
        position === 'left' && styles['drawer-sidebar_left'],
        position === 'right' && styles['drawer-sidebar_right'],
      )}
    >
      <div className={styles['drawer-sidebar__overlay']} onClick={handleOverlayClick} />
      <div style={{ width }} className={styles['drawer-sidebar__children']}>
        {children}
      </div>
    </aside>
  );
}
