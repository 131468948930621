import { Fetcher } from '@ws/shared/utils';

import {
  BUILD_SYNC_APPLY_EVENT_ENDPOINT_CONF,
  BUILD_SYNC_GET_DELTA_ENDPOINT_CONF,
  SYNC_INIT_ENDPOINT_CONF,
} from './endpoints';
import {
  IApplyEventRequestParams,
  IGetDeltaRequestParams,
  IInitSyncRequestParams,
  TApplyEventResponse,
  TGetDeltaResponse,
  TInitSyncResponse,
} from './types';

export class SyncRepository {
  private _fetcher: Fetcher;

  constructor(fetcher: Fetcher) {
    this._fetcher = fetcher;
  }

  public initSync(projectData: IInitSyncRequestParams) {
    return this._fetcher.fetch<TInitSyncResponse>({
      url: this._fetcher.buildURL(SYNC_INIT_ENDPOINT_CONF),
      method: 'POST',
      data: projectData,
    });
  }

  public applyEvent(params: IApplyEventRequestParams) {
    return this._fetcher.fetch<TApplyEventResponse>({
      url: this._fetcher.buildURL(BUILD_SYNC_APPLY_EVENT_ENDPOINT_CONF(params.projectId)),
      method: 'POST',
      data: { event: params.event, lastUpdateId: params.lastUpdateId },
    });
  }

  public getDelta(params: IGetDeltaRequestParams) {
    return this._fetcher.fetch<TGetDeltaResponse>({
      url: this._fetcher.buildURL(BUILD_SYNC_GET_DELTA_ENDPOINT_CONF(params.projectId)),
      method: 'POST',
      data: {
        lastUpdateId: params.lastUpdateId,
      },
    });
  }
}
