import {
  autoUpdate,
  flip,
  offset,
  shift,
  useDismiss,
  useFloating,
  useInteractions,
  useListNavigation,
  useRole,
  useTypeahead,
} from '@floating-ui/react';
import { Children, isValidElement, ReactNode, useEffect, useRef, useState } from 'react';

interface IPositionManagerProps {
  children: ReactNode;
  parent?: Element;
}

export function PositionManager({ children, parent }: IPositionManagerProps) {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const [isOpen, setIsOpen] = useState(false);

  const listItemsRef = useRef<Array<HTMLButtonElement | null>>([]);
  const listContentRef = useRef(
    Children.map(children, (child) => (isValidElement(child) ? child.props.label : null)) as Array<
      string | null
    >,
  );
  const allowMouseUpCloseRef = useRef(false);

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    middleware: [
      offset({ mainAxis: 5, alignmentAxis: 4 }),
      flip({
        fallbackPlacements: ['left-start'],
      }),
      shift({ padding: 10 }),
    ],
    placement: 'right-start',
    strategy: 'fixed',
    whileElementsMounted: autoUpdate,
  });

  const role = useRole(context, { role: 'menu' });
  const dismiss = useDismiss(context);
  const listNavigation = useListNavigation(context, {
    listRef: listItemsRef,
    onNavigate: setActiveIndex,
    activeIndex,
  });
  const typeahead = useTypeahead(context, {
    enabled: isOpen,
    listRef: listContentRef,
    onMatch: setActiveIndex,
    activeIndex,
  });

  const { getFloatingProps } = useInteractions([role, dismiss, listNavigation, typeahead]);

  useEffect(() => {
    let timeout: number;
    const parentElementForListen = parent || document;

    function onContextMenu(e: Event) {
      e.preventDefault();

      refs.setPositionReference({
        getBoundingClientRect() {
          return {
            width: 0,
            height: 0,
            x: (e as MouseEvent).clientX,
            y: (e as MouseEvent).clientY,
            top: (e as MouseEvent).clientY,
            right: (e as MouseEvent).clientX,
            bottom: (e as MouseEvent).clientY,
            left: (e as MouseEvent).clientX,
          };
        },
      });

      setIsOpen(true);
      clearTimeout(timeout);

      allowMouseUpCloseRef.current = false;
      timeout = window.setTimeout(() => {
        allowMouseUpCloseRef.current = true;
      }, 300);
    }

    function onMouseUp() {
      if (allowMouseUpCloseRef.current) {
        setIsOpen(false);
      }
    }

    parentElementForListen.addEventListener('contextmenu', onContextMenu);
    parentElementForListen.addEventListener('mouseup', onMouseUp);
    return () => {
      parentElementForListen.removeEventListener('contextmenu', onContextMenu);
      parentElementForListen.removeEventListener('mouseup', onMouseUp);
      clearTimeout(timeout);
    };
  }, [parent, refs]);

  return (
    <div
      ref={refs.setFloating}
      style={{ display: isOpen ? 'block' : 'none', ...floatingStyles }}
      {...getFloatingProps()}
    >
      {children}
    </div>
  );
}
