/* eslint-disable max-len */
import * as React from 'react';

import { createIcon, IIconProps, ISVGProps } from '../../internal';

function IconComponent(props: ISVGProps) {
  return (
    <svg
      {...props}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.166 10C14.166 9.07957 14.9122 8.33337 15.8327 8.33337C16.7532 8.33337 17.4993 9.07957 17.4993 10C17.4993 10.9205 16.7532 11.6667 15.8327 11.6667C14.9122 11.6667 14.166 10.9205 14.166 10Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.33398 10C8.33398 9.07957 9.08018 8.33337 10.0007 8.33337C10.9211 8.33337 11.6673 9.07957 11.6673 10C11.6673 10.9205 10.9211 11.6667 10.0007 11.6667C9.08018 11.6667 8.33398 10.9205 8.33398 10Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.5 10C2.5 9.07957 3.24619 8.33337 4.16667 8.33337C5.08714 8.33337 5.83333 9.07957 5.83333 10C5.83333 10.9205 5.08714 11.6667 4.16667 11.6667C3.24619 11.6667 2.5 10.9205 2.5 10Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}

IconComponent.displayName = 'MoreHorizontal';

export const MoreHorizontal20: React.FunctionComponent<IIconProps> = createIcon(IconComponent);
