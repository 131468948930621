import { ReactNode } from 'react';

import styles from './Body.module.css';

interface IBodyProps {
  children: ReactNode;
}

export function Body({ children }: IBodyProps) {
  return <div className={styles['body']}>{children}</div>;
}
