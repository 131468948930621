import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouterProvider } from 'react-router-dom';

import { TAppState } from '../../redux/types';
import { getUserInfo } from '../../redux/user/user.actions';
import { TextatorSplashScreen } from '../TextatorSplashScreen';

import { router } from './router';

export function App() {
  const { isLoading } = useSelector((state: TAppState) => state.user);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserInfo());
  }, [dispatch]);

  if (isLoading) {
    return <TextatorSplashScreen />;
  }

  return <RouterProvider router={router} fallbackElement={<TextatorSplashScreen />} />;
}
