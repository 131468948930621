import { Fetcher } from '../../utils/Fetcher';

import {
  BUILD_PREVIEW_BY_ID_ENDPOINT_CONF,
  BUILD_PREVIEW_BY_LINK_KEY_ENDPOINT_CONF,
  BUILD_PREVIEW_DELETE_ENDPOINT_CONF,
  BUILD_PREVIEW_LIST_BY_ENTITY_ID_ENDPOINT_CONF,
  PREVIEW_CREATE_ENDPOINT_CONF,
} from './endpoints';
import {
  ICreatePreviewProps,
  TCreatePreviewResponse,
  TGetPreviewByIdResponse,
  TGetPreviewByLinkKeyResponse,
  TGetPreviewsByEntityIdResponse,
} from './types';

export class PreviewRepository {
  private _fetcher: Fetcher;

  constructor(fetcher: Fetcher) {
    this._fetcher = fetcher;
  }

  public create(data: ICreatePreviewProps) {
    return this._fetcher.fetch<TCreatePreviewResponse>({
      url: this._fetcher.buildURL(PREVIEW_CREATE_ENDPOINT_CONF),
      method: 'POST',
      data,
    });
  }

  public delete(id: string) {
    return this._fetcher.fetch({
      url: this._fetcher.buildURL(BUILD_PREVIEW_DELETE_ENDPOINT_CONF({ previewId: id })),
      method: 'DELETE',
    });
  }

  public getById(id: string) {
    return this._fetcher.fetch<TGetPreviewByIdResponse>({
      url: this._fetcher.buildURL(BUILD_PREVIEW_BY_ID_ENDPOINT_CONF({ previewId: id })),
      method: 'GET',
    });
  }

  public getByLinkKey(linkKey: string) {
    return this._fetcher.fetch<TGetPreviewByLinkKeyResponse>({
      url: this._fetcher.buildURL(BUILD_PREVIEW_BY_LINK_KEY_ENDPOINT_CONF({ linkKey })),
      method: 'GET',
    });
  }

  public getListByEntityId(entityId: string) {
    return this._fetcher.fetch<TGetPreviewsByEntityIdResponse>({
      url: this._fetcher.buildURL(BUILD_PREVIEW_LIST_BY_ENTITY_ID_ENDPOINT_CONF({ entityId })),
      method: 'GET',
    });
  }
}
