import { useCallback, useEffect } from 'react';

import { Modal } from '../Modal';
import { IModal, TCloseAllModals, TCloseModal } from '../types';

export interface IModalManagerProps {
  modals: IModal[];
  closeModal: TCloseModal;
  closeAllModals: TCloseAllModals;
}

export function ModalsManager({ closeModal, modals, closeAllModals }: IModalManagerProps) {
  const handleCloseClick = useCallback(() => {
    closeModal(-1);
  }, [closeModal]);

  const handleEscapePress = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        handleCloseClick();
      }
    },
    [handleCloseClick],
  );

  useEffect(() => {
    document.addEventListener('keydown', handleEscapePress);

    return () => document.removeEventListener('keydown', handleEscapePress);
  }, [handleEscapePress]);

  return (
    <>
      {modals.map((modal, index, array) => (
        <Modal
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          modal={modal}
          closeAllModals={closeAllModals}
          closeModal={closeModal}
          isLast={index === array.length - 1}
        />
      ))}
    </>
  );
}
