/* eslint-disable max-len */
import * as React from 'react';

import { createIcon, IIconProps, ISVGProps } from '../../internal';

function IconComponent(props: ISVGProps) {
  return (
    <svg
      {...props}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.49935 11.6666C2.95959 11.6666 3.33268 12.0397 3.33268 12.5V15.8333C3.33268 16.0543 3.42048 16.2663 3.57676 16.4225C3.73304 16.5788 3.945 16.6666 4.16602 16.6666H15.8327C16.0537 16.6666 16.2657 16.5788 16.4219 16.4225C16.5782 16.2663 16.666 16.0543 16.666 15.8333V12.5C16.666 12.0397 17.0391 11.6666 17.4993 11.6666C17.9596 11.6666 18.3327 12.0397 18.3327 12.5V15.8333C18.3327 16.4963 18.0693 17.1322 17.6005 17.6011C17.1316 18.0699 16.4957 18.3333 15.8327 18.3333H4.16602C3.50297 18.3333 2.86709 18.0699 2.39825 17.6011C1.92941 17.1322 1.66602 16.4963 1.66602 15.8333V12.5C1.66602 12.0397 2.03911 11.6666 2.49935 11.6666Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.41074 1.9107C9.73618 1.58527 10.2638 1.58527 10.5893 1.9107L14.7559 6.07737C15.0814 6.40281 15.0814 6.93045 14.7559 7.25588C14.4305 7.58132 13.9028 7.58132 13.5774 7.25588L10 3.67847L6.42259 7.25588C6.09715 7.58132 5.56951 7.58132 5.24408 7.25588C4.91864 6.93045 4.91864 6.40281 5.24408 6.07737L9.41074 1.9107Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.99935 1.66663C10.4596 1.66663 10.8327 2.03972 10.8327 2.49996V12.5C10.8327 12.9602 10.4596 13.3333 9.99935 13.3333C9.53911 13.3333 9.16602 12.9602 9.16602 12.5V2.49996C9.16602 2.03972 9.53911 1.66663 9.99935 1.66663Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}

IconComponent.displayName = 'Upload';

export const Upload20: React.FunctionComponent<IIconProps> = createIcon(IconComponent);
