import { Logo } from '@ws/shared/components';

import textatorLogo from '../../assets/logo.svg';

interface ITextatorLogoProps {
  isFull?: boolean;
}

export function TextatorLogo({ isFull = true }: ITextatorLogoProps) {
  return <Logo label="TEXTATOR" isFull={isFull} imgSrc={textatorLogo} />;
}
