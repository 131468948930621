import {
  TProject,
  TSyncState,
  TNote,
  ICreateCollectionArguments,
  ICreateNoteArguments,
  ICreateProjectArguments,
  ISyncStateParams,
  TCollection,
  TEntityRef,
  TEntityType,
  TEntity,
} from '@ws/shared/types';
import { getSha1Hash } from '@ws/shared/utils';
import CyrillicToTranslit from 'cyrillic-to-translit-js';
import { v4 } from 'uuid';

import { getNowAndAName } from '../../getNowAndAName';
import { ProjectV2Builder } from '../v2';

const ROOT_PARENT_ID = null;

const DEFAULT_PROJECT_NAME = 'Твой новый проект';
const DEFAULT_DRAFTS_NAME = 'Черновики';
const DEFAULT_NOTE_NAME = 'Новая заметка';

export class ProjectV3Builder extends ProjectV2Builder {
  public createCollection({ name, parentId }: ICreateCollectionArguments): TCollection {
    const id = v4();
    const { now, augmentedName } = getNowAndAName();

    const children: TEntityRef[] = [];

    return {
      id,
      parentId,

      type: TEntityType.collection as 'collection',
      meta: {
        name: name || augmentedName,
        withTitle: false,
        isHidden: false,
      },

      children,
      hash: getSha1Hash(JSON.stringify(children)),

      createdAt: now,
      updatedAt: now,
    };
  }

  public createEntityRef({ id, type }: TEntity) {
    return {
      id,
      type,
    };
  }

  public createFile(project: TProject, syncState: TSyncState) {
    return {
      fileVersion: 3,
      project,
      syncState,
    };
  }

  public createNote(args: ICreateNoteArguments): TNote {
    return this.createNoteV2(args);
  }

  public createProject(props: ICreateProjectArguments): TProject {
    const id = v4();
    const { now, augmentedName } = getNowAndAName();

    const trans = new CyrillicToTranslit({ preset: 'ru' });

    const fullDefaultProjectName = `${DEFAULT_PROJECT_NAME} ${augmentedName}`;
    const fullDefaultSlug = trans.transform(fullDefaultProjectName);

    const { name = fullDefaultProjectName, slug = fullDefaultSlug } = props;

    const rootCollection = this.createCollection({ name, parentId: ROOT_PARENT_ID });
    const draftCollection = this.createCollection({
      name: DEFAULT_DRAFTS_NAME,
      parentId: ROOT_PARENT_ID,
    });

    const initNote = this.createNote({ name: DEFAULT_NOTE_NAME, parentId: rootCollection.id });
    rootCollection.children = [...rootCollection.children, this.createEntityRef(initNote)];

    return {
      version: 3,
      id,
      root: rootCollection.id,
      drafts: draftCollection.id,

      meta: {
        name,
        slug,
      },

      collections: {
        [rootCollection.id]: rootCollection,
        [draftCollection.id]: draftCollection,
      },
      notes: {
        [initNote.id]: initNote,
      },

      createdAt: now,
      updatedAt: now,
    };
  }

  public createSyncState({
    id,
    list = [],
    isSyncEnabled = false,
    lastUpdateId = null,
  }: ISyncStateParams): TSyncState {
    return {
      version: 1,
      id,
      list,
      isSyncEnabled,
      lastUpdateId,
    };
  }
}

export const projectV3Builder = new ProjectV3Builder();
