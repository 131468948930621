import { TProject, TSyncState } from '@ws/shared/types';

import { projectBuilder } from './projectProcessing';
import { transliterate } from './transliterate';

function upload(content: string, fileName: string, contentType: string) {
  const a = document.createElement('a');
  const file = new Blob([content], { type: contentType });

  a.href = URL.createObjectURL(file);
  a.download = fileName;
  a.click();
}

export function packToFile(project: TProject, syncState: TSyncState) {
  const file = projectBuilder.createFile(project, syncState);
  const jsonData = JSON.stringify(file, null, ' ');
  const name = transliterate(project.meta.name, '_');

  upload(jsonData, `${name}.json`, 'text/plain');
}
