import { ModalsContext, Sidebar } from '@ws/shared/components';
import { TCollection, TNote } from '@ws/shared/types';
import { getCompiledContentOfEntity } from '@ws/shared/utils';
import { useCallback, useContext, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { EditabilityContext } from '../../../context';
import { TAppState } from '../../../redux';
import { setGlobalIsPreviewsOpen } from '../../../redux/general/general.actions';
import { getPreviewCreatorModal } from '../PreviewCreator';
import { PreviewsComponent } from '../PreviewsComponent';
import { useFetchPreviewsByEntityId } from '../hooks';

interface IPreviewsContainerProps {
  entity: TCollection | TNote;
}

export function PreviewsContainer({ entity }: IPreviewsContainerProps) {
  const dispatch = useDispatch();
  const { isPreviewsOpen } = useSelector((state: TAppState) => state.general);
  const { getNodesByRefs } = useContext(EditabilityContext);
  const { setModal } = useContext(ModalsContext);
  const [filter, setFilter] = useState<number>(1);
  const { data, refetch, isLoading } = useFetchPreviewsByEntityId(entity.id);

  const compiled = useMemo(
    () => getCompiledContentOfEntity(entity, getNodesByRefs),
    [getNodesByRefs, entity],
  );
  const previews = data || [];

  const handleDrawerClose = useCallback(() => {
    dispatch(setGlobalIsPreviewsOpen({ isPreviewsOpen: false }));
  }, [dispatch]);

  const refetchList = useCallback(async () => {
    setTimeout(() => {
      refetch();
    }, 300);
  }, [refetch]);

  const handleCreatePreviewClick = useCallback(() => {
    const Modal = getPreviewCreatorModal({ entity, onCreationFinish: refetchList, compiled });

    setModal({ customView: Modal });
  }, [compiled, entity, refetchList, setModal]);

  return (
    <Sidebar.Container
      variant="drawer"
      position="right"
      isOpen={isPreviewsOpen}
      onClose={handleDrawerClose}
    >
      <PreviewsComponent
        previews={previews}
        compiled={compiled}
        onClose={handleDrawerClose}
        onCreatePreview={handleCreatePreviewClick}
        filter={filter}
        onFilter={setFilter}
        isLoading={isLoading}
      />
    </Sidebar.Container>
  );
}
