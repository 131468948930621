export const CLEAR_BREADCRUMBS = 'CLEAR_BREADCRUMBS';

export type TBreadcrumb = {
  id: string;
  name: string;
  isCurrent?: boolean;
};
export interface IBreadcrumbsState {
  isTargetSearch: boolean;
  path: TBreadcrumb[];
}

export interface IBreadcrumbsSetAction {
  path: TBreadcrumb[];
}
export const SET_BREADCRUMBS = 'SET_BREADCRUMBS';

export interface ITargetSearchSetAction {
  isTargetSearch: boolean;
}
export const SET_TARGET_SEARCH = 'SET_TARGET_SEARCH';
