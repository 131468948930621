import { TFileWithVersion, TFileWithDifferentEntityVersions, TFileV1 } from '@ws/shared/types';

export class FileAdaptorV1 {
  public static adapt(file: TFileWithVersion<TFileV1>): TFileWithDifferentEntityVersions {
    // delete fileVersion because FileV1 is TProjectV1 with fileVersion field
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { fileVersion, ...project } = file;

    return {
      fileVersion: 2,
      project,
      syncState: null,
    };
  }
}
