import { TAct, TActExt } from '@ws/shared/types';
import { v4 } from 'uuid';

import { getPartitionsFor } from './getPartitionsFor';

export function getNow() {
  const now = new Date();

  return `${now.getTime()}`;
}

export class EventHolder {
  public acts: TAct<any>[] = [];

  static fasten(...acts: TAct<any>[]) {
    const holder = new EventHolder();
    holder.push(...acts);
    return holder.getEvent();
  }

  public push(...act: TAct<any>[]) {
    this.acts.push(...act);
  }

  public getEvent(outerTime?: string) {
    const time = outerTime || getNow();
    const partitions = getPartitionsFor(this.acts.length);

    const extendedActs: TActExt[] = this.acts.map((act, idx) => ({
      id: v4(),
      eventPartition: partitions[idx],
      time,
      ...act,
    }));

    return {
      eventId: v4(),
      acts: extendedActs,
      time,
    };
  }
}
