import { TEntityRef } from '@ws/shared/types';

export function findLessIndex(refsSource: TEntityRef[], refsTarget: TEntityRef[]) {
  for (let i = 0; i < refsSource.length; i += 1) {
    const cursor = refsSource[i];
    const idx = refsTarget.findIndex((ref) => ref.id === cursor.id);

    if (idx !== -1) {
      return i;
    }
  }

  return -1;
}
