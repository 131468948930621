import {
  TFiles,
  TFileWithDifferentEntityVersions,
  TFileWithVersion,
  TProcessingResult,
  TFileV1,
} from '@ws/shared/types';

import { Adaptor } from './types';
import { FileAdaptorV1 } from './v1';

export class FileAdaptor extends Adaptor<TFileWithDifferentEntityVersions> {
  public adapt(
    file: TFileWithVersion<TFiles>,
  ): TProcessingResult<TFileWithDifferentEntityVersions> {
    const relevantVersion = 2;

    let adaptedFile: any = file;

    while (adaptedFile.fileVersion < relevantVersion) {
      switch (adaptedFile.fileVersion) {
        // adaptedFile the same as project in the first version
        case 1:
          adaptedFile = FileAdaptorV1.adapt(adaptedFile as TFileWithVersion<TFileV1>);
          break;

        default:
          adaptedFile = this.createFailureResponse('File adaptation failed. Unexpected version.');
      }
    }

    return this.createSuccessResponse(adaptedFile);
  }
}

export const fileAdaptor = new FileAdaptor();
