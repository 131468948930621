import { Button } from '@ws/shared/components';
import { Edit24 } from '@ws/shared/icons';

import styles from './EditButton.module.css';

interface IEditButtonProps {
  onClick(): void;
}

export function EditButton({ onClick }: IEditButtonProps) {
  return (
    <Button
      variant="shapeless"
      icon={<Edit24 color="textator_primary" />}
      className={styles['edit-button']}
      onClick={onClick}
    />
  );
}
