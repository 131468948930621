import { Button, Header, IHeaderKebabItem, Input } from '@ws/shared/components';
import { Sync20, SyncSlash20, Type20, UploadCloud20 } from '@ws/shared/icons';
import { useCallback, useContext, useEffect, useMemo, useRef, useState, FormEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ResolutionContext, EditabilityContext } from '../../context';
import { isDesktop, isTablet } from '../../modules/getResolution';
import { TAppState } from '../../redux';
import { setGlobalIsCompiled, setGlobalIsPreviewsOpen } from '../../redux/general/general.actions';
import { transliterate } from '../../utils/transliterate';

import { BeforeActions } from './BeforeActions';

const UPDATE_TIMEOUT = 1500;

export function HeaderContainer() {
  const dispatch = useDispatch();

  const { isCompiled } = useSelector((state: TAppState) => state.general);
  const { isAuth } = useSelector((state: TAppState) => state.user);
  const { resolution } = useContext(ResolutionContext);
  const { getNodeByRef, updateNodeMeta, updateProjectMeta } = useContext(EditabilityContext);

  const updateTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);
  const { selected, project } = useSelector((state: TAppState) => state.editor);

  const currentNode = useMemo(
    () => (selected ? getNodeByRef(selected) : selected),
    [getNodeByRef, selected],
  );

  const [name, setName] = useState(currentNode?.meta.name || '');
  const tabletOrDesktop = isTablet(resolution) || isDesktop(resolution);
  const isWithTitle = currentNode?.meta.withTitle || false;

  const handleCompilationClick = useCallback(
    () => dispatch(setGlobalIsCompiled({ isCompiled: !isCompiled })),
    [dispatch, isCompiled],
  );

  function sendNameChangeEvent(nameToSave: string) {
    if (selected) {
      updateNodeMeta(selected, { name: nameToSave });

      if (selected.id === project?.root) {
        updateProjectMeta({ name: nameToSave, slug: transliterate(nameToSave) });
      }
    }
  }

  function handleNameChange(event: FormEvent<HTMLInputElement>) {
    const newName = (event.target as HTMLInputElement).value;
    setName(newName);

    clearTimeout(updateTimeout.current as any);

    updateTimeout.current = setTimeout(() => {
      sendNameChangeEvent(newName);
    }, UPDATE_TIMEOUT);
  }

  function handleNameInputBlur() {
    clearTimeout(updateTimeout.current as any);

    if (currentNode?.meta.name !== name) {
      sendNameChangeEvent(name);
    }
  }

  const handlePreviewsClick = useCallback(
    () => dispatch(setGlobalIsPreviewsOpen({ isPreviewsOpen: true })),
    [dispatch],
  );

  const handleWithTitleChange = useCallback(() => {
    if (selected && currentNode !== null && typeof currentNode !== 'undefined') {
      const checked = !currentNode.meta.withTitle;

      updateNodeMeta(selected, { withTitle: checked });
    }
  }, [currentNode, selected, updateNodeMeta]);

  const kebabMenu = useMemo<IHeaderKebabItem[]>(
    () => [
      {
        leftIcon: <Type20 />,
        label: isWithTitle ? 'Убрать заголовок' : 'Отобразить заголовок',
        onClick: handleWithTitleChange,
      },
      {
        leftIcon: <UploadCloud20 />,
        label: 'Предпоказы',
        onClick: handlePreviewsClick,
        disabled: !isAuth,
        closeAfterClick: true,
      },
    ],
    [handlePreviewsClick, handleWithTitleChange, isAuth, isWithTitle],
  );

  useEffect(() => {
    setName(currentNode?.meta.name || '');
  }, [selected, currentNode?.meta.name]);

  if (!selected || !currentNode) {
    return null;
  }

  return (
    <Header
      title={
        <Input
          variant="shapeless"
          value={name}
          onChange={handleNameChange}
          onBlur={handleNameInputBlur}
        />
      }
      before={<BeforeActions />}
      after={
        tabletOrDesktop && (
          <Button
            color={isCompiled ? 'secondary' : 'primary'}
            onClick={handleCompilationClick}
            icon={isCompiled ? <SyncSlash20 /> : <Sync20 />}
          >
            {isCompiled ? 'Редактировать' : 'Компилировать'}
          </Button>
        )
      }
      kebab={kebabMenu}
    />
  );
}
