import { classes } from 'classifizer';
import { forwardRef, ReactNode } from 'react';

import styles from './Item.module.css';

interface IItemProps {
  children: ReactNode;
  className?: string;
}

export const Item = forwardRef<HTMLLIElement, IItemProps>(({ children, className }, ref) => {
  return (
    <li ref={ref} className={classes(styles.item, className)}>
      {children}
    </li>
  );
});

Item.displayName = 'Item';
