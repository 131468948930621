import { EMcsName } from '../../utils';

const PREVIEWS_ENDPOINT_BASE = 'v1/preview';

export const PREVIEW_CREATE_ENDPOINT_CONF = {
  base: `${PREVIEWS_ENDPOINT_BASE}/create-one`,
  mcsName: EMcsName.PREVIEWS,
  access: 'private',
};

interface PreviewDeleteConfParams {
  previewId: string;
}

export const BUILD_PREVIEW_DELETE_ENDPOINT_CONF = ({ previewId }: PreviewDeleteConfParams) => ({
  base: `${PREVIEWS_ENDPOINT_BASE}/${previewId}/delete`,
  mcsName: EMcsName.PREVIEWS,
  access: 'private',
});

interface PreviewByIdConfParams {
  previewId: string;
}

export const BUILD_PREVIEW_BY_ID_ENDPOINT_CONF = ({ previewId }: PreviewByIdConfParams) => ({
  base: `${PREVIEWS_ENDPOINT_BASE}/${previewId}/get-one`,
  mcsName: EMcsName.PREVIEWS,
  access: 'dynamic',
});

interface PreviewByLinkKeyConfParams {
  linkKey: string;
}

export const BUILD_PREVIEW_BY_LINK_KEY_ENDPOINT_CONF = ({
  linkKey,
}: PreviewByLinkKeyConfParams) => ({
  base: `${PREVIEWS_ENDPOINT_BASE}/by-link/${linkKey}/get-one`,
  mcsName: EMcsName.PREVIEWS,
  access: 'dynamic',
});

interface PreviewListByEntityIdConfParams {
  entityId: string;
}

export const BUILD_PREVIEW_LIST_BY_ENTITY_ID_ENDPOINT_CONF = ({
  entityId,
}: PreviewListByEntityIdConfParams) => ({
  base: `${PREVIEWS_ENDPOINT_BASE}/by-entity/${entityId}/get-list`,
  mcsName: EMcsName.PREVIEWS,
  access: 'private',
});
