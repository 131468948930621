import { TEntityRef, TProject, TProjectMeta, TActExt } from '@ws/shared/types';

export interface IEditorState {
  project: TProject | null;
  selected: TEntityRef | null;
  secondarySelected: TEntityRef | null;
}

export const PROJECT_SET = 'PROJECT_SET';

export interface IProjectSetAction {
  project: TProject;
}

export const PROJECT_CLEAN = 'PROJECT_CLEAN';
export const UPDATE_PROJECT_META = 'UPDATE_PROJECT_META';

export interface IProjectMetaUpdateAction {
  meta: Partial<TProjectMeta>;
}

export interface INodeSelectAction {
  selected: TEntityRef;
}

export interface ISecondaryNodeSelectAction {
  secondarySelected: TEntityRef | null;
}

export const SELECT_NODE = 'SELECT_NODE';
export const SELECT_SECONDARY_NODE = 'SELECT_SECONDARY_NODE';

export interface IEventsApplyAction {
  eventId: string;
  time: string;
  acts: TActExt[];
}

export const APPLY_EVENTS = 'APPLY_EVENTS';
export const INNER_APPLY_EVENTS = 'INNER_APPLY_EVENTS';
