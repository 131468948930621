import {
  TCollection,
  TEntity,
  TEntityMeta,
  TEntityRef,
  TNote,
  TProjectMeta,
} from '@ws/shared/types';
import { getDeepCopy, getSha1Hash } from '@ws/shared/utils';
import { PropsWithChildren } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { TAppState } from '../../redux';
import * as actions from '../../redux/editor/editor.actions';
import { findLessIndex } from '../../utils/array';
import { EventHolder } from '../../utils/events/EventHolder';
import * as acts from '../../utils/events/act.creators';
import { CHILD_MOVE_TYPE } from '../../utils/events/act.types';
import { getEntityRef } from '../../utils/getEntityRef';
import { projectBuilder } from '../../utils/projectProcessing';
import { getProjectRoute } from '../../utils/routes';

import {
  EditabilityCtxProvider,
  IDropParams,
  INewCollectionParams,
  INewNoteParams,
} from './context';

export function EditabilityContextManager({ children }: PropsWithChildren) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { project } = useSelector((state: TAppState) => state.editor);

  function getNode(wantedId: string) {
    const node = project?.collections[wantedId] || project?.notes[wantedId];

    return getDeepCopy(node);
  }

  function getNodeByRef(ref: TEntityRef) {
    if (ref.type === 'note') {
      return getDeepCopy(project?.notes[ref.id]);
    }

    return getDeepCopy(project?.collections[ref.id]);
  }

  function getNodes(wantedIds: string[] = []) {
    return wantedIds.map(getNode);
  }

  function getNodesByRefs(refs: TEntityRef[] = []) {
    return refs.map(getNodeByRef);
  }

  function getRootNode(): TCollection {
    return getNode(project?.root as string) as TCollection;
  }

  function getUnboundHolder(): TCollection {
    return getNode(project?.drafts as string) as TCollection;
  }

  function selectNode(ref: TEntityRef) {
    if (project?.id) {
      navigate(getProjectRoute(project.id, ref.id));
    }
  }

  function updateNodeMeta(nodeRef: TEntityRef, updatedMeta: Partial<TEntityMeta>) {
    dispatch(
      actions.applyEvents(
        EventHolder.fasten(acts.updateNodeMeta({ ref: nodeRef, meta: updatedMeta })),
      ),
    );
  }

  function updateNoteContent(entityId: string, content: string) {
    dispatch(
      actions.applyEvents(
        EventHolder.fasten(
          acts.updateNoteContent({ entityId, content, hash: getSha1Hash(content) }),
        ),
      ),
    );
  }

  function updateProjectMeta(updatedMeta: Partial<TProjectMeta>) {
    if (project) {
      dispatch(
        actions.applyEvents(
          EventHolder.fasten(acts.updateProjectMeta({ projectId: project.id, meta: updatedMeta })),
        ),
      );
    }
  }

  function addNewNote({ parentId, name, initialText, placeConfig }: INewNoteParams): TEntityRef {
    const eventHolder = new EventHolder();
    const parentNode = { ...(getNode(parentId) as TCollection) };
    const childrenList = [...parentNode.children];
    const newNote = projectBuilder.createNote({ parentId, name, initialText });
    eventHolder.push(acts.createNote({ note: newNote }));
    const newNoteRef = getEntityRef(newNote);

    let newPosition = -1;

    if (placeConfig) {
      const { cursorId, placeBefore } = placeConfig;
      const step = placeBefore ? 0 : 1;
      const cursorIdx = childrenList.findIndex((child) => child.id === cursorId);

      if (cursorIdx !== -1) {
        newPosition = cursorIdx + step;
      }
    }

    eventHolder.push(
      acts.updateCollectionChild({
        entityId: parentNode.id,
        children: [newNoteRef],
        move: CHILD_MOVE_TYPE.ADD,
        position: newPosition,
      }),
    );

    dispatch(actions.applyEvents(eventHolder.getEvent()));

    return newNoteRef;
  }

  function addNewCollection({ parentId, name }: INewCollectionParams): TEntityRef {
    const eventHolder = new EventHolder();
    const parentNode = getNode(parentId) as TCollection;
    const newCollection = projectBuilder.createCollection({ parentId, name });
    eventHolder.push(acts.createCollection({ collection: newCollection }));

    const newCollectionRef = getEntityRef(newCollection);
    eventHolder.push(
      acts.updateCollectionChild({
        entityId: parentNode.id,
        children: [newCollectionRef],
        move: CHILD_MOVE_TYPE.ADD,
        position: -1,
      }),
    );

    dispatch(actions.applyEvents(eventHolder.getEvent()));

    return newCollectionRef;
  }

  function copyNote(donorNote: TNote, name?: string): TEntityRef {
    const eventHolder = new EventHolder();
    const newNote = projectBuilder.createNote({
      parentId: donorNote.parentId,
      name: name || `${donorNote.meta.name} (2)`,
      initialText: donorNote.content,
    });
    const newNoteRef = getEntityRef(newNote);
    eventHolder.push(acts.createNote({ note: newNote }));

    const donorParentRef = { type: 'collection', id: donorNote.parentId } as TEntityRef;
    const donorParent = getNodeByRef(donorParentRef) as TCollection;

    const idx = donorParent.children.findIndex((child) => child.id === donorNote.id);
    const newPosition = idx + 1;

    eventHolder.push(
      acts.updateCollectionChild({
        entityId: donorParent.id,
        children: [newNoteRef],
        move: CHILD_MOVE_TYPE.ADD,
        position: newPosition,
      }),
    );

    dispatch(actions.applyEvents(eventHolder.getEvent()));

    return newNoteRef;
  }

  function mergeNotes(newNoteName: string, refsToMerge: TEntityRef[]): TEntityRef {
    const eventHolder = new EventHolder();

    // TODO: как-то обезопаситься от возможности получить коллекции
    const notesForMerge = getNodesByRefs(refsToMerge).filter(Boolean) as TNote[];
    const { parentId } = notesForMerge[0];

    // create new note
    const initialText = notesForMerge.reduce(
      (acc: string, item: TNote) => acc.concat(item.content, '\n\n'),
      '',
    );
    const newNote = projectBuilder.createNote({ parentId, name: newNoteName, initialText });
    eventHolder.push(acts.createNote({ note: newNote }));
    const newNoteRef = getEntityRef(newNote);

    // update parent children
    const parent = getNode(parentId) as TCollection;

    eventHolder.push(
      acts.updateCollectionChild({
        entityId: parentId,
        children: refsToMerge,
        move: CHILD_MOVE_TYPE.REMOVE,
        position: 0,
      }),
    );

    const wantedIdx = findLessIndex(parent.children, refsToMerge);

    eventHolder.push(
      acts.updateCollectionChild({
        entityId: parentId,
        children: [newNoteRef],
        move: CHILD_MOVE_TYPE.ADD,
        position: wantedIdx,
      }),
    );

    // remove old notes
    eventHolder.push(...refsToMerge.map((ref) => acts.removeNode({ ref })));

    // commit
    dispatch(actions.applyEvents(eventHolder.getEvent()));

    return newNoteRef;
  }

  function getAllNested(rootRef: TEntityRef): TEntityRef[] {
    if (rootRef.type === 'note') {
      throw Error('getAllNested | root is a note');
    }
    const listToRemove: TEntityRef[] = [];

    function unwrap(list: TEntityRef[]) {
      const entities = getNodesByRefs(list);

      entities.forEach((entity) => {
        if (!entity) {
          return;
        }

        listToRemove.push(getEntityRef(entity));

        if (entity.type === 'note') {
          return;
        }

        unwrap(entity.children);
      });
    }

    const rootCollection = getNode(rootRef.id) as TCollection;

    if (rootCollection) {
      unwrap(rootCollection.children);
    }

    return listToRemove;
  }

  function deleteNote(idToDelete: string) {
    const eventHolder = new EventHolder();

    const refToDelete = { type: 'note', id: idToDelete } as TEntityRef;
    const noteToDelete = getNodeByRef(refToDelete) as TNote;
    if (!noteToDelete) {
      return;
    }

    eventHolder.push(
      acts.updateCollectionChild({
        entityId: noteToDelete.parentId,
        children: [refToDelete],
        move: CHILD_MOVE_TYPE.REMOVE,
        position: 0,
      }),
    );

    eventHolder.push(acts.removeNode({ ref: refToDelete }));

    dispatch(actions.applyEvents(eventHolder.getEvent()));
  }

  function deleteCollection(idToDelete: string, isRecursively: boolean) {
    const collectionToDelete = getNode(idToDelete) as TCollection;
    if (!collectionToDelete) {
      return;
    }

    const collectionToDeleteRef = getEntityRef(collectionToDelete);

    const eventHolder = new EventHolder();

    if (collectionToDelete.parentId) {
      eventHolder.push(
        acts.updateCollectionChild({
          entityId: collectionToDelete.parentId,
          children: [collectionToDeleteRef],
          move: CHILD_MOVE_TYPE.REMOVE,
          position: 0,
        }),
      );
    }

    if (isRecursively) {
      const allNested = getAllNested(getEntityRef(collectionToDelete));
      const eventsToDelete = allNested.map((ref) => acts.removeNode({ ref }));
      eventHolder.push(...eventsToDelete);
    } else {
      const unboundHolder = getUnboundHolder();
      eventHolder.push(
        acts.updateCollectionChild({
          entityId: unboundHolder.id,
          children: collectionToDelete.children,
          move: CHILD_MOVE_TYPE.ADD,
          position: -1,
        }),
      );

      const eventsToUpdate = collectionToDelete.children.map((childRef) => {
        return acts.updateNodeParent({ ref: childRef, parentId: project?.drafts as string });
      });
      eventHolder.push(...eventsToUpdate);
    }

    eventHolder.push(acts.removeNode({ ref: getEntityRef(collectionToDelete) }));

    dispatch(actions.applyEvents(eventHolder.getEvent()));
  }

  function handleDrop({ newParentId, movableNodeRef, positionBaseRef, isLast }: IDropParams) {
    const eventHolder = new EventHolder();

    if (
      (!isLast && typeof positionBaseRef === 'undefined') ||
      positionBaseRef?.id === movableNodeRef.id
    ) {
      return;
    }

    // remove the target from previous parent
    const movableNode = getNodeByRef(movableNodeRef) as TEntity;
    if (!movableNode) {
      return;
    }

    const prevParent = movableNode.parentId ? (getNode(movableNode.parentId) as TCollection) : null;

    if (prevParent) {
      const prevIdx = prevParent.children.findIndex((child) => child.id === movableNode.id);

      eventHolder.push(
        acts.updateCollectionChild({
          entityId: prevParent.id,
          children: [movableNodeRef],
          move: CHILD_MOVE_TYPE.REMOVE,
          position: prevIdx,
        }),
      );
    }

    // add the target to a new parent
    const nextParent = getNode(newParentId) as TCollection;

    let newPosition = -1;
    if (positionBaseRef) {
      newPosition = nextParent.children.findIndex((child) => child.id === positionBaseRef.id);
    }

    eventHolder.push(
      acts.updateCollectionChild({
        entityId: nextParent.id,
        children: [movableNodeRef],
        move: CHILD_MOVE_TYPE.ADD,
        position: newPosition,
      }),
    );

    // change a parent inside the target
    eventHolder.push(
      acts.updateNodeParent({
        ref: getEntityRef(movableNode),
        parentId: newParentId,
      }),
    );

    dispatch(actions.applyEvents(eventHolder.getEvent()));
  }

  return (
    <EditabilityCtxProvider
      value={{
        updateProjectMeta,

        getNode,
        getNodes,

        getNodeByRef,
        getNodesByRefs,

        getRootNode,
        getUnboundHolder,

        selectNode,

        addNewNote,
        addNewCollection,

        updateNodeMeta,
        updateNoteContent,

        copyNote,
        mergeNotes,

        deleteNote,
        deleteCollection,

        handleDrop,
      }}
    >
      {children}
    </EditabilityCtxProvider>
  );
}
