import { Close } from './Close';
import { Container } from './Container';
import { Content } from './Content';
import { Description } from './Description';
import { Heading } from './Heading';
import { Trigger } from './Trigger';

export const Popover = {
  Container,
  Content,
  Close,
  Trigger,
  Description,
  Heading,
};
