import { TProjects, TProjectWithVersion, TProject, TProjectV1, TProjectV2 } from '@ws/shared/types';

import { Adaptor } from './types';
import { ProjectAdaptorV1 } from './v1';
import { ProjectAdaptorV2 } from './v2';

export class ProjectAdaptor extends Adaptor<TProject> {
  public adapt(project: TProjectWithVersion<TProjects>) {
    const relevantVersion = 3;

    let adaptedProject = project;

    while (adaptedProject.version < relevantVersion) {
      switch (adaptedProject.version) {
        case 2:
          adaptedProject = ProjectAdaptorV2.adapt(adaptedProject as TProjectV2);
          break;

        case 1:
          adaptedProject = ProjectAdaptorV1.adapt(
            adaptedProject as TProjectV1,
          ) as TProjectWithVersion<TProjectV2>;
          break;

        default:
          return this.createFailureResponse('Project adaptation failed. Unexpected version.');
      }
    }

    return this.createSuccessResponse(adaptedProject as TProject);
  }
}

export const projectAdaptor = new ProjectAdaptor();
