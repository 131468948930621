/* eslint-disable max-len */
import * as React from 'react';

import { createIcon, IIconProps, ISVGProps } from '../../internal';

function IconComponent(props: ISVGProps) {
  return (
    <svg
      {...props}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M18.984 8.64169C18.9056 8.545 18.8065 8.46712 18.694 8.41376C18.5815 8.36041 18.4585 8.33293 18.334 8.33335H16.6673V7.50002C16.6673 6.83698 16.4039 6.20109 15.9351 5.73225C15.4662 5.26341 14.8304 5.00002 14.1673 5.00002H8.93398L8.66732 4.16669C8.49444 3.67771 8.17377 3.2546 7.74973 2.95599C7.3257 2.65737 6.81928 2.49802 6.30065 2.50002H3.33398C2.67094 2.50002 2.03506 2.76341 1.56622 3.23225C1.09738 3.70109 0.833984 4.33698 0.833984 5.00002V15C0.833984 15.6631 1.09738 16.2989 1.56622 16.7678C2.03506 17.2366 2.67094 17.5 3.33398 17.5H15.334C15.9017 17.4984 16.4519 17.3036 16.8941 16.9476C17.3364 16.5917 17.6443 16.0959 17.7673 15.5417L19.1673 9.35002C19.1927 9.22578 19.1893 9.0974 19.1575 8.97466C19.1258 8.85191 19.0664 8.73803 18.984 8.64169ZM4.47565 15.1834C4.43332 15.3713 4.32714 15.5388 4.1752 15.6574C4.02327 15.7759 3.83495 15.8381 3.64232 15.8334H3.33398C3.11297 15.8334 2.90101 15.7456 2.74473 15.5893C2.58845 15.433 2.50065 15.221 2.50065 15V5.00002C2.50065 4.779 2.58845 4.56704 2.74473 4.41076C2.90101 4.25448 3.11297 4.16669 3.33398 4.16669H6.30065C6.48237 4.1572 6.6622 4.20746 6.81268 4.30978C6.96315 4.4121 7.076 4.56087 7.13398 4.73335L7.58398 6.10002C7.63746 6.25897 7.73765 6.39809 7.87146 6.49919C8.00527 6.60029 8.16647 6.65867 8.33398 6.66669H14.1673C14.3883 6.66669 14.6003 6.75448 14.7566 6.91076C14.9129 7.06704 15.0007 7.27901 15.0007 7.50002V8.33335H6.66732C6.47468 8.32864 6.28637 8.39084 6.13443 8.50935C5.98249 8.62786 5.87632 8.79537 5.83398 8.98335L4.47565 15.1834ZM16.1423 15.1834C16.1 15.3713 15.9938 15.5388 15.8419 15.6574C15.6899 15.7759 15.5016 15.8381 15.309 15.8334H6.00898C6.05202 15.7405 6.08284 15.6425 6.10065 15.5417L7.33398 10H17.334L16.1423 15.1834Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}

IconComponent.displayName = 'FolderOpen';

export const FolderOpen20: React.FunctionComponent<IIconProps> = createIcon(IconComponent);
