/* eslint-disable max-len */
import * as React from 'react';

import { createIcon, IIconProps, ISVGProps } from '../../internal';

function IconComponent(props: ISVGProps) {
  return (
    <svg
      {...props}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M15.8327 4.58333H10.5993L10.3327 3.75C10.1598 3.26102 9.83914 2.83792 9.4151 2.5393C8.99106 2.24068 8.48465 2.08133 7.96602 2.08333H4.16602C3.50297 2.08333 2.86709 2.34672 2.39825 2.81556C1.92941 3.28441 1.66602 3.92029 1.66602 4.58333V15.4167C1.66602 16.0797 1.92941 16.7156 2.39825 17.1844C2.86709 17.6533 3.50297 17.9167 4.16602 17.9167H15.8327C16.4957 17.9167 17.1316 17.6533 17.6005 17.1844C18.0693 16.7156 18.3327 16.0797 18.3327 15.4167V7.08333C18.3327 6.42029 18.0693 5.78441 17.6005 5.31556C17.1316 4.84672 16.4957 4.58333 15.8327 4.58333ZM16.666 15.4167C16.666 15.6377 16.5782 15.8496 16.4219 16.0059C16.2657 16.1622 16.0537 16.25 15.8327 16.25H4.16602C3.945 16.25 3.73304 16.1622 3.57676 16.0059C3.42048 15.8496 3.33268 15.6377 3.33268 15.4167V4.58333C3.33268 4.36232 3.42048 4.15036 3.57676 3.99408C3.73304 3.8378 3.945 3.75 4.16602 3.75H7.96602C8.14072 3.74955 8.31115 3.80401 8.45321 3.9057C8.59527 4.00739 8.70178 4.15115 8.75768 4.31666L9.20768 5.68333C9.26359 5.84885 9.3701 5.99261 9.51216 6.0943C9.65422 6.19598 9.82465 6.25045 9.99935 6.25H15.8327C16.0537 6.25 16.2657 6.3378 16.4219 6.49408C16.5782 6.65036 16.666 6.86232 16.666 7.08333V15.4167Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}

IconComponent.displayName = 'Folder';

export const Folder20: React.FunctionComponent<IIconProps> = createIcon(IconComponent);
