/* eslint-disable max-len */
import * as React from 'react';

import { createIcon, IIconProps, ISVGProps } from '../../internal';

function IconComponent(props: ISVGProps) {
  return (
    <svg
      {...props}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M9.88712 7.52667L7.06045 4.70001C6.99847 4.63752 6.92474 4.58792 6.8435 4.55408C6.76226 4.52023 6.67512 4.50281 6.58712 4.50281C6.49911 4.50281 6.41197 4.52023 6.33073 4.55408C6.24949 4.58792 6.17576 4.63752 6.11378 4.70001C5.98962 4.82491 5.91992 4.99388 5.91992 5.17001C5.91992 5.34613 5.98962 5.5151 6.11378 5.64001L8.47378 8.00001L6.11378 10.36C5.98962 10.4849 5.91992 10.6539 5.91992 10.83C5.91992 11.0061 5.98962 11.1751 6.11378 11.3C6.17608 11.3618 6.24995 11.4107 6.33118 11.4439C6.4124 11.477 6.49938 11.4938 6.58712 11.4933C6.67485 11.4938 6.76183 11.477 6.84305 11.4439C6.92428 11.4107 6.99816 11.3618 7.06045 11.3L9.88712 8.47334C9.9496 8.41136 9.9992 8.33763 10.033 8.25639C10.0669 8.17515 10.0843 8.08801 10.0843 8.00001C10.0843 7.912 10.0669 7.82486 10.033 7.74362C9.9992 7.66238 9.9496 7.58865 9.88712 7.52667Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}

IconComponent.displayName = 'AngleRight';

export const AngleRight16: React.FunctionComponent<IIconProps> = createIcon(IconComponent);
