export class EventQueue<TEvent> {
  private eventQueue: TEvent[] = [];
  constructor(events: TEvent[]) {
    this.eventQueue = events;
  }

  public resetQueue(events: TEvent[]) {
    this.eventQueue = events;
  }

  public pushEvent(event: TEvent) {
    this.eventQueue.push(event);
  }

  public showFirst() {
    return this.eventQueue[0];
  }

  public takeFirst() {
    return this.eventQueue.shift();
  }

  public getLength() {
    return this.eventQueue.length;
  }

  public getList() {
    return [...this.eventQueue];
  }
}
