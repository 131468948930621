/* eslint-disable max-len */
import * as React from 'react';

import { createIcon, IIconProps, ISVGProps } from '../../internal';

function IconComponent(props: ISVGProps) {
  return (
    <svg
      {...props}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.97631 10.3097C3.60143 9.68456 4.44928 9.33337 5.33333 9.33337H10.6667C11.5507 9.33337 12.3986 9.68456 13.0237 10.3097C13.6488 10.9348 14 11.7827 14 12.6667V14C14 14.3682 13.7015 14.6667 13.3333 14.6667C12.9651 14.6667 12.6667 14.3682 12.6667 14V12.6667C12.6667 12.1363 12.456 11.6276 12.0809 11.2525C11.7058 10.8774 11.1971 10.6667 10.6667 10.6667H5.33333C4.8029 10.6667 4.29419 10.8774 3.91912 11.2525C3.54405 11.6276 3.33333 12.1363 3.33333 12.6667V14C3.33333 14.3682 3.03486 14.6667 2.66667 14.6667C2.29848 14.6667 2 14.3682 2 14V12.6667C2 11.7827 2.35119 10.9348 2.97631 10.3097Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.99935 2.66671C6.89478 2.66671 5.99935 3.56214 5.99935 4.66671C5.99935 5.77128 6.89478 6.66671 7.99935 6.66671C9.10392 6.66671 9.99935 5.77128 9.99935 4.66671C9.99935 3.56214 9.10392 2.66671 7.99935 2.66671ZM4.66602 4.66671C4.66602 2.82576 6.1584 1.33337 7.99935 1.33337C9.8403 1.33337 11.3327 2.82576 11.3327 4.66671C11.3327 6.50766 9.8403 8.00004 7.99935 8.00004C6.1584 8.00004 4.66602 6.50766 4.66602 4.66671Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}

IconComponent.displayName = 'User';

export const User16: React.FunctionComponent<IIconProps> = createIcon(IconComponent);
