import { classes } from 'classifizer';
import { useContext, useRef, useState } from 'react';

import { useOutsideClick } from '@ws/shared/hooks';
import { Close24 } from '@ws/shared/icons';

import { TCustomer } from '../../types/domains/customer';
import { Button } from '../Button';
import { ModalsContext } from '../Modals';
import { TUserVariant, User } from '../User';

import styles from './Auth.module.css';
import { showEmbeddedAuthServiceModal } from './EmbeddedAuthServiceModal';

export interface IAuthProps {
  variant?: TUserVariant;
  authFrameOrigin: string;
  serviceName: string;
  isAuth: boolean;
  customer: TCustomer | null;
  onLogout(): void;
  getUserInfo(): void;
}

export function Auth({
  variant,
  authFrameOrigin,
  serviceName,
  onLogout,
  getUserInfo,
  isAuth,
  customer,
}: IAuthProps) {
  const [isOpen, setIsOpen] = useState(false);

  const popUpRef = useRef<HTMLDivElement>(null);
  const { setModal } = useContext(ModalsContext);

  function onPopUpToggle() {
    setIsOpen((prevState) => !prevState);
  }
  function handleClose() {
    setIsOpen(false);
  }

  useOutsideClick(popUpRef, handleClose);

  async function handleLogoutClick() {
    onLogout();
  }

  function handleProfileClick() {
    const CustomView = showEmbeddedAuthServiceModal({
      authFrameOrigin,
      serviceName,
      getUserInfo,
    });

    if (!CustomView) {
      return;
    }

    setModal({
      customView: CustomView,
    });
  }

  return (
    <section ref={popUpRef} className={styles['auth']}>
      <User onClick={onPopUpToggle} user={customer} variant={variant} />

      {isOpen && (
        <div className={classes(styles['status-panel'], styles['status-panel_bottom'])}>
          <Button
            variant="shapeless"
            className={styles['status-panel__cross']}
            icon={<Close24 />}
            onClick={handleClose}
          />

          {isAuth && (
            <div className={styles['auth__wrapper']}>
              <Button
                color="secondary"
                className={styles['auth__button']}
                onClick={handleProfileClick}
              >
                Profile
              </Button>
              <Button
                color="thirdary"
                className={styles['auth__button']}
                onClick={handleLogoutClick}
              >
                Logout
              </Button>
            </div>
          )}

          {!isAuth && (
            <Button
              color="secondary"
              className={styles['auth__button']}
              onClick={handleProfileClick}
            >
              Login
            </Button>
          )}
        </div>
      )}
    </section>
  );
}
