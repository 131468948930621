export function indexOfObject<T>(array: T[], iterator: (item: T, idx: number) => boolean) {
  for (let i = 0; i < array.length; i += 1) {
    const item = array[i];
    if (iterator(item, i)) {
      return i;
    }
  }

  return -1;
}
