import { PositionManager } from './PositionManager';

interface IPositionWrapperProps {
  children: JSX.Element;
  autoPositioning?: boolean;
  parent?: Element;
}

export function PositionWrapper({
  children,
  autoPositioning = false,
  parent,
}: IPositionWrapperProps) {
  if (!autoPositioning) {
    return children;
  }

  return <PositionManager parent={parent}>{children}</PositionManager>;
}
