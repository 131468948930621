import { Input, TModalCustomView } from '@ws/shared/components';
import { useEffect, useRef, useState } from 'react';

import { getNowAndAName } from '../../../../utils/getNowAndAName';

export function showSplitModal(splitFunc: (text: string) => void): TModalCustomView {
  return function SplitModal({
    closeAllModals,
    onAwayClose,
    actionHandlerCreator,
    components: {
      ModalWrapper,
      ModalContentWrapper,
      ModalCloseButton,
      ModalTitle,
      ModalSubTitle,
      ModalContent,
      ButtonsPanel,
    },
  }) {
    const inputRef = useRef<HTMLInputElement>(null);
    const { augmentedName } = getNowAndAName();
    const [text, setText] = useState(augmentedName);

    useEffect(() => {
      inputRef.current?.select();
    }, []);

    return (
      <ModalWrapper onClick={onAwayClose}>
        <ModalContentWrapper>
          <ModalCloseButton onClose={closeAllModals} />
          <ModalTitle>Разделение заметки</ModalTitle>
          <ModalSubTitle>Введите название для новой заметки</ModalSubTitle>
          <ModalContent>
            <Input ref={inputRef} value={text} onChange={(event) => setText(event.target.value)} />
          </ModalContent>

          <ButtonsPanel
            actions={[
              { text: 'Оставить', color: 'thirdary' },
              {
                text: 'Отделить',
                onClick: actionHandlerCreator(() => splitFunc(text)),
              },
            ]}
          />
        </ModalContentWrapper>
      </ModalWrapper>
    );
  };
}
