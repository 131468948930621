import { Button } from './Button';
import { Divider } from './Divider';
import { Item } from './Item';
import { List } from './List';

export const Menu = {
  List,
  Item,
  Divider,
  Button,
};
