import { IAction } from '@ws/shared/types';
import {
  delay,
  takeEvery,
  call,
  take,
  cancel,
  fork,
  put,
  all,
  race,
  // cancelled,
} from 'typed-redux-saga';

function* internalTickWorker(action: any) {
  console.log('internalTickWorker', action.payload?.intervalId);
  yield;
}

function* watchTick() {
  yield* takeEvery('INTERVAL_TICK', internalTickWorker);
}

function* intervalWorker(action: any) {
  const { payload } = action;
  const { intervalId, time } = payload;

  try {
    while (true) {
      yield call(delay, time); // Use a custom delay function
      yield put({ type: 'INTERVAL_TICK', payload: { intervalId } });
    }
  } catch (error) {
    console.log('ERROR');
    // Handle any errors or cancellation here
  }
}

const tasks: Record<string, any> = {};

function* watchInterval() {
  while (true) {
    const { startAction, stopAction } = yield* race({
      startAction: take<IAction<{ intervalId: string }>>('TEST_TIMER_START'),
      stopAction: take<IAction<{ intervalId: string }>>('TEST_TIMER_STOP'),
    });

    if (startAction) {
      const intervalId = startAction.payload?.intervalId;
      if (!intervalId) {
        return;
      }

      const prevTask = tasks[intervalId];
      if (prevTask) {
        yield* cancel(prevTask);
      }

      const task = yield* fork(intervalWorker, startAction);
      tasks[intervalId] = task;
    }

    if (stopAction) {
      console.log('stopAction', stopAction);

      const intervalId = stopAction.payload?.intervalId;
      if (!intervalId) {
        return;
      }

      const wantedTask = tasks[intervalId];
      if (!wantedTask) {
        return;
      }

      yield* cancel(wantedTask);
    }
  }
}

export function* testSaga() {
  yield* all([watchInterval(), watchTick()]);

  console.log('test saga');
  yield;
}
