import { Input, IModalAction, TModalCustomView } from '@ws/shared/components';
import { useEffect, useRef, useState } from 'react';

import { isMobile } from '../../../../modules/getResolution';

export function showCopyNoteModal(
  initialName: string,
  createFunc: (text: string) => void,
  createAndGoFunc: (text: string) => void,
  resolution: string,
): TModalCustomView {
  return function CopyNoteModal({
    closeAllModals,
    onAwayClose,
    actionHandlerCreator,
    components: {
      ModalWrapper,
      ModalContentWrapper,
      ModalCloseButton,
      ModalTitle,
      ModalSubTitle,
      ModalContent,
      ButtonsPanel,
    },
  }) {
    const inputRef = useRef<HTMLInputElement>(null);
    const [text, setText] = useState(`${initialName} (2)`);
    const mobile = isMobile(resolution);
    const baseButtons: IModalAction[] = [
      {
        text: 'Создать',
        color: 'secondary',
        onClick: actionHandlerCreator(() => createFunc(text)),
      },
      {
        text: 'Создать и перейти',
        onClick: actionHandlerCreator(() => createAndGoFunc(text)),
      },
    ];
    const isNotMobileButtons: IModalAction[] = mobile
      ? []
      : [{ text: 'Отмена', color: 'thirdary' }];

    useEffect(() => {
      inputRef.current?.select();
    }, []);

    return (
      <ModalWrapper onClick={onAwayClose}>
        <ModalContentWrapper>
          <ModalCloseButton onClose={closeAllModals} />
          <ModalTitle>Копирование заметки</ModalTitle>
          <ModalSubTitle>Введите название</ModalSubTitle>
          <ModalContent>
            <Input ref={inputRef} value={text} onChange={(event) => setText(event.target.value)} />
          </ModalContent>

          <ButtonsPanel actions={[...isNotMobileButtons, ...baseButtons]} />
        </ModalContentWrapper>
      </ModalWrapper>
    );
  };
}
