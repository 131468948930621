export function getSelected(element: HTMLTextAreaElement) {
  const { selectionStart, selectionEnd } = element;
  const text = element.value;
  const commonLen = text.length;

  return {
    selectionStart,
    selectionEnd,
    isEmpty: selectionStart === selectionEnd,
    isInStart: selectionStart === 0,
    isInEnd: selectionEnd === commonLen,
  };
}
