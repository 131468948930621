/* eslint-disable max-len */
import * as React from 'react';

import { createIcon, IIconProps, ISVGProps } from '../../internal';

function IconComponent(props: ISVGProps) {
  return (
    <svg
      {...props}
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M1.5 4H10.5C10.6326 4 10.7598 3.94732 10.8536 3.85355C10.9473 3.75979 11 3.63261 11 3.5C11 3.36739 10.9473 3.24021 10.8536 3.14645C10.7598 3.05268 10.6326 3 10.5 3H1.5C1.36739 3 1.24021 3.05268 1.14645 3.14645C1.05268 3.24021 1 3.36739 1 3.5C1 3.63261 1.05268 3.75979 1.14645 3.85355C1.24021 3.94732 1.36739 4 1.5 4ZM10.5 8H1.5C1.36739 8 1.24021 8.05268 1.14645 8.14645C1.05268 8.24021 1 8.36739 1 8.5C1 8.63261 1.05268 8.75979 1.14645 8.85355C1.24021 8.94732 1.36739 9 1.5 9H10.5C10.6326 9 10.7598 8.94732 10.8536 8.85355C10.9473 8.75979 11 8.63261 11 8.5C11 8.36739 10.9473 8.24021 10.8536 8.14645C10.7598 8.05268 10.6326 8 10.5 8ZM10.5 5.5H1.5C1.36739 5.5 1.24021 5.55268 1.14645 5.64645C1.05268 5.74021 1 5.86739 1 6C1 6.13261 1.05268 6.25979 1.14645 6.35355C1.24021 6.44732 1.36739 6.5 1.5 6.5H10.5C10.6326 6.5 10.7598 6.44732 10.8536 6.35355C10.9473 6.25979 11 6.13261 11 6C11 5.86739 10.9473 5.74021 10.8536 5.64645C10.7598 5.55268 10.6326 5.5 10.5 5.5Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}

IconComponent.displayName = 'Bars';

export const Bars12: React.FunctionComponent<IIconProps> = createIcon(IconComponent);
