import { useId } from '@floating-ui/react';
import { forwardRef, HTMLProps, useLayoutEffect } from 'react';

import { usePopoverContext } from '../context';

export const Heading = forwardRef<HTMLHeadingElement, HTMLProps<HTMLHeadingElement>>(
  function PopoverHeading(props, ref) {
    const { setLabelId } = usePopoverContext();
    const id = useId();

    // Only sets `aria-labelledby` on the Popover root element
    // if this component is mounted inside it.
    useLayoutEffect(() => {
      setLabelId(id);
      return () => setLabelId(undefined);
    }, [id, setLabelId]);

    return (
      <h2 {...props} ref={ref} id={id}>
        {props.children}
      </h2>
    );
  },
);
