import { ReactNode } from 'react';

import { PopoverContext } from '../context';
import { usePopover } from '../hooks';
import { PopoverOptions } from '../types';

export function Container({
  children,
  modal = false,
  ...restOptions
}: {
  children: ReactNode;
} & PopoverOptions) {
  // This can accept any props as options, e.g. `placement`,
  // or other positioning options.
  const popover = usePopover({ modal, placement: 'bottom-start', ...restOptions });
  return <PopoverContext.Provider value={popover}>{children}</PopoverContext.Provider>;
}
