import styles from './ErrorScreen.module.css';

interface IErrorData {
  error: Error;
  description?: string;
}

export function ErrorScreen({ error, description }: IErrorData) {
  return (
    <div className={styles['error-screen']}>
      <h1>:(</h1>
      <h2>Error</h2>
      <h3>{error.message.toUpperCase()}</h3>

      {description && <pre>{description}</pre>}
    </div>
  );
}
