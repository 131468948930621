import { STATUS_BAD_REQUEST } from '@ws/shared/utils';

import { SyncState } from './SyncState';
import { LastUpdateIdKeeper } from './lastUpdateIdKeeper';
import {
  IConstructorParams,
  TCommunicateFunc,
  TDeltaFunc,
  TFetchFunc,
  TOnApplySuccessFunc,
  TOnChangeFunc,
  TOnSyncStateChange,
} from './types';

const DELTA_TIMER = 5000;
const RETRY_APPLY_TIMER = 5000;

export class EventTrain<T> {
  private _watchInterval: NodeJS.Timer | undefined = undefined;
  private _runTimeout: NodeJS.Timer | undefined = undefined;

  private _isBlocked = false;
  private _isRunning = false;

  private _syncState: SyncState<T>;
  private _lastUpdateIdInConflict: LastUpdateIdKeeper;

  private _fetcherFunc: TFetchFunc<T> | null = null;
  private _deltaFunc: TDeltaFunc | null = null;
  private _communicate: TCommunicateFunc;

  private _onCharge: TOnChangeFunc<T>;
  private _onApplySuccess: TOnApplySuccessFunc<T>;
  private _onSyncStateChange: TOnSyncStateChange<T>;

  public constructor(params: IConstructorParams<T>) {
    this._isBlocked = params.isBlocked;
    this._syncState = params.syncState;
    this._fetcherFunc = params.fetcherFunc;
    this._deltaFunc = params.deltaFunc;
    this._communicate = params.communicate;

    this._onCharge = params.onCharge;
    this._onApplySuccess = params.onApplySuccess;
    this._onSyncStateChange = params.onSyncStateChange;

    this._lastUpdateIdInConflict = new LastUpdateIdKeeper();
  }

  public setIsBlocked({ isBlocked }: { isBlocked: boolean }) {
    this._isBlocked = isBlocked;
  }

  get lastUpdateIdInConflict(): string | null {
    return this._lastUpdateIdInConflict.get();
  }

  set lastUpdateIdInConflict(lastUpdateIdInConflict: string | null) {
    this._lastUpdateIdInConflict.set(lastUpdateIdInConflict);
  }

  public setConflictLastIdToLastUpdateId() {
    this._syncState.lastUpdateId = this.lastUpdateIdInConflict;
    this.lastUpdateIdInConflict = null;
    this._onSyncStateChange(this._syncState);
  }

  public async checkDelta() {
    if (this._isBlocked) {
      return;
    }

    console.log('check backend');
    const lastUpdateIdToUse = this.lastUpdateIdInConflict || this._syncState?.lastUpdateId;

    if (this._deltaFunc && lastUpdateIdToUse) {
      const response = await this._deltaFunc(lastUpdateIdToUse);

      console.log('delta response', response);

      if (response.isOk) {
        if (response.events && response.events.length > 0) {
          this.stopWatch();

          this.lastUpdateIdInConflict = response.upcomingLastUpdateId || '';
          this._onApplySuccess(this._syncState);

          const action = {
            type: 'ON_DELTA_RECEIVED',
            payload: {
              remote: response.events,
              local: this._syncState.eventQueue.getList(),
              notes: response.notes,
            },
          };

          this._communicate(action);
          return;
        }

        // this._syncState.lastUpdateId = response.lastUpdateId || null;
      }
    }
  }

  public async watch() {
    if (this._isBlocked) {
      return;
    }

    console.log('RUN WATCH');

    if (this._watchInterval) {
      return;
    }

    this.checkDelta();

    this._watchInterval = setInterval(async () => {
      this.checkDelta();
    }, DELTA_TIMER);
  }

  public async stopRun() {
    console.log('STOP RUN');
    clearInterval(this._runTimeout);
    this._runTimeout = undefined;
  }

  public async stopWatch() {
    console.log('STOP WATCH');
    clearInterval(this._watchInterval);
    this._watchInterval = undefined;
  }

  public async totalStop() {
    await this.stopRun();
    await this.stopWatch();
  }

  public async run() {
    console.log('RUN');
    if (this._isBlocked) {
      return;
    }

    this._isRunning = true;

    const eventToSend = this._syncState.eventQueue.showFirst();

    if (!eventToSend) {
      this._isRunning = false;
      this.watch();
      return;
    }

    const response = await this.fetch(eventToSend);
    if (response?.isOk) {
      this._syncState.eventQueue.takeFirst();
      this._syncState.lastUpdateId = response.data?.upcomingLastUpdateId || null;
      this._onApplySuccess(this._syncState);
    } else if (response?.status !== STATUS_BAD_REQUEST) {
      this._isRunning = false;
      this.watch();
      return;
    } else {
      this._runTimeout = setTimeout(() => {
        this.run();
      }, RETRY_APPLY_TIMER);
      return;
    }

    if (this._syncState.eventQueue.getLength() === 0) {
      this._isRunning = false;
      this.watch();
      return;
    }

    this._runTimeout = setTimeout(() => {
      this.run();
    }, 0);
  }

  public async fetch(event: T) {
    if (this._fetcherFunc && this._syncState.lastUpdateId) {
      return this._fetcherFunc(event, this._syncState.lastUpdateId);
    }

    return;
  }

  public charge(event: T) {
    console.log('CHARGE');
    this._syncState.eventQueue.pushEvent(event);

    this._onCharge(this._syncState);

    if (!this._isRunning) {
      this.stopWatch();
      this.run();
    }
  }
}
