/* eslint-disable max-len */
import * as React from 'react';

import { createIcon, IIconProps, ISVGProps } from '../../internal';

function IconComponent(props: ISVGProps) {
  return (
    <svg
      {...props}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M9.4077 10L12.3577 7.05004C12.5129 6.8939 12.6 6.68269 12.6 6.46254C12.6 6.24238 12.5129 6.03117 12.3577 5.87504C12.2802 5.79693 12.1881 5.73494 12.0865 5.69263C11.985 5.65032 11.876 5.62854 11.766 5.62854C11.656 5.62854 11.5471 5.65032 11.4455 5.69263C11.344 5.73494 11.2518 5.79693 11.1744 5.87504L7.64103 9.40837C7.56292 9.48584 7.50093 9.57801 7.45862 9.67956C7.41631 9.78111 7.39453 9.89003 7.39453 10C7.39453 10.11 7.41631 10.219 7.45862 10.3205C7.50093 10.4221 7.56292 10.5142 7.64103 10.5917L11.1744 14.1667C11.2522 14.2439 11.3446 14.305 11.4461 14.3465C11.5476 14.388 11.6564 14.409 11.766 14.4084C11.8757 14.409 11.9844 14.388 12.086 14.3465C12.1875 14.305 12.2798 14.2439 12.3577 14.1667C12.5129 14.0106 12.6 13.7994 12.6 13.5792C12.6 13.359 12.5129 13.1478 12.3577 12.9917L9.4077 10Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}

IconComponent.displayName = 'AngleLeft';

export const AngleLeft20: React.FunctionComponent<IIconProps> = createIcon(IconComponent);
