/* eslint-disable max-len */
import * as React from 'react';

import { createIcon, IIconProps, ISVGProps } from '../../internal';

function IconComponent(props: ISVGProps) {
  return (
    <svg
      {...props}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M8.79961 11.7333C9.24144 11.7333 9.59961 12.1153 9.59961 12.5866C9.59961 13.0579 9.24144 13.44 8.79961 13.44H7.19961C6.75778 13.44 6.39961 13.0579 6.39961 12.5866C6.39961 12.1153 6.75778 11.7333 7.19961 11.7333H8.79961ZM11.1996 7.46662C11.6414 7.46662 11.9996 7.84867 11.9996 8.31995C11.9996 8.79123 11.6414 9.17328 11.1996 9.17328H4.79961C4.35778 9.17328 3.99961 8.79123 3.99961 8.31995C3.99961 7.84867 4.35778 7.46662 4.79961 7.46662H11.1996ZM13.5996 3.19995C14.0414 3.19995 14.3996 3.582 14.3996 4.05328C14.3996 4.52457 14.0414 4.90662 13.5996 4.90662H2.39961C1.95778 4.90662 1.59961 4.52457 1.59961 4.05328C1.59961 3.582 1.95778 3.19995 2.39961 3.19995H13.5996Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}

IconComponent.displayName = 'Filter';

export const Filter16: React.FunctionComponent<IIconProps> = createIcon(IconComponent);
