import { BaseSyntheticEvent, useMemo, useState } from 'react';
import { debounce } from 'throttle-debounce';

import { ISelectionManagerChildrenParams, ISelectionState, TChildren } from '../types';

import { getSelected } from './helpers';

export const DEFAULT_SELECTION_STATE = {
  selectionStart: 0,
  selectionEnd: 0,
  isEmpty: true,
  isInStart: true,
  isInEnd: false,
};

interface ISelectionManagerExtensionProps {
  children: TChildren<ISelectionManagerChildrenParams>;
}

export function SelectionManagerExtension({ children }: ISelectionManagerExtensionProps) {
  const [selection, setSelection] = useState<ISelectionState>(DEFAULT_SELECTION_STATE);

  const rawSelectHandler = (event: BaseSyntheticEvent) => {
    const newSelection = getSelected(event.target);

    setSelection(newSelection);
  };

  const handleSelect = useMemo(() => debounce(250, rawSelectHandler), []);

  const params: ISelectionManagerChildrenParams = useMemo(
    () => ({
      onSelect: handleSelect,
      selection,
    }),
    [selection, handleSelect],
  );

  return children(params);
}
