import { classes } from 'classifizer';
import { ReactNode } from 'react';

import styles from './TreeNodeTools.module.css';

interface ITreeNodeToolsProps {
  children: ReactNode;
  className?: string;
}

export function TreeNodeTools({ children, className }: ITreeNodeToolsProps) {
  return <div className={classes(styles['tools'], className)}>{children}</div>;
}
