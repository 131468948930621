import { Fetcher } from '../../utils';

import { GET_USER_INFO_CONF } from './endpoints';
import { TUserInfoResponse } from './types';

export class UserRepository {
  private fetcher: Fetcher;

  constructor(fetcher: Fetcher) {
    this.fetcher = fetcher;
  }

  public getUserInfo() {
    return this.fetcher.fetch<TUserInfoResponse>({
      url: this.fetcher.buildURL(GET_USER_INFO_CONF),
    });
  }
}
