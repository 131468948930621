import { TNote } from '@ws/shared/types';
import { useSetState } from 'react-use';

import { ContentWindow } from './ContentWindow';
import styles from './ContentWindow.module.css';
import { DraggableWrapper } from './DraggableWrapper';
import { TWindowCoords, TWindowMoveDelta, TWindowSettings, TWindowSize } from './types';

interface DraggableWindowProps {
  note: TNote;
  size: TWindowSize;
  coords: TWindowCoords;
  scrollPercent: number;
  onCollapseClick(settings: TWindowSettings): void;
  onCloseClick(): void;
}

export function DraggableWindow({
  note,
  size,
  coords,
  scrollPercent,
  onCollapseClick,
  onCloseClick,
}: DraggableWindowProps) {
  const [settings, setSettings] = useSetState<{
    size: TWindowSize;
    coords: TWindowCoords;
    scrollPercent: number;
  }>({
    size,
    coords,
    scrollPercent,
  });

  function handleDragStop(data: TWindowCoords) {
    setSettings((prevState) => ({
      ...prevState,
      coords: {
        x: data.x,
        y: data.y,
      },
    }));
  }

  function handleResizeStop(delta: TWindowMoveDelta) {
    setSettings((prevState) => ({
      ...prevState,
      size: {
        width: prevState.size.width + delta.width,
        height: prevState.size.height + delta.height,
      },
    }));
  }

  function handleScrollStop(newPercent: number) {
    setSettings({ scrollPercent: newPercent });
  }

  function handleCollapseClick() {
    onCollapseClick(settings);
  }

  return (
    <DraggableWrapper
      style={{ position: 'fixed' }}
      className={styles['window-wrapper']}
      handlerClassName={styles['window__header']}
      {...settings}
      onDragStop={handleDragStop}
      onResizeStop={handleResizeStop}
    >
      <ContentWindow
        note={note}
        scrollPercent={scrollPercent}
        onScrollStop={handleScrollStop}
        onCollapseClick={handleCollapseClick}
        onCloseClick={onCloseClick}
      />
    </DraggableWrapper>
  );
}
