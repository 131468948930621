import styles from './BrowserSupport.module.css';
import { isBrowserSupported } from './checkBrowser';

export function BrowserSupport() {
  if (!isBrowserSupported()) {
    return (
      <div
        className={styles['browser__support-error']}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: 'Do not support' }}
      />
    );
  }

  return null;
}
