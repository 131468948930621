import { classes } from 'classifizer';
import { ReactNode } from 'react';
import { Helmet } from 'react-helmet';

import styles from './Page.module.css';

export interface IPageProps {
  title?: string;
  className?: string;
  children?: ReactNode;
}

export function Page({ children, title, className }: IPageProps) {
  return (
    <div className={classes(styles['page'], className)}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {children}
    </div>
  );
}
