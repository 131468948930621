import { createContext } from 'react';

export interface ITreeContext {
  activeNodeId: string | null;
  setActiveNodeId(nodeId: string): void;
}
export const treeContext = createContext<ITreeContext>({
  activeNodeId: null,
  setActiveNodeId: () => {},
});
export const { Provider: TreeContextProvider } = treeContext;
