/* eslint-disable max-len */
import * as React from 'react';

import { createIcon, IIconProps, ISVGProps } from '../../internal';

function IconComponent(props: ISVGProps) {
  return (
    <svg
      {...props}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.83398 15C5.83398 14.5397 6.20708 14.1666 6.66732 14.1666H17.5007C17.9609 14.1666 18.334 14.5397 18.334 15C18.334 15.4602 17.9609 15.8333 17.5007 15.8333H6.66732C6.20708 15.8333 5.83398 15.4602 5.83398 15Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.66602 15C1.66602 14.5397 2.03911 14.1666 2.49935 14.1666H2.50768C2.96792 14.1666 3.34102 14.5397 3.34102 15C3.34102 15.4602 2.96792 15.8333 2.50768 15.8333H2.49935C2.03911 15.8333 1.66602 15.4602 1.66602 15Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.83398 9.99996C5.83398 9.53972 6.20708 9.16663 6.66732 9.16663H17.5007C17.9609 9.16663 18.334 9.53972 18.334 9.99996C18.334 10.4602 17.9609 10.8333 17.5007 10.8333H6.66732C6.20708 10.8333 5.83398 10.4602 5.83398 9.99996Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.66602 9.99996C1.66602 9.53972 2.03911 9.16663 2.49935 9.16663H2.50768C2.96792 9.16663 3.34102 9.53972 3.34102 9.99996C3.34102 10.4602 2.96792 10.8333 2.50768 10.8333H2.49935C2.03911 10.8333 1.66602 10.4602 1.66602 9.99996Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.83398 4.99996C5.83398 4.53972 6.20708 4.16663 6.66732 4.16663H17.5007C17.9609 4.16663 18.334 4.53972 18.334 4.99996C18.334 5.4602 17.9609 5.83329 17.5007 5.83329H6.66732C6.20708 5.83329 5.83398 5.4602 5.83398 4.99996Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.66602 4.99996C1.66602 4.53972 2.03911 4.16663 2.49935 4.16663H2.50768C2.96792 4.16663 3.34102 4.53972 3.34102 4.99996C3.34102 5.4602 2.96792 5.83329 2.50768 5.83329H2.49935C2.03911 5.83329 1.66602 5.4602 1.66602 4.99996Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}

IconComponent.displayName = 'List';

export const List20: React.FunctionComponent<IIconProps> = createIcon(IconComponent);
