import { TFiles, TFileWithVersion, TFile, TFileV1 } from '@ws/shared/types';

import { Validator } from './types';
import { fileValidatorV1 } from './v1';
import { fileValidatorV2 } from './v2';

export class FileValidator extends Validator<TFiles> {
  public validate(file: TFileWithVersion<TFiles>) {
    switch (file.fileVersion) {
      case 2:
        return fileValidatorV2.validate(file as TFile);
      case 1:
        // eslint-disable-next-line no-case-declarations,@typescript-eslint/no-unused-vars
        const { fileVersion, ...restFileFields } = file;
        return fileValidatorV1.validate(restFileFields as TFileV1);

      default:
        return this.createFailureResponse(this.createUnexpectedProjectVersionMessage());
    }
  }
}

export const fileValidator = new FileValidator();
