import { useSelector } from 'react-redux';

import { TAppState } from '../../../../redux';
import { BoardManager } from '../../BoardManager';

export function BoardContainer() {
  const { selected } = useSelector((state: TAppState) => state.editor);
  const { isCompiled } = useSelector((state: TAppState) => state.general);

  if (!selected) {
    return <div>Select something in the Sidebar</div>;
  }
  return <BoardManager isCompiled={isCompiled} entityRef={selected} />;
}
