/* eslint-disable max-len */
import * as React from 'react';

import { createIcon, IIconProps, ISVGProps } from '../../internal';

function IconComponent(props: ISVGProps) {
  return (
    <svg
      {...props}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.83398 9.99998C5.83398 9.07951 6.58018 8.33331 7.50065 8.33331C8.42113 8.33331 9.16732 9.07951 9.16732 9.99998C9.16732 10.9205 8.42113 11.6666 7.50065 11.6666C6.58018 11.6666 5.83398 10.9205 5.83398 9.99998Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.834 9.99998C10.834 9.07951 11.5802 8.33331 12.5007 8.33331C13.4211 8.33331 14.1673 9.07951 14.1673 9.99998C14.1673 10.9205 13.4211 11.6666 12.5007 11.6666C11.5802 11.6666 10.834 10.9205 10.834 9.99998Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.83398 4.16667C5.83398 3.24619 6.58018 2.5 7.50065 2.5C8.42113 2.5 9.16732 3.24619 9.16732 4.16667C9.16732 5.08714 8.42113 5.83333 7.50065 5.83333C6.58018 5.83333 5.83398 5.08714 5.83398 4.16667Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.834 4.16667C10.834 3.24619 11.5802 2.5 12.5007 2.5C13.4211 2.5 14.1673 3.24619 14.1673 4.16667C14.1673 5.08714 13.4211 5.83333 12.5007 5.83333C11.5802 5.83333 10.834 5.08714 10.834 4.16667Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.83398 15.8334C5.83398 14.9129 6.58018 14.1667 7.50065 14.1667C8.42113 14.1667 9.16732 14.9129 9.16732 15.8334C9.16732 16.7538 8.42113 17.5 7.50065 17.5C6.58018 17.5 5.83398 16.7538 5.83398 15.8334Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.834 15.8334C10.834 14.9129 11.5802 14.1667 12.5007 14.1667C13.4211 14.1667 14.1673 14.9129 14.1673 15.8334C14.1673 16.7538 13.4211 17.5 12.5007 17.5C11.5802 17.5 10.834 16.7538 10.834 15.8334Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}

IconComponent.displayName = 'DragIndicator';

export const DragIndicator20: React.FunctionComponent<IIconProps> = createIcon(IconComponent);
