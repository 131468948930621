import { TextatorLogo } from '../../../components/TextatorLogo';

import styles from './Header.module.css';

export function Header() {
  return (
    <header className={styles['header']}>
      <TextatorLogo />
    </header>
  );
}
