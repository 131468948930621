import { useContext } from 'react';

import { ProjectBoardContainer } from '../../components';
import { Page } from '../../components/Page';
import { ResolutionContext } from '../../context';
import { isMobile } from '../../modules/getResolution';

import { ActionPanel } from './ActionPanel';
import { Header } from './Header';
import styles from './ProjectsPage.module.css';
import { Sidebar } from './Sidebar';

export function ProjectsPage() {
  const { resolution } = useContext(ResolutionContext);
  const mobile = isMobile(resolution);

  return (
    <Page className={styles['index-page']}>
      {!mobile && <Sidebar />}
      {mobile && <Header />}

      <ProjectBoardContainer />

      {mobile && <ActionPanel />}
    </Page>
  );
}
