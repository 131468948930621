import styles from './MarkdownEditor.module.css';

interface ILabelWrapperProps {
  children: any;
}

export function LabelWrapper({ children }: ILabelWrapperProps) {
  return (
    <label htmlFor="markdown-editor" className={styles['editor-label-wrapper']}>
      {children}
    </label>
  );
}
