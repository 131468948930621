import * as React from 'react';
import { ReactNode } from 'react';
import { throttle } from 'throttle-debounce';

import { getResolution } from '../../modules/getResolution';

import { ResolutionContextProvider } from './context';

export interface IResolutionContextManagerProps {
  children: ReactNode;
}

export interface IResolutionContextHolderState {
  resolution: string;
}

export class ResolutionContextManager extends React.Component<
  IResolutionContextManagerProps,
  IResolutionContextHolderState
> {
  public constructor(props: IResolutionContextManagerProps) {
    super(props);

    this.state = {
      resolution: getResolution() || 'no',
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.throttledResizeHandler);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.throttledResizeHandler);
  }

  onResizeHandler = () => {
    this.setState({ resolution: getResolution() || 'no' });
  };

  throttledResizeHandler = throttle(300, this.onResizeHandler);

  public render() {
    const { children } = this.props;
    const { resolution } = this.state;

    return <ResolutionContextProvider value={{ resolution }}>{children}</ResolutionContextProvider>;
  }
}
