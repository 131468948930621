import { classes } from 'classifizer';

import styles from './BreadcrumbSeparator.module.css';

interface IBreadcrumbSeparatorProps {
  leftMargin?: boolean;
}

export function BreadcrumbSeparator({ leftMargin = false }: IBreadcrumbSeparatorProps) {
  return (
    <div
      className={classes(
        styles['breadcrumb-separator'],
        leftMargin && styles['breadcrumb-separator_left-margin'],
      )}
    >
      /
    </div>
  );
}
