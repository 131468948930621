/* eslint-disable max-len */
import * as React from 'react';

import { createIcon, IIconProps, ISVGProps } from '../../internal';

function IconComponent(props: ISVGProps) {
  return (
    <svg
      {...props}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M2.00065 5.33333H14.0007C14.1775 5.33333 14.347 5.2631 14.4721 5.13807C14.5971 5.01305 14.6673 4.84348 14.6673 4.66667C14.6673 4.48986 14.5971 4.32029 14.4721 4.19526C14.347 4.07024 14.1775 4 14.0007 4H2.00065C1.82384 4 1.65427 4.07024 1.52925 4.19526C1.40422 4.32029 1.33398 4.48986 1.33398 4.66667C1.33398 4.84348 1.40422 5.01305 1.52925 5.13807C1.65427 5.2631 1.82384 5.33333 2.00065 5.33333ZM14.0007 10.6667H2.00065C1.82384 10.6667 1.65427 10.7369 1.52925 10.8619C1.40422 10.987 1.33398 11.1565 1.33398 11.3333C1.33398 11.5101 1.40422 11.6797 1.52925 11.8047C1.65427 11.9298 1.82384 12 2.00065 12H14.0007C14.1775 12 14.347 11.9298 14.4721 11.8047C14.5971 11.6797 14.6673 11.5101 14.6673 11.3333C14.6673 11.1565 14.5971 10.987 14.4721 10.8619C14.347 10.7369 14.1775 10.6667 14.0007 10.6667ZM14.0007 7.33333H2.00065C1.82384 7.33333 1.65427 7.40357 1.52925 7.5286C1.40422 7.65362 1.33398 7.82319 1.33398 8C1.33398 8.17681 1.40422 8.34638 1.52925 8.4714C1.65427 8.59643 1.82384 8.66667 2.00065 8.66667H14.0007C14.1775 8.66667 14.347 8.59643 14.4721 8.4714C14.5971 8.34638 14.6673 8.17681 14.6673 8C14.6673 7.82319 14.5971 7.65362 14.4721 7.5286C14.347 7.40357 14.1775 7.33333 14.0007 7.33333Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}

IconComponent.displayName = 'Bars';

export const Bars16: React.FunctionComponent<IIconProps> = createIcon(IconComponent);
