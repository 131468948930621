import { PropsWithChildren } from 'react';
import { useSelector } from 'react-redux';

import { TAppState } from '../../../redux';

import { SplitBoardLayout } from './SplitBoard';
import styles from './SplitManager.module.css';

export function SplitManager({ children }: PropsWithChildren) {
  const { secondarySelected } = useSelector((state: TAppState) => state.editor);

  return (
    <div className={styles['split-wrapper']}>
      <div className={styles['split-wrapper__children']}>{children}</div>

      {secondarySelected && (
        <div className={styles['split-wrapper__secondary']}>
          <SplitBoardLayout />
        </div>
      )}
    </div>
  );
}
