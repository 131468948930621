import Joi from 'joi';

export type TConfig = {
  apiUri: string;
  authFrameOrigin: string;
  previewsClientOrigin: string;
  reduxLoggerEnabled: boolean;
  serviceWorkerEnabled: boolean;
};

const ConfigSchema = Joi.object({
  apiUri: Joi.string().required(),
  authFrameOrigin: Joi.string().required(),
  previewsClientOrigin: Joi.string().required(),
  reduxLoggerEnabled: Joi.boolean().required(),
  serviceWorkerEnabled: Joi.boolean().required(),
});

const defaultConfig = {
  apiUri: 'http://localhost:4040',
  authFrameOrigin: 'http://localhost:3000',
  previewsClientOrigin: 'http://localhost:3030',
  reduxLoggerEnabled: true,
  serviceWorkerEnabled: false,
};

const __config: TConfig = defaultConfig;

export function initConfig(config: TConfig | null) {
  console.log('==> initConfig');
  if (!config) {
    throw Error('No config file');
  }

  const validationResults = ConfigSchema.validate(config);
  if (validationResults.error) {
    console.log('Config error', validationResults.error);
    throw Error('Config file is NOT VALID!');
  }

  __config.apiUri = validationResults.value.apiUri;
  __config.authFrameOrigin = validationResults.value.authFrameOrigin;
  __config.previewsClientOrigin = validationResults.value.previewsClientOrigin;
  __config.reduxLoggerEnabled = validationResults.value.reduxLoggerEnabled;
  __config.serviceWorkerEnabled = validationResults.value.serviceWorkerEnabled;
}

export function getConfig() {
  return __config;
}
