import { TResponse, Notifier, TWithIsOk } from '../../types';
import { Fetcher } from '../../utils';
import { getErrorMessage } from '../../utils/getErrorMessage';

import { UserRepository } from './User.repository';
import { TCustomer, TUserInfoResponse } from './types';

export class UserService {
  private repository: UserRepository;
  protected notifier: Notifier;

  constructor(fetcher: Fetcher, notifier: Notifier) {
    this.repository = new UserRepository(fetcher);
    this.notifier = notifier;
  }

  public async getInfo(): Promise<TWithIsOk<{ customer?: TCustomer }>> {
    let response: TResponse<TUserInfoResponse>;

    try {
      response = await this.repository.getUserInfo();
    } catch (err) {
      console.error(getErrorMessage(err));
      return { isOk: false };
    }

    if (response.error) {
      console.error(response.error.toString());
      return { isOk: false };
    }

    return { isOk: true, customer: response.data?.customer };
  }
}
