import { TModalCustomView } from '@ws/shared/components';
import { TCollection, TCompiled, TNote } from '@ws/shared/types';

import { PreviewCreator } from './PreviewCreator';

interface IGetPreviewCreatorModalProps {
  entity: TCollection | TNote;
  compiled: TCompiled;
  onCreationFinish(): void;
}

export function getPreviewCreatorModal({
  entity,
  compiled,
  onCreationFinish,
}: IGetPreviewCreatorModalProps): TModalCustomView {
  return function PreviewCreatorModal({
    closeAllModals,
    onAwayClose,
    components: { ModalWrapper, ModalContentWrapper, ModalCloseButton, ModalTitle, ModalContent },
  }) {
    return (
      <ModalWrapper onClick={onAwayClose}>
        <ModalContentWrapper>
          <ModalCloseButton onClose={closeAllModals} />
          <ModalTitle>Новый предпоказ</ModalTitle>
          <ModalContent>
            <PreviewCreator
              entity={entity}
              compiled={compiled}
              onCreationFinish={onCreationFinish}
              onClose={closeAllModals}
            />
          </ModalContent>
        </ModalContentWrapper>
      </ModalWrapper>
    );
  };
}
