import { combineReducers } from '@reduxjs/toolkit';

import { breadcrumbsReducer } from './breadcrumbs';
import { contentWindowsReducer } from './contentWindows';
import { editorReducer } from './editor';
import { generalReducer } from './general';
import { projectsReducer } from './projects';
import { remoteReducer } from './remote';
import { syncReducer } from './sync';
import { userReducer } from './user';

export const rootReducer = combineReducers({
  remote: remoteReducer,
  general: generalReducer,
  user: userReducer,
  breadcrumbs: breadcrumbsReducer,
  editor: editorReducer,
  projects: projectsReducer,
  contentWindows: contentWindowsReducer,
  sync: syncReducer,
});
