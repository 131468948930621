/* eslint-disable */

export type TColors =
  'textator_primary'
  | 'textator_primary_hover'
  | 'other_red'
  | 'other_red_01'
  | 'other_red_02'
  | 'textator_primary_02'
  | 'textator_primary_01'
  | 'other_grey'
  | 'other_light_grey'
  | 'other_dark_grey'
  | 'other_main_text'
  | 'other_dark_grey_05'
  | 'other_dark_grey_02'
  | 'other_dark_grey_01'
  | 'other_dark_grey_006'
  | 'textator_primary_05'
  | 'other_white'
  | 'other_black_01'
  | 'other_black_03'
  | 'other_orange'
  | 'other_success'
  | 'other_success_01'
  | 'other_orange_01'
  | 'current_color';

export const colorsClassesMap: { [key in TColors]: string } = {
  'textator_primary': 'color_textator_primary',
  'textator_primary_hover': 'color_textator_primary_hover',
  'other_red': 'color_other_red',
  'other_red_01': 'color_other_red_01',
  'other_red_02': 'color_other_red_02',
  'textator_primary_02': 'color_textator_primary_02',
  'textator_primary_01': 'color_textator_primary_01',
  'other_grey': 'color_other_grey',
  'other_light_grey': 'color_other_light_grey',
  'other_dark_grey': 'color_other_dark_grey',
  'other_main_text': 'color_other_main_text',
  'other_dark_grey_05': 'color_other_dark_grey_05',
  'other_dark_grey_02': 'color_other_dark_grey_02',
  'other_dark_grey_01': 'color_other_dark_grey_01',
  'other_dark_grey_006': 'color_other_dark_grey_006',
  'textator_primary_05': 'color_textator_primary_05',
  'other_white': 'color_other_white',
  'other_black_01': 'color_other_black_01',
  'other_black_03': 'color_other_black_03',
  'other_orange': 'color_other_orange',
  'other_success': 'color_other_success',
  'other_success_01': 'color_other_success_01',
  'other_orange_01': 'color_other_orange_01',
  'current_color': 'color_current_color',
};
