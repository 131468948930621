import { ReactNode } from 'react';

import styles from './TreeNodeTitleWrapper.module.css';

interface ITreeNodeTitleWrapperProps {
  children: ReactNode;
}

export function TreeNodeTitleWrapper({ children }: ITreeNodeTitleWrapperProps) {
  return <div className={styles['wrapper']}>{children}</div>;
}
