import {
  TProjectV1,
  TCollectionV1,
  TNoteV1,
  TCollectionV2,
  TEntityRefV2,
  TNoteV2,
  TProjectV2,
} from '@ws/shared/types';
import { getSha1Hash } from '@ws/shared/utils';
import CyrillicToTranslit from 'cyrillic-to-translit-js';
import { v4 } from 'uuid';

import { projectV2Builder } from '../v2';

export class ProjectAdaptorV1 {
  private static transformNote(noteV1: TNoteV1) {
    const version = noteV1.relevantVersion;

    const noteContent = noteV1.content.find((one) => one.version === version);
    const content = noteContent?.text || '';

    const noteV2: TNoteV2 = {
      id: noteV1.id,
      parentId: noteV1.parentId,

      type: 'note',
      meta: {
        name: noteV1.name,
        withTitle: noteV1.withTitle,
        isHidden: noteV1.isHidden,
      },

      content: noteContent?.text || '',
      hash: getSha1Hash(content),

      createdAt: noteV1.created,
      updatedAt: noteV1.updated,
    };

    return noteV2;
  }

  private static transformCollection(collectionV1: TCollectionV1) {
    const children: any[] = [];

    const collectionV2: TCollectionV2 = {
      id: collectionV1.id,
      parentId: collectionV1.parentId,

      type: 'collection',
      meta: {
        name: collectionV1.name,
        withTitle: collectionV1.withTitle,
        isHidden: collectionV1.isHidden,
      },

      children,
      hash: getSha1Hash(JSON.stringify(children)),

      createdAt: collectionV1.created,
      updatedAt: collectionV1.updated,
    };

    return collectionV2;
  }

  private static transform(
    kit: any[],
    rootChildIds: string[],
    rootId: string,
    unboundChildIds: string[],
    unboundId: string,
  ) {
    const oldDict: { [key: string]: TNoteV1 | TCollectionV1 } = {};
    const dictToDict: { [key: string]: TEntityRefV2 } = {
      root: { id: rootId, type: 'collection' },
      unbound: { id: unboundId, type: 'collection' },
    };

    const notes: { [key: string]: TNoteV2 } = {};
    const collections: { [key: string]: TCollectionV2 } = {};

    kit.forEach((one: any) => {
      const oldId = one.id;
      const newId = v4();

      oldDict[newId] = { ...one, id: newId };
      dictToDict[oldId] = { id: newId, type: one.type };
    });

    Object.values(oldDict).forEach((one: TNoteV1 | TCollectionV1) => {
      if (one.type === 'note') {
        const newNote = this.transformNote(one);
        newNote.parentId = dictToDict[newNote.parentId]?.id || newNote.parentId;
        notes[one.id] = newNote;
      } else if (one.type === 'collection') {
        const newCollection = this.transformCollection(one);
        newCollection.parentId = dictToDict[newCollection.parentId]?.id || newCollection.parentId;
        newCollection.children = one.children.map((child) => dictToDict[child]);
        newCollection.hash = getSha1Hash(JSON.stringify(newCollection.children));
        collections[one.id] = newCollection;
      }
    });

    const rootChildRefs = rootChildIds.map((wantedId) => dictToDict[wantedId]);
    const unboundChildRefs = unboundChildIds.map((wantedId) => dictToDict[wantedId]);

    return {
      notes,
      collections,
      rootChildRefs,
      unboundChildRefs,
    };
  }

  public static adapt(projectV1: TProjectV1) {
    const children: any[] = [];

    const rootCollection: TCollectionV2 = {
      id: v4(),
      parentId: 'root',

      type: 'collection',
      meta: {
        name: projectV1.root.name,
        withTitle: projectV1.root.withTitle,
        isHidden: false,
      },

      children,
      hash: getSha1Hash(JSON.stringify(children)),

      createdAt: projectV1.created,
      updatedAt: projectV1.root.updated,
    };

    const unboundCollection = projectV2Builder.createCollectionV2({ parentId: 'unbound' });

    const trans = new CyrillicToTranslit({ preset: 'ru' });

    const dicts = this.transform(
      projectV1.kit,
      projectV1.root.children,
      rootCollection.id,
      projectV1.unbound,
      unboundCollection.id,
    );
    rootCollection.children = dicts.rootChildRefs;
    rootCollection.hash = getSha1Hash(JSON.stringify(rootCollection.children));
    unboundCollection.children = dicts.unboundChildRefs;
    unboundCollection.hash = getSha1Hash(JSON.stringify(unboundCollection.children));

    const projectV2: TProjectV2 = {
      version: 2,
      id: v4(),
      root: rootCollection.id,
      unbound: unboundCollection.id,

      meta: {
        name: projectV1.root.name,
        slug: trans.transform(projectV1.root.name),
      },

      collections: {
        ...dicts.collections,
        [rootCollection.id]: rootCollection,
        [unboundCollection.id]: unboundCollection,
      },
      notes: dicts.notes,

      createdAt: projectV1.created,
      updatedAt: projectV1.updated,
    };

    return projectV2;
  }
}
