/* eslint-disable max-len */
import * as React from 'react';

import { createIcon, IIconProps, ISVGProps } from '../../internal';

function IconComponent(props: ISVGProps) {
  return (
    <svg
      {...props}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_767_14471)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.16602 8.33329C8.70578 8.33329 8.33268 8.70639 8.33268 9.16663V16.6666C8.33268 17.1269 8.70578 17.5 9.16602 17.5H16.666C17.1263 17.5 17.4993 17.1269 17.4993 16.6666V9.16663C17.4993 8.70639 17.1263 8.33329 16.666 8.33329H9.16602ZM6.66602 9.16663C6.66602 7.78591 7.7853 6.66663 9.16602 6.66663H16.666C18.0467 6.66663 19.166 7.78591 19.166 9.16663V16.6666C19.166 18.0473 18.0467 19.1666 16.666 19.1666H9.16602C7.7853 19.1666 6.66602 18.0473 6.66602 16.6666V9.16663Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.33398 2.50004C3.11297 2.50004 2.90101 2.58784 2.74473 2.74412C2.58845 2.9004 2.50065 3.11236 2.50065 3.33337V10.8334C2.50065 11.0544 2.58845 11.2663 2.74473 11.4226C2.90101 11.5789 3.11297 11.6667 3.33398 11.6667H4.16732C4.62755 11.6667 5.00065 12.0398 5.00065 12.5C5.00065 12.9603 4.62755 13.3334 4.16732 13.3334H3.33398C2.67094 13.3334 2.03506 13.07 1.56622 12.6011C1.09738 12.1323 0.833984 11.4964 0.833984 10.8334V3.33337C0.833984 2.67033 1.09738 2.03445 1.56622 1.56561C2.03506 1.09677 2.67094 0.833374 3.33398 0.833374H10.834C11.497 0.833374 12.1329 1.09677 12.6018 1.56561C13.0706 2.03445 13.334 2.67033 13.334 3.33337V4.16671C13.334 4.62694 12.9609 5.00004 12.5007 5.00004C12.0404 5.00004 11.6673 4.62694 11.6673 4.16671V3.33337C11.6673 3.11236 11.5795 2.9004 11.4232 2.74412C11.267 2.58784 11.055 2.50004 10.834 2.50004H3.33398Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_767_14471">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

IconComponent.displayName = 'Copy';

export const Copy20: React.FunctionComponent<IIconProps> = createIcon(IconComponent);
