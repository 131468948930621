import { TCollection, TEntity, TEntityRef, TNote } from '@ws/shared/types';
import { useContext } from 'react';

import { EditabilityContext } from '../../../context/EditabilityContext';
import { ContentBoardContainer } from '../ContentBoardContainer';
import { WriteBoardContainer } from '../WriteBoardContainer';

import styles from './BoardManager.module.css';

interface IBoardManagerProps {
  entityRef: TEntityRef;
  isCompiled: boolean;
}

export function BoardManager({ entityRef, isCompiled }: IBoardManagerProps) {
  const editability = useContext(EditabilityContext);
  const entity = { ...editability.getNodeByRef(entityRef) } as TEntity;

  return (
    <div className={styles['board-wrapper']}>
      {entity.type === 'note' ? (
        <WriteBoardContainer isCompiled={isCompiled} note={entity as TNote} />
      ) : (
        <ContentBoardContainer isCompiled={isCompiled} entity={entity as TCollection} />
      )}
    </div>
  );
}
