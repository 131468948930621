import { Notifier, TResponse, TWithIsOk } from '@ws/shared/types';
import { Fetcher, getErrorMessage } from '@ws/shared/utils';

import { ReviewRepository } from './Review.repository';
import {
  ICreateReviewRequestParams,
  TCreateReviewResponse,
  TGetReviewByIdResponse,
  TGetReviewsByPreviewIdResponse,
} from './types';

export class ReviewService {
  private _repository: ReviewRepository;
  private _notifier: Notifier;

  constructor(fetcher: Fetcher, notifier: Notifier) {
    this._repository = new ReviewRepository(fetcher);
    this._notifier = notifier;
  }

  public async create(
    params: ICreateReviewRequestParams,
  ): Promise<TWithIsOk<Partial<TCreateReviewResponse>>> {
    let response: TResponse<TCreateReviewResponse>;

    try {
      response = await this._repository.create(params);
    } catch (err) {
      this._notifier.error(getErrorMessage(err));
      return { isOk: false };
    }

    if (response.error) {
      this._notifier.error(response.error.toString());
      return { isOk: false };
    }

    if (!response.data) {
      this._notifier.error('Error during sharing fetching by id request');
      return { isOk: false };
    }

    return { isOk: true, ...response.data };
  }

  public async getReviewById(id: string): Promise<TWithIsOk<Partial<TGetReviewByIdResponse>>> {
    let response: TResponse<TGetReviewByIdResponse>;

    try {
      response = await this._repository.getById(id);
    } catch (err) {
      this._notifier.error(getErrorMessage(err));
      return { isOk: false };
    }

    if (response.error) {
      this._notifier.error(response.error.toString());
      return { isOk: false };
    }

    if (!response.data) {
      this._notifier.error('Error during preview fetching by id request');
      return { isOk: false };
    }

    return { isOk: true, ...response.data };
  }

  public async getListByPreviewId(
    sharingId: string,
  ): Promise<TWithIsOk<Partial<TGetReviewsByPreviewIdResponse>>> {
    let response: TResponse<TGetReviewsByPreviewIdResponse>;

    try {
      response = await this._repository.getListByPreviewId(sharingId);
    } catch (err) {
      this._notifier.error(getErrorMessage(err));
      return { isOk: false };
    }

    if (response.error) {
      this._notifier.error(response.error.toString());
      return { isOk: false };
    }

    if (!response.data) {
      this._notifier.error('Error during sharing fetching by id request');
      return { isOk: false };
    }

    return { isOk: true, ...response.data };
  }
}
