import { TNote } from '@ws/shared/types';
import { useContext } from 'react';

import { EditabilityContext } from '../../context/EditabilityContext';

import { DraggableWindow } from './DraggableWindow';
import { TWindowCoords, TWindowSettings, TWindowSize } from './types';

interface ContentWindowContainerProps {
  nodeId: string;
  size: TWindowSize;
  coords: TWindowCoords;
  scrollPercent: number;
  onCollapseClick(updatedSettings: TWindowSettings): void;
  onCloseClick(): void;
}

export function ContentWindowContainer({
  size,
  coords,
  scrollPercent,
  nodeId,
  onCollapseClick,
  onCloseClick,
}: ContentWindowContainerProps) {
  const { getNode } = useContext(EditabilityContext);
  const note = getNode(nodeId) as TNote;

  if (!note) {
    return null;
  }

  return (
    <DraggableWindow
      size={size}
      coords={coords}
      scrollPercent={scrollPercent}
      note={note}
      onCollapseClick={onCollapseClick}
      onCloseClick={onCloseClick}
    />
  );
}
