import {
  autoUpdate,
  flip,
  offset,
  safePolygon,
  shift,
  useFloating,
  useHover,
  useInteractions,
} from '@floating-ui/react';
import { useState } from 'react';

import { AngleRight16 } from '../Icons';
import { Menu } from '../Menu';

import { PositionWrapper } from './PositionWrapper';
import { IContextMenuItem } from './types';

export interface IContextMenuProps {
  items: IContextMenuItem[];
  width?: string;
  height?: string;
  autoPositioning?: boolean;
  open?: boolean;
  parent?: Element;
}

export function ContextMenu({
  items,
  width,
  height,
  autoPositioning = false,
  open,
  parent,
}: IContextMenuProps) {
  if (!open && typeof open !== 'undefined') {
    return null;
  }

  return (
    <PositionWrapper parent={parent} autoPositioning={autoPositioning}>
      <Menu.List width={width} height={height}>
        {items.map((item, index) => {
          if (item.type === 'divider') {
            return <Menu.Divider key={index} />;
          }

          return <ContextItem key={index} item={item} />;
        })}
      </Menu.List>
    </PositionWrapper>
  );
}

interface IContextItemProps {
  item: IContextMenuItem;
}

// can't move it to another component because cycle dependency (recursive usage of ContextMenu)
export function ContextItem({ item }: IContextItemProps) {
  const [isOpen, setIsOpen] = useState(false);

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: 'bottom-start',
    middleware: [offset({ mainAxis: 4, alignmentAxis: 0 }), flip(), shift()],
    whileElementsMounted: autoUpdate,
  });

  const hover = useHover(context, {
    handleClose: safePolygon({
      requireIntent: true,
    }),
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([hover]);

  const hasChildren = Boolean(item.children?.length);

  return (
    <Menu.Item ref={refs.setReference} {...getReferenceProps()}>
      <Menu.Button
        onClick={item.onClick}
        icon={item.leftIcon}
        endIcon={hasChildren ? <AngleRight16 /> : item.rightIcon}
        disabled={item.disabled}
        className={item.className}
      >
        {item.label}
      </Menu.Button>

      {hasChildren && isOpen && (
        <div ref={refs.setFloating} style={floatingStyles} {...getFloatingProps()}>
          {/* condition is above (hasChildren) */}
          {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
          <ContextMenu items={item.children!} />
        </div>
      )}
    </Menu.Item>
  );
}
