import { TEvent } from './events';
import { TParentId } from './project';

export const TEntityType = {
  collection: 'collection',
  note: 'note',
};

export interface ICreateCollectionArguments {
  name?: string;
  parentId: TParentId;
}

export interface ICreateNoteArguments {
  name?: string;
  parentId: string;
  initialText?: string;
}

export interface ICreateProjectArguments {
  name?: string;
  slug?: string;
}

export interface ISyncStateParams {
  id: string;
  list: TEvent[];
  isSyncEnabled?: boolean;
  lastUpdateId?: string | null;
}
