import { classes } from 'classifizer';
import { forwardRef, InputHTMLAttributes } from 'react';

import { Info12 } from '../Icons';

import styles from './Input.module.css';

export type TInputVariant = 'standard' | 'shapeless';

interface IInputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  icon?: JSX.Element;
  endIcon?: JSX.Element;
  captionText?: string;
  captionIcon?: JSX.Element;
  error?: boolean;
  variant?: TInputVariant;
  wrapperClassName?: string;
}

export const Input = forwardRef<HTMLInputElement, IInputProps>(
  (
    {
      type = 'text',
      placeholder,
      label,
      icon,
      endIcon,
      captionText,
      captionIcon,
      disabled,
      error,
      variant = 'standard',
      wrapperClassName,
      ...restProps
    },
    ref,
  ) => (
    <div
      className={classes(
        styles['component-wrapper'],
        disabled && styles['disabled'],
        error && styles['error'],
        variant === 'standard' && styles['standard'],
        variant === 'shapeless' && styles['shapeless'],
        wrapperClassName,
      )}
    >
      <label>
        {label && <span className={styles['label']}>{label}</span>}

        <div className={styles['input-wrapper']}>
          {icon && <div className={styles['left-icon']}>{icon}</div>}

          <input
            ref={ref}
            type={type}
            placeholder={placeholder}
            className={styles['input']}
            disabled={disabled}
            {...restProps}
          />

          {endIcon && <div className={styles['end-icon']}>{endIcon}</div>}
        </div>
      </label>

      {captionText && (
        <div className={styles['caption']}>
          <span className={styles['caption__icon']}>{captionIcon || <Info12 />}</span>
          <span className={styles['caption__text']}>{captionText}</span>
        </div>
      )}
    </div>
  ),
);

Input.displayName = 'Input';
