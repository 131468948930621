/* eslint-disable max-len */
import * as React from 'react';

import { createIcon, IIconProps, ISVGProps } from '../../internal';

function IconComponent(props: ISVGProps) {
  return (
    <svg
      {...props}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_767_14488)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.4114 9.41076C9.73683 9.08533 10.2645 9.08533 10.5899 9.41076L13.9232 12.7441C14.2487 13.0695 14.2487 13.5972 13.9232 13.9226C13.5978 14.248 13.0702 14.248 12.7447 13.9226L10.0007 11.1785L7.25657 13.9226C6.93114 14.248 6.4035 14.248 6.07806 13.9226C5.75263 13.5972 5.75263 13.0695 6.07806 12.7441L9.4114 9.41076Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.99935 9.16669C10.4596 9.16669 10.8327 9.53978 10.8327 10V17.5C10.8327 17.9603 10.4596 18.3334 9.99935 18.3334C9.53911 18.3334 9.16602 17.9603 9.16602 17.5V10C9.16602 9.53978 9.53911 9.16669 9.99935 9.16669Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.21995 1.67379C8.35286 1.63205 9.48048 1.84768 10.5181 2.30447C11.5556 2.76126 12.4762 3.44732 13.2104 4.31108C13.8033 5.00852 14.2623 5.80715 14.5664 6.66665H15.0005C16.1114 6.66739 17.1909 7.03804 18.0678 7.7201C18.9446 8.40217 19.5693 9.3568 19.8433 10.4333C20.1173 11.5099 20.025 12.647 19.581 13.6653C19.1369 14.6835 18.3664 15.5249 17.3911 16.0567C16.987 16.277 16.4808 16.128 16.2605 15.7239C16.0402 15.3198 16.1892 14.8136 16.5933 14.5933C17.2435 14.2388 17.7572 13.6779 18.0532 12.9991C18.3493 12.3202 18.4108 11.5621 18.2281 10.8444C18.0455 10.1268 17.629 9.49033 17.0444 9.03562C16.4599 8.58091 15.7406 8.33381 15 8.33332H13.9505C13.5706 8.33332 13.2388 8.07638 13.1437 7.70858C12.923 6.8549 12.5117 6.06236 11.9406 5.39055C11.3695 4.71874 10.6535 4.18513 9.84651 3.82985C9.03951 3.47457 8.16247 3.30686 7.28132 3.33932C6.40017 3.37179 5.53784 3.60359 4.75917 4.01729C3.9805 4.43099 3.30575 5.01584 2.78564 5.72785C2.26554 6.43987 1.91361 7.26053 1.75632 8.12813C1.59903 8.99574 1.64047 9.88771 1.87753 10.737C2.11459 11.5863 2.5411 12.3708 3.12498 13.0315C3.42975 13.3764 3.39724 13.903 3.05236 14.2078C2.70749 14.5125 2.18086 14.48 1.87609 14.1351C1.12538 13.2856 0.577018 12.277 0.272229 11.1851C-0.0325595 10.0931 -0.0858428 8.94632 0.116386 7.83083C0.318614 6.71534 0.771091 5.6602 1.4398 4.74476C2.10851 3.82931 2.97605 3.07736 3.9772 2.54546C4.97834 2.01355 6.08705 1.71553 7.21995 1.67379Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.4114 9.41076C9.73683 9.08533 10.2645 9.08533 10.5899 9.41076L13.9232 12.7441C14.2487 13.0695 14.2487 13.5972 13.9232 13.9226C13.5978 14.248 13.0702 14.248 12.7447 13.9226L10.0007 11.1785L7.25657 13.9226C6.93114 14.248 6.4035 14.248 6.07806 13.9226C5.75263 13.5972 5.75263 13.0695 6.07806 12.7441L9.4114 9.41076Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_767_14488">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

IconComponent.displayName = 'UploadCloud';

export const UploadCloud20: React.FunctionComponent<IIconProps> = createIcon(IconComponent);
