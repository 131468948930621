import { Button, Checkbox, ModalsContext } from '@ws/shared/components';
import { Copy20, Eye20, EyeOff20, FileBlank24, Folder24, Trash20 } from '@ws/shared/icons';
import { TCollection, TNote } from '@ws/shared/types';
import { ChangeEvent, useCallback, useContext, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { EntityCard } from '../../../../components';
import { ResolutionContext } from '../../../../context';
import { EditabilityContext } from '../../../../context/EditabilityContext';
import { getEntityRef } from '../../../../utils/getEntityRef';
import { getProjectRoute } from '../../../../utils/routes';
import { showCopyNoteModal } from '../CopyNoteModal';

interface IItemCardProps {
  entity: TCollection | TNote;
  projectId: string;
  isSelected: boolean;
  onCheckboxClick(entity: TCollection | TNote): (event: ChangeEvent<HTMLInputElement>) => void;
}

export function ItemCard({ entity, projectId, isSelected, onCheckboxClick }: IItemCardProps) {
  const { setModal, closeAllModals } = useContext(ModalsContext);
  const routerParams = useParams();
  const { copyNote, deleteNote, deleteCollection, updateNodeMeta } = useContext(EditabilityContext);
  const { resolution } = useContext(ResolutionContext);
  const { id } = entity;
  const navigate = useNavigate();

  const getCopiedId = useCallback(
    (item: TNote) => (name: string) => {
      const resultRef = copyNote(item, name);

      return resultRef.id;
    },
    [copyNote],
  );

  const handleCopyNote = useCallback(
    (item: TNote) => {
      const copyAndGo = (name: string) => {
        const copiedNoteId = getCopiedId(item)(name);
        if (routerParams.projectId) {
          navigate(getProjectRoute(routerParams.projectId, copiedNoteId));
        }
      };
      const Modal = showCopyNoteModal(item.meta.name, getCopiedId(item), copyAndGo, resolution);

      setModal({
        closeAfterAction: true,
        customView: Modal,
      });
    },
    [getCopiedId, navigate, resolution, routerParams.projectId, setModal],
  );

  const handleRemoveNote = useCallback(() => {
    setModal({
      title: 'Удаление записки',
      subTitle: `Вы действительно хотите удалить "${entity.meta.name}"?`,
      closeAfterAction: true,
      actions: [
        {
          text: 'Удалить',
          onClick: () => deleteNote(entity.id),
          color: 'error',
        },
        { text: 'Оставить' },
      ],
    });
  }, [deleteNote, entity.id, entity.meta.name, setModal]);

  const handleRemoveCollections = useCallback(() => {
    const collection = entity as TCollection;

    setModal({
      title: 'Удаление коллекции',
      subTitle: `Вы действительно хотите удалить "${collection.meta.name}"?`,
      closeAfterAction: true,
      actions: [
        {
          text: 'Удалить',
          color: 'error',
          onClick: () => {
            if (collection.children.length > 0) {
              setModal({
                title: 'Есть вложенности',
                subTitle: `Что с ними делать?`,
                actions: [
                  {
                    text: 'Удалить',
                    color: 'error',
                    onClick: () => {
                      deleteCollection(collection.id, true);
                      closeAllModals();
                    },
                  },
                  {
                    text: 'Отвязать',
                    onClick: () => {
                      deleteCollection(collection.id, false);
                      closeAllModals();
                    },
                  },
                ],
              });
            } else {
              deleteCollection(collection.id, false);
            }
          },
        },
        { text: 'Оставить' },
      ],
    });
  }, [closeAllModals, deleteCollection, entity, setModal]);

  const handleHieEntity = useCallback(() => {
    updateNodeMeta(getEntityRef(entity), {
      isHidden: !entity.meta.isHidden,
    });
  }, [entity, updateNodeMeta]);

  const beforeActions = useMemo(
    () =>
      isSelected
        ? [
            <Checkbox
              key="entity-select"
              disabled={entity.type === 'collection'}
              onChange={onCheckboxClick(entity)}
            />,
          ]
        : [],
    [entity, isSelected, onCheckboxClick],
  );
  const actions = useMemo(
    () => ({
      note: [
        <Button
          key="copy"
          variant="shapeless"
          onClick={() => handleCopyNote(entity as TNote)}
          icon={<Copy20 />}
        />,
        <Button
          key="hide-note"
          variant="shapeless"
          onClick={handleHieEntity}
          icon={entity.meta.isHidden ? <EyeOff20 /> : <Eye20 />}
        />,
        <Button
          key="delete-note"
          variant="shapeless"
          icon={<Trash20 color="other_red" />}
          onClick={handleRemoveNote}
        />,
      ],
      collection: [
        <Button
          key="hide-collection"
          variant="shapeless"
          onClick={handleHieEntity}
          icon={entity.meta.isHidden ? <EyeOff20 /> : <Eye20 />}
        />,
        <Button
          key="delete-collection"
          variant="shapeless"
          icon={<Trash20 color="other_red" />}
          onClick={handleRemoveCollections}
        />,
      ],
    }),
    [entity, handleCopyNote, handleHieEntity, handleRemoveCollections, handleRemoveNote],
  );
  const afterActions = useMemo(
    () => (isSelected ? [] : actions[entity.type]),
    [isSelected, actions, entity.type],
  );

  const setGoToPath = useCallback(() => `/project/${projectId}/node/${id}`, [projectId, id]);
  const titleIcon = useMemo(() => {
    if (entity.type === 'note') {
      return <FileBlank24 />;
    }

    return <Folder24 />;
  }, [entity.type]);

  return (
    <EntityCard
      title={entity.meta.name}
      titleIcon={titleIcon}
      beforeActions={beforeActions}
      afterActions={afterActions}
      setGoTo={setGoToPath}
    />
  );
}
