import { ContextMenu, IContextMenuItem } from '@ws/shared/components';
import { ReactNode, useMemo } from 'react';

import { SECTION_ID } from '../../../constants';
import { ISelectionState, TOnChange } from '../types';

import { createContextItems } from './utils';

interface IContextMenuExtensionProps {
  children: ReactNode;
  selection: ISelectionState;
  value: string;
  onChange: TOnChange;
  onSplitNoteAbove(): void;
  onSplitNoteBelow(): void;
}

export function ContextMenuExtension({ children, ...rest }: IContextMenuExtensionProps) {
  const items: IContextMenuItem[] = useMemo(() => createContextItems(rest), [rest]);
  const parent = document.getElementById(SECTION_ID.WRITE_BOARD);

  return (
    <>
      {children}

      <ContextMenu autoPositioning items={items} parent={parent || undefined} />
    </>
  );
}
