/* eslint-disable max-len */
import * as React from 'react';

import { createIcon, IIconProps, ISVGProps } from '../../internal';

function IconComponent(props: ISVGProps) {
  return (
    <svg
      {...props}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.00065 9.33337C2.36884 9.33337 2.66732 9.63185 2.66732 10V12.6667C2.66732 12.8435 2.73756 13.0131 2.86258 13.1381C2.9876 13.2631 3.15717 13.3334 3.33398 13.3334H12.6673C12.8441 13.3334 13.0137 13.2631 13.1387 13.1381C13.2637 13.0131 13.334 12.8435 13.334 12.6667V10C13.334 9.63185 13.6325 9.33337 14.0007 9.33337C14.3688 9.33337 14.6673 9.63185 14.6673 10V12.6667C14.6673 13.1971 14.4566 13.7058 14.0815 14.0809C13.7065 14.456 13.1978 14.6667 12.6673 14.6667H3.33398C2.80355 14.6667 2.29484 14.456 1.91977 14.0809C1.5447 13.7058 1.33398 13.1971 1.33398 12.6667V10C1.33398 9.63185 1.63246 9.33337 2.00065 9.33337Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.5286 1.52864C7.78894 1.26829 8.21106 1.26829 8.4714 1.52864L11.8047 4.86197C12.0651 5.12232 12.0651 5.54443 11.8047 5.80478C11.5444 6.06513 11.1223 6.06513 10.8619 5.80478L8 2.94285L5.13807 5.80478C4.87772 6.06513 4.45561 6.06513 4.19526 5.80478C3.93491 5.54443 3.93491 5.12232 4.19526 4.86197L7.5286 1.52864Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.00065 1.33337C8.36884 1.33337 8.66732 1.63185 8.66732 2.00004V10C8.66732 10.3682 8.36884 10.6667 8.00065 10.6667C7.63246 10.6667 7.33398 10.3682 7.33398 10V2.00004C7.33398 1.63185 7.63246 1.33337 8.00065 1.33337Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}

IconComponent.displayName = 'Upload';

export const Upload16: React.FunctionComponent<IIconProps> = createIcon(IconComponent);
