import { classes } from 'classifizer';

import { IButtonProps, Button as UiKitButton } from '../../Button';

import styles from './Button.module.css';

export function Button({ children, className, ...rest }: IButtonProps) {
  return (
    <UiKitButton variant="shapeless" className={classes(styles['button'], className)} {...rest}>
      {children}
    </UiKitButton>
  );
}
