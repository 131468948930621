import { TNote } from '@ws/shared/types';
import { useContext, useEffect, useRef, useState } from 'react';

import { EditabilityContext } from '../../context/EditabilityContext';

import { ContextMenuExtension } from './ContextMenuExtension';
import { MarkdownEditor, LabelWrapper } from './MarkdownEditor';
import { NoteSplitterExtension } from './NoteSplitterExtension';
import { SaveManagerExtension } from './SaveManagerExtension';
import { SelectionManagerExtension } from './SelectionManagerExtension';

export interface IWriteBoardEditorProps {
  note: TNote;
}

export function WriteBoardEditor({ note }: IWriteBoardEditorProps) {
  const noteRef = useRef<TNote>(note);
  const { updateNoteContent } = useContext(EditabilityContext);
  const [content, setContent] = useState(note.content);

  useEffect(() => {
    if (note.id !== noteRef.current.id) {
      noteRef.current = note;

      setContent(note.content);
    }
  }, [note]);

  function handleSave() {
    if (note.content !== content) {
      updateNoteContent(noteRef.current.id, content);
    }
  }

  return (
    <SaveManagerExtension value={content} onChange={setContent} onSave={handleSave}>
      {(saverParams) => (
        <SelectionManagerExtension {...saverParams}>
          {(selectionParams) => (
            <NoteSplitterExtension
              note={note}
              onChange={saverParams.onChange}
              selection={selectionParams.selection}
              value={saverParams.value}
            >
              {(noteSplitterParams) => (
                <ContextMenuExtension
                  {...noteSplitterParams}
                  value={saverParams.value}
                  selection={selectionParams.selection}
                  onChange={saverParams.onChange}
                >
                  <LabelWrapper>
                    <MarkdownEditor
                      value={saverParams.value}
                      onChange={saverParams.onChange}
                      onBlur={saverParams.onBlur}
                      onSelect={selectionParams.onSelect}
                    />
                  </LabelWrapper>
                </ContextMenuExtension>
              )}
            </NoteSplitterExtension>
          )}
        </SelectionManagerExtension>
      )}
    </SaveManagerExtension>
  );
}
