import { createLogger } from 'redux-logger';

import { getConfig } from '../../config/config';

export function getLoggerMiddleware() {
  if (getConfig().reduxLoggerEnabled) {
    return [createLogger({ collapsed: true })];
  }

  return [];
}

export const loggerMiddleware = getLoggerMiddleware();
