/* eslint-disable max-len */
import * as React from 'react';

import { createIcon, IIconProps, ISVGProps } from '../../internal';

function IconComponent(props: ISVGProps) {
  return (
    <svg
      {...props}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.0008 3.79999C12.6635 3.79999 13.2008 4.33725 13.2008 4.99999V19C13.2008 19.6627 12.6635 20.2 12.0008 20.2C11.338 20.2 10.8008 19.6627 10.8008 19V4.99999C10.8008 4.33725 11.338 3.79999 12.0008 3.79999Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.29289 11.2929C4.68342 10.9024 5.31658 10.9024 5.70711 11.2929L12 17.5858L18.2929 11.2929C18.6834 10.9024 19.3166 10.9024 19.7071 11.2929C20.0976 11.6834 20.0976 12.3166 19.7071 12.7071L12.7071 19.7071C12.3166 20.0976 11.6834 20.0976 11.2929 19.7071L4.29289 12.7071C3.90237 12.3166 3.90237 11.6834 4.29289 11.2929Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}

IconComponent.displayName = 'ArrowDown';

export const ArrowDown24: React.FunctionComponent<IIconProps> = createIcon(IconComponent);
