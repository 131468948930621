import { classes } from 'classifizer';
import { SelectValue as AriaSelectValue, SelectValueProps } from 'react-aria-components';

import { IListOption } from '@ws/shared/types';

import styles from './SelectValue.module.css';

export function SelectValue({ className, ...props }: SelectValueProps<IListOption>) {
  return <AriaSelectValue className={classes(styles['select-value'], className)} {...props} />;
}
