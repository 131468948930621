/* eslint-disable max-len */
import * as React from 'react';

import { createIcon, IIconProps, ISVGProps } from '../../internal';

function IconComponent(props: ISVGProps) {
  return (
    <svg
      {...props}
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M4.5 5H5C5.13261 5 5.25979 4.94732 5.35355 4.85355C5.44732 4.75979 5.5 4.63261 5.5 4.5C5.5 4.36739 5.44732 4.24021 5.35355 4.14645C5.25979 4.05268 5.13261 4 5 4H4.5C4.36739 4 4.24021 4.05268 4.14645 4.14645C4.05268 4.24021 4 4.36739 4 4.5C4 4.63261 4.05268 4.75979 4.14645 4.85355C4.24021 4.94732 4.36739 5 4.5 5ZM4.5 6C4.36739 6 4.24021 6.05268 4.14645 6.14645C4.05268 6.24021 4 6.36739 4 6.5C4 6.63261 4.05268 6.75979 4.14645 6.85355C4.24021 6.94732 4.36739 7 4.5 7H7.5C7.63261 7 7.75979 6.94732 7.85355 6.85355C7.94732 6.75979 8 6.63261 8 6.5C8 6.36739 7.94732 6.24021 7.85355 6.14645C7.75979 6.05268 7.63261 6 7.5 6H4.5ZM10 4.47C9.99479 4.42407 9.98474 4.37882 9.97 4.335V4.29C9.94596 4.23859 9.91389 4.19133 9.875 4.15L6.875 1.15C6.83367 1.11111 6.78641 1.07904 6.735 1.055C6.72008 1.05288 6.70493 1.05288 6.69 1.055C6.63921 1.02587 6.58311 1.00717 6.525 1H3.5C3.10218 1 2.72064 1.15804 2.43934 1.43934C2.15804 1.72064 2 2.10218 2 2.5V9.5C2 9.89782 2.15804 10.2794 2.43934 10.5607C2.72064 10.842 3.10218 11 3.5 11H8.5C8.89782 11 9.27936 10.842 9.56066 10.5607C9.84196 10.2794 10 9.89782 10 9.5V4.5C10 4.5 10 4.5 10 4.47ZM7 2.705L8.295 4H7.5C7.36739 4 7.24021 3.94732 7.14645 3.85355C7.05268 3.75979 7 3.63261 7 3.5V2.705ZM9 9.5C9 9.63261 8.94732 9.75979 8.85355 9.85355C8.75979 9.94732 8.63261 10 8.5 10H3.5C3.36739 10 3.24021 9.94732 3.14645 9.85355C3.05268 9.75979 3 9.63261 3 9.5V2.5C3 2.36739 3.05268 2.24021 3.14645 2.14645C3.24021 2.05268 3.36739 2 3.5 2H6V3.5C6 3.89782 6.15804 4.27936 6.43934 4.56066C6.72064 4.84196 7.10218 5 7.5 5H9V9.5ZM7.5 8H4.5C4.36739 8 4.24021 8.05268 4.14645 8.14645C4.05268 8.24021 4 8.36739 4 8.5C4 8.63261 4.05268 8.75979 4.14645 8.85355C4.24021 8.94732 4.36739 9 4.5 9H7.5C7.63261 9 7.75979 8.94732 7.85355 8.85355C7.94732 8.75979 8 8.63261 8 8.5C8 8.36739 7.94732 8.24021 7.85355 8.14645C7.75979 8.05268 7.63261 8 7.5 8Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}

IconComponent.displayName = 'FileAlt';

export const FileAlt12: React.FunctionComponent<IIconProps> = createIcon(IconComponent);
