import { classes } from 'classifizer';
import { ReactNode, MouseEvent, ButtonHTMLAttributes, CSSProperties } from 'react';

import styles from './Button.module.css';
import { TButtonColor, TButtonSize, TButtonVariant } from './types';

export interface IButtonProps {
  children?: ReactNode;
  onClick?(event: MouseEvent): void;
  color?: TButtonColor;
  variant?: TButtonVariant;
  disabled?: boolean;
  className?: string;
  type?: ButtonHTMLAttributes<HTMLButtonElement>['type'];
  href?: string;
  target?: string;
  icon?: ReactNode;
  endIcon?: ReactNode;
  size?: TButtonSize;
  style?: CSSProperties;
}

export const Button = ({
  children,
  type = 'button',
  color = 'primary',
  variant = 'standard',
  icon,
  endIcon,
  className,
  size = 'l',
  href,
  ...restProps
}: IButtonProps) => {
  const Tag: keyof JSX.IntrinsicElements = href ? 'a' : 'button';

  return (
    <Tag
      href={href}
      type={type}
      className={classes(
        styles['button-base'],
        styles[`button-${variant}`],
        styles[`button-size_${size}`],
        variant !== 'shapeless' && styles[`button_${color}`],
        className,
      )}
      {...restProps}
    >
      {icon && <div className={styles['left-icon']}>{icon}</div>}
      {children && <span>{children}</span>}
      {endIcon && <div className={styles['end-icon']}>{endIcon}</div>}
    </Tag>
  );
};

Button.displayName = 'Button';
