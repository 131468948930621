import { buildQueryString } from './buildQueryString';

export function buildQueryFromStringAndParams({
  queryString,
  queryParams,
}: {
  queryString?: string;
  queryParams?: { [key: string]: any };
}) {
  const fullQueryParts = [];

  if (queryString || queryParams) {
    fullQueryParts.push('?');
  }

  if (queryString) {
    fullQueryParts.push(queryString);
  }

  if (queryString && queryParams) {
    fullQueryParts.push('&');
  }

  if (queryParams) {
    fullQueryParts.push(buildQueryString(queryParams).join('&'));
  }

  const fullQuery = fullQueryParts.join('');

  return fullQuery;
}
