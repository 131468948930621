import { InputHTMLAttributes, useRef } from 'react';

import { Button, IButtonProps } from '../Button';

import styles from './FileUpload.module.css';

interface IFileUploadProps extends Omit<IButtonProps, 'type' | 'onChange'> {
  onChange: InputHTMLAttributes<HTMLInputElement>['onChange'];
}

export function FileUpload({ onChange, children, ...rest }: IFileUploadProps) {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleButtonClick = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  return (
    <div>
      <input className={styles['input']} ref={inputRef} type="file" onChange={onChange} />

      <Button {...rest} onClick={handleButtonClick}>
        {children}
      </Button>
    </div>
  );
}
