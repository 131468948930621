/* eslint-disable max-len */
import * as React from 'react';

import { createIcon, IIconProps, ISVGProps } from '../../internal';

function IconComponent(props: ISVGProps) {
  return (
    <svg
      {...props}
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.00039 1.74573C6.33176 1.74573 6.60039 2.01436 6.60039 2.34573V9.34573C6.60039 9.6771 6.33176 9.94573 6.00039 9.94573C5.66902 9.94573 5.40039 9.6771 5.40039 9.34573V2.34573C5.40039 2.01436 5.66902 1.74573 6.00039 1.74573Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.14645 5.64645C2.34171 5.45118 2.65829 5.45118 2.85355 5.64645L6 8.79289L9.14645 5.64645C9.34171 5.45118 9.65829 5.45118 9.85355 5.64645C10.0488 5.84171 10.0488 6.15829 9.85355 6.35355L6.35355 9.85355C6.15829 10.0488 5.84171 10.0488 5.64645 9.85355L2.14645 6.35355C1.95118 6.15829 1.95118 5.84171 2.14645 5.64645Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}

IconComponent.displayName = 'ArrowDown';

export const ArrowDown12: React.FunctionComponent<IIconProps> = createIcon(IconComponent);
