import { TResponse } from '../../types/async';

import { IBuilderParams, TUrlBuilder } from './buildMcsUrl';
import { buildQueryFromStringAndParams } from './buildQueryFromStringAndParams';
import { getRequestHeaders } from './getRequestHeaders';
import { STATUS_UNAUTHORIZED } from './httpStatuses';

const METHOD_GET = 'GET';
const METHOD_POST = 'POST';

export interface IFetcherParams {
  url: string;
  method?: string;
  headers?: Headers;
  data?: any;
  queryParams?: Record<string, any>;
  queryString?: string;
  isExternal?: boolean;
  isLoggerOn?: boolean;
}

type Handler = (response: Response) => void;

export class Fetcher {
  private apiUri: string;
  private handleUnAuth?: Handler;
  private buildMcsURL?: TUrlBuilder;

  constructor(apiUri = '', handleUnAuth?: Handler, builder?: TUrlBuilder) {
    this.apiUri = apiUri;
    this.handleUnAuth = handleUnAuth;
    this.buildMcsURL = builder;
  }

  public buildURL(params: IBuilderParams) {
    if (typeof this.buildMcsURL === 'function') {
      return this.buildMcsURL(params);
    }

    throw new Error('UrlBuilder not provided!');
  }

  public fetch<T>(params: IFetcherParams) {
    const {
      url,
      method,
      headers: additionalHeaders = {},
      queryParams,
      queryString,
      data,
      isExternal,
      isLoggerOn,
    } = params;

    const headers: { [key: string]: string } = {
      ...getRequestHeaders(),
      ...additionalHeaders,
    };

    let baseUrl: string;

    if (isExternal) {
      baseUrl = url;
    } else {
      if (!this.apiUri) {
        throw new Error('No apiUri provided in Fetcher!');
      }

      const fullQuery = buildQueryFromStringAndParams({ queryString, queryParams });
      baseUrl = `${this.apiUri}/${url}${fullQuery}`;
    }

    const fetchOptions: RequestInit = {
      headers,
      body: data ? JSON.stringify(data) : undefined,
      method: method || (data ? method || METHOD_POST : METHOD_GET),
      credentials: 'include',
    };

    return fetch(baseUrl, fetchOptions)
      .then(async (response: Response) => {
        if (response.status === STATUS_UNAUTHORIZED) {
          if (typeof this.handleUnAuth === 'function') {
            this.handleUnAuth(response);
          }
        }

        if (!response.ok) {
          throw Error(response.statusText);
        }

        if (response.status === 204) {
          return {
            status: response.status,
            response: await response.json(),
          };
        }

        return {
          status: response.status,
          response: await response.json(),
        };
      })
      .then(({ response: parsedResponse, status }) => {
        if (isLoggerOn) {
          console.log('parsedResponse', parsedResponse);
          console.log('parsedResponse.data', parsedResponse.data);
        }

        const preparedResponse = {
          status,
          data: typeof parsedResponse.data !== 'undefined' ? parsedResponse.data : parsedResponse,
          error: null,
        } as TResponse<T>;

        if (isLoggerOn) {
          console.log('preparedResponse', preparedResponse);
        }

        return preparedResponse;
      })
      .catch((error) => {
        console.error(`
          => Request to ${baseUrl} falied

          Error: ${error}
          _______________

        `);

        return {
          data: null,
          error,
        } as TResponse<T>;
      });
  }
}
