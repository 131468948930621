/* eslint-disable max-len */
import * as React from 'react';

import { createIcon, IIconProps, ISVGProps } from '../../internal';

function IconComponent(props: ISVGProps) {
  return (
    <svg
      {...props}
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4 10C4 9.72386 4.22386 9.5 4.5 9.5H7.5C7.77614 9.5 8 9.72386 8 10C8 10.2761 7.77614 10.5 7.5 10.5H4.5C4.22386 10.5 4 10.2761 4 10Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.5 2C1.5 1.72386 1.72386 1.5 2 1.5H10C10.2761 1.5 10.5 1.72386 10.5 2V3.5C10.5 3.77614 10.2761 4 10 4C9.72386 4 9.5 3.77614 9.5 3.5V2.5H2.5V3.5C2.5 3.77614 2.27614 4 2 4C1.72386 4 1.5 3.77614 1.5 3.5V2Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6 1.5C6.27614 1.5 6.5 1.72386 6.5 2V10C6.5 10.2761 6.27614 10.5 6 10.5C5.72386 10.5 5.5 10.2761 5.5 10V2C5.5 1.72386 5.72386 1.5 6 1.5Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}

IconComponent.displayName = 'Type';

export const Type12: React.FunctionComponent<IIconProps> = createIcon(IconComponent);
