import { PropsWithChildren, CSSProperties } from 'react';
import { Rnd } from 'react-rnd';

import { TWindowCoords, TWindowMoveDelta, TWindowSize } from './types';

interface DraggableWrapperProps extends PropsWithChildren {
  className?: string;
  handlerClassName: string;
  size: TWindowSize;
  coords: TWindowCoords;
  onDragStop(coords: TWindowCoords): void;
  onResizeStop(delta: TWindowMoveDelta): void;
  style?: CSSProperties;
}

export function DraggableWrapper({
  className,
  handlerClassName,
  size,
  coords,
  onDragStop,
  onResizeStop,
  children,
  style,
}: DraggableWrapperProps) {
  return (
    <Rnd
      style={style}
      className={className}
      dragHandleClassName={handlerClassName}
      size={{ ...size }}
      position={{ ...coords }}
      minWidth="250px"
      minHeight="74px"
      onDragStop={(_event, _dragData) => {
        onDragStop({ x: _dragData.x, y: _dragData.y });
      }}
      onResizeStop={(_event, _direction, _ref, _delta) => {
        onResizeStop(_delta);
      }}
    >
      {children}
    </Rnd>
  );
}
