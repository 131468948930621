/* eslint-disable max-len */
import * as React from 'react';

import { createIcon, IIconProps, ISVGProps } from '../../internal';

function IconComponent(props: ISVGProps) {
  return (
    <svg
      {...props}
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M1.5 7.5H6.5C6.63261 7.5 6.75979 7.44732 6.85355 7.35355C6.94732 7.25979 7 7.13261 7 7C7 6.86739 6.94732 6.74021 6.85355 6.64645C6.75979 6.55268 6.63261 6.5 6.5 6.5H1.5C1.36739 6.5 1.24021 6.55268 1.14645 6.64645C1.05268 6.74021 1 6.86739 1 7C1 7.13261 1.05268 7.25979 1.14645 7.35355C1.24021 7.44732 1.36739 7.5 1.5 7.5ZM1.5 5.5H6.5C6.63261 5.5 6.75979 5.44732 6.85355 5.35355C6.94732 5.25979 7 5.13261 7 5C7 4.86739 6.94732 4.74021 6.85355 4.64645C6.75979 4.55268 6.63261 4.5 6.5 4.5H1.5C1.36739 4.5 1.24021 4.55268 1.14645 4.64645C1.05268 4.74021 1 4.86739 1 5C1 5.13261 1.05268 5.25979 1.14645 5.35355C1.24021 5.44732 1.36739 5.5 1.5 5.5ZM1.5 3.5H10.5C10.6326 3.5 10.7598 3.44732 10.8536 3.35355C10.9473 3.25979 11 3.13261 11 3C11 2.86739 10.9473 2.74021 10.8536 2.64645C10.7598 2.55268 10.6326 2.5 10.5 2.5H1.5C1.36739 2.5 1.24021 2.55268 1.14645 2.64645C1.05268 2.74021 1 2.86739 1 3C1 3.13261 1.05268 3.25979 1.14645 3.35355C1.24021 3.44732 1.36739 3.5 1.5 3.5ZM10.5 8.5H1.5C1.36739 8.5 1.24021 8.55268 1.14645 8.64645C1.05268 8.74021 1 8.86739 1 9C1 9.13261 1.05268 9.25979 1.14645 9.35355C1.24021 9.44732 1.36739 9.5 1.5 9.5H10.5C10.6326 9.5 10.7598 9.44732 10.8536 9.35355C10.9473 9.25979 11 9.13261 11 9C11 8.86739 10.9473 8.74021 10.8536 8.64645C10.7598 8.55268 10.6326 8.5 10.5 8.5Z"
          fill="currentColor"
        />
        <path
          d="M10.1524 7.20476C10.2018 7.25251 10.2605 7.28958 10.3248 7.31364C10.3892 7.33771 10.4578 7.34826 10.5264 7.34464C10.595 7.34102 10.6621 7.3233 10.7235 7.2926C10.785 7.26189 10.8394 7.21885 10.8835 7.16617C10.9276 7.11348 10.9603 7.05228 10.9797 6.98638C10.9991 6.92048 11.0047 6.85129 10.9961 6.78313C10.9876 6.71497 10.9651 6.64931 10.93 6.59023C10.895 6.53115 10.8481 6.47993 10.7924 6.43976L10.2524 5.98976L10.7924 5.53976C10.843 5.49778 10.8849 5.44623 10.9156 5.38806C10.9462 5.32989 10.9651 5.26623 10.9712 5.20074C10.9772 5.13525 10.9703 5.06922 10.9508 5.00642C10.9312 4.94361 10.8995 4.88528 10.8574 4.83476C10.8104 4.77832 10.7515 4.73293 10.685 4.70182C10.6184 4.6707 10.5459 4.65464 10.4724 4.65476C10.355 4.65593 10.2417 4.69841 10.1524 4.77476L9.15241 5.60476C9.09579 5.65167 9.05022 5.7105 9.01894 5.77705C8.98765 5.8436 8.97143 5.91622 8.97143 5.98976C8.97143 6.06329 8.98765 6.13592 9.01894 6.20246C9.05022 6.26901 9.09579 6.32784 9.15241 6.37476L10.1524 7.20476Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}

IconComponent.displayName = 'AlignLess';

export const AlignLess12: React.FunctionComponent<IIconProps> = createIcon(IconComponent);
