import { ReactNode, useCallback } from 'react';
import { useList } from 'react-use';

import { indexOfObject } from '../../utils/indexOfObject';

import { NotificationCtxProvider } from './context';

export interface INotificationManagerProps {
  className?: string;
  children: ReactNode;
}

type TMessage = {
  message: string;
  actions: React.ReactNode[];
  key: number;
  autohide: number | null;
  background: string;
  position?: string;
};

export function NotificationManager({ children, className }: INotificationManagerProps) {
  const [listOfSnacks, { push, removeAt }] = useList<TMessage>();

  const handleNewMessage = useCallback(
    (message: string, options: Partial<TMessage> & { color?: string } = {}) => {
      push({
        message,
        actions: options.actions || [],
        key: new Date().getTime(),
        autohide: options.autohide || null,
        background: options.color || '#2d3444',
        position: options.position,
      });
    },
    [push],
  );

  const handleSnackClose = useCallback(
    (key: number) => (_: any, type?: string) => {
      if (type === 'clickaway') {
        return;
      }

      const idx = indexOfObject(listOfSnacks, (snack) => snack.key === key);
      removeAt(idx);
    },
    [removeAt, listOfSnacks],
  );

  return (
    <NotificationCtxProvider value={{ push: handleNewMessage }}>
      {children}

      {listOfSnacks.map((snack, idx) => (
        <div key={idx} className={className} onClick={handleSnackClose(idx)}>
          {snack?.message}
        </div>
      ))}
    </NotificationCtxProvider>
  );
}
