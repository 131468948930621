import { CompilerContent } from '@ws/shared/components';
import { TFormatted } from '@ws/shared/types';

import { useNote } from '../../../hooks';
import { InCompilerEditor } from '../../Editor';

import { CompilerTemplate } from './CompilerTemplate';
import { CompleteButton } from './CompleteButton';
import { EditButton } from './EditButton';

export interface ICompiledContainerProps {
  entity: TFormatted;
  showEditButton: boolean;
  onEntityClick(): void;
  isEditing: boolean;
  onEdit(): void;
}

export function CompilerContainer({
  entity,
  showEditButton,
  onEntityClick,
  isEditing,
  onEdit,
}: ICompiledContainerProps) {
  const note = useNote(entity.id);

  return (
    <>
      <CompilerTemplate
        isEditing={isEditing}
        content={<CompilerContent entity={entity} onClick={onEntityClick} />}
        editor={note && isEditing && <InCompilerEditor note={note} />}
        editButton={showEditButton && !isEditing && <EditButton onClick={onEdit} />}
        completeButton={note && isEditing && <CompleteButton isDesktopOnly onClick={onEdit} />}
      />
      {note && isEditing && <CompleteButton isNotDesktop onClick={onEdit} />}
    </>
  );
}
