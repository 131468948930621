export interface IGeneralState {
  isCompiled: boolean;
  isSecondaryCompiled: boolean;
  isSidebarOpen: boolean;
  isPreviewsOpen: boolean;
}

export const GENERAL_SET = 'GENERAL_SET';
export const GENERAL_SET_IS_COMPILED = 'GENERAL_SET_IS_COMPILED';
export const GENERAL_SET_IS_SECONDARY_COMPILED = 'GENERAL_SET_IS_SECONDARY_COMPILED';
export const GENERAL_SET_IS_SIDEBAR_OPEN = 'GENERAL_SET_IS_SIDEBAR_OPEN';
export const GENERAL_SET_IS_PREVIEWS_OPEN = 'GENERAL_SET_IS_PREVIEWS_OPEN';

export interface IGeneralSetAction {
  isCompiled?: boolean;
  isSecondaryCompiled?: boolean;
  isSidebarOpen?: boolean;
  isPreviewsOpen?: boolean;
}

export interface IGeneralSetIsCompiledAction {
  isCompiled: boolean;
}

export interface IGeneralSetIsSecondaryCompiledAction {
  isSecondaryCompiled: boolean;
}

export interface IGeneralSetIsSidebarOpenAction {
  isSidebarOpen: boolean;
}

export interface IGeneralSetIsPreviewsOpenAction {
  isPreviewsOpen: boolean;
}
