import { useDispatch, useSelector } from 'react-redux';

import { TAppState } from '../../redux';
import { removeContentWindow, setContentWindowSettings } from '../../redux/contentWindows';

import { ContentWindowContainer } from './ContentWindowContainer';
import { TWindowSettings } from './types';

export function ContentWindowsKeeper() {
  const dispatch = useDispatch();
  const { list, settings } = useSelector((state: TAppState) => state.contentWindows);

  function handleCollapseClick(nodeId: string) {
    return (updatedSettings: TWindowSettings) =>
      dispatch(setContentWindowSettings({ nodeId, isOpen: false, ...updatedSettings }));
  }

  function handleCloseClick(nodeId: string) {
    return () => dispatch(removeContentWindow({ nodeIdToRemove: nodeId }));
  }

  return (
    <>
      {list.map((nodeId) => {
        const certainSettings = settings[nodeId];
        if (!certainSettings.isOpen) {
          return null;
        }

        return (
          <ContentWindowContainer
            size={certainSettings.size}
            coords={certainSettings.coords}
            scrollPercent={certainSettings.scrollPercent}
            key={nodeId}
            nodeId={nodeId}
            onCollapseClick={handleCollapseClick(nodeId)}
            onCloseClick={handleCloseClick(nodeId)}
          />
        );
      })}
    </>
  );
}
