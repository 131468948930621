import { createReducer } from '@reduxjs/toolkit';

import { IProjectsState } from './projects.types';

const INITIAL_STATE: IProjectsState = {
  projects: [],
  errorMessage: null,
  isLoading: true,
};

export const projectsReducer = createReducer(INITIAL_STATE, (builder) => {
  builder;
});
