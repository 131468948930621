import { CollectionCompilerTemplate } from '@ws/shared/components';
import { TCollection } from '@ws/shared/types';
import { compileCollection } from '@ws/shared/utils';
import { useContext } from 'react';

import { EditabilityContext } from '../../../context/EditabilityContext';
import { CompilerContainer } from '../CompilerContainer';
import { useCompilerHandlers } from '../hooks';

export interface ICompilerProps {
  collection: TCollection;
}

export function CollectionCompiler({ collection }: ICompilerProps) {
  const { getNodesByRefs } = useContext(EditabilityContext);
  const compiledText = compileCollection({ collection, getNodesByRefs });

  const { selectedEntity, editEntityId, handleEditClick, handleEntityClick } =
    useCompilerHandlers();

  return (
    <CollectionCompilerTemplate
      compiledNodes={compiledText.map((entity) => (
        <CompilerContainer
          key={entity.id}
          entity={entity}
          showEditButton={selectedEntity === entity.id}
          onEntityClick={handleEntityClick(entity.id)}
          onEdit={handleEditClick(entity.id)}
          isEditing={editEntityId === entity.id}
        />
      ))}
    />
  );
}
