import { Workbox } from 'workbox-window';

import { getConfig } from './config/config';

export function SWRegister() {
  if (!getConfig().serviceWorkerEnabled) {
    return;
  }

  if ('serviceWorker' in navigator) {
    const wb = new Workbox('/sw.js');

    wb.addEventListener('installed', (event) => {
      if (event.isUpdate) {
        // eslint-disable-next-line no-alert
        if (confirm(`New app update is available!. Click OK to refresh`)) {
          window.location.reload();
        }
      }
    });
    wb.register();
  }
}
