import { useQuery } from '@tanstack/react-query';

import { getResourceManager } from '../../../resources';

export function useFetchPreviewsByEntityId(entityId: string) {
  return useQuery({
    queryKey: ['getPreviewsByEntityId', entityId],
    queryFn: async ({ queryKey }) => {
      const manager = getResourceManager();
      const response = await manager.PreviewService.getPreviewsByEntityId(queryKey[1]);

      if (!response.isOk || !response.previews) {
        return [];
      }

      return response.previews || [];
    },
  });
}
