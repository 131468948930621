import { TModalCustomView, IModalAction, Input } from '@ws/shared/components';
import { useEffect, useRef, useState } from 'react';

import { isMobile } from '../../../../modules/getResolution';
import { getNowAndAName } from '../../../../utils/getNowAndAName';

export function showMergeNotesModal(
  createFunc: (text: string) => void,
  createAndGoFunc: (text: string) => void,
  resolution: string,
): TModalCustomView {
  return function MergeNotesModal({
    closeAllModals,
    onAwayClose,
    actionHandlerCreator,
    components: {
      ModalWrapper,
      ModalContentWrapper,
      ModalCloseButton,
      ModalTitle,
      ModalSubTitle,
      ModalContent,
      ButtonsPanel,
    },
  }) {
    const inputRef = useRef<HTMLInputElement>(null);
    const { augmentedName } = getNowAndAName();
    const [text, setText] = useState(augmentedName);
    const mobile = isMobile(resolution);
    const baseButtons: IModalAction[] = [
      {
        text: 'Создать',
        color: 'secondary',
        onClick: actionHandlerCreator(() => createFunc(text)),
      },
      {
        text: 'Создать и перейти',
        onClick: actionHandlerCreator(() => createAndGoFunc(text)),
      },
    ];
    const isNotMobileButtons: IModalAction[] = mobile
      ? []
      : [{ text: 'Отмена', color: 'thirdary', onClick: closeAllModals }];

    useEffect(() => {
      inputRef.current?.select();
    }, []);

    return (
      <ModalWrapper onClick={onAwayClose}>
        <ModalContentWrapper>
          <ModalCloseButton onClose={closeAllModals} />
          <ModalTitle>Слияние заметок в одну</ModalTitle>
          <ModalSubTitle>Введите название новой заметки</ModalSubTitle>
          <ModalContent>
            <Input ref={inputRef} value={text} onChange={(event) => setText(event.target.value)} />
          </ModalContent>

          <ButtonsPanel actions={[...isNotMobileButtons, ...baseButtons]} />
        </ModalContentWrapper>
      </ModalWrapper>
    );
  };
}
