import { PlaygroundWrapper } from '../../PlaygroundWrapper';
import { HeaderContainer } from '../Header/HeaderContainer';

import { SplitBoardContainer } from './SplitBoardContainer';

export function SplitBoardLayout() {
  return (
    <PlaygroundWrapper>
      <HeaderContainer />

      <SplitBoardContainer />
    </PlaygroundWrapper>
  );
}
