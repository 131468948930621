import { MouseEvent, useCallback } from 'react';

import { Portal } from '../../Portal';
import * as ModalComponents from '../ModalComponents';
import {
  ButtonsPanel,
  ModalContent,
  ModalTitle,
  ModalSubTitle,
  ModalWrapper,
  ModalContentWrapper,
  ModalCloseButton,
} from '../ModalComponents';
import { IModal, TActionHandlerCreator, TCloseAllModals, TCloseModal } from '../types';

interface IModalProps {
  modal: IModal;
  closeAllModals: TCloseAllModals;
  closeModal: TCloseModal;
  isLast?: boolean;
}

export function Modal({ modal, closeModal, isLast = true, closeAllModals }: IModalProps) {
  const {
    title,
    subTitle,
    content,
    closeAfterAction = false,
    customView: CustomView,
    hasClose = true,
  } = modal;

  const createActionHandler = useCallback<TActionHandlerCreator>(
    (preparedHandler = () => {}) =>
      () => {
        if (closeAfterAction) {
          closeModal(null, preparedHandler);
        } else if (typeof preparedHandler === 'function') {
          preparedHandler();
        }
      },
    [closeModal, closeAfterAction],
  );

  const handleVeilClick = useCallback(
    (event: MouseEvent) => {
      if (event.currentTarget === event.target) {
        closeModal(-1);
      }
    },
    [closeModal],
  );

  return (
    <Portal wrapperId="modals-root">
      <ModalWrapper isLast={isLast} onClick={handleVeilClick}>
        {CustomView ? (
          <CustomView
            actionHandlerCreator={createActionHandler}
            closeModal={closeModal}
            closeAllModals={closeAllModals}
            onAwayClose={handleVeilClick}
            components={{ ...ModalComponents, ModalCloseButton }}
          />
        ) : (
          <ModalContentWrapper>
            {hasClose && <ModalCloseButton onClose={closeAllModals} />}
            {title && <ModalTitle>{title}</ModalTitle>}
            {subTitle && <ModalSubTitle>{subTitle}</ModalSubTitle>}
            {content && <ModalContent>{content}</ModalContent>}

            <ButtonsPanel actions={modal.actions} onClick={createActionHandler} />
          </ModalContentWrapper>
        )}
      </ModalWrapper>
    </Portal>
  );
}
