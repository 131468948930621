import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { IOnChangeOptions, ISaveManagerChildrenParams, TChildren } from '../types';

import { SAVE_AFTER_STOP_DELAY } from './constants';

interface ISaveManagerExtensionProps {
  value: string;
  onSave?(): void;
  onChange(content: string): void;
  children: TChildren<ISaveManagerChildrenParams>;
}

export function SaveManagerExtension({
  value,
  onChange,
  onSave,
  children,
}: ISaveManagerExtensionProps) {
  const timer = useRef<NodeJS.Timer | number>(0);

  const [wasChanged, setWasChanged] = useState(false);

  const clearOwnTimer = useCallback(() => {
    if (timer.current) {
      clearTimeout(timer.current as number);
      timer.current = 0;
    }
  }, []);

  const handleSaveNote = useCallback(() => {
    setWasChanged(false);

    if (typeof onSave === 'function') {
      onSave();
    }
  }, [onSave]);

  const handleSaveAfterStop = useCallback(() => {
    clearOwnTimer();

    timer.current = setTimeout(() => {
      handleSaveNote();
    }, SAVE_AFTER_STOP_DELAY);
  }, [clearOwnTimer, handleSaveNote]);

  const handleChange = useCallback(
    (newContent: string, options?: IOnChangeOptions) => {
      onChange(newContent);

      if (options?.saveImmediately) {
        if (typeof onSave === 'function') {
          onSave();
        }

        setWasChanged(false);
      } else {
        handleSaveAfterStop();

        setWasChanged(true);
      }
    },
    [handleSaveAfterStop, onChange, onSave],
  );

  const handleBlur = useCallback(() => {
    clearOwnTimer();

    handleSaveNote();
  }, [clearOwnTimer, handleSaveNote]);

  const handleBeforeUnload = useCallback(
    (event: BeforeUnloadEvent) => {
      // todo should we check this only if not saved?
      if (wasChanged) {
        // most modern browsers don't support custom message
        // some browsers support this step;
        event.preventDefault();

        // some browsers support this step;
        // eslint-disable-next-line no-param-reassign
        event.returnValue = 'Wait until saving..';
        handleSaveNote();
      }
    },
    [handleSaveNote, wasChanged],
  );

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, [handleBeforeUnload]);

  const params: ISaveManagerChildrenParams = useMemo(
    () => ({
      value,
      onChange: handleChange,
      onBlur: handleBlur,
    }),
    [value, handleChange, handleBlur],
  );

  return children(params);
}
