import { ReactNode } from 'react';

import { Logo } from '../Logo';

import styles from './SplashScreen.module.css';

interface Props {
  logo?: ReactNode;
}

export function SplashScreen({ logo }: Props) {
  return (
    <div className={styles['splash-screen']}>
      <div className={styles['splash-screen__loading']}>{logo || <Logo />}</div>
    </div>
  );
}
