import { Button, CompilerContent } from '@ws/shared/components';
import { Close16 } from '@ws/shared/icons';
import { TNote } from '@ws/shared/types';
import { compileNote } from '@ws/shared/utils';
import { classes } from 'classifizer';
import { useLayoutEffect, useMemo, useRef } from 'react';
import { useMount } from 'react-use';
import { debounce } from 'throttle-debounce';

import styles from './ContentWindow.module.css';

interface ContentWindowProps {
  note: TNote;
  scrollPercent: number;
  onScrollStop(scrollPercent: number): void;
  onCollapseClick(): void;
  onCloseClick?(): void;
}

export function ContentWindow({
  note,
  scrollPercent,
  onScrollStop,
  onCollapseClick,
  onCloseClick,
}: ContentWindowProps) {
  const contentRef = useRef<HTMLDivElement>(null);

  function handleCollapseClick() {
    onCollapseClick();
  }

  const handleScroll = debounce(300, (event: HTMLElementEventMap['scroll']) => {
    if (!event.target) {
      return;
    }

    const element = event.target as HTMLDivElement;

    const height = element.scrollHeight;
    const top = element.scrollTop;

    const percent = top / height;

    onScrollStop(percent);
  });

  useMount(() => {
    if (contentRef.current) {
      const scrollTop = contentRef.current.scrollHeight * scrollPercent;
      contentRef.current.scrollTop = scrollTop;
    }
  });

  useLayoutEffect(() => {
    const { current } = contentRef;
    current?.addEventListener('scroll', handleScroll);

    return () => current?.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  const compiledContent = useMemo(() => {
    return compileNote(note);
  }, [note]);

  return (
    <div className={styles['window']}>
      <div className={styles['window__header']}>
        <div className={styles['window__header-title']}>{compiledContent.originalName}</div>
        <Button
          className={classes(styles['window__button'], styles['window__collapse-button'])}
          variant="shapeless"
          onClick={handleCollapseClick}
          icon={<div className={styles['window__collapse-icon']} />}
        />
        <Button
          className={classes(styles['window__button'], styles['window__close-button'])}
          variant="shapeless"
          onClick={onCloseClick}
          icon={<Close16 />}
        />
      </div>

      <div ref={contentRef} className={styles['window__content']}>
        <CompilerContent entity={compiledContent} />
      </div>
    </div>
  );
}
