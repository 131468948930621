import { FloatingFocusManager, FloatingPortal, useMergeRefs } from '@floating-ui/react';
import { classes } from 'classifizer';
import { forwardRef, HTMLProps } from 'react';

import { usePopoverContext } from '../context';

import styles from './Content.module.css';

interface IContentProps extends HTMLProps<HTMLDivElement> {
  withoutPaddings?: boolean;
}

export const Content = forwardRef<HTMLDivElement, IContentProps>(function PopoverContent(
  { style, withoutPaddings, ...props },
  propRef,
) {
  const { context: floatingContext, ...context } = usePopoverContext();
  const ref = useMergeRefs([context.refs.setFloating, propRef]);

  if (!floatingContext.open) {
    return null;
  }

  return (
    <FloatingPortal>
      <FloatingFocusManager context={floatingContext} modal={context.modal}>
        <div
          ref={ref}
          className={classes(
            styles['popover-content'],
            withoutPaddings && styles['popover-content_without-paddings'],
          )}
          style={{ ...context.floatingStyles, ...style }}
          aria-labelledby={context.labelId}
          aria-describedby={context.descriptionId}
          {...context.getFloatingProps(props)}
        >
          {props.children}
        </div>
      </FloatingFocusManager>
    </FloatingPortal>
  );
});
