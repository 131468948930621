import { Fetcher } from '../../utils';

import {
  AUTH_LOGIN_ENDPOINT_CONF,
  AUTH_SIGN_UP_ENDPOINT_CONF,
  AUTH_LOGOUT_ENDPOINT_CONF,
} from './endpoints';
import { IAuthLoginUser, IAuthSignUpUser } from './types';

export class AuthRepository {
  private fetcher: Fetcher;

  constructor(fetcher: Fetcher) {
    this.fetcher = fetcher;
  }

  public signUp(user: IAuthSignUpUser) {
    return this.fetcher.fetch<{ id: string }>({
      url: this.fetcher.buildURL(AUTH_SIGN_UP_ENDPOINT_CONF),
      method: 'POST',
      data: { ...user },
    });
  }

  public login(user: IAuthLoginUser) {
    return this.fetcher.fetch<void>({
      url: this.fetcher.buildURL(AUTH_LOGIN_ENDPOINT_CONF),
      method: 'POST',
      data: { ...user },
    });
  }

  public logout() {
    return this.fetcher.fetch<{ id: string }>({
      url: this.fetcher.buildURL(AUTH_LOGOUT_ENDPOINT_CONF),
      method: 'GET',
    });
  }
}
