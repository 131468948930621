import { TNote } from '@ws/shared/types';
import { useContext, useMemo } from 'react';

import { EditabilityContext } from '../../context/EditabilityContext';

export function useNote(id: string): TNote | null {
  const { getNode } = useContext(EditabilityContext);
  const node = getNode(id);

  return useMemo<TNote | null>(() => (node?.type === 'note' ? node : null), [node]);
}
