import { getErrorMessage } from '@ws/shared/utils';

export const handleFileUpload =
  (callback: (parsedData: any) => void, fallback: (error: any) => void) =>
  (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = (event.target as HTMLInputElement).files?.[0];
    const reader = new FileReader();

    reader.onloadend = (innerEvent: ProgressEvent<FileReader>) => {
      if ((innerEvent.target as FileReader).readyState === FileReader.DONE) {
        const { result: plainText } = innerEvent.target as FileReader;

        if (!plainText) {
          throw Error('Something went wrong during parsing of a file');
        }

        try {
          const parsed = JSON.parse(plainText as string);
          callback(parsed);
        } catch (err) {
          console.error(err);

          if (err instanceof SyntaxError) {
            fallback('Bad file to parse. Choose another one.');
            return;
          }

          fallback(getErrorMessage(err));
        }
      }
    };

    if (!file) {
      throw Error('Something went wrong during uploading a file');
    }

    reader.readAsText(file, 'UTF-8');
  };
