import { classes } from 'classifizer';
import { useContext } from 'react';

import { Button } from '../../Button';
import { AlignLess24, AlignMore24 } from '../../Icons';
import { sidebarContext } from '../context';

import styles from './Toggler.module.css';

interface ITogglerProps {
  position?: 'static' | 'absolute';
}

export function Toggler({ position = 'static' }: ITogglerProps) {
  const { isOpen, onOpen, onClose } = useContext(sidebarContext);

  const handleToggleButton = () => {
    if (isOpen) {
      typeof onClose === 'function' && onClose();
    } else {
      typeof onOpen === 'function' && onOpen();
    }
  };

  return (
    <div
      className={classes(
        styles['toggler'],
        position === 'absolute' && styles['toggler_absolute'],
        isOpen && styles['toggler_opened'],
        !isOpen && styles['toggler_closed'],
      )}
    >
      <Button
        onClick={handleToggleButton}
        variant="shapeless"
        icon={isOpen ? <AlignLess24 /> : <AlignMore24 />}
      />
    </div>
  );
}
