import { classes } from 'classifizer';
import { ChangeEvent, FunctionComponent, ReactNode } from 'react';

import { TIconSize } from '@ws/shared/types';

import {
  CheckboxChecked12,
  CheckboxChecked16,
  CheckboxChecked20,
  CheckboxChecked24,
  CheckboxUnchecked12,
  CheckboxUnchecked16,
  CheckboxUnchecked20,
  CheckboxUnchecked24,
} from '../Icons';
import { IIconProps } from '../Icons/internal';

import styles from './Checkbox.module.css';

const checkboxCheckedMap: Record<TIconSize, FunctionComponent<IIconProps>> = {
  12: CheckboxChecked12,
  16: CheckboxChecked16,
  20: CheckboxChecked20,
  24: CheckboxChecked24,
};

const checkboxUncheckedMap: Record<TIconSize, FunctionComponent<IIconProps>> = {
  12: CheckboxUnchecked12,
  16: CheckboxUnchecked16,
  20: CheckboxUnchecked20,
  24: CheckboxUnchecked24,
};

interface ICheckboxProps {
  onChange?(event: ChangeEvent<HTMLInputElement>): void;
  disabled?: boolean;
  value?: boolean;
  children?: ReactNode;
  id?: string;
  size?: TIconSize;
}

export function Checkbox({ onChange, disabled, value, children, size = 20 }: ICheckboxProps) {
  const CheckBoxCheckedIcon = checkboxCheckedMap[size];
  const CheckBoxUncheckedIcon = checkboxUncheckedMap[size];

  return (
    <label className={styles['wrapper']}>
      <input
        className={styles['input']}
        type="checkbox"
        onChange={onChange}
        disabled={disabled}
        checked={value}
      />
      <i className={classes(styles['checkbox'], styles['checkbox_checked'])}>
        <CheckBoxCheckedIcon color="textator_primary" />
      </i>
      <i className={classes(styles['checkbox'], styles['checkbox_unchecked'])}>
        <CheckBoxUncheckedIcon color="other_grey" />
      </i>

      {children && <span className={styles['label']}>{children}</span>}
    </label>
  );
}
