import axios from 'axios';

import { getConfig } from '../config/config';

function generateParams(params = {}) {
  const defaultParams = { ...params };

  return Object.keys(defaultParams)
    .map((param) => `${[param]}=${defaultParams[param]}`)
    .join('&');
}

export const request = (conditions) => {
  const { url, method, headers, payload, data, params } = conditions;

  const paramString = generateParams(params);

  const getParams = paramString ? `?${paramString}` : '';

  const checkIfLocal = `${getConfig().apiUri}/${url}${getParams}`;

  const options = {
    data:
      method && method !== 'GET'
        ? data || {
            ...payload,
          }
        : payload,
    headers: headers || {
      'Content-Type': 'application/json',
    },
    method: method || 'GET',
    url: checkIfLocal,
    withCredentials: true,
  };

  return axios(options)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log('error :', error);
      console.log('error.response :', error.response);
      throw error.response;
    });
};
