import { TUserVariant, Auth } from '@ws/shared/components';
import { useSelector, useDispatch } from 'react-redux';

import { getConfig } from '../../config/config';
import { TAppState } from '../../redux';
import { logoutUser, getUserInfo } from '../../redux/user/user.actions';

export interface IAuthProps {
  variant?: TUserVariant;
}

export function AuthContainer({ variant }: IAuthProps) {
  const dispatch = useDispatch();
  const { isAuth, info: customer } = useSelector((state: TAppState) => state.user);

  function handleLogoutClick() {
    dispatch(logoutUser());
  }

  function handleUserInfoFetch() {
    dispatch(getUserInfo());
  }

  const { authFrameOrigin } = getConfig();

  return (
    <Auth
      variant={variant}
      isAuth={isAuth}
      customer={customer}
      authFrameOrigin={authFrameOrigin}
      serviceName="TEXTATOR"
      getUserInfo={handleUserInfoFetch}
      onLogout={handleLogoutClick}
    />
  );
}
