/* eslint-disable max-len */
import * as React from 'react';

import { createIcon, IIconProps, ISVGProps } from '../../internal';

function IconComponent(props: ISVGProps) {
  return (
    <svg
      {...props}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.66602 5.00002C1.66602 4.53978 2.03911 4.16669 2.49935 4.16669H17.4993C17.9596 4.16669 18.3327 4.53978 18.3327 5.00002C18.3327 5.46026 17.9596 5.83335 17.4993 5.83335H2.49935C2.03911 5.83335 1.66602 5.46026 1.66602 5.00002Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.33398 2.49998C8.11297 2.49998 7.90101 2.58778 7.74473 2.74406C7.58845 2.90034 7.50065 3.1123 7.50065 3.33331V4.99998C7.50065 5.46022 7.12755 5.83331 6.66732 5.83331C6.20708 5.83331 5.83398 5.46022 5.83398 4.99998V3.33331C5.83398 2.67027 6.09738 2.03439 6.56622 1.56555C7.03506 1.09671 7.67094 0.833313 8.33398 0.833313H11.6673C12.3304 0.833313 12.9662 1.09671 13.4351 1.56555C13.9039 2.03439 14.1673 2.67027 14.1673 3.33331V4.99998C14.1673 5.46022 13.7942 5.83331 13.334 5.83331C12.8737 5.83331 12.5007 5.46022 12.5007 4.99998V3.33331C12.5007 3.1123 12.4129 2.90034 12.2566 2.74406C12.1003 2.58778 11.8883 2.49998 11.6673 2.49998H8.33398ZM4.16732 4.16665C4.62756 4.16665 5.00065 4.53974 5.00065 4.99998V16.6666C5.00065 16.8877 5.08845 17.0996 5.24473 17.2559C5.40101 17.4122 5.61297 17.5 5.83398 17.5H14.1673C14.3883 17.5 14.6003 17.4122 14.7566 17.2559C14.9129 17.0996 15.0007 16.8877 15.0007 16.6666V4.99998C15.0007 4.53974 15.3737 4.16665 15.834 4.16665C16.2942 4.16665 16.6673 4.53974 16.6673 4.99998V16.6666C16.6673 17.3297 16.4039 17.9656 15.9351 18.4344C15.4662 18.9033 14.8304 19.1666 14.1673 19.1666H5.83398C5.17094 19.1666 4.53506 18.9033 4.06622 18.4344C3.59738 17.9656 3.33398 17.3297 3.33398 16.6666V4.99998C3.33398 4.53974 3.70708 4.16665 4.16732 4.16665Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}

IconComponent.displayName = 'Trash';

export const Trash20: React.FunctionComponent<IIconProps> = createIcon(IconComponent);
