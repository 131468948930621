import { classes } from 'classifizer';

import { TFormatted } from '../../../types';

import styles from './CompilerContent.module.css';

export interface ICompiledContentProps {
  entity: TFormatted;
  onClick?(): void;
}

export function CompilerContent({ entity, onClick }: ICompiledContentProps) {
  const htmlContent = entity.withTitle ? entity.name + entity.text : entity.text;

  return (
    <div
      onClick={onClick}
      className={classes(
        styles['content'],
        'note_content',
        typeof onClick !== 'undefined' && styles['content_clickable'],
      )}
      dangerouslySetInnerHTML={{ __html: htmlContent }}
    />
  );
}
