/* eslint-disable max-len */
import * as React from 'react';

import { createIcon, IIconProps, ISVGProps } from '../../internal';

function IconComponent(props: ISVGProps) {
  return (
    <svg
      {...props}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M8.00065 11.3334C8.92287 11.3334 9.70898 11.0084 10.359 10.3584C11.009 9.70837 11.334 8.92226 11.334 8.00004C11.334 7.07782 11.009 6.29171 10.359 5.64171C9.70898 4.99171 8.92287 4.66671 8.00065 4.66671C7.07843 4.66671 6.29232 4.99171 5.64232 5.64171C4.99232 6.29171 4.66732 7.07782 4.66732 8.00004C4.66732 8.92226 4.99232 9.70837 5.64232 10.3584C6.29232 11.0084 7.07843 11.3334 8.00065 11.3334ZM8.00065 14.6667C7.07843 14.6667 6.21176 14.4917 5.40065 14.1417C4.58954 13.7917 3.88398 13.3167 3.28398 12.7167C2.68398 12.1167 2.20898 11.4112 1.85898 10.6C1.50898 9.78893 1.33398 8.92226 1.33398 8.00004C1.33398 7.07782 1.50898 6.21115 1.85898 5.40004C2.20898 4.58893 2.68398 3.88337 3.28398 3.28337C3.88398 2.68337 4.58954 2.20837 5.40065 1.85837C6.21176 1.50837 7.07843 1.33337 8.00065 1.33337C8.92287 1.33337 9.78954 1.50837 10.6007 1.85837C11.4118 2.20837 12.1173 2.68337 12.7173 3.28337C13.3173 3.88337 13.7923 4.58893 14.1423 5.40004C14.4923 6.21115 14.6673 7.07782 14.6673 8.00004C14.6673 8.92226 14.4923 9.78893 14.1423 10.6C13.7923 11.4112 13.3173 12.1167 12.7173 12.7167C12.1173 13.3167 11.4118 13.7917 10.6007 14.1417C9.78954 14.4917 8.92287 14.6667 8.00065 14.6667ZM8.00065 13.3334C9.48954 13.3334 10.7507 12.8167 11.784 11.7834C12.8173 10.75 13.334 9.48893 13.334 8.00004C13.334 6.51115 12.8173 5.25004 11.784 4.21671C10.7507 3.18337 9.48954 2.66671 8.00065 2.66671C6.51176 2.66671 5.25065 3.18337 4.21732 4.21671C3.18398 5.25004 2.66732 6.51115 2.66732 8.00004C2.66732 9.48893 3.18398 10.75 4.21732 11.7834C5.25065 12.8167 6.51176 13.3334 8.00065 13.3334Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}

IconComponent.displayName = 'RadioButtonChecked';

export const RadioButtonChecked16: React.FunctionComponent<IIconProps> = createIcon(IconComponent);
