import { takeEvery } from 'typed-redux-saga';

import { GENERAL_SET } from './general.types';

function* setGeneralEffect() {
  yield undefined;
}

export function* generalSaga() {
  yield* takeEvery(GENERAL_SET, setGeneralEffect);
}
