import { ReactNode } from 'react';
import { Button } from 'react-aria-components';

import { AngleDown24 } from '@ws/shared/icons';

import { SelectValue } from '../SelectValue';

import styles from './SelectButton.module.css';

interface ISelectButtonProps {
  before?: ReactNode;
}

export function SelectButton({ before }: ISelectButtonProps) {
  return (
    <Button className={styles['button']}>
      {before && <span className={styles['button-before']}>{before}</span>}

      <SelectValue />
      <span className={styles.angle} aria-hidden="true">
        <AngleDown24 />
      </span>
    </Button>
  );
}
