export class LastUpdateIdKeeper {
  private _lastUpdateId: string | null;
  constructor(lastUpdateId?: string | null) {
    this._lastUpdateId = lastUpdateId || null;
  }

  public set(lastUpdateId: string | null) {
    this._lastUpdateId = lastUpdateId;
  }

  public get() {
    return this._lastUpdateId;
  }
}
