import styles from './ProgressBar.module.css';

export interface IModalProgressBar {
  totalSteps: number;
  currentStep: number;
}

export function ProgressBar({ totalSteps, currentStep }: IModalProgressBar) {
  const stepsСompleted = (currentStep * 100) / totalSteps;

  return (
    <div className={styles['progress-bar']}>
      <div className={styles['progress-bar_past']} style={{ width: `${stepsСompleted}%` }}></div>
      <div className={styles['progress-bar_future']}></div>
    </div>
  );
}
