import { ReactNode, useCallback, useMemo } from 'react';
import {
  Select as AriaSelect,
  Label,
  Popover,
  Key,
  SelectProps,
  ValidationResult,
  FieldError,
  Text,
  ListBox,
} from 'react-aria-components';

import { TOption } from '../../types';
import { List } from '../List';

import styles from './Select.module.css';
import { SelectButton } from './SelectButton';
import { getDisabledKeysFromOptions } from './helpers';

interface ISelectProps<T extends object, Value extends Key>
  extends Omit<
    SelectProps<T>,
    'selectedKey' | 'defaultSelectedKey' | 'items' | 'onSelectionChange' | 'children'
  > {
  value: Value | null;
  defaultValue?: Value;
  options: TOption[];
  onChange(newValue: Value): void;
  label?: string;
  description?: string;
  errorMessage?: string | ((validation: ValidationResult) => string);
  children?: ReactNode | ((item: T) => ReactNode);
  before?: ReactNode;
}

export function Select<T extends object, Value extends Key>({
  label,
  description,
  errorMessage,
  children,
  options,
  value,
  defaultValue,
  onChange,
  before,
  ...props
}: ISelectProps<T, Value>) {
  const disabledKeys = useMemo(() => getDisabledKeysFromOptions(options), [options]);

  const handleSelectChange = useCallback(
    (newValue: Key) => onChange(newValue as Value),
    [onChange],
  );

  return (
    <AriaSelect
      selectedKey={value}
      defaultSelectedKey={defaultValue}
      onSelectionChange={handleSelectChange}
      disabledKeys={disabledKeys}
      {...props}
    >
      {label && <Label>{label}</Label>}

      <SelectButton before={before} />

      {description && <Text slot="description">{description}</Text>}

      <FieldError>{errorMessage}</FieldError>

      <Popover>
        {children ? (
          <ListBox>{children}</ListBox>
        ) : (
          <List className={styles.list} options={options} />
        )}
      </Popover>
    </AriaSelect>
  );
}
