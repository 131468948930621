export const TEntityTypeV2 = {
  collection: 'collection',
  note: 'note',
};

export interface ICreateCollectionArgumentsV2 {
  name?: string;
  parentId: string;
}

export interface ICreateNoteArgumentsV2 {
  name?: string;
  parentId: string;
  initialText?: string;
}

export interface ICreateProjectArgumentsV2 {
  name?: string;
  slug?: string;
}
