/* eslint-disable max-len */
import * as React from 'react';

import { createIcon, IIconProps, ISVGProps } from '../../internal';

function IconComponent(props: ISVGProps) {
  return (
    <svg
      {...props}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.66602 8.00004C2.66602 7.63185 2.96449 7.33337 3.33268 7.33337H12.666C13.0342 7.33337 13.3327 7.63185 13.3327 8.00004C13.3327 8.36823 13.0342 8.66671 12.666 8.66671H3.33268C2.96449 8.66671 2.66602 8.36823 2.66602 8.00004Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}

IconComponent.displayName = 'Minus';

export const Minus16: React.FunctionComponent<IIconProps> = createIcon(IconComponent);
