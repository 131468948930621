import { CompilerContentBlock } from '@ws/shared/components';
import { classes } from 'classifizer';
import { ReactNode } from 'react';

import styles from './CompilerTemplate.module.css';

interface ICompiledProps {
  content: ReactNode;
  editor: ReactNode;
  editButton: ReactNode;
  completeButton: ReactNode;
  isEditing: boolean;
}

export function CompilerTemplate({
  isEditing,
  content,
  editor,
  editButton,
  completeButton,
}: ICompiledProps) {
  return (
    <CompilerContentBlock isEditing={isEditing}>
      <div
        className={classes(
          styles['compiler-template__content'],
          isEditing && styles['compiler-template__content_hided'],
        )}
      >
        {content}
      </div>

      {editor && (
        <div
          className={classes(
            styles['compiler-template__editor'],
            isEditing && styles['compiler-template__editor_active'],
          )}
        >
          {editor}
        </div>
      )}

      {editButton && <div className={styles['edit-button-slot']}>{editButton}</div>}
      {completeButton && <div className={styles['complete-button-slot']}>{completeButton}</div>}
    </CompilerContentBlock>
  );
}
