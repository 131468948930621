import styles from './Logo.module.css';

interface ILogoProps {
  label?: string;
  isFull?: boolean;
  imgSrc?: string;
}

export function Logo({ label, imgSrc, isFull = true }: ILogoProps) {
  const labelToShow = label || 'WRITER STUDIO';

  return (
    <div className={styles['logo']}>
      {imgSrc && <img src={imgSrc} alt="textator" />}
      {isFull ? labelToShow : null}
    </div>
  );
}
