import { TEntityRef } from '@ws/shared/types';

export function expandArrayWithItemsAtPosition(
  children: TEntityRef[],
  itemsToInsert: TEntityRef[],
  position?: number,
) {
  if (typeof position === 'undefined' || position === -1) {
    return [...children.slice(), ...itemsToInsert.slice()];
  }

  const newChildren = [
    ...children.slice(0, position),
    ...itemsToInsert.slice(),
    ...children.slice(position),
  ];

  return newChildren;
}
