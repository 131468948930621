import * as React from 'react';

interface INotificationSettings {
  actions?: React.ReactNode[];
  autohide?: number;
  color?: string;
  position?: string;
}

export interface INotificationContext {
  push(notification: string, notificationSettings?: INotificationSettings): void;
}

export interface INotificationContextProps {
  notifications: INotificationContext;
}

const defaultValue = {
  push: () => {},
};

export const NotificationContext = React.createContext<INotificationContext>(defaultValue);
export const { Provider: NotificationCtxProvider, Consumer: NotificationCtxConsumer } =
  NotificationContext;

// export function withNotificationContext<T>(
//   Comp: React.JSXElementConstructor<any>,
// ): React.FC<Omit<T, 'context'>> {
//   return function (props) {
//     return (
//       <NotificationCtxConsumer>
//         {(notifications) => <Comp notifications={notifications} {...props} />}
//       </NotificationCtxConsumer>
//     );
//   };
// }
