/* eslint-disable max-len */
import * as React from 'react';

import { createIcon, IIconProps, ISVGProps } from '../../internal';

function IconComponent(props: ISVGProps) {
  return (
    <svg
      {...props}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M11.3325 8.94L8.47249 6.11334C8.41051 6.05085 8.33678 6.00125 8.25554 5.96741C8.1743 5.93356 8.08716 5.91614 7.99915 5.91614C7.91114 5.91614 7.82401 5.93356 7.74277 5.96741C7.66153 6.00125 7.58779 6.05085 7.52582 6.11334L4.69915 8.94C4.63667 9.00198 4.58707 9.07571 4.55322 9.15695C4.51938 9.23819 4.50195 9.32533 4.50195 9.41334C4.50195 9.50134 4.51938 9.58848 4.55322 9.66972C4.58707 9.75096 4.63667 9.82469 4.69915 9.88667C4.82406 10.0108 4.99303 10.0805 5.16915 10.0805C5.34528 10.0805 5.51424 10.0108 5.63915 9.88667L7.99915 7.52667L10.3592 9.88667C10.4833 10.0098 10.6509 10.0793 10.8258 10.08C10.9136 10.0805 11.0005 10.0637 11.0818 10.0305C11.163 9.99734 11.2369 9.94846 11.2992 9.88667C11.3639 9.82692 11.4161 9.75495 11.4528 9.6749C11.4896 9.59485 11.5101 9.50831 11.5132 9.42029C11.5163 9.33226 11.5019 9.2445 11.4709 9.16206C11.4399 9.07963 11.3928 9.00415 11.3325 8.94Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}

IconComponent.displayName = 'AngleUp';

export const AngleUp16: React.FunctionComponent<IIconProps> = createIcon(IconComponent);
