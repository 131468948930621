export function getResolution() {
  const sizes: { [key: string]: { from: number; to: number } } = {
    no: {
      from: 0,
      to: 319,
    },
    mobile_small: {
      from: 320,
      to: 479,
    },
    mobile_big: {
      from: 480,
      to: 720,
    },
    tablet: {
      from: 721,
      to: 1023,
    },
    desktop_small: {
      from: 1024,
      to: 1279,
    },
    desktop_big: {
      from: 1280,
      to: 1599,
    },
    hd: {
      from: 1600,
      to: 10000,
    },
  };

  if (typeof window !== 'undefined') {
    const curWidth = window.innerWidth;

    return Object.keys(sizes).find((size) => {
      const { from, to } = sizes[size];
      return from <= curWidth && curWidth <= to;
    });
  }

  return null;
}

export const isMobile = (resolution: string) => ['mobile_small', 'mobile_big'].includes(resolution);
export const isNotMobile = (resolution: string) =>
  !['mobile_small', 'mobile_big'].includes(resolution);
export const isTablet = (resolution: string) => ['tablet'].includes(resolution);
export const isDesktop = (resolution: string) =>
  ['desktop_small', 'desktop_big', 'hd'].includes(resolution);
export const isNotDesktop = (resolution: string) =>
  !['desktop_small', 'desktop_big', 'hd'].includes(resolution);
