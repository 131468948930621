import { ReactNode } from 'react';

import styles from './CollectionCompilerTemplate.module.css';

interface ICompilerProps {
  compiledNodes: ReactNode;
}

export function CollectionCompilerTemplate({ compiledNodes }: ICompilerProps) {
  return (
    <section id="compiler" className={styles['compiler']}>
      {compiledNodes}
    </section>
  );
}
