import { classes } from 'classifizer';
import { FunctionComponent, MouseEvent, useCallback, useMemo } from 'react';

import { Close12, Close16, Close20, Close24 } from '@ws/shared/icons';
import { TIconSize } from '@ws/shared/types';

import { Button, IButtonProps } from '../../Button';
import { IIconProps } from '../../Icons/internal';
import { usePopoverContext } from '../context';

import styles from './Close.module.css';

interface ICloseProps extends Omit<IButtonProps, 'size'> {
  size?: TIconSize;
}

const closeIcon: Record<TIconSize, FunctionComponent<IIconProps>> = {
  12: Close12,
  16: Close16,
  20: Close20,
  24: Close24,
};

export const Close = (props: ICloseProps) => {
  const { onClick, size = 20, className, ...restProps } = props;
  const { setOpen } = usePopoverContext();
  const CloseComponent = useMemo(() => closeIcon[size], [size]);

  const handleCloseClick = useCallback(
    (event: MouseEvent) => {
      onClick?.(event);
      setOpen(false);
    },
    [onClick, setOpen],
  );

  return (
    <Button
      onClick={handleCloseClick}
      variant="shapeless"
      icon={<CloseComponent />}
      className={classes(styles['close'], className)}
      {...restProps}
    />
  );
};
