import { classes } from 'classifizer';
import { Children } from 'react';
import { ListBoxItem, ListBoxItemProps } from 'react-aria-components';

import { IListOption } from '@ws/shared/types';

import { Check16 } from '../../Icons';

import styles from './ListItem.module.css';

type TListItemChildren = ListBoxItemProps['children'];

export function ListItem({ className, children, ...props }: ListBoxItemProps<IListOption>) {
  const array = Children.map<TListItemChildren, TListItemChildren>(children, (child) => child);

  const first = Array.isArray(array) ? array[0] : null;
  const rest = Array.isArray(array) ? array.slice(1) : null;

  return (
    <ListBoxItem
      {...props}
      className={({ isDisabled, isHovered, isFocusVisible, isSelected }) =>
        classes(
          styles.item,
          isSelected && styles['item_selected'],
          isHovered && styles['item_hovered'],
          isFocusVisible && styles['item_focused'],
          isDisabled && styles['item_disabled'],
          className,
        )
      }
    >
      {(renderProps) => (
        <div className={styles['item-children']}>
          <div className={styles['item-first']}>
            <span className={styles['item-check']}>{renderProps.isSelected && <Check16 />}</span>
            {typeof first === 'function' ? first(renderProps) : first}
          </div>

          {Array.isArray(rest) && rest.length > 0 ? (
            <div className={styles['item__children-wrapper']}>
              {Children.map(rest, (item) => item).map((comp) =>
                typeof comp === 'function' ? comp(renderProps) : comp,
              )}
            </div>
          ) : null}
        </div>
      )}
    </ListBoxItem>
  );
}
