import { useContext } from 'react';

import { EditabilityContext } from '../../../context/EditabilityContext';

import { WindowTab } from './WindowTab';

interface WindowTabContainerProps {
  className?: string;
  nodeId: string;
  isOpen: boolean;
  onCloseClick(): void;
  onExposeClick(): void;
}

export function WindowTabContainer({
  className,
  nodeId,
  isOpen,
  onCloseClick,
  onExposeClick,
}: WindowTabContainerProps) {
  const { getNode } = useContext(EditabilityContext);
  const node = getNode(nodeId);

  if (!node) {
    return null;
  }

  return (
    <WindowTab
      className={className}
      isOpen={isOpen}
      name={node.meta.name}
      onCloseClick={onCloseClick}
      onExposeClick={onExposeClick}
    />
  );
}
