import { TSyncState } from '@ws/shared/types';

import { EventQueue } from './EventQueue';
import { LastUpdateIdKeeper } from './lastUpdateIdKeeper';

interface ISyncStateParams<T> {
  isSyncEnabled: boolean;
  projectId: string;
  lastUpdateIdKeeper: LastUpdateIdKeeper;
  eventQueue: EventQueue<T>;
}

type TTempChanges<T> = {
  isSyncEnabled?: boolean;
  lastUpdateId?: string | null;
  list?: T[];
};

interface ITempChanges<T> {
  isSyncEnabled?: boolean;
  lastUpdateId?: string | null;
  list?: T[];
}

export class SyncState<T> {
  private _projectId: string;
  private _isSyncEnabled: boolean;
  private _lastUpdateId: LastUpdateIdKeeper;
  public eventQueue: EventQueue<T>;

  private _changes: TTempChanges<T> = {};

  constructor(params: ISyncStateParams<T>) {
    this._isSyncEnabled = params.isSyncEnabled;
    this._projectId = params.projectId;
    this._lastUpdateId = params.lastUpdateIdKeeper;
    this.eventQueue = params.eventQueue;
  }

  get isSyncEnabled() {
    return this._isSyncEnabled;
  }

  set isSyncEnabled(isSyncEnabled: boolean) {
    this._isSyncEnabled = isSyncEnabled;
  }

  get lastUpdateId(): string | null {
    return this._lastUpdateId.get();
  }

  set lastUpdateId(lastUpdateId: string | null) {
    this._lastUpdateId.set(lastUpdateId);
  }

  public collectChanges(changes: ITempChanges<T>) {
    this._changes = {
      ...this._changes,
      ...changes,
    };
  }

  public commitChanges() {
    if (typeof this._changes.isSyncEnabled !== 'undefined') {
      this._isSyncEnabled = this._changes.isSyncEnabled;
    }

    if (typeof this._changes.lastUpdateId !== 'undefined') {
      this._lastUpdateId.set(this._changes.lastUpdateId);
    }

    if (typeof this._changes.list !== 'undefined') {
      this.eventQueue.resetQueue(this._changes.list);
    }

    this._changes = {};
  }

  public rollBackChanges() {
    this._changes = {};
  }

  public toStorage(): TSyncState<T> {
    return {
      version: 1,
      id: this._projectId,
      isSyncEnabled: this._isSyncEnabled,
      lastUpdateId: this._lastUpdateId.get(),
      list: this.eventQueue.getList(),
    };
  }
}
