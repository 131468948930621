/**
 * RegExp to check browser version.
 *
 * Generated using browserslist-useragent-regexp
 *
 * `npx browserslist-useragent-regexp --allowHigherVersions`
 */
const supportedBrowsersRegex =
  // eslint-disable-next-line max-len
  /((CPU[ +]OS|iPhone[ +]OS|CPU[ +]iPhone|CPU IPhone OS)[ +]+(12[_.]2|12[_.]([3-9]|\d{2,})|12[_.]5|12[_.]([6-9]|\d{2,})|(1[3-9]|[2-9]\d|\d{3,})[_.]\d+|13[_.]4|13[_.]([5-9]|\d{2,})|13[_.]7|13[_.]([8-9]|\d{2,})|(1[4-9]|[2-9]\d|\d{3,})[_.]\d+|14[_.]0|14[_.]([1-9]|\d{2,})|14[_.]4|14[_.]([5-9]|\d{2,})|14[_.]8|14[_.](9|\d{2,})|(1[5-9]|[2-9]\d|\d{3,})[_.]\d+|15[_.]0|15[_.]([1-9]|\d{2,})|(1[6-9]|[2-9]\d|\d{3,})[_.]\d+)(?:[_.]\d+)?)|((?:Chrome).*OPR\/(80\.0|80\.([1-9]|\d{2,})|(8[1-9]|9\d|\d{3,})\.\d+)\.\d+)|(SamsungBrowser\/(15\.0|15\.([1-9]|\d{2,})|(1[6-9]|[2-9]\d|\d{3,})\.\d+))|(Edge\/(95(?:\.0)?|95(?:\.([1-9]|\d{2,}))?|(9[6-9]|\d{3,})(?:\.\d+)?))|((Chromium|Chrome)\/(87\.0|87\.([1-9]|\d{2,})|(8[8-9]|9\d|\d{3,})\.\d+|91\.0|91\.([1-9]|\d{2,})|(9[2-9]|\d{3,})\.\d+)(?:\.\d+)?)|(Version\/(13\.1|13\.([2-9]|\d{2,})|(1[4-9]|[2-9]\d|\d{3,})\.\d+|14\.0|14\.([1-9]|\d{2,})|(1[5-9]|[2-9]\d|\d{3,})\.\d+|15\.0|15\.([1-9]|\d{2,})|(1[6-9]|[2-9]\d|\d{3,})\.\d+)(?:\.\d+)? Safari\/)|(Trident\/7\.0)|(Firefox\/(93\.0|93\.([1-9]|\d{2,})|(9[4-9]|\d{3,})\.\d+)\.\d+)|(Firefox\/(93\.0|93\.([1-9]|\d{2,})|(9[4-9]|\d{3,})\.\d+)(pre|[ab]\d+[a-z]*)?)|(([MS]?IE) (11\.0|11\.([1-9]|\d{2,})|(1[2-9]|[2-9]\d|\d{3,})\.\d+))/;

export const isBrowserSupported = () => supportedBrowsersRegex.test(navigator.userAgent);
