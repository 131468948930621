import createSagaMiddleware from 'redux-saga';
import { all } from 'typed-redux-saga';

import { editorSagaWatch } from '../editor';
import { generalSaga } from '../general';
import { remoteProjectsWatcher } from '../remote';
import { syncSagaWatch } from '../sync/sync.saga';
import { testSaga } from '../test/test.saga';
import { userWatch } from '../user/user.saga';

import { getChannel } from './sagaChannel';

const channel = getChannel();

export const sagaMiddleware = createSagaMiddleware({
  channel,
});

export function* rootSaga() {
  yield all([
    generalSaga(),
    remoteProjectsWatcher(),
    editorSagaWatch(),
    userWatch(),
    syncSagaWatch(),
    testSaga(),
  ]);
}

export function initSagas() {
  sagaMiddleware.run(rootSaga);
}
