import {
  ButtonsPanel,
  IModalAction,
  IModalCustomViewProps,
  ModalCloseButton,
  ModalContent,
  ModalContentWrapper,
  ModalsContext,
  ModalTitle,
  TButtonColor,
} from '@ws/shared/components';
import { useCallback, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { TAppState } from '../../../../redux';
import { destroySync, initSync } from '../../../../redux/sync';

export function SyncModal(props: IModalCustomViewProps) {
  const { project } = useSelector((state: TAppState) => state.editor);
  const { setModal } = useContext(ModalsContext);
  const dispatch = useDispatch();
  const { isEnabled, status: syncStatus } = useSelector((state: TAppState) => state.sync.sync);

  const handleDelete = useCallback(() => {
    if (!project) {
      return;
    }
    setModal({
      title: 'Удаление проекта',
      subTitle: `Вы действительно хотите удалить "${project.meta.name}"?`,
      closeAfterAction: true,
      actions: [
        { text: 'Оставить', color: 'thirdary' },
        {
          text: 'Удалить',
          color: 'error',
          onClick: () => {
            dispatch(destroySync());
          },
        },
      ],
    });
  }, [project, dispatch, setModal]);

  const button = [
    !isEnabled && {
      text: 'Создать удалённую версию',
      onClick: () => {
        dispatch(initSync());
      },
    },
    isEnabled && {
      text: 'Стереть удалённую версию',
      color: 'error' as TButtonColor,
      onClick: () => {
        handleDelete();
      },
    },
  ].filter(Boolean) as IModalAction[];

  return (
    <ModalContentWrapper>
      <ModalCloseButton onClose={props.closeAllModals} />
      <ModalTitle>Синхронизация проекта</ModalTitle>
      <ModalContent>
        <div>{`Синхронизация: ${isEnabled ? 'активна' : 'не активна'}`}</div>
        {isEnabled && <div>{`Статус: ${syncStatus}`}</div>}
      </ModalContent>

      <ButtonsPanel actions={button} />
    </ModalContentWrapper>
  );
}
