import { createReducer } from '@reduxjs/toolkit';

import { IAction } from '../../types/redux';

import {
  IProjectsGetByUserSuccessPayload,
  IRemoteState,
  PROJECTS_GET_BY_USER,
} from './remote.types';

const INITIAL_STATE: IRemoteState = {
  projects: [],
  errorMessage: null,
  isLoading: true,
};

export const remoteReducer = createReducer(INITIAL_STATE, (builder) => {
  builder
    .addCase(PROJECTS_GET_BY_USER.REQUEST, (state: IRemoteState) => {
      state.isLoading = true;
    })
    .addCase(
      PROJECTS_GET_BY_USER.SUCCESS,
      (state: IRemoteState, action: IAction<IProjectsGetByUserSuccessPayload>) => {
        state.projects = action.payload?.projects || [];
        state.isLoading = false;
      },
    )
    .addCase(PROJECTS_GET_BY_USER.FAILURE, (state: IRemoteState) => {
      state.isLoading = false;
    });
});
