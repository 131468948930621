import { TProject } from '@ws/shared/types';
import Joi from 'joi';

import { Validator } from '../types';

export class ProjectValidatorV3 extends Validator<TProject> {
  private getMetaSchema() {
    return Joi.object({
      name: Joi.string().required(),
      withTitle: Joi.boolean().required(),
      isHidden: Joi.boolean().required(),
    });
  }

  private getCollectionSchema() {
    return Joi.object({
      id: Joi.string().required(),
      parentId: Joi.string().allow(null).required(),

      type: Joi.string().required().valid('collection'),
      meta: this.getMetaSchema(),

      children: Joi.array()
        .items(
          Joi.object({
            id: Joi.string().required(),
            type: Joi.string().required().valid('collection', 'note'),
          }),
        )
        .required(),
      hash: Joi.string().allow(''),

      createdAt: Joi.string().required(),
      updatedAt: Joi.string().required(),
    });
  }

  private getNoteSchema() {
    return Joi.object({
      id: Joi.string().required(),
      parentId: Joi.string().required(),

      type: Joi.string().required().valid('note'),
      meta: this.getMetaSchema(),

      content: Joi.string().required().allow(''),
      hash: Joi.string().allow(''),

      createdAt: Joi.string().required(),
      updatedAt: Joi.string().required(),
    });
  }

  private getProjectSchema() {
    return Joi.object({
      version: Joi.number().required(),
      id: Joi.string().required(),
      root: Joi.string().required(),
      drafts: Joi.string().required(),

      meta: Joi.object({
        name: Joi.string().required(),
        slug: Joi.string().required(),
      }),

      collections: Joi.object({}).pattern(
        /^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i,
        this.getCollectionSchema(),
      ),
      notes: Joi.object({}).pattern(
        /^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i,
        this.getNoteSchema(),
      ),

      createdAt: Joi.string().required(),
      updatedAt: Joi.string().required(),
    });
  }

  public validate(project: TProject) {
    const projectSchema = this.getProjectSchema();

    const result = projectSchema.validate(project);

    if (result.error) {
      return this.createFailureResponse(this.createProjectValidationMessage(3));
    }

    return this.createSuccessResponse(result.value);
  }
}

export const projectValidatorV3 = new ProjectValidatorV3();
