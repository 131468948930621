import { TPreview } from '@ws/shared';

import { PreviewListItem } from '../PreviewListItem';

import styles from './PreviewList.module.css';

interface PreviewListProps {
  list: TPreview[];
  currentHash: string;
}

export function PreviewList({ list, currentHash }: PreviewListProps) {
  return (
    <div className={styles['preview-list']}>
      {list.map((item) => {
        if (!item.linkKey) {
          return null;
        }

        return <PreviewListItem key={item.id} preview={item} currentHash={currentHash} />;
      })}
    </div>
  );
}
