/* eslint-disable max-len */
import * as React from 'react';

import { createIcon, IIconProps, ISVGProps } from '../../internal';

function IconComponent(props: ISVGProps) {
  return (
    <svg
      {...props}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.33398 9.99998C8.33398 9.07951 9.08018 8.33331 10.0007 8.33331C10.9211 8.33331 11.6673 9.07951 11.6673 9.99998C11.6673 10.9205 10.9211 11.6666 10.0007 11.6666C9.08018 11.6666 8.33398 10.9205 8.33398 9.99998Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.33398 4.16667C8.33398 3.24619 9.08018 2.5 10.0007 2.5C10.9211 2.5 11.6673 3.24619 11.6673 4.16667C11.6673 5.08714 10.9211 5.83333 10.0007 5.83333C9.08018 5.83333 8.33398 5.08714 8.33398 4.16667Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.33398 15.8334C8.33398 14.9129 9.08018 14.1667 10.0007 14.1667C10.9211 14.1667 11.6673 14.9129 11.6673 15.8334C11.6673 16.7538 10.9211 17.5 10.0007 17.5C9.08018 17.5 8.33398 16.7538 8.33398 15.8334Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}

IconComponent.displayName = 'MoreVertical';

export const MoreVertical20: React.FunctionComponent<IIconProps> = createIcon(IconComponent);
