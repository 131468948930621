import { createContext } from 'react';

import { DEFAULT_IS_OPEN, DEFAULT_VARIANT } from '../internal/constants';
import { TSidebarVariant } from '../types';

interface IVariantContext {
  isOpen: boolean;
  variant: TSidebarVariant;
  onOpen?(): void;
  onClose?(): void;
}

const defaultContextState: IVariantContext = {
  isOpen: DEFAULT_IS_OPEN,
  variant: DEFAULT_VARIANT,
  onOpen: () => {},
  onClose: () => {},
};

export const sidebarContext = createContext<IVariantContext>(defaultContextState);
export const { Provider: SidebarContextProvider } = sidebarContext;
