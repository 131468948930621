import { Sidebar as UiKitSidebar } from '@ws/shared/components';

import { AuthContainer } from '../../../components';
import { TextatorLogo } from '../../../components/TextatorLogo';
import { useSidebar } from '../../../hooks';

export function Sidebar() {
  const { onOpen, onClose } = useSidebar();

  return (
    <UiKitSidebar.Container variant="narrow" onOpen={onOpen} onClose={onClose}>
      <UiKitSidebar.Header>
        <UiKitSidebar.HeaderWrapper>
          <TextatorLogo isFull={false} />
        </UiKitSidebar.HeaderWrapper>
      </UiKitSidebar.Header>

      <UiKitSidebar.Footer>
        <AuthContainer variant="compressed" />
      </UiKitSidebar.Footer>
    </UiKitSidebar.Container>
  );
}
