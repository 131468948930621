/* eslint-disable max-len */
import * as React from 'react';

import { createIcon, IIconProps, ISVGProps } from '../../internal';

function IconComponent(props: ISVGProps) {
  return (
    <svg
      {...props}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_767_14496)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.5781 0.244078C13.9035 -0.0813592 14.4311 -0.0813592 14.7566 0.244078L18.0899 3.57741C18.4153 3.90285 18.4153 4.43049 18.0899 4.75592L14.7566 8.08926C14.4311 8.41469 13.9035 8.41469 13.5781 8.08926C13.2526 7.76382 13.2526 7.23618 13.5781 6.91074L16.3221 4.16667L13.5781 1.42259C13.2526 1.09715 13.2526 0.569515 13.5781 0.244078Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.83268 4.99998C5.16964 4.99998 4.53376 5.26337 4.06492 5.73221C3.59607 6.20105 3.33268 6.83694 3.33268 7.49998V9.16665C3.33268 9.62688 2.95959 9.99998 2.49935 9.99998C2.03911 9.99998 1.66602 9.62688 1.66602 9.16665V7.49998C1.66602 6.39491 2.105 5.3351 2.8864 4.5537C3.66781 3.7723 4.72761 3.33331 5.83268 3.33331H17.4993C17.9596 3.33331 18.3327 3.70641 18.3327 4.16665C18.3327 4.62688 17.9596 4.99998 17.4993 4.99998H5.83268Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.42194 11.9108C6.74737 12.2362 6.74737 12.7638 6.42194 13.0893L3.67786 15.8334L6.42194 18.5774C6.74737 18.9029 6.74737 19.4305 6.42194 19.7559C6.0965 20.0814 5.56886 20.0814 5.24343 19.7559L1.91009 16.4226C1.58466 16.0972 1.58466 15.5695 1.91009 15.2441L5.24343 11.9108C5.56886 11.5853 6.0965 11.5853 6.42194 11.9108Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.4993 10C17.9596 10 18.3327 10.3731 18.3327 10.8333V12.5C18.3327 13.6051 17.8937 14.6649 17.1123 15.4463C16.3309 16.2277 15.2711 16.6667 14.166 16.6667H2.49935C2.03911 16.6667 1.66602 16.2936 1.66602 15.8333C1.66602 15.3731 2.03911 15 2.49935 15H14.166C14.8291 15 15.4649 14.7366 15.9338 14.2678C16.4026 13.7989 16.666 13.163 16.666 12.5V10.8333C16.666 10.3731 17.0391 10 17.4993 10Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_767_14496">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

IconComponent.displayName = 'Repeat';

export const Repeat20: React.FunctionComponent<IIconProps> = createIcon(IconComponent);
