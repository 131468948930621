import { DIFF_DELETE, DIFF_EQUAL, DIFF_INSERT, diff_match_patch } from '@ws/shared/utils';

import styles from './TestPage.module.css';

// function DeletedElem({ text }: { text: string }) {
//   return <div className={styles['deleted-elem']}>{text}</div>;
// }
//
// function InsertedElem({ text }: { text: string }) {
//   return <div className={styles['inserted-elem']}>{text}</div>;
// }

export function getPrettyView(diffs: diff_match_patch.Diff[]) {
  const html = [];

  const pattern_amp = /&/g;
  const pattern_lt = /</g;
  const pattern_gt = />/g;
  const pattern_para = /\n/g;

  for (let x = 0; x < diffs.length; x += 1) {
    const operation = diffs[x][0]; // Operation (insert, delete, equal)
    const data = diffs[x][1]; // Text of change.
    const text = data
      .replace(pattern_amp, '&amp;')
      .replace(pattern_lt, '&lt;')
      .replace(pattern_gt, '&gt;')
      .replace(pattern_para, '<br>');

    switch (operation) {
      case DIFF_INSERT:
        html[x] = `<ins class="${styles['inserted-elem']}">` + text + '</ins>';
        break;
      case DIFF_DELETE:
        html[x] = `<del class="${styles['deleted-elem']}">` + text + '</del>';
        break;
      case DIFF_EQUAL:
        html[x] = '<span>' + text + '</span>';
        break;
    }

    // switch (operation) {
    //   case DIFF_INSERT:
    //     html[x] = <InsertedElem text={text} />;
    //     break;
    //   case DIFF_DELETE:
    //     html[x] = <DeletedElem text={text} />;
    //     break;
    //   case DIFF_EQUAL:
    //     html[x] = text;
    //     break;
    // }
  }

  return html.join('');
  // return html;
}
