import { EMcsName } from '../../utils';

const AUTH_ENDPOINT_BASE = 'v1/auth';

export const AUTH_SIGN_UP_ENDPOINT_CONF = {
  base: `${AUTH_ENDPOINT_BASE}/sign-up`,
  mcsName: EMcsName.USER,
  access: 'public',
};

export const AUTH_LOGIN_ENDPOINT_CONF = {
  base: `${AUTH_ENDPOINT_BASE}/login`,
  mcsName: EMcsName.USER,
  access: 'public',
};

export const AUTH_LOGOUT_ENDPOINT_CONF = {
  base: `${AUTH_ENDPOINT_BASE}/logout`,
  mcsName: EMcsName.USER,
  access: 'private',
};
