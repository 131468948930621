import { useCallback, useEffect } from 'react';

const AUTH_STATUS_CHANGE_EVENT = 'AUTH_STATUS_CHANGE_EVENT';

type TAuthStatusMessage = {
  event: string;
  status: string;
};

const AUTH_STATUS = {
  AUTHED: 'AUTHED',
  UNAUTHED: 'UNAUTHED',
};

interface IProps {
  authFrameOrigin: string;
  getUserInfo(): void;
}

export function FrameMessageReceiver({ authFrameOrigin, getUserInfo }: IProps) {
  const messageListener = useCallback(
    (event: MessageEvent<TAuthStatusMessage>) => {
      if (event.origin !== authFrameOrigin) {
        return;
      }

      if (event.data.event !== AUTH_STATUS_CHANGE_EVENT) {
        return;
      }

      if (event.data.status === AUTH_STATUS.AUTHED || event.data.status === AUTH_STATUS.UNAUTHED) {
        setTimeout(() => {
          getUserInfo();
        }, 300);
      }
    },
    [authFrameOrigin, getUserInfo],
  );

  useEffect(() => {
    window.addEventListener('message', messageListener);

    return () => {
      window.removeEventListener('message', messageListener);
    };
  }, [messageListener]);

  return null;
}
