import { classes } from 'classifizer';
import { ReactNode, useContext } from 'react';

import { sidebarContext } from '../context';

import styles from './Header.module.css';

interface IHeaderProps {
  children: ReactNode;
}

export function Header({ children }: IHeaderProps) {
  const { isOpen } = useContext(sidebarContext);

  return (
    <header
      className={classes(
        styles['header'],
        !isOpen && styles['header_compressed'],
        isOpen && styles['header_opened'],
      )}
    >
      {children}
    </header>
  );
}
