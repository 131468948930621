import { TSyncState } from '@ws/shared/types';
import Joi from 'joi';

import { Validator } from '../types';

export class SyncStateValidatorV1 extends Validator<TSyncState> {
  private getActSchema() {
    return Joi.object({
      id: Joi.string().required(),
      type: Joi.string().required(),
      payload: Joi.object().required(),
      eventPartition: Joi.string().required(),
      time: Joi.string().required(),
    });
  }

  private getEventSchema() {
    return Joi.object({
      eventId: Joi.string().required(),
      acts: Joi.array().items(this.getActSchema()),
      time: Joi.string().required(),
    });
  }

  private getSyncStateSchema() {
    return Joi.object({
      version: Joi.number().required(),
      id: Joi.string().required(),
      list: Joi.array().items(this.getEventSchema()),
      isSyncEnabled: Joi.boolean().required(),
      lastUpdateId: Joi.string().required().allow(null),
    });
  }

  public validate(syncState: TSyncState) {
    const schema = this.getSyncStateSchema();
    const result = schema.validate(syncState);

    if (result.error) {
      return this.createFailureResponse(this.createSyncStateValidationMessage(1));
    }

    return this.createSuccessResponse(result.value);
  }
}

export const syncStateValidatorV1 = new SyncStateValidatorV1();
