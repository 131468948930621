import { Breadcrumbs, BreadcrumbSeparator, Button, IBreadCrumbItem } from '@ws/shared/components';
import { AlignLess24, AlignMore24 } from '@ws/shared/icons';
import { useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { ResolutionContext } from '../../../context';
import { useSidebar } from '../../../hooks';
import { isMobile } from '../../../modules/getResolution';
import { TAppState } from '../../../redux';
import { TRouterKeys } from '../../../types/router';
import { createBreadcrumbsItems } from '../helpers';

import styles from './BeforeActions.module.css';

export function BeforeActions() {
  const { resolution } = useContext(ResolutionContext);
  const breadcrumbs = useSelector((state: TAppState) => state.breadcrumbs);
  const { isSidebarOpen } = useSelector((state: TAppState) => state.general);
  const { toggleSidebar } = useSidebar();
  const mobile = isMobile(resolution);
  const { projectId } = useParams<TRouterKeys>();
  const breadcrumbsItems = useMemo<IBreadCrumbItem[]>(
    () => createBreadcrumbsItems(breadcrumbs.path, projectId!),
    [breadcrumbs.path, projectId],
  );
  const hasBreadcrumbs = breadcrumbsItems.length > 0;

  return (
    <div className={styles['before-actions']}>
      {!mobile && (
        <Button
          onClick={toggleSidebar}
          variant="shapeless"
          icon={isSidebarOpen ? <AlignLess24 /> : <AlignMore24 />}
        />
      )}
      <div className={styles['before-actions__breadcrumbs']}>
        {hasBreadcrumbs && (
          <>
            <Breadcrumbs items={breadcrumbsItems} />
            <BreadcrumbSeparator />
          </>
        )}
      </div>
    </div>
  );
}
