/* eslint-disable max-len */
import * as React from 'react';

import { createIcon, IIconProps, ISVGProps } from '../../internal';

function IconComponent(props: ISVGProps) {
  return (
    <svg
      {...props}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M14.1671 11.175L10.5921 7.64164C10.5146 7.56353 10.4224 7.50154 10.3209 7.45923C10.2193 7.41692 10.1104 7.39514 10.0004 7.39514C9.89039 7.39514 9.78147 7.41692 9.67992 7.45923C9.57837 7.50154 9.48621 7.56353 9.40874 7.64164L5.8754 11.175C5.7973 11.2524 5.7353 11.3446 5.693 11.4462C5.65069 11.5477 5.62891 11.6566 5.62891 11.7666C5.62891 11.8766 5.65069 11.9856 5.693 12.0871C5.7353 12.1887 5.7973 12.2808 5.8754 12.3583C6.03154 12.5135 6.24275 12.6006 6.4629 12.6006C6.68306 12.6006 6.89427 12.5135 7.0504 12.3583L10.0004 9.40831L12.9504 12.3583C13.1056 12.5123 13.3151 12.5991 13.5337 12.6C13.6434 12.6006 13.7521 12.5796 13.8537 12.5381C13.9552 12.4966 14.0475 12.4355 14.1254 12.3583C14.2063 12.2836 14.2716 12.1937 14.3175 12.0936C14.3634 11.9935 14.3891 11.8854 14.393 11.7753C14.3968 11.6653 14.3789 11.5556 14.3401 11.4525C14.3013 11.3495 14.2425 11.2552 14.1671 11.175Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}

IconComponent.displayName = 'AngleUp';

export const AngleUp20: React.FunctionComponent<IIconProps> = createIcon(IconComponent);
