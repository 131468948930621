/* eslint-disable max-len */
import * as React from 'react';

import { createIcon, IIconProps, ISVGProps } from '../../internal';

function IconComponent(props: ISVGProps) {
  return (
    <svg
      {...props}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M8.93931 8L13.1393 3.80667C13.2648 3.68113 13.3354 3.51087 13.3354 3.33333C13.3354 3.1558 13.2648 2.98554 13.1393 2.86C13.0138 2.73447 12.8435 2.66394 12.666 2.66394C12.4884 2.66394 12.3182 2.73447 12.1926 2.86L7.99931 7.06L3.80597 2.86C3.68044 2.73447 3.51018 2.66394 3.33264 2.66394C3.15511 2.66394 2.98484 2.73447 2.85931 2.86C2.73377 2.98554 2.66325 3.1558 2.66325 3.33333C2.66325 3.51087 2.73377 3.68113 2.85931 3.80667L7.05931 8L2.85931 12.1933C2.79682 12.2553 2.74723 12.329 2.71338 12.4103C2.67954 12.4915 2.66211 12.5787 2.66211 12.6667C2.66211 12.7547 2.67954 12.8418 2.71338 12.9231C2.74723 13.0043 2.79682 13.078 2.85931 13.14C2.92128 13.2025 2.99502 13.2521 3.07626 13.2859C3.1575 13.3198 3.24463 13.3372 3.33264 13.3372C3.42065 13.3372 3.50779 13.3198 3.58903 13.2859C3.67027 13.2521 3.744 13.2025 3.80597 13.14L7.99931 8.94L12.1926 13.14C12.2546 13.2025 12.3284 13.2521 12.4096 13.2859C12.4908 13.3198 12.578 13.3372 12.666 13.3372C12.754 13.3372 12.8411 13.3198 12.9224 13.2859C13.0036 13.2521 13.0773 13.2025 13.1393 13.14C13.2018 13.078 13.2514 13.0043 13.2852 12.9231C13.3191 12.8418 13.3365 12.7547 13.3365 12.6667C13.3365 12.5787 13.3191 12.4915 13.2852 12.4103C13.2514 12.329 13.2018 12.2553 13.1393 12.1933L8.93931 8Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}

IconComponent.displayName = 'Close';

export const Close16: React.FunctionComponent<IIconProps> = createIcon(IconComponent);
