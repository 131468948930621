import { TProcessingFailureResult, TProcessingResult } from '@ws/shared/types';

export abstract class ErrorHandler {
  public hasError(result: TProcessingResult<unknown>): result is TProcessingFailureResult {
    return !result.isOk;
  }

  protected createFileMissingMessage() {
    return 'File is missing.';
  }

  protected createProjectMissingMessage() {
    return 'Project is missing.';
  }

  protected createUnexpectedFileVersionMessage() {
    return 'File validation error. Unexpected file version.';
  }

  protected createUnexpectedProjectVersionMessage() {
    return 'Project validation error. Unexpected project version.';
  }

  protected createUnexpectedSyncStateVersionMessage() {
    return 'SyncState validation error. Unexpected SyncState version.';
  }

  protected createSyncStateMissingMessage() {
    return 'SyncState is missing.';
  }

  protected createProjectValidationMessage(version = 1) {
    return `Version ${version} project validation error.`;
  }

  protected createFileValidationMessage(version = 1) {
    return `Version ${version} file validation error.`;
  }

  protected createSyncStateValidationMessage(version = 1) {
    return `Version ${version} syncState validation error.`;
  }
}
