import { Sidebar } from '@ws/shared/components';
import { TEntity, TEntityRef, TParentId } from '@ws/shared/types';
import { useDragDropManager, useDrop } from 'react-dnd';

interface IDividerProps {
  isOver: boolean;
}

export interface IDropResult {
  newParentId: TParentId;
  positionBaseRef?: TEntityRef;
  isLast: boolean;
}

interface IDropDividerProps {
  parentId: TParentId;
  positionBaseRef?: TEntityRef;
  isLast?: boolean;
}

export function DropDivider({ positionBaseRef, parentId, isLast = false }: IDropDividerProps) {
  const [collectedProps, targetRef] = useDrop<TEntity, IDropResult, IDividerProps>(
    () => ({
      accept: 'node',
      drop: () => ({ newParentId: parentId, isLast, positionBaseRef }),
      collect: (monitor) => ({ isOver: monitor.isOver({ shallow: true }) }),
    }),
    [positionBaseRef, parentId],
  );

  const dragDropManager = useDragDropManager();

  const monitor = dragDropManager.getMonitor();
  const isDragging = monitor.isDragging();

  return (
    <Sidebar.DropDivider ref={targetRef} isDragging={isDragging} isOver={collectedProps.isOver} />
  );
}
