import { TWindowCoords, TWindowSize } from '../../components/ContentWindow/types';

export type TWindowSettings = {
  isOpen: boolean;
  coords: TWindowCoords;
  size: TWindowSize;
  scrollPercent: number;
};

export interface IContentWindowsState {
  list: string[];
  settings: Record<string, TWindowSettings>;
}

export const CONTENT_WINDOWS_RESET = 'CONTENT_WINDOWS_RESET';
export const CONTENT_WINDOWS_APPEND = 'CONTENT_WINDOWS_APPEND';
export const CONTENT_WINDOWS_REMOVE = 'CONTENT_WINDOWS_REMOVE';
export const CONTENT_WINDOWS_SET_IS_OPEN = 'CONTENT_WINDOWS_SET_IS_OPEN';
export const CONTENT_WINDOWS_SET_SETTINGS = 'CONTENT_WINDOWS_SET_SETTINGS';

export interface IContentWindowsAppendAction {
  nodeIdToAppend: string;
}

export interface IContentWindowsRemoveAction {
  nodeIdToRemove: string;
}

export interface IContentWindowsSetIsOpenAction {
  nodeId: string;
  isOpen: boolean;
}

export interface IContentWindowsSetSettingsAction {
  nodeId: string;
  isOpen: boolean;
  coords: TWindowCoords;
  size: TWindowSize;
  scrollPercent: number;
}
