import { useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { EditabilityContext } from '../../context';
import { TAppState } from '../../redux';

import { PreviewsContainer } from './PreviewsContainer';

export function Previews() {
  const { getNodeByRef } = useContext(EditabilityContext);
  const { selected } = useSelector((state: TAppState) => state.editor);
  const { isPreviewsOpen } = useSelector((state: TAppState) => state.general);
  const currentNode = useMemo(
    () => (selected ? getNodeByRef(selected) : selected),
    [getNodeByRef, selected],
  );

  if (!currentNode || !isPreviewsOpen) {
    return null;
  }

  return <PreviewsContainer entity={currentNode} />;
}
