import { createReducer } from '@reduxjs/toolkit';

import { IAction } from '../../types/redux';
import { PROJECT_CLEAN } from '../editor';

import {
  ISyncEnableAction,
  ISyncStatusAction,
  SET_SYNC_ENABLE,
  SET_SYNC_STATUS,
  SyncStatus,
  ISyncState,
  UPDATE_CONFLICT_STATE,
  IUpdateConflictStateAction,
} from './sync.types';

const INITIAL_STATE: ISyncState = {
  sync: {
    isEnabled: false,
    status: SyncStatus.INACTIVE,
  },
  conflictState: {
    dispenser: {},
    registry: {},
    conflicts: {},
    remoteEvents: [],
    localEvents: [],
    applyOnClient: [],
    applyOnServer: [],
  },
};

export const syncReducer = createReducer(INITIAL_STATE, (builder) => {
  builder
    .addCase(
      UPDATE_CONFLICT_STATE,
      (state: ISyncState, action: IAction<IUpdateConflictStateAction>) => {
        if (!action.payload) {
          return;
        }

        state.conflictState = {
          ...state.conflictState,
          ...action.payload,
        };
      },
    )
    .addCase(PROJECT_CLEAN, (state: ISyncState) => {
      state.sync.isEnabled = false;
      state.sync.status = SyncStatus.INACTIVE;
    })
    .addCase(SET_SYNC_ENABLE, (state: ISyncState, action: IAction<ISyncEnableAction>) => {
      if (typeof action.payload?.isEnable === 'undefined') {
        return;
      }

      state.sync.isEnabled = action.payload.isEnable;

      if (action.payload.isEnable) {
        state.sync.status = SyncStatus.ACTIVE;
      } else {
        state.sync.status = SyncStatus.INACTIVE;
      }
    })
    .addCase(SET_SYNC_STATUS, (state: ISyncState, action: IAction<ISyncStatusAction>) => {
      if (!action.payload?.status) {
        return;
      }

      const selectedStatus = SyncStatus[action.payload.status];
      if (!selectedStatus) {
        return;
      }

      state.sync.status = action.payload.status;
    });
});
