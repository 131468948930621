export type TApiActionTypes = {
  DEFAULT: string;
  REQUEST: string;
  SUCCESS: string;
  FAILURE: string;
};

export const createApiActionTypes = (base: string): TApiActionTypes => ({
  DEFAULT: base,
  REQUEST: `${base}_REQUEST`,
  SUCCESS: `${base}_SUCCESS`,
  FAILURE: `${base}_FAILURE`,
});
