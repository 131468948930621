/* eslint-disable max-len */
import * as React from 'react';

import { createIcon, IIconProps, ISVGProps } from '../../internal';

function IconComponent(props: ISVGProps) {
  return (
    <svg
      {...props}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_767_14469)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.0007 2.50004C5.85851 2.50004 2.50065 5.8579 2.50065 10C2.50065 14.1422 5.85851 17.5 10.0007 17.5C14.1428 17.5 17.5006 14.1422 17.5006 10C17.5006 5.8579 14.1428 2.50004 10.0007 2.50004ZM0.833984 10C0.833984 4.93743 4.93804 0.833374 10.0007 0.833374C15.0633 0.833374 19.1673 4.93743 19.1673 10C19.1673 15.0626 15.0633 19.1667 10.0007 19.1667C4.93804 19.1667 0.833984 15.0626 0.833984 10Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.99935 9.16663C10.4596 9.16663 10.8327 9.53972 10.8327 9.99996V13.3333C10.8327 13.7935 10.4596 14.1666 9.99935 14.1666C9.53911 14.1666 9.16602 13.7935 9.16602 13.3333V9.99996C9.16602 9.53972 9.53911 9.16663 9.99935 9.16663Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.16602 6.66671C9.16602 6.20647 9.53911 5.83337 9.99935 5.83337H10.0077C10.4679 5.83337 10.841 6.20647 10.841 6.66671C10.841 7.12694 10.4679 7.50004 10.0077 7.50004H9.99935C9.53911 7.50004 9.16602 7.12694 9.16602 6.66671Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_767_14469">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

IconComponent.displayName = 'Info';

export const Info20: React.FunctionComponent<IIconProps> = createIcon(IconComponent);
