/* eslint-disable max-len */
import * as React from 'react';

import { createIcon, IIconProps, ISVGProps } from '../../internal';

function IconComponent(props: ISVGProps) {
  return (
    <svg
      {...props}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.66602 7.99996C4.66602 7.26358 5.26297 6.66663 5.99935 6.66663C6.73573 6.66663 7.33268 7.26358 7.33268 7.99996C7.33268 8.73634 6.73573 9.33329 5.99935 9.33329C5.26297 9.33329 4.66602 8.73634 4.66602 7.99996Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.66602 7.99996C8.66602 7.26358 9.26297 6.66663 9.99935 6.66663C10.7357 6.66663 11.3327 7.26358 11.3327 7.99996C11.3327 8.73634 10.7357 9.33329 9.99935 9.33329C9.26297 9.33329 8.66602 8.73634 8.66602 7.99996Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.66602 3.33333C4.66602 2.59695 5.26297 2 5.99935 2C6.73573 2 7.33268 2.59695 7.33268 3.33333C7.33268 4.06971 6.73573 4.66667 5.99935 4.66667C5.26297 4.66667 4.66602 4.06971 4.66602 3.33333Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.66602 3.33333C8.66602 2.59695 9.26297 2 9.99935 2C10.7357 2 11.3327 2.59695 11.3327 3.33333C11.3327 4.06971 10.7357 4.66667 9.99935 4.66667C9.26297 4.66667 8.66602 4.06971 8.66602 3.33333Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.66602 12.6667C4.66602 11.9303 5.26297 11.3334 5.99935 11.3334C6.73573 11.3334 7.33268 11.9303 7.33268 12.6667C7.33268 13.4031 6.73573 14 5.99935 14C5.26297 14 4.66602 13.4031 4.66602 12.6667Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.66602 12.6667C8.66602 11.9303 9.26297 11.3334 9.99935 11.3334C10.7357 11.3334 11.3327 11.9303 11.3327 12.6667C11.3327 13.4031 10.7357 14 9.99935 14C9.26297 14 8.66602 13.4031 8.66602 12.6667Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}

IconComponent.displayName = 'DragIndicator';

export const DragIndicator16: React.FunctionComponent<IIconProps> = createIcon(IconComponent);
