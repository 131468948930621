import { Button } from '@ws/shared/components';
import { AlignMore24, Sync20, SyncSlash20 } from '@ws/shared/icons';
import { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ResolutionContext } from '../../context';
import { useSidebar } from '../../hooks';
import { isMobile } from '../../modules/getResolution';
import { TAppState } from '../../redux';
import { setGlobalIsCompiled } from '../../redux/general/general.actions';

import styles from './MobileActionPanel.module.css';

export function MobileActionPanelComponent() {
  const dispatch = useDispatch();
  const { isCompiled } = useSelector((state: TAppState) => state.general);
  const { onOpen: onSidebarOpen } = useSidebar();

  const handleCompilationToggle = () => dispatch(setGlobalIsCompiled({ isCompiled: !isCompiled }));

  return (
    <aside className={styles['panel']}>
      <Button
        color="secondary"
        onClick={onSidebarOpen}
        icon={<AlignMore24 color="textator_primary" />}
      />

      <Button
        className={styles['panel__right-button']}
        color={isCompiled ? 'secondary' : 'primary'}
        onClick={handleCompilationToggle}
        icon={isCompiled ? <SyncSlash20 /> : <Sync20 />}
      />
    </aside>
  );
}

export function MobileActionPanel() {
  const { resolution } = useContext(ResolutionContext);
  const mobile = isMobile(resolution);

  if (!mobile) {
    return null;
  }

  return <MobileActionPanelComponent />;
}
