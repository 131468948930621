/* eslint-disable max-len */
import * as React from 'react';

import { createIcon, IIconProps, ISVGProps } from '../../internal';

function IconComponent(props: ISVGProps) {
  return (
    <svg
      {...props}
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M7.645 8.145L6.5 9.295V3.5C6.5 3.36739 6.44732 3.24021 6.35355 3.14645C6.25979 3.05268 6.13261 3 6 3C5.86739 3 5.74021 3.05268 5.64645 3.14645C5.55268 3.24021 5.5 3.36739 5.5 3.5V9.295L4.355 8.145C4.26085 8.05085 4.13315 7.99795 4 7.99795C3.86685 7.99795 3.73915 8.05085 3.645 8.145C3.55085 8.23915 3.49795 8.36685 3.49795 8.5C3.49795 8.63315 3.55085 8.76085 3.645 8.855L5.645 10.855C5.69255 10.9005 5.74862 10.9362 5.81 10.96C5.86985 10.9865 5.93456 11.0001 6 11.0001C6.06544 11.0001 6.13015 10.9865 6.19 10.96C6.25138 10.9362 6.30745 10.9005 6.355 10.855L8.355 8.855C8.44915 8.76085 8.50205 8.63315 8.50205 8.5C8.50205 8.36685 8.44915 8.23915 8.355 8.145C8.30838 8.09838 8.25304 8.0614 8.19212 8.03617C8.13121 8.01094 8.06593 7.99795 8 7.99795C7.86685 7.99795 7.73915 8.05085 7.645 8.145ZM9.5 1H2.5C2.36739 1 2.24021 1.05268 2.14645 1.14645C2.05268 1.24021 2 1.36739 2 1.5C2 1.63261 2.05268 1.75979 2.14645 1.85355C2.24021 1.94732 2.36739 2 2.5 2H9.5C9.63261 2 9.75979 1.94732 9.85355 1.85355C9.94732 1.75979 10 1.63261 10 1.5C10 1.36739 9.94732 1.24021 9.85355 1.14645C9.75979 1.05268 9.63261 1 9.5 1Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}

IconComponent.displayName = 'ArrowFromTop';

export const ArrowFromTop12: React.FunctionComponent<IIconProps> = createIcon(IconComponent);
