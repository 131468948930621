import { TEntityRef } from '@ws/shared/types';
import { useContext, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { EditabilityContext } from '../../context/EditabilityContext';
import { TAppState } from '../../redux';
import { setBreadcrumbs, setTargetSearch, TBreadcrumb } from '../../redux/breadcrumbs';

export function BreadcrumbsCounter() {
  const dispatch = useDispatch();
  const editability = useContext(EditabilityContext);
  const { project, selected } = useSelector((state: TAppState) => state.editor);
  const breadcrumbs = useSelector((state: TAppState) => state.breadcrumbs);
  const prevSelected = useRef<TEntityRef | null>(null);

  useEffect(() => {
    if (selected?.id !== prevSelected.current?.id) {
      dispatch(setTargetSearch({ isTargetSearch: true }));
      prevSelected.current = selected;
    }

    if (!selected) {
      return;
    }

    const lastCrumb = breadcrumbs.path[breadcrumbs.path.length - 1];

    if (
      selected.id === lastCrumb?.id ||
      ((selected?.id === project?.root || selected?.id === project?.drafts) && !lastCrumb)
    ) {
      return;
    }

    if (selected?.id === project?.root && lastCrumb) {
      dispatch(setBreadcrumbs({ path: [] }));
      return;
    }

    if (selected?.id === project?.drafts && lastCrumb) {
      dispatch(setBreadcrumbs({ path: [] }));
      return;
    }

    if (lastCrumb?.id !== selected.id) {
      const bcPath: TBreadcrumb[] = [];
      let levelRef = selected;
      let isEnd = false;

      while (!isEnd) {
        const levelNode = editability.getNodeByRef(levelRef);
        if (!levelNode) {
          break;
        }

        bcPath.unshift({
          id: levelNode.id,
          name: levelNode.meta.name,
          isCurrent: levelNode.id === selected.id,
        });

        if (levelNode.parentId === null) {
          isEnd = true;
          break;
        }

        levelRef = { id: levelNode.parentId, type: 'collection' };
      }

      dispatch(setBreadcrumbs({ path: bcPath }));
    }
  }, [
    dispatch,
    selected?.id,
    breadcrumbs.path,
    editability,
    project?.root,
    project?.drafts,
    selected,
  ]);

  return null;
}
