import { getMcsAddress } from './getMcsAddress';

export interface IBuilderParams {
  base: string;
  mcsName: string;
  access?: string;
}

export type TUrlBuilder = (params: IBuilderParams) => string;

export function buildMcsUrl({ base, mcsName, access = 'public' }: IBuilderParams) {
  let url = 'api';

  const mcsPart = getMcsAddress(mcsName);
  if (mcsPart) {
    url = `${url}/${mcsPart}`;
  }

  url = `${url}/access/${access}/${base}`;

  return url;
}
