import { TCustomer } from '@ws/shared/types';

import { createApiActionTypes } from '../../utils/createApiActionTypes';

export interface IUserState {
  isLoading: boolean;
  isAuth: boolean;
  info: TCustomer | null;
  errorMessage: string | null;
}

export interface IUserGetInfoSuccess {
  customer: TCustomer;
}
export const USER_GET_INFO = createApiActionTypes('USER_GET_INFO');

export interface IAuthStatusAction {
  isAuth: boolean;
}
export const USER_SET_AUTH_STATUS = 'USER_SET_AUTH_STATUS';

export const USER_LOGOUT = createApiActionTypes('USER_LOGOUT');
