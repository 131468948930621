import { ReactNode, useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { BreadcrumbSeparator } from '../BreadcrumbSeparator';
import { ContextMenu, TContextMenu } from '../ContextMenu';
import { FolderOpen16, MoreHorizontal16 } from '../Icons';
import { Popover } from '../Popover';

import styles from './Breadcrumbs.module.css';
import { IBreadCrumbItem } from './types';

interface IBreadcrumbsProps {
  items: IBreadCrumbItem[];
  separator?: ReactNode;
}

export function Breadcrumbs({ items, separator }: IBreadcrumbsProps) {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const preparedItems = useMemo(
    () =>
      items.reduce<TContextMenu>((acc, { to, label, ...item }, index) => {
        if (index === items.length - 1) {
          return acc;
        }

        return [
          ...acc,
          {
            leftIcon: separator || index === 0 ? <FolderOpen16 /> : <BreadcrumbSeparator />,
            ...item,
            onClick: () => navigate(to),
            label,
          },
        ];
      }, []),
    [items, navigate, separator],
  );

  const toggleKebab = useCallback(() => setOpen((prevState) => !prevState), []);

  return (
    <Popover.Container open={open}>
      <Popover.Trigger onClick={toggleKebab}>
        <div className={styles['breadcrumbs-trigger']}>
          <MoreHorizontal16 />
        </div>
      </Popover.Trigger>

      <Popover.Content withoutPaddings>
        <ContextMenu items={preparedItems} />
      </Popover.Content>
    </Popover.Container>
  );
}
