import { useMutation } from '@tanstack/react-query';
import { ContextMenu, Popover, TContextMenu } from '@ws/shared/components';
import { MoreHorizontal16, Trash16 } from '@ws/shared/icons';
import { useCallback, useMemo, useState } from 'react';
import { toast } from 'react-toastify';

import { getResourceManager } from '../../../resources';

import styles from './Tools.modules.css';

interface IToolsProps {
  previewId: string;
}

export const Tools = ({ previewId }: IToolsProps) => {
  const { mutate } = useMutation(
    () => {
      return getResourceManager().PreviewService.deletePreview(previewId);
    },
    {
      onSuccess() {
        toast.success('Предпросмотр успешно удален!');
      },
    },
  );
  const [isOpen, setIsOpen] = useState(false);

  const handleDeleteClick = useCallback(() => {
    mutate();
    setIsOpen(false);
  }, [mutate]);

  const items = useMemo<TContextMenu>(
    () => [
      {
        leftIcon: <Trash16 />,
        onClick: handleDeleteClick,
        label: 'Удалить',
        className: styles['tools__delete-icon'],
      },
    ],
    [handleDeleteClick],
  );

  const handleTogglePopover = useCallback(() => setIsOpen((prevState) => !prevState), []);

  return (
    <Popover.Container open={isOpen} onOpenChange={setIsOpen}>
      <Popover.Trigger onClick={handleTogglePopover}>
        <div className={styles['tools__button']}>
          <MoreHorizontal16 />
        </div>
      </Popover.Trigger>

      <Popover.Content withoutPaddings>
        <ContextMenu items={items} />
      </Popover.Content>
    </Popover.Container>
  );
};
