import { ReactNode } from 'react';

import styles from './TreesContainer.module.css';

interface ITreeContainerProps {
  children: ReactNode;
}

export function TreesContainer({ children }: ITreeContainerProps) {
  return <div className={styles['trees-container']}>{children}</div>;
}
