import {
  TSyncState,
  ICreateCollectionArgumentsV2,
  ICreateNoteArgumentsV2,
  ICreateProjectArgumentsV2,
  TCollectionV2,
  TEntityRefV2,
  TEntityTypeV2,
  TEntityV2,
  TNoteV2,
  TProjectV2,
} from '@ws/shared/types';
import { getSha1Hash } from '@ws/shared/utils';
import CyrillicToTranslit from 'cyrillic-to-translit-js';
import { v4 } from 'uuid';

import { getNowAndAName } from '../../getNowAndAName';

const rootId = 'root';
const unboundId = 'unbound';
const defaultProjectName = 'Твой новый проект';
const defaultUnboundName = 'Черновики';
const defaultNoteName = 'Новая заметка';

export class ProjectV2Builder {
  public createCollectionV2({ name, parentId }: ICreateCollectionArgumentsV2): TCollectionV2 {
    const id = v4();
    const { now, augmentedName } = getNowAndAName();

    const children: TEntityRefV2[] = [];

    return {
      id,
      parentId,

      type: TEntityTypeV2.collection as 'collection',
      meta: {
        name: name || augmentedName,
        withTitle: false,
        isHidden: false,
      },

      children,
      hash: getSha1Hash(JSON.stringify(children)),

      createdAt: now,
      updatedAt: now,
    };
  }

  public createEntityRefV2({ id, type }: TEntityV2) {
    return {
      id,
      type,
    };
  }

  public createFileV2(project: TProjectV2, syncState: TSyncState) {
    return {
      fileVersion: 2,
      project,
      syncState,
    };
  }

  public createNoteV2({ name, parentId, initialText }: ICreateNoteArgumentsV2): TNoteV2 {
    const id = v4();
    const { now, augmentedName } = getNowAndAName();

    const content = initialText || '';

    return {
      id,
      parentId,

      type: TEntityTypeV2.note as 'note',
      meta: {
        name: name || augmentedName,
        withTitle: false,
        isHidden: false,
      },

      content,
      hash: getSha1Hash(content),

      createdAt: now,
      updatedAt: now,
    };
  }

  public createProjectV2(props: ICreateProjectArgumentsV2): TProjectV2 {
    const id = v4();
    const { now, augmentedName } = getNowAndAName();

    const trans = new CyrillicToTranslit({ preset: 'ru' });

    const fullDefaultProjectName = `${defaultProjectName} ${augmentedName}`;
    const fullDefaultSlug = trans.transform(fullDefaultProjectName);

    const { name = fullDefaultProjectName, slug = fullDefaultSlug } = props;

    const rootCollection = this.createCollectionV2({ name, parentId: rootId });
    const unboundCollection = this.createCollectionV2({
      name: defaultUnboundName,
      parentId: unboundId,
    });

    const initNote = this.createNoteV2({ name: defaultNoteName, parentId: rootCollection.id });
    rootCollection.children = [...rootCollection.children, this.createEntityRefV2(initNote)];

    return {
      version: 2,
      id,
      root: rootCollection.id,
      unbound: unboundCollection.id,

      meta: {
        name,
        slug,
      },

      collections: {
        [rootCollection.id]: rootCollection,
        [unboundCollection.id]: unboundCollection,
      },
      notes: {
        [initNote.id]: initNote,
      },

      createdAt: now,
      updatedAt: now,
    };
  }
}

export const projectV2Builder = new ProjectV2Builder();
