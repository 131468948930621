/* eslint-disable max-len */
import * as React from 'react';

import { createIcon, IIconProps, ISVGProps } from '../../internal';

function IconComponent(props: ISVGProps) {
  return (
    <svg
      {...props}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M11.1746 10L16.4246 4.75837C16.5815 4.60145 16.6697 4.38862 16.6697 4.1667C16.6697 3.94478 16.5815 3.73195 16.4246 3.57503C16.2677 3.41811 16.0549 3.32996 15.833 3.32996C15.611 3.32996 15.3982 3.41811 15.2413 3.57503L9.99962 8.82503L4.75796 3.57503C4.60104 3.41811 4.38821 3.32996 4.16629 3.32996C3.94437 3.32996 3.73154 3.41811 3.57462 3.57503C3.4177 3.73195 3.32955 3.94478 3.32955 4.1667C3.32955 4.38862 3.4177 4.60145 3.57462 4.75837L8.82462 10L3.57462 15.2417C3.49652 15.3192 3.43452 15.4113 3.39221 15.5129C3.34991 15.6144 3.32812 15.7234 3.32812 15.8334C3.32812 15.9434 3.34991 16.0523 3.39221 16.1538C3.43452 16.2554 3.49652 16.3476 3.57462 16.425C3.65209 16.5031 3.74426 16.5651 3.84581 16.6074C3.94736 16.6497 4.05628 16.6715 4.16629 16.6715C4.2763 16.6715 4.38522 16.6497 4.48677 16.6074C4.58832 16.5651 4.68049 16.5031 4.75796 16.425L9.99962 11.175L15.2413 16.425C15.3188 16.5031 15.4109 16.5651 15.5125 16.6074C15.614 16.6497 15.7229 16.6715 15.833 16.6715C15.943 16.6715 16.0519 16.6497 16.1534 16.6074C16.255 16.5651 16.3472 16.5031 16.4246 16.425C16.5027 16.3476 16.5647 16.2554 16.607 16.1538C16.6493 16.0523 16.6711 15.9434 16.6711 15.8334C16.6711 15.7234 16.6493 15.6144 16.607 15.5129C16.5647 15.4113 16.5027 15.3192 16.4246 15.2417L11.1746 10Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}

IconComponent.displayName = 'Close';

export const Close20: React.FunctionComponent<IIconProps> = createIcon(IconComponent);
