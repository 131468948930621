import { configureStore } from '@reduxjs/toolkit';

import { getRootMiddleware } from './middlewares/rootMiddleware';
import { initSagas } from './middlewares/sagaMiddleware';
import { rootReducer } from './rootReducer';

export function initStore() {
  const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => [
      ...getDefaultMiddleware({ thunk: false }),
      ...getRootMiddleware(),
    ],
    devTools: process.env.NODE_ENV !== 'production',
  });

  initSagas();

  return store;
}

export const store = initStore();
