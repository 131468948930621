import {
  HeaderContainer,
  MobileActionPanel,
  BreadcrumbsCounter,
  Previews,
} from '../../../components';
import { PlaygroundWrapper } from '../PlaygroundWrapper';

import { BoardContainer } from './Board';

export function PlayGround() {
  return (
    <PlaygroundWrapper>
      <BreadcrumbsCounter />
      <HeaderContainer />

      <BoardContainer />

      <Previews />
      <MobileActionPanel />
    </PlaygroundWrapper>
  );
}
