import { TFile } from '@ws/shared/types';
import Joi from 'joi';

import { Validator } from '../types';

export class FileValidatorV2 extends Validator<TFile> {
  private getFileSchema() {
    return Joi.object({
      fileVersion: Joi.number().required(),
      project: Joi.object().required(),
      syncState: Joi.object().allow(null),
    });
  }

  public validate(file: TFile) {
    const fileSchema = this.getFileSchema();
    const result = fileSchema.validate(file);

    if (result.error) {
      return this.createFailureResponse(this.createFileValidationMessage(2));
    }

    return this.createSuccessResponse(result.value);
  }
}

export const fileValidatorV2 = new FileValidatorV2();
