import { TProject } from '@ws/shared/types';

import { openDB, WST_DB_PROJECTS_KEY } from '../../modules';

const DB_ACCESS_LEVEL = 'readwrite';

export class iDBProjectService {
  public static saveOne(project: TProject, afterSave?: () => void) {
    return openDB()
      .then(async (db) => {
        const transaction = db.transaction(WST_DB_PROJECTS_KEY, DB_ACCESS_LEVEL);
        const store = transaction.objectStore(WST_DB_PROJECTS_KEY);

        await store.put(project);

        return transaction.commit();
      })
      .then((res) => {
        if (typeof afterSave === 'function') {
          afterSave();
        }

        return res;
      });
  }

  public static getOneById(id: string, callback?: (project?: TProject) => void) {
    return openDB()
      .then((db) => {
        const transaction = db.transaction([WST_DB_PROJECTS_KEY], DB_ACCESS_LEVEL);
        const store = transaction.objectStore(WST_DB_PROJECTS_KEY);

        return store.get(id) as unknown as TProject | undefined;
      })
      .then((res) => {
        if (typeof callback === 'function') {
          callback(res);
        }

        return res as TProject | undefined;
      });
  }

  public static getAll(callback?: (projects: TProject[]) => void) {
    return openDB()
      .then((db) => {
        const transaction = db.transaction([WST_DB_PROJECTS_KEY], DB_ACCESS_LEVEL);
        const store = transaction.objectStore(WST_DB_PROJECTS_KEY);

        return store.getAll();
      })
      .then((res) => {
        if (typeof callback === 'function') {
          callback(res);
        }

        return res;
      });
  }

  public static removeOne(id: string, callback?: (res?: any) => void) {
    return openDB()
      .then((db) => {
        const transaction = db.transaction([WST_DB_PROJECTS_KEY], DB_ACCESS_LEVEL);
        const store = transaction.objectStore(WST_DB_PROJECTS_KEY);

        return store.delete(id);
      })
      .then((res) => {
        if (typeof callback === 'function') {
          callback(res);
        }

        return res;
      });
  }
}
