import { Notifier, TResponse, TWithIsOk } from '../../types';
import { Fetcher, getErrorMessage } from '../../utils';

import { PreviewRepository } from './Preview.repository';
import {
  ICreatePreviewProps,
  TCreatePreviewResponse,
  TGetPreviewByIdResponse,
  TGetPreviewByLinkKeyResponse,
  TGetPreviewsByEntityIdResponse,
} from './types';

export class PreviewService {
  private _repository: PreviewRepository;
  private _notifier: Notifier;

  constructor(fetcher: Fetcher, notifier: Notifier) {
    this._repository = new PreviewRepository(fetcher);
    this._notifier = notifier;
  }

  public async getPreviewById(id: string): Promise<TWithIsOk<Partial<TGetPreviewByIdResponse>>> {
    let response: TResponse<TGetPreviewByIdResponse>;

    try {
      response = await this._repository.getById(id);
    } catch (err) {
      this._notifier.error(getErrorMessage(err));
      return { isOk: false };
    }

    if (response.error) {
      this._notifier.error(response.error.toString());
      return { isOk: false };
    }

    if (!response.data) {
      this._notifier.error('Error during preview fetching by id request');
      return { isOk: false };
    }

    return { isOk: true, ...response.data };
  }

  public async getPreviewByLinkKey(
    linkKey: string,
  ): Promise<TWithIsOk<Partial<TGetPreviewByLinkKeyResponse>>> {
    let response: TResponse<TGetPreviewByLinkKeyResponse>;

    try {
      response = await this._repository.getByLinkKey(linkKey);
    } catch (err) {
      this._notifier.error(getErrorMessage(err));
      return { isOk: false };
    }

    if (response.error) {
      this._notifier.error(response.error.toString());
      return { isOk: false };
    }

    if (!response.data) {
      this._notifier.error('Error during preview fetching by link key request');
      return { isOk: false };
    }

    return { isOk: true, ...response.data };
  }

  public async getPreviewsByEntityId(
    entityId: string,
  ): Promise<TWithIsOk<Partial<TGetPreviewsByEntityIdResponse>>> {
    let response: TResponse<TGetPreviewsByEntityIdResponse>;

    try {
      response = await this._repository.getListByEntityId(entityId);
    } catch (err) {
      this._notifier.error(getErrorMessage(err));
      return { isOk: false };
    }

    if (response.error) {
      this._notifier.error(response.error.toString());
      return { isOk: false };
    }

    if (!response.data) {
      this._notifier.error('Error during preview fetching by id request');
      return { isOk: false };
    }

    return { isOk: true, ...response.data };
  }

  public async createPreview(
    data: ICreatePreviewProps,
  ): Promise<TWithIsOk<Partial<TCreatePreviewResponse>>> {
    let response: TResponse<TCreatePreviewResponse>;

    try {
      response = await this._repository.create(data);
    } catch (err) {
      this._notifier.error(getErrorMessage(err));
      return { isOk: false };
    }

    if (response.error) {
      this._notifier.error(response.error.toString());
      return { isOk: false };
    }

    if (!response.data) {
      this._notifier.error('Error during preview fetching by id request');
      return { isOk: false };
    }

    return { isOk: true, ...response.data };
  }

  public async deletePreview(previewId: string) {
    let response;

    try {
      response = await this._repository.delete(previewId);
    } catch (error) {
      this._notifier.error(getErrorMessage(error));
      return { isOk: false };
    }

    if (response.error) {
      this._notifier.error(response.error.toString());
      return { isOk: false };
    }

    if (!response.data) {
      this._notifier.error('Error during preview fetching by id request');
      return { isOk: false };
    }

    return { isOk: true, ...response.data };
  }
}
