import { classes } from 'classifizer';
import { ReactNode, Children, useContext, useMemo, PropsWithChildren, MouseEvent } from 'react';
import { useNavigate } from 'react-router-dom';

import { ResolutionContext } from '../../context';
import { isMobile } from '../../modules/getResolution';

import styles from './EntityCard.module.css';

interface IEntityCardProps {
  title: string;
  titleIcon?: ReactNode;
  beforeActions?: ReactNode[];
  afterActions?: ReactNode[];

  setGoTo?(): string;
}

export function EntityCard({
  title,
  titleIcon,
  beforeActions,
  afterActions,
  setGoTo,
}: IEntityCardProps) {
  const navigate = useNavigate();
  const { resolution } = useContext(ResolutionContext);
  const mobile = isMobile(resolution);
  const goToPath = useMemo(() => (setGoTo ? setGoTo() : ''), [setGoTo]);

  const handleTitleClick = () => navigate(goToPath);

  // eslint-disable-next-line react/no-unstable-nested-components
  function EntityWrapper({ children }: PropsWithChildren) {
    if (mobile) {
      return (
        <button onClick={handleTitleClick} className={classes(styles['card'], styles['button'])}>
          {children}
        </button>
      );
    }

    return <div className={classes(styles['card'])}>{children}</div>;
  }
  // eslint-disable-next-line react/no-unstable-nested-components
  function TitleWrapper({ children }: PropsWithChildren) {
    if (!mobile) {
      return (
        <button
          onClick={handleTitleClick}
          className={classes(styles['card__title'], styles['button'], 'reset-button')}
        >
          {children}
        </button>
      );
    }

    return <div className={classes(styles['card__title'])}>{children}</div>;
  }

  const handleActionsClick = (event: MouseEvent<HTMLUListElement>) => event.stopPropagation();

  return (
    <EntityWrapper>
      <div className={styles['card__header']}>
        {beforeActions?.length !== 0 && !mobile && (
          <div className={styles['card__before-actions']}>
            {Children.map(beforeActions, (action) => action)}
          </div>
        )}
        {titleIcon && <i className={styles['card__title-icon']}>{titleIcon}</i>}
        <TitleWrapper>
          <span>{title}</span>
        </TitleWrapper>
      </div>
      {(afterActions?.length !== 0 || (mobile && beforeActions?.length !== 0)) && (
        // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
        <ul className={styles['card__after-actions']} onClick={handleActionsClick}>
          {mobile && beforeActions && Children.map(beforeActions, (action) => <li>{action}</li>)}
          {Children.map(afterActions, (action) => (
            <li>{action}</li>
          ))}
        </ul>
      )}
    </EntityWrapper>
  );
}
