import { createAction } from '../../utils/createAction';

import {
  IGeneralSetIsCompiledAction,
  IGeneralSetAction,
  GENERAL_SET,
  GENERAL_SET_IS_COMPILED,
  GENERAL_SET_IS_SECONDARY_COMPILED,
  GENERAL_SET_IS_SIDEBAR_OPEN,
  IGeneralSetIsSidebarOpenAction,
  IGeneralSetIsSecondaryCompiledAction,
  GENERAL_SET_IS_PREVIEWS_OPEN,
  IGeneralSetIsPreviewsOpenAction,
} from './general.types';

export const setGlobal = createAction<IGeneralSetAction>(GENERAL_SET);

export const setGlobalIsCompiled =
  createAction<IGeneralSetIsCompiledAction>(GENERAL_SET_IS_COMPILED);
export const setGlobalIsSecondaryCompiled = createAction<IGeneralSetIsSecondaryCompiledAction>(
  GENERAL_SET_IS_SECONDARY_COMPILED,
);
export const setGlobalIsSidebarOpen = createAction<IGeneralSetIsSidebarOpenAction>(
  GENERAL_SET_IS_SIDEBAR_OPEN,
);
export const setGlobalIsPreviewsOpen = createAction<IGeneralSetIsPreviewsOpenAction>(
  GENERAL_SET_IS_PREVIEWS_OPEN,
);
