import {
  COLLECTION_CREATE,
  COLLECTION_UPDATE_CHILD,
  ICollectionChildUpdateAct,
  ICollectionCreateAct,
  INodeMetaUpdateAct,
  INodeParentUpdateAct,
  INodeRemoveAct,
  INoteContentUpdateAct,
  INoteCreateAct,
  IProjectCreateAct,
  IProjectMetaUpdateAct,
  NODE_META_UPDATE,
  NODE_PARENT_UPDATE,
  NODE_REMOVE,
  NOTE_CREATE,
  NOTE_UPDATE_CONTENT,
  PROJECT_CREATE,
  PROJECT_META_UPDATE,
} from './act.types';
import { createAct } from './createAct';

export const createProject = createAct<IProjectCreateAct>(PROJECT_CREATE);

export const updateProjectMeta = createAct<IProjectMetaUpdateAct>(PROJECT_META_UPDATE);

export const createNote = createAct<INoteCreateAct>(NOTE_CREATE);

export const createCollection = createAct<ICollectionCreateAct>(COLLECTION_CREATE);

export const updateNodeMeta = createAct<INodeMetaUpdateAct>(NODE_META_UPDATE);

export const updateNodeParent = createAct<INodeParentUpdateAct>(NODE_PARENT_UPDATE);

export const updateNoteContent = createAct<INoteContentUpdateAct>(NOTE_UPDATE_CONTENT);

export const updateCollectionChild = createAct<ICollectionChildUpdateAct>(COLLECTION_UPDATE_CHILD);

export const removeNode = createAct<INodeRemoveAct>(NODE_REMOVE);
