import { Checkbox, IModalAction, Input, TModalCustomView } from '@ws/shared/components';
import { ChangeEvent, useEffect, useRef, useState } from 'react';

import { isMobile } from '../../../modules/getResolution';
import { getNowAndAName } from '../../../utils/getNowAndAName';
import { transliterate } from '../../../utils/transliterate';
import { ICreateProjectParams } from '../types';

const modalTitle = 'Создаём новый проект';
const modalMessage = 'Введите имя';

export function showProjectCreationModal(
  createProject: (params: ICreateProjectParams) => void,
  createProjectAndGo: (params: ICreateProjectParams) => void,
  resolution: string,
): TModalCustomView {
  return function ProjectCreationModal({
    closeAllModals,
    onAwayClose,
    actionHandlerCreator,
    components: {
      ModalWrapper,
      ModalContentWrapper,
      ModalCloseButton,
      ModalTitle,
      ModalSubTitle,
      ModalContent,
      ButtonsPanel,
    },
  }) {
    const inputRef = useRef<HTMLInputElement>(null);

    const { now } = getNowAndAName();
    const [name, setName] = useState(`Твой новый проект ${now}`);
    const [slug, setSlug] = useState(transliterate(name));
    const [isWithSync, setIsWithSync] = useState(true);
    const mobile = isMobile(resolution);
    const baseButtons: IModalAction[] = [
      {
        text: 'Сохранить',
        color: 'secondary',
        onClick: actionHandlerCreator(() => createProject({ name, slug, isWithSync })),
      },
      {
        text: 'Сохранить и перейти',
        onClick: actionHandlerCreator(() => createProjectAndGo({ name, slug, isWithSync })),
      },
    ];
    const isNotMobileButtons: IModalAction[] = mobile
      ? []
      : [{ text: 'Отмена', color: 'thirdary', onClick: closeAllModals }];

    function handleInputOnChange(event: ChangeEvent<HTMLInputElement>) {
      const newName = event.target.value;
      const newSlug = transliterate(newName);

      setName(newName);
      setSlug(newSlug);
    }

    useEffect(() => {
      inputRef.current?.select();
    }, []);

    return (
      <ModalWrapper onClick={onAwayClose}>
        <ModalContentWrapper>
          <ModalCloseButton onClose={closeAllModals} />
          <ModalTitle>{modalTitle}</ModalTitle>
          <ModalSubTitle>{modalMessage}</ModalSubTitle>
          <ModalContent>
            <Input ref={inputRef} value={name} onChange={handleInputOnChange} />
            <div style={{ height: '4px' }} />
            <Checkbox value={isWithSync} onChange={() => setIsWithSync(!isWithSync)}>
              С синхронизацей
            </Checkbox>
          </ModalContent>

          <ButtonsPanel actions={[...isNotMobileButtons, ...baseButtons]} />
        </ModalContentWrapper>
      </ModalWrapper>
    );
  };
}
