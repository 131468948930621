import { classes } from 'classifizer';
import { ReactNode, MouseEvent } from 'react';

import styles from './TreeNodeTitle.module.css';

interface ITreeNodeTitleProps {
  children: ReactNode;
  className?: string;
  onClick?(event: MouseEvent<HTMLButtonElement>): void;
  icon?: ReactNode;
  width?: string;
}

export function TreeNodeTitle({
  width = '100%',
  children,
  className,
  onClick,
  icon,
}: ITreeNodeTitleProps) {
  return (
    <button className={classes(styles['title-wrapper'], className)} onClick={onClick}>
      {icon && <div className={styles['icon']}>{icon}</div>}
      <span style={{ width }} className={classes(styles['title'], 'text-ellipsis')}>
        {children}
      </span>
    </button>
  );
}
