import { Notifier, TResponse, TWithIsOk, TWithIsOkAndData } from '@ws/shared/types';
import { Fetcher, getErrorMessage } from '@ws/shared/utils';

import { SyncRepository } from './Sync.repository';
import {
  IApplyEventRequestParams,
  IGetDeltaRequestParams,
  IInitSyncRequestParams,
  TApplyEventResponse,
  TGetDeltaResponse,
  TInitSyncResponse,
} from './types';

export class SyncService {
  private _repository: SyncRepository;
  private _notifier: Notifier;

  constructor(fetcher: Fetcher, notifier: Notifier) {
    this._repository = new SyncRepository(fetcher);
    this._notifier = notifier;
  }

  public async initSync(
    projectData: IInitSyncRequestParams,
  ): Promise<TWithIsOk<Partial<TInitSyncResponse>>> {
    let response: TResponse<TInitSyncResponse>;

    try {
      response = await this._repository.initSync({
        eventId: projectData.eventId,
        project: projectData.project,
      });
    } catch (err) {
      console.log('initSync error', err, getErrorMessage(err));
      this._notifier.error(getErrorMessage(err));
      return { isOk: false };
    }

    if (response.error) {
      this._notifier.error(response.error.toString());
      return { isOk: false };
    }

    if (!response.data) {
      this._notifier.error('Error during sync init request');
      return { isOk: false };
    }

    return { isOk: true, ...response.data };
  }

  public async applyEvent(
    params: IApplyEventRequestParams,
  ): Promise<TWithIsOkAndData<TApplyEventResponse>> {
    let response: TResponse<TApplyEventResponse>;

    try {
      response = await this._repository.applyEvent(params);
    } catch (err) {
      console.log('applyEvent error', err, getErrorMessage(err));
      this._notifier.error(getErrorMessage(err));
      return { isOk: false };
    }

    if (response.error) {
      this._notifier.error(response.error.toString());
      return { isOk: false, status: response?.status };
    }

    if (!response.data) {
      this._notifier.error('Error during event apply request');
      return { isOk: false, status: response?.status };
    }

    return { isOk: true, status: response?.status, data: response.data };
  }

  public async getDelta(
    params: IGetDeltaRequestParams,
  ): Promise<TWithIsOk<Partial<TGetDeltaResponse>>> {
    let response: TResponse<TGetDeltaResponse>;

    try {
      response = await this._repository.getDelta(params);
    } catch (err) {
      console.log('applyEvent error', err, getErrorMessage(err));
      this._notifier.error(getErrorMessage(err));
      return { isOk: false };
    }

    if (response.error) {
      this._notifier.error(response.error.toString());
      return { isOk: false };
    }

    if (!response.data) {
      this._notifier.error('Error during event apply request');
      return { isOk: false };
    }

    return { isOk: true, ...response.data };
  }
}
