import { TSyncState } from '@ws/shared/types';

import { syncStateValidator } from './SyncStateValidator';
import { Processor } from './types';

export class SyncStateProcessor extends Processor<TSyncState | null> {
  public process(syncState: TSyncState | null) {
    if (!syncState) {
      return this.createSuccessResponse(null);
    }

    const validatedSyncState = syncStateValidator.validate(syncState);

    if (this.hasError(validatedSyncState)) {
      return this.createFailureResponse(validatedSyncState.error);
    }

    return this.createSuccessResponse(validatedSyncState.value);
  }
}

export const syncStateProcessor = new SyncStateProcessor();
