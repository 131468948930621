import { createReducer } from '@reduxjs/toolkit';

import { IAction } from '../../types/redux';

import { getSidebarDefaultState } from './general.helpers';
import {
  GENERAL_SET,
  GENERAL_SET_IS_COMPILED,
  IGeneralState,
  IGeneralSetIsCompiledAction,
  GENERAL_SET_IS_SIDEBAR_OPEN,
  IGeneralSetIsSidebarOpenAction,
  IGeneralSetIsSecondaryCompiledAction,
  GENERAL_SET_IS_SECONDARY_COMPILED,
  GENERAL_SET_IS_PREVIEWS_OPEN,
  IGeneralSetIsPreviewsOpenAction,
} from './general.types';

const INITIAL_STATE: IGeneralState = {
  isCompiled: false,
  isSecondaryCompiled: true,
  isSidebarOpen: getSidebarDefaultState(),
  isPreviewsOpen: false,
};

export const generalReducer = createReducer(INITIAL_STATE, (builder) => {
  builder
    .addCase(GENERAL_SET, (state: IGeneralState, action: IAction<IGeneralSetIsCompiledAction>) => {
      return { ...state, ...action.payload };
    })
    .addCase(
      GENERAL_SET_IS_COMPILED,
      (state: IGeneralState, action: IAction<IGeneralSetIsCompiledAction>) => {
        state.isCompiled = action.payload?.isCompiled || false;
      },
    )
    .addCase(
      GENERAL_SET_IS_SECONDARY_COMPILED,
      (state: IGeneralState, action: IAction<IGeneralSetIsSecondaryCompiledAction>) => {
        state.isSecondaryCompiled = action.payload?.isSecondaryCompiled || false;
      },
    )
    .addCase(
      GENERAL_SET_IS_SIDEBAR_OPEN,
      (state: IGeneralState, action: IAction<IGeneralSetIsSidebarOpenAction>) => {
        state.isSidebarOpen = action.payload?.isSidebarOpen || false;
      },
    )
    .addCase(
      GENERAL_SET_IS_PREVIEWS_OPEN,
      (state: IGeneralState, action: IAction<IGeneralSetIsPreviewsOpenAction>) => {
        state.isPreviewsOpen = action.payload?.isPreviewsOpen || false;
      },
    );
});
