import { TNote } from '@ws/shared/types';
import { useContext, useEffect, useRef, useState } from 'react';

import { EditabilityContext } from '../../context/EditabilityContext';

import { MarkdownEditor } from './MarkdownEditor';
import { SaveManagerExtension } from './SaveManagerExtension';

export interface IInCompilerEditorProps {
  note: TNote;
}

export function InCompilerEditor({ note }: IInCompilerEditorProps) {
  const noteRef = useRef<TNote>(note);
  const { updateNoteContent } = useContext(EditabilityContext);
  const [content, setContent] = useState(note.content);

  useEffect(() => {
    if (note.id !== noteRef.current.id) {
      noteRef.current = note;

      setContent(note.content);
    }
  }, [note]);

  function handleSave() {
    if (note.content !== content) {
      updateNoteContent(noteRef.current.id, content);
    }
  }

  return (
    <SaveManagerExtension value={content} onChange={setContent} onSave={handleSave}>
      {(saverParams) => <MarkdownEditor {...saverParams} />}
    </SaveManagerExtension>
  );
}
