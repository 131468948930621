import { TCollection, TProject, TCollectionV2, TProjectV2 } from '@ws/shared/types';

export class ProjectAdaptorV2 {
  private static transformCollections(
    collections: Record<string, TCollectionV2>,
  ): Record<string, TCollection> {
    return Object.keys(collections).reduce(
      (acc, key) => {
        const parentId =
          collections[key].parentId === 'root' || collections[key].parentId === 'unbound'
            ? null
            : collections[key].parentId;

        return { ...acc, [key]: { ...collections[key], parentId } };
      },
      {} as Record<string, TCollection>,
    );
  }

  public static adapt({ unbound, ...projectV2 }: TProjectV2): TProject {
    return {
      ...projectV2,
      drafts: unbound,
      version: 3,
      collections: this.transformCollections(projectV2.collections),
    };
  }
}
