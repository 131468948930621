import * as React from 'react';

export interface IResolutionContext {
  resolution: string;
}

export interface IResolutionContextProps {
  resolutionContext: IResolutionContext;
}

const defaultValue = {
  resolution: 'no',
};

export const ResolutionContext = React.createContext<IResolutionContext>(defaultValue);
export const { Provider: ResolutionContextProvider, Consumer: ResolutionConsumer } =
  ResolutionContext;

export function withResolutionContext<Props>(
  Comp: React.ComponentType<IResolutionContextProps>,
): React.FC<Omit<Props, 'resolutionContext'>> {
  return function WithResolutionContext(props) {
    return (
      <ResolutionConsumer>
        {(resolutionContext) => <Comp resolutionContext={resolutionContext} {...props} />}
      </ResolutionConsumer>
    );
  };
}

export function ResolutionContextDecorator<T extends React.ComponentClass>(Component: T): T {
  return ((props: any) => (
    <ResolutionConsumer>
      {(resolutionContext) => <Component {...props} resolutionContext={resolutionContext} />}
    </ResolutionConsumer>
  )) as any as T;
}
