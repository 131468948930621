import { useState } from 'react';

export const useCompilerHandlers = () => {
  const [selectedEntity, setSelectedEntity] = useState<string | null>(null);
  const [editEntityId, setEditEntityId] = useState<string | null>(null);

  const handleEntityClick = (id: string) => () =>
    setSelectedEntity((previousId) => {
      if (previousId === id) {
        return null;
      }

      return id;
    });
  const handleEditClick = (id: string) => () =>
    setEditEntityId((previousId) => {
      if (previousId === id) {
        return null;
      }

      return id;
    });

  return { selectedEntity, editEntityId, handleEntityClick, handleEditClick };
};
