import { Button } from '@ws/shared/components';
import { Check24 } from '@ws/shared/icons';
import { classes } from 'classifizer';

import styles from './CompleteButton.module.css';

interface ICompleteButtonProps {
  onClick(): void;
  isDesktopOnly?: boolean;
  isNotDesktop?: boolean;
}

export function CompleteButton({ onClick, isDesktopOnly, isNotDesktop }: ICompleteButtonProps) {
  return (
    <Button
      variant="shapeless"
      icon={<Check24 color="other_success" />}
      onClick={onClick}
      className={classes(
        styles['complete-button'],
        isDesktopOnly && styles['complete-button_desktop-only'],
        isNotDesktop && styles['complete-button_not-desktop'],
      )}
    />
  );
}
