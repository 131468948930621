import { TFileV1 } from '@ws/shared/types';

import { Validator } from '../types';

import { projectValidatorV1 } from './ProjectValidatorV1';

export class FileValidatorV1 extends Validator<TFileV1> {
  public validate(file: TFileV1) {
    const result = projectValidatorV1.validate(file);

    if (super.hasError(result)) {
      return this.createFailureResponse(this.createFileValidationMessage(1));
    }

    return this.createSuccessResponse(result.value);
  }
}

export const fileValidatorV1 = new FileValidatorV1();
