import { TProject, TProjects, TProjectWithVersion, TProjectV1, TProjectV2 } from '@ws/shared/types';

import { Validator } from './types';
import { projectValidatorV1 } from './v1';
import { projectValidatorV2 } from './v2';
import { projectValidatorV3 } from './v3';

export class ProjectValidator extends Validator<TProjects> {
  public validate(project: TProjectWithVersion<TProjects>) {
    switch (project.version) {
      case 3:
        return projectValidatorV3.validate(project as TProject);
      case 2:
        return projectValidatorV2.validate(project as TProjectV2);
      case 1:
        return projectValidatorV1.validate(project as TProjectV1);

      default:
        return this.createFailureResponse(this.createUnexpectedProjectVersionMessage());
    }
  }
}

export const projectValidator = new ProjectValidator();
