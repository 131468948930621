const isLocalStorageSupported = () => {
  try {
    localStorage.setItem('abc', 'def');

    if (localStorage.getItem('abc') === 'def') {
      return true;
    }

    return false;
  } catch (err) {
    return false;
  }
};

const createReasonMessage = (feature: string) => `insufficent ${feature} support`;

const appNotSupportedReason = () => {
  if (!isLocalStorageSupported()) {
    return createReasonMessage('localStorage');
  }

  return '';
};

export const isFeaturesSupported = () => true;
export const notSupportedReason = () => appNotSupportedReason();
