import { classes } from 'classifizer';

import { Button } from '../../Button';
import { Close24 } from '../../Icons';
import {
  IButtonsPanelProps,
  IModalCloseButton,
  IModalComponentProps,
  IModalWrapperProps,
} from '../types';

import styles from './ModalComponents.module.css';

export function ModalWrapper({ children, isLast = true, onClick }: IModalWrapperProps) {
  return (
    <div className={classes(styles['modal'], !isLast && styles['modal__veil'])} onClick={onClick}>
      {children}
    </div>
  );
}

export function ModalContentWrapper({ children }: IModalComponentProps) {
  return <div className={styles['modal__content-wrapper']}>{children}</div>;
}

export function ModalTitle({ children }: IModalComponentProps) {
  return <div className={styles['modal__title']}>{children}</div>;
}

export function ModalSubTitle({ children }: IModalComponentProps) {
  return <div className={styles['modal__subtitle']}>{children}</div>;
}

export function ModalContent({ children }: IModalComponentProps) {
  return <div className={styles['modal__content']}>{children}</div>;
}

export function ButtonsPanel({ actions, onClick, className }: IButtonsPanelProps) {
  if (!actions) {
    return null;
  }

  return (
    <div className={classes(styles['modal__buttons'], className)}>
      {actions.map(
        ({ text, color = 'primary', variant, onClick: onActionClick, disabled, icon, endIcon }) => (
          <Button
            key={text}
            type="button"
            onClick={onClick ? onClick(onActionClick) : onActionClick}
            color={color}
            variant={variant}
            disabled={disabled}
            icon={icon}
            endIcon={endIcon}
          >
            {text}
          </Button>
        ),
      )}
    </div>
  );
}

export function ModalCloseButton({ onClose }: IModalCloseButton) {
  return (
    <Button
      className={styles['modal__close']}
      variant="shapeless"
      onClick={onClose}
      icon={<Close24 />}
    />
  );
}
