import { createReducer } from '@reduxjs/toolkit';

import {
  CONTENT_WINDOW_DEFAULT_HEIGHT,
  CONTENT_WINDOW_DEFAULT_WIDTH,
  CONTENT_WINDOW_GAP,
} from '../../constants/contentWindow';
import { IAction } from '../../types/redux';

import {
  CONTENT_WINDOWS_APPEND,
  CONTENT_WINDOWS_SET_IS_OPEN,
  CONTENT_WINDOWS_REMOVE,
  CONTENT_WINDOWS_RESET,
  IContentWindowsAppendAction,
  IContentWindowsSetIsOpenAction,
  IContentWindowsRemoveAction,
  IContentWindowsState,
  CONTENT_WINDOWS_SET_SETTINGS,
  IContentWindowsSetSettingsAction,
} from './contentWindows.types';

const INITIAL_STATE: IContentWindowsState = {
  list: [],
  settings: {},
};

const WINDOW_AMOUNT_LIMIT = 3;

export const contentWindowsReducer = createReducer(INITIAL_STATE, (builder) => {
  builder
    .addCase(CONTENT_WINDOWS_RESET, (state: IContentWindowsState) => {
      state.list = [];
    })
    .addCase(
      CONTENT_WINDOWS_APPEND,
      (state: IContentWindowsState, action: IAction<IContentWindowsAppendAction>) => {
        if (!action.payload) {
          return;
        }

        const idx = state.list.indexOf(action.payload.nodeIdToAppend);

        if (idx === -1) {
          if (state.list.length >= WINDOW_AMOUNT_LIMIT) {
            state.list = state.list.slice(1, WINDOW_AMOUNT_LIMIT);
          }

          const amount = state.list.length + 1;

          state.list = [...state.list, action.payload.nodeIdToAppend];
          state.settings[action.payload.nodeIdToAppend] = {
            isOpen: true,
            coords: {
              x: CONTENT_WINDOW_GAP * amount,
              y: CONTENT_WINDOW_GAP * amount,
            },
            size: {
              width: CONTENT_WINDOW_DEFAULT_WIDTH,
              height: CONTENT_WINDOW_DEFAULT_HEIGHT,
            },
            scrollPercent: 0,
          };
          return;
        }

        state.list.splice(idx, 1);

        state.list = [...state.list, action.payload.nodeIdToAppend];
        state.settings[action.payload.nodeIdToAppend].isOpen = true;
      },
    )
    .addCase(
      CONTENT_WINDOWS_REMOVE,
      (state: IContentWindowsState, action: IAction<IContentWindowsRemoveAction>) => {
        if (!action.payload) {
          return;
        }

        const updated = [...state.list];

        const idx = updated.indexOf(action.payload.nodeIdToRemove);

        if (idx === -1) {
          return;
        }

        updated.splice(idx, 1);

        state.list = updated;
        delete state.settings[action.payload.nodeIdToRemove];
      },
    )
    .addCase(
      CONTENT_WINDOWS_SET_IS_OPEN,
      (state: IContentWindowsState, action: IAction<IContentWindowsSetIsOpenAction>) => {
        if (
          !action.payload ||
          typeof action.payload.nodeId === 'undefined' ||
          typeof action.payload.isOpen === 'undefined'
        ) {
          return;
        }

        const { nodeId, isOpen } = action.payload;

        state.settings[nodeId].isOpen = isOpen;
      },
    )
    .addCase(
      CONTENT_WINDOWS_SET_SETTINGS,
      (state: IContentWindowsState, action: IAction<IContentWindowsSetSettingsAction>) => {
        if (
          !action.payload ||
          typeof action.payload.nodeId === 'undefined' ||
          typeof action.payload.isOpen === 'undefined'
        ) {
          return;
        }

        const { nodeId, ...settings } = action.payload;

        state.settings[nodeId] = settings;
      },
    );
});
