import { parser } from '@ws/shared/utils';

function getData(entity) {
  const { id, name, withTitle } = entity;
  return {
    id,
    withTitle,
    name: parser(name),
    text: '',
  };
}

export function compileEntity(entity) {
  const { relevantVersion, content } = entity;
  const relevantContent = content.find((one) => one.version === relevantVersion);
  return {
    ...getData(entity),
    text: parser(relevantContent.text),
  };
}

function flatify({ type, isHidden, ...elseFields }, get) {
  if (isHidden) return [];

  const entity = {
    ...elseFields,
  };

  if (type === 'note') {
    return compileEntity(entity);
  }

  const collectionData = entity.withTitle && getData(entity);

  return map(entity.children, get, collectionData);
}

function map(children, get, meta) {
  let mapped = [];

  if (meta) {
    mapped.push(meta);
  }

  children.forEach((childId) => {
    const child = get(childId);
    const returned = flatify(child, get);

    mapped = mapped.concat(returned);
  });

  return mapped;
}

export function compileText(income, get) {
  const data = [...income];

  return map(data, get);
}
