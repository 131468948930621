import { TProcessingFailureResult, TProcessingSuccessfulResult } from '@ws/shared/types';

import { ErrorHandler } from './ErrorHandler';

export abstract class ResultFormatter<T> extends ErrorHandler {
  protected createFailureResponse(message: string): TProcessingFailureResult {
    return { isOk: false, error: message, value: null };
  }

  protected createSuccessResponse(value: T): TProcessingSuccessfulResult<T> {
    return { isOk: true, error: null, value };
  }
}
