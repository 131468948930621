import { Sidebar } from '@ws/shared/components';
import { FileBlank16, Folder16 } from '@ws/shared/icons';
import { TEntity, TEntityRef } from '@ws/shared/types';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useDrop } from 'react-dnd';
import { useSelector } from 'react-redux';

import { EditabilityContext } from '../../../../context/EditabilityContext';
import { useSpecialDrag } from '../../../../hooks';
import { TAppState } from '../../../../redux';
import { IBreadcrumbsState } from '../../../../redux/breadcrumbs';
import { isCollection } from '../../../../utils/isCollection';
import { DropDivider } from '../Divider';
import { NodePreview } from '../NodePreview';
import { TOnHideButtonClick, TOnNodeClick, TOnWithTitleButtonClick } from '../types';

import { NodeTools } from './NodeTools';
import styles from './RecursiveNodes.module.css';

interface ICollected {
  isOver: boolean;
}

interface IRecursiveNodesProps {
  node: TEntityRef;
  onNodeClick: TOnNodeClick;
  onHideButtonClick: TOnHideButtonClick;
  onWithTitleClick: TOnWithTitleButtonClick;
  depth: number;
}

export function RecursiveNodes({
  node,
  onNodeClick,
  onHideButtonClick,
  onWithTitleClick,
  depth,
}: IRecursiveNodesProps) {
  const editability = useContext(EditabilityContext);
  const breadcrumbs = useSelector((state: TAppState) => state.breadcrumbs);
  const { selected } = useSelector((state: TAppState) => state.editor);
  const currentNode = editability.getNodeByRef(node);

  if (!currentNode) {
    return null;
  }

  return (
    <RecursiveNodesView
      node={currentNode}
      selected={selected}
      depth={depth}
      breadcrumbs={breadcrumbs}
      onNodeClick={onNodeClick}
      onHideButtonClick={onHideButtonClick}
      onWithTitleClick={onWithTitleClick}
    />
  );
}

interface IRecursiveNodesViewProps {
  node: TEntity;
  selected: TEntityRef | null;
  breadcrumbs: IBreadcrumbsState;
  depth: number;
  onNodeClick: TOnNodeClick;
  onHideButtonClick: TOnHideButtonClick;
  onWithTitleClick: TOnWithTitleButtonClick;
}

export function RecursiveNodesView({
  node,
  selected,
  breadcrumbs,
  depth,
  onHideButtonClick,
  onWithTitleClick,
  onNodeClick,
}: IRecursiveNodesViewProps) {
  const [isOpen, setIsOpen] = useState(false);
  const hasChildren = isCollection(node);
  const timeout = useRef<NodeJS.Timeout>();
  const [collected, drag] = useSpecialDrag(node);
  const [collectedProps, targetRef] = useDrop<null, null, ICollected>(
    () => ({
      accept: 'node',
      collect: (monitor) => ({ isOver: monitor.isOver({ shallow: true }) }),
      canDrop: () => false,
    }),
    [],
  );

  const toggleIsOpen = useCallback(() => setIsOpen((prevState) => !prevState), []);

  const openParent = useCallback(() => {
    setIsOpen((prev) => prev === false);
  }, []);

  useEffect(() => {
    if (breadcrumbs?.path?.[depth]?.id === node?.id && selected?.id !== node?.id) {
      setIsOpen(true);
    }
  }, [breadcrumbs.isTargetSearch, breadcrumbs?.path, node?.id, depth, selected?.id]);

  useEffect(() => {
    if (!collectedProps.isOver && timeout.current) {
      clearTimeout(timeout.current);
    }

    if (collectedProps.isOver) {
      timeout.current = setTimeout(() => {
        if (typeof openParent === 'function') {
          openParent();
        }
      }, 500);
    }
  }, [collectedProps.isOver, openParent]);

  return (
    <Sidebar.TreeNode ref={targetRef}>
      <DropDivider parentId={node.parentId} positionBaseRef={node} />

      {collected.isDragging && <NodePreview name={node.meta.name} />}

      <Sidebar.TreeNodeRow
        ref={drag}
        onOpen={toggleIsOpen}
        isOpen={isOpen}
        hasChildren={hasChildren}
        active={selected?.id === node.id}
        className={styles['row']}
      >
        <Sidebar.TreeNodeTitleWrapper>
          <Sidebar.TreeNodeTitle
            width="73%"
            icon={node.type === 'note' ? <FileBlank16 /> : <Folder16 />}
            onClick={onNodeClick(node)}
          >
            {node.meta.name}
          </Sidebar.TreeNodeTitle>

          <Sidebar.TreeNodeTools className={styles['tools']}>
            <NodeTools
              node={node}
              onWithTitleClick={onWithTitleClick}
              onHideButtonClick={onHideButtonClick}
            />
          </Sidebar.TreeNodeTools>
        </Sidebar.TreeNodeTitleWrapper>
      </Sidebar.TreeNodeRow>

      {isOpen && hasChildren && (
        <Sidebar.Tree>
          {node.children.map((item) => (
            <RecursiveNodes
              key={item.id}
              node={item}
              onNodeClick={onNodeClick}
              onHideButtonClick={onHideButtonClick}
              onWithTitleClick={onWithTitleClick}
              depth={depth + 1}
            />
          ))}
          <DropDivider parentId={node.id} positionBaseRef={node} isLast />
        </Sidebar.Tree>
      )}
    </Sidebar.TreeNode>
  );
}
