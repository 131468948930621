import { classes } from 'classifizer';
import { ReactNode, useContext } from 'react';

import { sidebarContext } from '../context';

import styles from './Footer.module.css';

interface IFooterProps {
  children: ReactNode;
}

export function Footer({ children }: IFooterProps) {
  const { isOpen } = useContext(sidebarContext);

  return (
    <footer
      className={classes(
        styles['footer'],
        !isOpen && styles['footer_compressed'],
        isOpen && styles['footer_opened'],
      )}
    >
      {children}
    </footer>
  );
}
