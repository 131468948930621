import { TEntity, TEntityRef } from '@ws/shared/types';
import { useContext } from 'react';
import { ConnectDragPreview, ConnectDragSource, useDrag } from 'react-dnd';

import { EditabilityContext } from '../../context/EditabilityContext';
import { IDropResult } from '../../pages/EditorPage/Sidebar/Divider';
import { getEntityRef } from '../../utils/getEntityRef';

export function useSpecialDrag(node: TEntity): [
  {
    isDragging: boolean;
  },
  ConnectDragSource,
  ConnectDragPreview,
] {
  const { handleDrop } = useContext(EditabilityContext);

  const [collected, drag, dragPreview] = useDrag<
    { ref: TEntityRef },
    IDropResult,
    { isDragging: boolean }
  >(
    () => ({
      type: 'node',
      item: { ref: getEntityRef(node) },
      collect: (monitor) => ({ isDragging: monitor.isDragging() }),
      end(item, monitor) {
        if (!monitor.didDrop()) {
          return;
        }

        const dropResult = monitor.getDropResult();

        if (!dropResult || !dropResult.newParentId) {
          return;
        }

        handleDrop({
          movableNodeRef: item.ref,
          newParentId: dropResult.newParentId,
          positionBaseRef: dropResult.positionBaseRef,
          isLast: dropResult.isLast,
        });
      },
    }),
    [getEntityRef, node],
  );

  return [collected, drag, dragPreview];
}
