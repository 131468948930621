/* eslint-disable max-len */
import * as React from 'react';

import { createIcon, IIconProps, ISVGProps } from '../../internal';

function IconComponent(props: ISVGProps) {
  return (
    <svg
      {...props}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_767_14768)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.3327 2C15.7009 2 15.9993 2.29848 15.9993 2.66667V6.66667C15.9993 7.03486 15.7009 7.33333 15.3327 7.33333H11.3327C10.9645 7.33333 10.666 7.03486 10.666 6.66667C10.666 6.29848 10.9645 6 11.3327 6H14.666V2.66667C14.666 2.29848 14.9645 2 15.3327 2Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 9.33329C0 8.9651 0.298477 8.66663 0.666667 8.66663H4.66667C5.03486 8.66663 5.33333 8.9651 5.33333 9.33329C5.33333 9.70148 5.03486 9.99996 4.66667 9.99996H1.33333V13.3333C1.33333 13.7015 1.03486 14 0.666667 14C0.298477 14 0 13.7015 0 13.3333V9.33329Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.47822 2.87831C8.61278 2.62798 7.69802 2.60097 6.81932 2.79979C5.94061 2.99861 5.12658 3.41678 4.4532 4.01529C3.77982 4.6138 3.26902 5.37314 2.96848 6.22245C2.84565 6.56955 2.4647 6.75135 2.1176 6.62853C1.77051 6.5057 1.5887 6.12475 1.71152 5.77765C2.0872 4.71601 2.7257 3.76684 3.56743 3.0187C4.40915 2.27057 5.42668 1.74785 6.52507 1.49933C7.62346 1.2508 8.7669 1.28457 9.8487 1.59749C10.9271 1.9094 11.9092 2.48856 12.7039 3.28118L15.7899 6.18088C16.0582 6.43301 16.0713 6.85492 15.8192 7.12324C15.567 7.39155 15.1451 7.40468 14.8768 7.15255L11.7835 4.24589C11.7784 4.24112 11.7734 4.23626 11.7685 4.23134C11.1316 3.59413 10.3437 3.12864 9.47822 2.87831ZM0.180833 8.87687C0.432961 8.60855 0.854867 8.59542 1.12319 8.84755L4.21652 11.7542C4.2216 11.759 4.2266 11.7638 4.23152 11.7688C4.86841 12.406 5.65634 12.8715 6.52178 13.1218C7.38723 13.3721 8.30198 13.3991 9.18069 13.2003C10.0594 13.0015 10.8734 12.5833 11.5468 11.9848C12.2202 11.3863 12.731 10.627 13.0315 9.77765C13.1544 9.43055 13.5353 9.24875 13.8824 9.37157C14.2295 9.4944 14.4113 9.87535 14.2885 10.2224C13.9128 11.2841 13.2743 12.2333 12.4326 12.9814C11.5909 13.7295 10.5733 14.2523 9.47493 14.5008C8.37655 14.7493 7.23311 14.7155 6.1513 14.4026C5.07293 14.0907 4.09085 13.5115 3.29607 12.7189L0.210151 9.81922C-0.0581685 9.56709 -0.0712944 9.14519 0.180833 8.87687Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_767_14768">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

IconComponent.displayName = 'RefreshCw';

export const RefreshCw16: React.FunctionComponent<IIconProps> = createIcon(IconComponent);
