import { classes } from 'classifizer';
import { CSSProperties, forwardRef, ReactNode } from 'react';

import { Button } from '../../Button';
import { AngleDown16, AngleRight16 } from '../../Icons';

import styles from './TreeNodeRow.module.css';

interface ITreeNodeTitleProps {
  children: ReactNode;
  isOpen?: boolean;
  onOpen?(): void;
  hasChildren?: boolean;
  active?: boolean;
  style?: CSSProperties;
  className?: string;
}

export const TreeNodeRow = forwardRef<HTMLDivElement, ITreeNodeTitleProps>(
  (
    {
      children,
      isOpen = false,
      onOpen,
      hasChildren = false,
      active,
      style,
      className,
    }: ITreeNodeTitleProps,
    ref,
  ) => {
    return (
      <div
        ref={ref}
        style={style}
        className={classes(styles['container'], active && styles['container_active'], className)}
      >
        <div className={styles['row']}>
          {hasChildren && (
            <Button
              className={styles['row__arrow']}
              variant="shapeless"
              onClick={onOpen}
              icon={isOpen ? <AngleDown16 /> : <AngleRight16 />}
            />
          )}

          {children}
        </div>
      </div>
    );
  },
);

TreeNodeRow.displayName = 'TreeNodeRow';
