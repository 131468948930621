import { TShortProject } from '@ws/shared/types';

import { createApiActionTypes } from '../../utils/createApiActionTypes';

export interface IRemoteState {
  projects: TShortProject[];
  errorMessage: string | null;
  isLoading: boolean;
}

export interface IProjectsGetByUserSuccessPayload {
  projects: TShortProject[];
}

export const PROJECTS_GET_BY_USER = createApiActionTypes('PROJECTS_GET_BY_USER');
