import { createBrowserRouter, Link, Navigate } from 'react-router-dom';

import {
  LOGIN_ROUTE,
  PROJECT_ROUTE,
  PROJECTS_ROUTE,
  ROOT_ROUTE,
  TEST_ROUTE,
  UNDEFINED_ROUTE,
} from '../../constants';
import { AuthPage, EditorPage, ErrorPage, ProjectsPage, TestPage } from '../../pages';

export const router = createBrowserRouter([
  {
    path: ROOT_ROUTE,
    element: <Navigate replace to={PROJECTS_ROUTE} />,
  },
  {
    path: PROJECTS_ROUTE,
    element: <ProjectsPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: TEST_ROUTE,
    element: <TestPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: LOGIN_ROUTE,
    element: <AuthPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: UNDEFINED_ROUTE,
    element: (
      <div>
        <div>Not found</div>
        <Link to={ROOT_ROUTE}>Back Home</Link>
      </div>
    ),
  },
  {
    path: PROJECT_ROUTE,
    element: <EditorPage />,
    errorElement: <ErrorPage />,
  },
]);
