/* eslint-disable max-len */
import * as React from 'react';

import { createIcon, IIconProps, ISVGProps } from '../../internal';

function IconComponent(props: ISVGProps) {
  return (
    <svg
      {...props}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M2.49935 12.5H10.8327C11.0537 12.5 11.2657 12.4122 11.4219 12.2559C11.5782 12.0996 11.666 11.8876 11.666 11.6666C11.666 11.4456 11.5782 11.2337 11.4219 11.0774C11.2657 10.9211 11.0537 10.8333 10.8327 10.8333H2.49935C2.27834 10.8333 2.06637 10.9211 1.91009 11.0774C1.75381 11.2337 1.66602 11.4456 1.66602 11.6666C1.66602 11.8876 1.75381 12.0996 1.91009 12.2559C2.06637 12.4122 2.27834 12.5 2.49935 12.5ZM2.49935 9.16663H10.8327C11.0537 9.16663 11.2657 9.07883 11.4219 8.92255C11.5782 8.76627 11.666 8.55431 11.666 8.33329C11.666 8.11228 11.5782 7.90032 11.4219 7.74404C11.2657 7.58776 11.0537 7.49996 10.8327 7.49996H2.49935C2.27834 7.49996 2.06637 7.58776 1.91009 7.74404C1.75381 7.90032 1.66602 8.11228 1.66602 8.33329C1.66602 8.55431 1.75381 8.76627 1.91009 8.92255C2.06637 9.07883 2.27834 9.16663 2.49935 9.16663ZM2.49935 5.83329H17.4993C17.7204 5.83329 17.9323 5.7455 18.0886 5.58921C18.2449 5.43293 18.3327 5.22097 18.3327 4.99996C18.3327 4.77895 18.2449 4.56698 18.0886 4.4107C17.9323 4.25442 17.7204 4.16663 17.4993 4.16663H2.49935C2.27834 4.16663 2.06637 4.25442 1.91009 4.4107C1.75381 4.56698 1.66602 4.77895 1.66602 4.99996C1.66602 5.22097 1.75381 5.43293 1.91009 5.58921C2.06637 5.7455 2.27834 5.83329 2.49935 5.83329ZM17.4993 14.1666H2.49935C2.27834 14.1666 2.06637 14.2544 1.91009 14.4107C1.75381 14.567 1.66602 14.7789 1.66602 15C1.66602 15.221 1.75381 15.4329 1.91009 15.5892C2.06637 15.7455 2.27834 15.8333 2.49935 15.8333H17.4993C17.7204 15.8333 17.9323 15.7455 18.0886 15.5892C18.2449 15.4329 18.3327 15.221 18.3327 15C18.3327 14.7789 18.2449 14.567 18.0886 14.4107C17.9323 14.2544 17.7204 14.1666 17.4993 14.1666Z"
          fill="currentColor"
        />
        <path
          d="M16.9233 12.0079C17.0056 12.0875 17.1034 12.1493 17.2106 12.1894C17.3179 12.2295 17.4322 12.2471 17.5465 12.241C17.6609 12.235 17.7727 12.2055 17.8751 12.1543C17.9775 12.1031 18.0683 12.0314 18.1418 11.9436C18.2152 11.8558 18.2698 11.7538 18.3021 11.6439C18.3344 11.5341 18.3437 11.4188 18.3295 11.3052C18.3152 11.1916 18.2777 11.0821 18.2193 10.9837C18.1609 10.8852 18.0828 10.7998 17.99 10.7329L17.09 9.98289L17.99 9.23289C18.0743 9.16293 18.1441 9.07701 18.1952 8.98006C18.2463 8.8831 18.2778 8.77701 18.2879 8.66786C18.298 8.55872 18.2864 8.44866 18.2539 8.34399C18.2213 8.23932 18.1685 8.14209 18.0983 8.05789C18.0199 7.96382 17.9218 7.88817 17.8109 7.83632C17.7 7.78447 17.5791 7.75769 17.4566 7.75789C17.2609 7.75984 17.0721 7.83065 16.9233 7.95789L15.2566 9.34122C15.1623 9.41942 15.0863 9.51746 15.0342 9.62838C14.982 9.73929 14.955 9.86033 14.955 9.98289C14.955 10.1054 14.982 10.2265 15.0342 10.3374C15.0863 10.4483 15.1623 10.5464 15.2566 10.6246L16.9233 12.0079Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}

IconComponent.displayName = 'AlignLess';

export const AlignLess20: React.FunctionComponent<IIconProps> = createIcon(IconComponent);
