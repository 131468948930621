import { ReactNode } from 'react';

import { SECTION_ID } from '../../../../constants';

import styles from './WriteBoardTemplate.module.css';

interface IWriteBoardProps {
  contentView: ReactNode;
}

export function WriteBoardTemplate({ contentView }: IWriteBoardProps) {
  return (
    <section id={SECTION_ID.WRITE_BOARD} className={styles['write-board']}>
      <div className={styles['write-board__content']}>{contentView}</div>
    </section>
  );
}
