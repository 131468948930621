import { EMcsName } from '../../utils';

const REVIEWS_ENDPOINT_BASE = 'v1/review';

export const REVIEW_CREATE_ENDPOINT_CONF = {
  base: `${REVIEWS_ENDPOINT_BASE}/create-one`,
  mcsName: EMcsName.PREVIEWS,
  access: 'private',
};

interface ReviewByIdConfParams {
  reviewId: string;
}

export const BUILD_REVIEW_BY_ID_ENDPOINT_CONF = ({ reviewId }: ReviewByIdConfParams) => ({
  base: `${REVIEWS_ENDPOINT_BASE}/${reviewId}/get-one`,
  mcsName: EMcsName.PREVIEWS,
  access: 'private',
});

interface ReviewListPreviewIdConf {
  previewId: string;
}

export const BUILD_REVIEW_LIST_BY_PREVIEW_ID_ENDPOINT_CONF = ({
  previewId,
}: ReviewListPreviewIdConf) => ({
  base: `${REVIEWS_ENDPOINT_BASE}/by-preview/${previewId}/get-list`,
  mcsName: EMcsName.PREVIEWS,
  access: 'private',
});

interface PreviewListByEntityIdConf {
  entityId: string;
}

export const BUILD_REVIEW_LIST_BY_ENTITY_ID_ENDPOINT_CONF = ({
  entityId,
}: PreviewListByEntityIdConf) => ({
  base: `${REVIEWS_ENDPOINT_BASE}/by-entity/${entityId}/get-list`,
  mcsName: EMcsName.PREVIEWS,
  access: 'private',
});
