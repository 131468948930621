import { Button, Spinner } from '@ws/shared/components';
import { Close20 } from '@ws/shared/icons';
import { TCompiled, TPreview } from '@ws/shared/types';

import { EmptyPreviews } from '../EmptyPreviews';
import { PreviewList } from '../PreviewList';

import styles from './PreviewsComponent.module.css';

// const filterOptions = [
//   { value: 1, label: 'Все предпоказы этого проекта' },
//   { value: 2, label: 'Все предпоказы этой заметки/коллекции' },
// ];

interface IPreviewsComponentProps {
  previews: TPreview[];
  compiled: TCompiled;
  onClose(): void;
  onCreatePreview(): void;
  filter: number;
  onFilter(newFilter: number): void;
  isLoading: boolean;
}

export function PreviewsComponent({
  previews,
  compiled,
  onClose,
  onCreatePreview,
  // onFilter,
  // filter,
  isLoading,
}: IPreviewsComponentProps) {
  return (
    <div className={styles['previews']}>
      <div className={styles['previews__header']}>
        <h4 className={styles['previews__title']}>Предпоказы</h4>

        <Button
          className={styles['previews__close']}
          onClick={onClose}
          variant="shapeless"
          icon={<Close20 />}
          size="m"
          disabled={isLoading}
        />

        <Button className={styles['previews__new']} onClick={onCreatePreview} disabled={isLoading}>
          Создать новый предпоказ
        </Button>

        {/*<Select*/}
        {/*  className={styles['previews__filter']}*/}
        {/*  value={filter}*/}
        {/*  onChange={onFilter}*/}
        {/*  before={<Filter16 />}*/}
        {/*  placeholder="Все предпоказы этого проекта"*/}
        {/*  options={filterOptions}*/}
        {/*  isDisabled={isLoading}*/}
        {/*/>*/}
      </div>

      {isLoading ? (
        <Spinner />
      ) : previews.length === 0 ? (
        <EmptyPreviews />
      ) : (
        <PreviewList list={previews} currentHash={compiled.hash} />
      )}
    </div>
  );
}
