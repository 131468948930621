import { useId } from '@floating-ui/react';
import { forwardRef, HTMLProps, useLayoutEffect } from 'react';

import { usePopoverContext } from '../context';

interface IDescriptionProps extends HTMLProps<HTMLParagraphElement> {
  width?: string;
  maxWidth?: string;
}

export const Description = forwardRef<HTMLParagraphElement, IDescriptionProps>(
  function PopoverDescription(props, ref) {
    const { setDescriptionId } = usePopoverContext();
    const id = useId();

    // Only sets `aria-describedby` on the Popover root element
    // if this component is mounted inside it.
    useLayoutEffect(() => {
      setDescriptionId(id);
      return () => setDescriptionId(undefined);
    }, [id, setDescriptionId]);

    return (
      <p
        {...props}
        style={{ width: props.width, maxWidth: props.maxWidth, ...props.style }}
        ref={ref}
        id={id}
      />
    );
  },
);
